<template>
  <div>
    <!-- content : 화면 리사이징 되는 화면 resize  -->
    <div class="content_box beforetab">
      <!-- content_box : 탭있을 경우 beforetab  추가 -->
      <div class="border_bottom_gray_box border_bottom_none">
        <table class="tbl_search">
          <colgroup>
            <col width="10%">
            <col width="35%">
            <col width="10%">
            <col width="35%">
            <col width="10%">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.MAIN100.006') }}</th><!-- 선박 -->
              <td>
                <!-- <input type="text" id="" name="" placeholder="선박명 입력(영어)"> -->
                <e-auto-complete-vessel @change="change1" :ctr-cd="ctrCd" :cd-nm="vesselNm" id="vesselNm" />
              </td>
              <th><span class="ml20">{{ $t('msg.MAIN100.019') }}</span></th><!-- 항차 -->
              <td>
                <input type="text" id="vslVoy" v-model="vslVoy" @input="vslVoy=vslVoy.toUpperCase()">
              </td>
              <td class="text_right">
                <a class="button blue sh" @click="getVesselSchedule()">{{ $t('msg.CMBA100.00120') }}<!-- 검색 --></a>
              </td>
            </tr>
            <!-- <tr>
              <th>{{ $t('msg.VOSD100.053') }}</th> 조회년월일
              <td colspan="2">
                <span>
                  <div class="input_calendar">
                    <e-date-picker v-model="yyyymmdd" />
                  </div>
                </span>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div><!-- content_box // -->
    <div class="content_box mt10" v-show="vesselScheduleList.length !== 0">
      <!-- content_box -->
      <table class="tbl_col mt10">
        <colgroup>
          <col width="13%">
          <col width="18%">
          <col width="30%">
          <col width="5%">
          <col width="16%">
          <col width="16%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.VOSD100_M3.004') }}</th><!-- 지역 -->
            <th>{{ $t('msg.VOSD100_M3.005') }}</th><!-- 항구 -->
            <th>{{ $t('msg.VOSD100_M3.006') }}</th><!-- Terminal -->
            <th>Skip</th><!-- Skip -->
            <th>{{ $t('msg.VOSD100_M3.007') }}</th><!-- 수입 & 입항일 -->
            <th>{{ $t('msg.VOSD100_M3.008') }}</th><!-- 수출 & 출항일 -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list,index) in vesselScheduleList" :key="index">
            <td :class="list.skpYn === 'Y' ? 'color_red' : ''">{{ list.ctrNm }}</td>
            <td :class="list.skpYn === 'Y' ? 'color_red' : ''">{{ list.portNm }}</td>
            <td :class="list.skpYn === 'Y' ? 'color_red' : ''">{{ list.trmlEnm }}</td>
            <td :class="list.skpYn === 'Y' ? 'color_red' : ''">{{ list.skpYn }}</td>
            <td v-if="list.skpYn === 'Y'"></td>
            <td v-if="list.skpYn === 'Y'"></td>
            <td v-if="voyOrNot === 'N' && list.skpYn !== 'Y'">{{ list.etaWdayCd }}, {{ list.etaTm.substring(0, 2) }}:{{ list.etaTm.substring(2, 4) }}</td>
            <td v-if="voyOrNot === 'N' && list.skpYn !== 'Y'">{{ list.etdWdayCd }}, {{ list.etdTm.substring(0, 2) }}:{{ list.etdTm.substring(2, 4) }}</td>
            <td v-if="voyOrNot === 'Y' && list.skpYn !== 'Y'">{{ changeDate2Display(list.apoDt, list.apoTm, lang) }}</td>
            <td v-if="voyOrNot === 'Y' && list.skpYn !== 'Y'">{{ changeDate2Display(list.dpoDt, list.dpoTm, lang) }}</td>
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->
  </div><!-- content // -->
</template>

<script>
import { rootComputed } from '@/store/helpers'
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'

export default {
  name: 'ScheduleVessel',
  computed: {
    ...rootComputed
  },
  components: {
    EAutoCompleteVessel: () => import('@/components/common/EAutoCompleteVessel')
  },
  watch: {
    yyyymmdd () {
      console.log('>>>> [Vessel] yyyymmdd : ' + this.yyyymmdd)
    }
  },
  created () {
    // this.init()
    // console.log('$$$$$$$$$$$$ [Vessel] auth $$$$$$$$$$$$')
    // console.log(this.auth)
    // console.log('$$$$$$$$ [Vessel] memberDetail $$$$$$$$')
    // console.log(this.memberDetail)
    /*
    const date = new Date()
    this.year = date.getFullYear()
    this.month = date.getMonth() + 1
    this.day = date.getDate()
    if (this.month < 10) {
      this.month = '0' + this.month
    }
    if (this.day < 10) {
      this.day = '0' + this.day
    }
    this.yyyymmdd = this.year + '.' + this.month + '.' + this.day
    */
    this.lang = this.auth.serviceLang
  },
  data: function () {
    return {
      lang: 'KOR',
      ctrCd: 'KR',
      vesselNm: '',
      vesselCd: '',
      vslVoy: '',
      yyyymmdd: '',
      year: '',
      month: '',
      day: '',
      vesselScheduleList: [],
      voyOrNot: 'N'
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
     })
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  methods: {
    handleKeydown (e) {
      if (e.key === 'Enter' && $('.tab ul li:eq(1)').hasClass('on')) {
        this.getVesselSchedule()
      }
    },
    change1 (vo) {
      this.vesselNm = vo.cdNm
      this.vesselCd = vo.cd
    },
    getVesselSchedule () {
      let isOk = true
      const elemVesselNm = document.querySelector('#vesselNm')
      this.$ekmtcCommon.hideErrorTooltip(elemVesselNm)
      if (this.$ekmtcCommon.isEmpty(this.vesselCd)) {
        this.$ekmtcCommon.showErrorTooltip(elemVesselNm, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      const elemVslVoy = document.querySelector('#vslVoy')
      this.$ekmtcCommon.hideErrorTooltip(elemVslVoy)
      if (this.$ekmtcCommon.isEmpty(this.vslVoy)) {
        this.$ekmtcCommon.showErrorTooltip(elemVslVoy, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      if (!isOk) {
        return
      }

      const params = {
        vslNm: this.vesselNm,
        vslCd: this.vesselCd,
        //searchDate: this.yyyymmdd.replaceAll('.', ''),
        vslVoy: this.vslVoy
      }
      vslScheduleInfo.getVesselSchedule(params).then((rtnData) => {
        // console.log('######### getVesselSchedule #########')
        // console.log(rtnData)
        this.vesselScheduleList = rtnData.data.vesselScheduleList
        this.voyOrNot = rtnData.data.isVoy
        if (this.vesselScheduleList === undefined || this.vesselScheduleList.length === 0) {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
        }
      })
    },
    changeDate2Display (strDate, strTime, lang) {
      var rtnStrDate = ''
      if (strDate === null || strDate === '') {
        rtnStrDate = ''
      } else {
        if (lang === 'ENG') {
          if (strDate.substring(4, 6) === '01') {
            rtnStrDate = strDate.substring(0, 4) + '.Jan.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '02') {
            rtnStrDate = strDate.substring(0, 4) + '.Feb.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '03') {
            rtnStrDate = strDate.substring(0, 4) + '.Mar.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '04') {
            rtnStrDate = strDate.substring(0, 4) + '.Apr.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '05') {
            rtnStrDate = strDate.substring(0, 4) + '.May.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '06') {
            rtnStrDate = strDate.substring(0, 4) + '.Jun.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '07') {
            rtnStrDate = strDate.substring(0, 4) + '.Jul.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '08') {
            rtnStrDate = strDate.substring(0, 4) + '.Aug.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '09') {
            rtnStrDate = strDate.substring(0, 4) + '.Sep.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '10') {
            rtnStrDate = strDate.substring(0, 4) + '.Oct.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '11') {
            rtnStrDate = strDate.substring(0, 4) + '.Nov.' + strDate.substring(6, 8)
          } else {
            rtnStrDate = strDate.substring(0, 4) + '.Dec.' + strDate.substring(6, 8)
          }
        } else {
          rtnStrDate = strDate.substring(0, 4) + '.' + strDate.substring(4, 6) + '.' + strDate.substring(6, 8)
        }
      }
      if (strTime === null || strTime === '') {
        rtnStrDate = ''
      } else {
        rtnStrDate = rtnStrDate + ' ' + strTime.substring(0, 2) + ':' + strTime.substring(2, 4)
      }
      return rtnStrDate
    }
  }
}

</script>

<style scoped>
  .tbl_search th {vertical-align: top;}
</style>
