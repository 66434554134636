<template>
  <div class="position_relative">
    <template v-if="isChar">
      <textarea ref="textareaTransAddr"
                :id="id"
                :name="name"
                :maxlength="maxLength"
                v-model="text"
                @blur="onInput($event)"
                @input="onInput1($event)"
      ></textarea>
    </template>
    <template v-else>
      <textarea ref="textareaTransAddr"
                :id="id"
                :name="name"
                :maxlength="maxLength"
                v-model="text"
                @blur="onInput($event)"
                @input="onInput1($event)"
      ></textarea>
    </template>
    <!-- <div class="div_req_byte">
      <span class="color_black font_medium" :id="'byte_' + id">0</span>
      <span class="color_lightgray">/</span>
      <span class="color_lightgray">2,000</span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'ETransAddressTextarea',
  props: {
    id: { type: String, required: true },
    isRequired: { type: Boolean, default: false, required: false },
    messageRequired: { type: String, default: '', required: false },
    maxLine: { type: Number, default: 0, required: false },
    maxLength: { type: Number, default: 6000, required: false },
    columnLength: { type: Number, default: 0, required: false },
    name: { type: String, default: '', required: false },
    value: { type: String, default: '', required: false },
    isChar: { type: Boolean, default: false, required: false }
  },
  data () {
    return {
      elemId: '',
      text: '',
      rtnText: '',
      rtnNumber: 0,
      interval: undefined,
      isOk: true
    }
  },
  watch: {
    value () {
      if (this.text !== this.$props.value) {
        this.text = this.$props.value
        this.check(this.text)
      }
    }
  },
  created () {
  },
  methods: {
    onInput1 (e) {
      let s = e.target.value

      if (s.split('\n').length > this.maxLine) { // MaxLine 체크
        s = s.split('\n').slice(0, this.maxLine).join('\n')
        e.target.value = s
        this.text = s
        const elem = document.querySelector('#' + this.id)
        this.$ekmtcCommon.showErrorTooltip(elem, this.maxLine + this.$t('js.utillmanager.010')) // 줄 까지 입력 가능합니다.
      }
    },
    onInput (e) {
      let val = e.target.value.toUpperCase()

      this.check(val, e)
    },
    async check (val, e) {
      const arr = []
      let cnt = 0
      let str
      var lineCnt = 0
      let colOverCnt = 0
      let c
      let isOverRow = false
      //특수 문자 체크 부분 isChar 부분으로 관리
      if (this.isChar) {
        for (let i = 0; i < val.length; i++) {
          str = val.charAt(i)
          c = val.charCodeAt(i)
          //if ('?`$_|{}[]!^\\"\\='.indexOf(str) < 0 && ((c >= 0 && c <= 91) || (c >= 93 && c <= 127))) {
          if (c >= 0 && c <= 127) {
            arr.push('' + str)
          } else {
            cnt++
          }
        }
      } else {
        for (let i = 0; i < val.length; i++) {
          c = val.charCodeAt(i)
          if (c >= 0 && c <= 127) {
            arr.push('' + val.charAt(i))
          } else {
            cnt++
          }
        }
      }

      const THIS = this
      const elem = document.querySelector('#' + this.id)

      if (cnt > 0) {
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('js.common.007')) // 영문만 입력해주세요.

        e.target.value = arr.join('')
        this.text = arr.join('')
        this.$emit('input', arr.join(''))

        if (this.interval !== undefined) {
          clearInterval(this.interval)
          this.interval = undefined
        }

        this.interval = setInterval(() => {
          THIS.$ekmtcCommon.hideErrorTooltip(elem)
          clearInterval(this.interval)
          this.interval = undefined
        }, 3000)

        return
      }

      if (val === '') {
        lineCnt = 0
      } else if (val.indexOf('\n') > -1) {
        const arr = val.replace(/\r/gi, '').split('\n')
        lineCnt = arr.length
        if (this.columnLength > 0) {
          const tempArr = []
          //라인 입력 isChar 부분으로 관리 아예 입력 못하게 막아버림
          if (this.isChar) {
            arr.some((str, idx) => {
              if (this.maxLine > idx) {
                while (str.length > this.columnLength) {
                  tempArr.push(str.substring(0, this.columnLength))
                  str = str.substring(this.columnLength)
                }
                tempArr.push(str)
              } else {
                return true
              }
            })
          } else {
              arr.forEach(str => {
                while (str.length > this.columnLength) {
                  tempArr.push(str.substring(0, this.columnLength))
                  str = str.substring(this.columnLength)
                }
                tempArr.push(str)
              })
          }
          val = tempArr.join('\r\n')
          lineCnt = tempArr.length
        }
      } else {
        lineCnt = 1
        // 한 Line 에 columnLength 초과 될경우 줄바꿈 처리
        if (this.columnLength > 0 && val.length > this.columnLength) {
          const tempArr = []
          while (val.length > this.columnLength) {
            tempArr.push(val.substring(0, this.columnLength))
            val = val.substring(this.columnLength)
          }
          tempArr.push(val)
          val = tempArr.join('\n')
          lineCnt = tempArr.length
        }
      }

      let arrVal = val.replace(/\r/gi, '').split('\n')

      if (arrVal.length > this.maxLine) {
        const options = {
          useConfirmBtn: true,
          message: this.$t('js.utillmanager.021', { maxLine: this.maxLine }),
          onConfirmSelf: (resolve, $Simplert) => {
            const tempArr = []

            arrVal.some((str, idx) => {
              if (this.maxLine > idx) {
                while (str.length > this.columnLength) {
                  tempArr.push(str.substring(0, this.columnLength))
                  str = str.substring(this.columnLength)
                }
                tempArr.push(str)
              } else {
                return true
              }
            })

            val = tempArr.join('\r\n')
            lineCnt = tempArr.length

            resolve(true)
            $Simplert.close()
          }
        }

        if (!await this.$ekmtcCommon.asyncAlertMessage(options)) {
          return
        }
      }

      let isOk = true
      let msg = ''

      if (this.isRequired && val === '') {
        isOk = false
        msg = this.messageRequired
      } else if (this.maxLine > 0 && lineCnt > this.maxLine) {
        isOk = false
        msg = this.$t('js.utillmanager.010', { maxLine: this.maxLine })
        console.log('maxLine @@@@ ')
      } else if (!this.$ekmtcCommon.checkTextByte(val, 2000)) {
        isOk = false
        msg = 'Too large text to create S/R.'
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      if (e !== undefined) {
        e.target.value = val
      }

      this.isOk = isOk
      this.text = val
      this.$emit('input', val)
    },
    isValid () {
      if (this.isOk && this.isRequired && this.text === '') {
        this.check(this.text)
        return false
      }
      return this.isOk
    }
  }
}
</script>

<style scoped>
  .div_req_byte {text-align:right; margin-top:2px;}
  textarea {
  font-family: 'Courier New';
  /* padding-right: calc(100% - 206px); */
  padding-top: 2px;
  padding-bottom: 2px;
  box-sizing: border-box;
  text-align: start;
  writing-mode: horizontal-tb !important;
  /* resize: none; */
  line-height: normal;
}
</style>
