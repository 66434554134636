<template>
  <div class="content resize">
    <a @click="clickEvent">상세이동</a>
  </div>
</template>

<script>
export default {
  name: 'WorkingPickup',
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    clickEvent () {
      const TH = this
      TH.$router.push({ name: 'pickup-detail', params: { detailBkgNo: 'KR02955705' } }).catch(() => {})
    }
  }
}
</script>
