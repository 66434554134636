<template>
  <div class="select_opt_area" :id="'flexi_area_' + cntrSeq">
    <!-- Case03. Flexi Bag 시작 -->
    <div class="select_opt_area mt10">
      <table class="tbl_col t2">
        <colgroup>
          <col style="width:25%">
          <col style="width:25%">
          <col style="width:25%">
          <col style="width:auto">
        </colgroup>
        <tbody>
          <tr>
            <th colspan="2" scope="col">Commodity</th>
            <th colspan="2" scope="col">COA-Supplied Name</th>
          </tr>
          <tr>
            <td colspan="2">
              <input
                :value="flexiInfo.cmdtDsc"
                :id="'cmdtDsc_' + cntrSeq"
                type="text"
                @input="checkValidationFlexi('cmdtDsc', $event)"
              >
            </td>
            <td colspan="2">
              <input
                :value="flexiInfo.mfcoNm"
                :id="'mfcoNm_' + cntrSeq"
                type="text"
                @input="checkValidationFlexi('mfcoNm', $event)"
              >
            </td>
          </tr>
          <tr>
            <th scope="col">
              Hazardous
            </th>
            <th scope="col">
              Marine Pollutant
            </th>
            <th scope="col">
              New Flexible or Not
            </th>
            <th scope="col">
              Flash Point
            </th>
          </tr>
          <tr>
            <td>
              <select
                :value="flexiInfo.dgYn"
                @change="changeDgYn('dgYn', $event)"
              >
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
            <td>
              <select
                :value="flexiInfo.mpltYn"
                @change="changeDgYn('mpltYn', $event)"
              >
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
            <td>
              <select
                :value="flexiInfo.newFbYn"
                @change="changeNewFbYn"
              >
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
            <td>
              <e-input-number
                :value="flexiInfo.flsTmpr"
                :class="flexiInfo.flsTmpr !== '' && flexiInfo.flsTmpr < 60 ? 'bg_pk col_9' : 'col_9'"
                @input="handleInput('flsTmpr', $event)"
                maxlength="3"
              >
              </e-input-number> ℃
            </td>
          </tr>
          <tr>
            <th colspan="2" scope="col">
              Flexibag MAX. Loadable Capacity
              <span class="position_relative span_tooltip">
                <button
                  type="button"
                  class="tbl_icon help"
                  @click="$ekmtcCommon.showTooltip($event)"
                >도움말</button>
                <div class="tooltip_wrap short position_absolute"><!-- tooltip_wrap   -->
                  <div class="cont"><!-- cont -->
                    <button
                      type="button"
                      class="close"
                      @click="$ekmtcCommon.hideTooltip($event)"
                    >close</button>
                    <ul class="bul_list_sm t2">
                      <li>
                        {{ $t('msg.CSBK100.141') }}
                      </li>
                    </ul>
                  </div><!-- cont -->
                </div><!-- tooltip_wrap // -->
              </span>
            </th>
            <th scope="col">
              Actual Loading Volume
              <span class="position_relative span_tooltip">
                <button
                  type="button"
                  class="tbl_icon help"
                  @click="$ekmtcCommon.showTooltip($event)"
                >도움말</button>
                <div class="tooltip_wrap short position_absolute"><!-- tooltip_wrap   -->
                  <div class="cont"><!-- cont -->
                    <button
                      type="button"
                      class="close"
                      @click="$ekmtcCommon.hideTooltip($event)"
                    >close</button>
                    <ul class="bul_list_sm t2">
                      <li>
                        {{ $t('msg.CSBK100.138') }}<br>
                        {{ $t('msg.CSBK100.139') }}
                      </li>
                    </ul>
                  </div><!-- cont -->
                </div><!-- tooltip_wrap // -->
              </span>
            </th>
            <th scope="col">Loading Ratio</th>
          </tr>
          <tr>
            <td colspan="2">
              <select
                :value="flexiInfo.fbCapa"
                class="col_8"
                @change="fnFbWeightChk('fbCapa', $event.target.value)"
              >
                <option value="14000">14,000</option>
                <option value="16000">16,000</option>
                <option value="17500">17,500 </option>
                <option value="18000">18,000</option>
                <option value="19000">19,000</option>
                <option value="20000">20,000</option>
                <option value="21000">21,000</option>
                <option value="22000">22,000</option>
                <option value="23000">23,000 </option>
                <option value="24000">24,000</option>
              </select>
              liter
            </td>
            <td>
              <e-input-number
                :value="flexiInfo.fbGrsWt"
                input-class="col_8"
                :is-comma="true"
                :id="'fbGrsWt_' + cntrSeq"
                @input="fnFbWeightChk('fbGrsWt', $event)"
                maxlength="12"
              >
              </e-input-number>
              liter
            </td>
            <td>
              <input
                :id="'perMax_' + cntrSeq"
                v-model="flexiInfo.perMax"
                type="text"
                class="col_8"
                readonly
              >
            </td>
          </tr>
          <tr>
            <th colspan="1" scope="col">Insurance Cert' Start</th>
            <th colspan="1" scope="col">Insurance Cert' End</th>
            <th colspan="1" scope="col">COA membership Cert Start</th>
            <th colspan="1" scope="col">COA membership Cert End</th>
          </tr>
          <tr>
            <td colspan="1">
              <e-date-picker
                v-model="flexiInfo.insrCertStrDt"
                :id="'insrCertStrDt_' + cntrSeq"
                @input="checkValidationFlexi('insrCertStrDt', $event)"
                class="margin-left"
              >
              </e-date-picker>
              <!-- <input
                :value="flexiInfo.insrCertStrDt"
                :id="'insrCertStrDt_' + cntrSeq"
                type="text"
                @input="checkValidationFlexi('insrCertStrDt', $event)"
                placeholder="ex) YYYYMMDD"
                maxlength="10"
              > -->
            </td>
            <td colspan="1">
              <e-date-picker
                v-model="flexiInfo.insrCertEndDt"
                :id="'insrCertEndDt_' + cntrSeq"
                @input="checkValidationFlexi('insrCertEndDt', $event)"
                class="margin-left"
              >
              </e-date-picker>
              <!-- <input
                :value="flexiInfo.insrCertEndDt"
                :id="'insrCertEndDt_' + cntrSeq"
                type="text"
                @input="checkValidationFlexi('insrCertEndDt', $event)"
                placeholder="ex) YYYYMMDD"
                maxlength="10"
              > -->
            </td>
            <td colspan="1">
              <e-date-picker
                v-model="flexiInfo.coaCertStrDt"
                :id="'coaCertStrDt_' + cntrSeq"
                @input="checkValidationFlexi('coaCertStrDt', $event)"
                class="margin-left"
              >
              </e-date-picker>
              <!-- <input
                :value="flexiInfo.coaCertStrDt"
                :id="'coaCertStrDt_' + cntrSeq"
                type="text"
                @input="checkValidationFlexi('coaCertStrDt', $event)"
                placeholder="ex) YYYYMMDD"
                maxlength="10"
              > -->
            </td>
            <td colspan="1">
              <e-date-picker
                v-model="flexiInfo.coaCertEndDt"
                :id="'coaCertEndDt_' + cntrSeq"
                @input="checkValidationFlexi('coaCertEndDt', $event)"
                class="margin-left"
              >
              </e-date-picker>
              <!-- <input
                :value="flexiInfo.coaCertEndDt"
                :id="'coaCertEndDt_' + cntrSeq"
                type="text"
                @input="checkValidationFlexi('coaCertEndDt', $event)"
                placeholder="ex) YYYYMMDD"
                maxlength="10"
              > -->
            </td>
          </tr>
          <tr>
            <th colspan="1" scope="col">Rail Impact Test Report Date</th>
            <th colspan="1" scope="col">Specific gravity</th>
            <th colspan="2" scope="col"></th>
          </tr>
          <tr>
            <td colspan="1">
              <e-date-picker
                v-model="flexiInfo.railTestDt"
                :id="'railTestDt_' + cntrSeq"
                @input="checkValidationFlexi('railTestDt', $event)"
                class="margin-left"
              >
              </e-date-picker>
              <!-- <input
                :value="flexiInfo.railTestDt"
                :id="'railTestDt_' + cntrSeq"
                type="text"
                @input="checkValidationFlexi('railTestDt', $event)"
                placeholder="ex) YYYYMMDD"
                maxlength="10"
              > -->
            </td>
            <td colspan="1">
              <e-input-number
                v-model="flexiInfo.spcfcGrvt"
                input-class="col_8"
                :id="'spcfcGrvt' + cntrSeq"
                :point="2"
                :digit-max="3"
                @input="handleInput('spcfcGrvt', $event)"
              >
              </e-input-number>
            </td>
            <td colspan="2">
            </td>
          </tr>
        </tbody>
        <tbody v-if="saveFlag !== 'PICKUP'">
          <tr>
            <th scope="row">
              File Upload
            </th>
            <td colspan="3">
              <div class="filebox">
                <a
                  @click="uploadPopup()"
                  :class="$ekmtcCommon.isEmpty(flexiInfo.reqRno) ? 'button sm gray file' : 'button sm blue'"
                >
                  {{ $t('msg.CSBK100.205') }}
                </a>
                <a
                  v-if="loiDownYn === 'Y'"
                  @click="downloadLOI()"
                  :class="$ekmtcCommon.isEmpty(parentInfo.bkgNo) ? 'button sm gray file' : 'button sm blue'"
                  style="margin-left: 10px;"
                >
                  {{ $t('msg.NEWB010P140.005') }}
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">
              Request Remark
            </th>
            <td colspan="3">
              <textarea
                :value="flexiInfo.reqConts"
                @input="setContainer('reqConts', $event.target.value)"
              />
            </td>
          </tr>
          <tr>
            <th scope="row">status</th>
            <td>
              <span class="span_scg">{{ flexiInfo.apvStsNm }}</span>
            </td>
            <th scope="row">Ref No.</th>
            <td>
              <span class="span_scg">{{ flexiInfo.scgAppNo }}</span>
            </td>
          </tr>
          <tr>
            <th scope="row">
              KMTC Remark
            </th>
            <td colspan="3">
              <textarea
                class="txt_border_none"
                :value="flexiInfo.apvCont"
                readonly
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="desc_list">
        <ul>
          <li>{{ $t('msg.CSBK100.211') }}</li>
          <li>{{ $t('msg.CSBK100.212') }}<br>{{ $t('msg.CSBK100.213') }}</li>
          <li>{{ $t('msg.CSBK100.512') }}</li>
        </ul>
      </div>
    </div>
    <!-- Case03. Flexi Bag 끝 -->
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePoup"
        @selectFunc="selectFunc"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import EInputNumber from '@/components/common/EInputNumber.vue'
import bookings from '@/api/rest/trans/bookings'
import EDatePicker from '@/components/common/EDatePicker'

export default {
  name: 'BookingTableFlaxi',
  components: {
    EInputNumber,
    EDatePicker,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'dg-oog-certi-pop': () => import('@/pages/trans/popup/DgOogCertiPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: null
    },
    lang: {
      type: String,
      default: ''
    },
    saveFlag: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: null
    },
    loiDownYn: {
      type: String,
      default: 'Y'
    }
  },
  data () {
    return {
      popParams: {},
      selectFunc: null,
      customComponent: null,
      cntrSeq: '',
      flexiInfo: {
        cmdtDsc: '',
        mfcoNm: '',
        dgYn: 'N',
        mpltYn: 'N',
        newFbYn: 'Y',
        flsTmpr: '',
        fbCapa: '24000',
        fbGrsWt: '',
        perMax: '',
        reqRno: '',
        flexiCont: '',
        insrCertStrDt: '',
        insrCertEndDt: '',
        coaCertStrDt: '',
        coaCertEndDt: '',
        railTestDt: '',
        spcfcGrvt: ''
      }
    }
  },
  watch: {
    async parentInfo () {
      await this.initParams()
    }
  },
  created () {
    this.initParams()
  },
  methods: {
    handleInput (key, payload) {
      console.log('@@@@ key: ', key)
      console.log('@@@@ payload: ', payload)

      this.setContainer(key, payload)
    },
    initParams () {
      console.log('@@@@ this.parentInfo.fbGrsWt: ', this.parentInfo.fbGrsWt)
      console.log('@@@@ this.parentInfo.fbCapa: ', this.parentInfo.fbCapa)
      this.flexiInfo = {
        cmdtDsc: this.parentInfo.cmdtDsc || '',
        mfcoNm: this.parentInfo.mfcoNm || '',
        dgYn: this.parentInfo.dgYn || '',
        mpltYn: this.parentInfo.mpltYn || '',
        newFbYn: this.parentInfo.newFbYn || '',
        flsTmpr: this.parentInfo.flsTmpr || '',
        fbCapa: this.parentInfo.fbCapa || '',
        fbGrsWt: this.parentInfo.fbGrsWt || '',
        // perMax: this.parentInfo.perMax || '',
        perMax: this.calcPerMax(),
        apvCont: this.parentInfo.apvCont || '',
        reqConts: this.parentInfo.reqConts || '',
        reqRno: this.parentInfo.reqRno || '',
        apvStsCd: this.parentInfo.apvStsCd || '',
        apvStsNm: this.parentInfo.apvStsNm || '',
        scgAppNo: this.parentInfo.scgAppNo || '',
        insrCertStrDt: this.parentInfo.insrCertStrDt || '',
        insrCertEndDt: this.parentInfo.insrCertEndDt || '',
        coaCertStrDt: this.parentInfo.coaCertStrDt || '',
        coaCertEndDt: this.parentInfo.coaCertEndDt || '',
        railTestDt: this.parentInfo.railTestDt || '',
        spcfcGrvt: this.parentInfo.spcfcGrvt || ''
      }

      this.cntrSeq = this.parentInfo.cntrSeq
      console.log('@@@@ this.flexiInfo.perMax: ', this.flexiInfo.perMax)

      // default값 세팅
      if (this.$ekmtcCommon.isEmpty(this.parentInfo.dgYn)) {
        this.setValueAndVuexApply('dgYn', 'N')
      }
      if (this.$ekmtcCommon.isEmpty(this.parentInfo.mpltYn)) {
        this.setValueAndVuexApply('mpltYn', 'N')
      }
      if (this.$ekmtcCommon.isEmpty(this.parentInfo.newFbYn)) {
        this.setValueAndVuexApply('newFbYn', 'Y')
      }
      if (this.$ekmtcCommon.isEmpty(this.parentInfo.fbCapa)) {
        this.setValueAndVuexApply('fbCapa', '24000')
      }

      this.$nextTick(() => {
        if (this.flexiInfo.perMax !== '' && (this.flexiInfo.perMax < 95 || this.flexiInfo.perMax >= 105)) {
          document.querySelector('#perMax_' + this.cntrSeq).className = 'bg_pk col_8'
        } else {
          document.querySelector('#perMax_' + this.cntrSeq).className = 'col_8'
        }
      })

      // if (this.$ekmtcCommon.isEmpty(this.parentInfo.perMax) && this.$ekmtcCommon.isNotEmpty(this.parentInfo.fbGrsWt)) {
      //   this.$nextTick(() => {
      //     this.fnFbWeightChk()
      //   })
      // }
    },
    setValueAndVuexApply (key, value) { // 값을 변경후 Vuex에 적용한다
      this.flexiInfo[key] = value
      this.setContainer(key, value)
    },
    calcPerMax () {
      let s = ''
      if (this.parentInfo.fbGrsWt !== undefined && isNaN(Number(this.parentInfo.fbGrsWt)) === false) {
        s = Math.round(Number(this.parentInfo.fbGrsWt) / (this.parentInfo.fbCapa ? this.parentInfo.fbCapa : '24000') * 1000) / 10.0
      }
      return s
    },
    checkValidationFlexiAll () {
      console.log('@@@@ enter checkValidationFlexiAll')
      const prefix = ['cmdtDsc', 'mfcoNm', 'fbGrsWt', 'insrCertStrDt', 'insrCertEndDt', 'coaCertStrDt', 'coaCertEndDt']

      let isOk = true
      let failCnt = 0
      prefix.forEach(el => {
        if (!this.checkValidationFlexi(el)) {
          failCnt++
        }
      })

      if (failCnt > 0) {
        isOk = false
      }

      return isOk
    },
    checkValidationFlexi (prefix, payload) {
      console.log('@@@@ prefix: ', prefix)
      console.log('@@@@ payload: ', payload)
      let _payload = ''

      switch (prefix) {
        case 'mfcoNm':
        case 'cmdtDsc':
          if (payload) {
            _payload = payload.target.value
          }

          break
        case 'insrCertStrDt':
        case 'insrCertEndDt':
        case 'coaCertStrDt':
        case 'coaCertEndDt':
        case 'railTestDt':
          if (payload) {
            // let formattedDate = payload.target.value.replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3')
            _payload = payload
          }

          break
        case 'prefix':
          if (payload) {
            _payload = payload
          }

          break
        default:
          _payload = payload
      }

      let isOk = true
      const frm = document.querySelector('#frm')
      const id = prefix + '_' + this.cntrSeq
      const selector = frm.querySelector('#' + id)
      console.log('@@@@ selector: ', selector)

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (selector && this.$ekmtcCommon.isEmpty(selector.value.trim())) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      if (payload !== undefined) {
        this.setContainer(prefix, _payload)
      }

      return isOk
    },
    changeDgYn (key, e) {
      const val = e.target.value

      console.log('@@@@ val: ', val)
      this.setContainer(key, val)

      if (val === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('js.restriction.021'))
      }
    },
    changeNewFbYn (e) {
      const val = e.target.value

      this.setContainer('newFbYn', val)

      if (val === 'N') {
        this.$ekmtcCommon.alertDefault(this.$t('js.restriction.022'))
      }
    },
    fnFbWeightChk (key, payload) {
      console.log('@@@@ key: ', key)
      console.log('@@@@ payload: ', payload)
      const flexiInfo = this.flexiInfo
      const fbCapa = flexiInfo.fbCapa
      // let fbGrsWt = flexiInfo.fbGrsWt
      let fbGrsWt = payload
      let perMax = ''
      if (fbGrsWt !== '' && fbGrsWt !== '0') {
        fbGrsWt = Number(fbGrsWt.replace(/,/g, ''))
        console.log('@@@@ enter fbGrsWt: ', fbGrsWt)
        console.log('@@@@ enter fbCapa: ', fbCapa)
        perMax = Math.round(Number(fbGrsWt) / fbCapa * 1000) / 10.0
        console.log('@@@@ perMax: ', perMax)
        this.setContainer('perMax', perMax)
      }

      document.querySelector('#perMax_' + this.cntrSeq).value = perMax
      this.flexiInfo.perMax = perMax

      if (perMax !== '' && (perMax < 95 || perMax >= 105)) {
        document.querySelector('#perMax_' + this.cntrSeq).className = 'bg_pk col_8'
      } else {
        document.querySelector('#perMax_' + this.cntrSeq).className = 'col_8'
      }

      this.checkValidationFlexi(key, payload)
    },
    uploadPopup () {
      this.popParams = {
        taskCatCd: 'B',
        type: 'F',
        bkgNo: (this.parentInfo.bkgNo !== undefined ? this.parentInfo.bkgNo : ''),
        scgAppNo: (this.parentInfo.scgAppNo !== undefined ? this.parentInfo.scgAppNo : ''),
        reqRno: (this.flexiInfo.reqRno !== undefined ? this.flexiInfo.reqRno : ''),
        dgUploadFlag: this.saveFlag,
        count: this.cntrSeq
      }

      this.selectFunc = this.uploadComplete
      this.openPopup('dg-oog-certi-pop')
    },
    uploadComplete (returnVo) {
      if (returnVo.deleteAllYn === 'Y') {
        this.flexiInfo.reqRno = '' // 전체 삭제했을 경우 reqRno 초기화
      } else {
        this.flexiInfo.reqRno = returnVo.reqRno
      }

      this.setContainer('reqRno', this.flexiInfo.reqRno)

      const uploadFileInfos = returnVo.uploadFileInfos
      const result = {
        fbCat01: 'N',
        fbCat02: 'N',
        fbCat03: 'N',
        fbCat04: 'N',
        fbCat05: 'N'
      }

      if (uploadFileInfos !== undefined) {
        uploadFileInfos.forEach(vo => {
          if (this.$ekmtcCommon.isNotEmpty(vo.category)) {
            if (vo.category === '01') {
              result.fbCat01 = 'Y'
            } else if (vo.category === '02') {
              result.fbCat02 = 'Y'
            } else if (vo.category === '03') {
              result.fbCat03 = 'Y'
            } else if (vo.category === '04') {
              result.fbCat04 = 'Y'
            } else if (vo.category === '05') {
              result.fbCat05 = 'Y'
            }
          }
        })

        this.$emit('fbCatInfo', result)
      }

      this.$ekmtcCommon.layerClose('#flexi_area_' + this.cntrSeq + ' > .popup_dim')
    },
    setContainer (key, payload) {
      console.log('@@@@ key: ', key)
      console.log('@@@@ payload: ', payload)

      if (payload === undefined) return

      let _containerList = [...this.$store.state.schedule.containerList]
      console.log({
        [key]: payload
      })

      this.$store.commit('schedule/defaultAssign', {
        key: 'containerList',
        value: [
          ..._containerList.slice(0, this.index),
          {
            ..._containerList[this.index],
            [key]: payload
          },
          ..._containerList.slice(this.index + 1)
        ],
        test: 'flexi setContainer'
      })
    },
    openPopup (compNm) {
      this.customComponent = null
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('#flexi_area_' + this.cntrSeq + ' > .popup_dim')
    },
    closePoup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('#flexi_area_' + this.cntrSeq + ' > .popup_dim')
    },
    downloadLOI () {
      if (!this.$ekmtcCommon.isEmpty(this.parentInfo.bkgNo) && this.saveFlag !== 'ADD') {
        let bkgNo = this.parentInfo.bkgNo
        let cntrSeq = this.parentInfo.cntrSeq

        bookings.getflexibagLoi(bkgNo, cntrSeq).then(async (result) => {
          const fileName = 'KMTC_' + bkgNo + '_LOI.xlsx'
          //blob 객체 생성
          const blob = new Blob([result.data], { type: result.headers['content-type'] })
          if (blob.size === 0) {
            this.$ekmtcCommon.alertDefault(this.$t('js.restriction.024'))
          } else {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              //IE에서 동작
              window.navigator.msSaveOrOpenBlob(result.data, fileName)
            } else {
              //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
              const url = window.URL.createObjectURL(blob)
              const link = document.createElement('a')

              link.href = url
              link.target = '_self'
              link.download = fileName
              link.click()

              //url 사용 후 메모리에서 제거
              window.URL.revokeObjectURL(url)
            }
          }
        }).catch((err) => {
            console.log(err)
            this.$ekmtcCommon.alertDefault(this.$t('js.restriction.024'))
        })
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('js.restriction.024'))
      }
    }
  }
}
</script>

<style scoped>
  .bg_pk {background-color: #ffc6c6 !important; color: #909090 !important;}
  .actual_alert {height: auto !important; min-height: 350px;}
  .txt_border_none {border: none;}
  .span_scg {color: #075bb9}
  .tooltip_wrap {min-width:300px; right:0; top:24px; display:none; text-align: left;}
  .tooltip_wrap .cont {word-break: keep-all;}
  .margin-left {
    margin-left: 80px !important;
  }
</style>
