<template>
  <div
    class="popup_wrap"
    style="width:500px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <form id="mailFrm">
      <div class="popup_cont">
        <h1 class="page_title">Schedule Mail</h1>
        <div class="content_box"><!-- content_box -->
          <table class="tbl_row">
            <colgroup>
              <col width="120"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>To (e-mail)</th>
                <td>
                  <input type="text" id="toEmlAddr" name="" v-model="params.recptEmlAddr" @keyup="checkValidationEmail('toEmlAddr')">
                </td>
              </tr>
              <tr>
                <th>From (e-mail)</th>
                <td>
                  <input type="text" id="fromEmlAddr" name="" v-model="params.trsrEmlAddr" @keyup="checkValidationEmail('fromEmlAddr')">
                </td>
              </tr>
              <tr>
                <th>Subject</th>
                <td>
                  <input type="text" id="subject" name="" maxlength="20" v-model="params.emlTite" @keyup="checkValidationContents('subject')">
                </td>
              </tr>
              <tr>
                <th class="valign_top">Additional <br/>Message</th>
                <td>
                  <textarea style="height:180px;" id="message" maxlength="100" v-model="params.emlCont" @keyup="checkValidationContents('message')">
                  </textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- content_box // -->
        <div class="mt10 text_center">
            <a class="button blue lg" href="#" @click.prevent="sendEmail">{{ $t('msg.VOSD100_M5.072') }}</a><!-- 보내기 -->
            <a class="button gray lg" href="#" @click.prevent="$emit('close')">{{ $t('msg.CSBK100.024') }}</a><!-- 취소 -->
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import emailSchedule from '@/api/rest/schedule/emailSchedule'

export default {
  name: 'EmailPop',
  components: {},
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          porCtrCd: '',
          porPlcCd: ''
        }
      }
    }
  },
  data () {
    return {
      // 스케쥴 구독 저장 API 실행 여부
      isProcess: false,
      params: {
        porCtrCd: '',
        porPlcCd: '',
        recptEmlAddr: '',
        trsrEmlAddr: '',
        emlTite: '',
        emlCont: ''
      }
    }
  },
  created () {
    this.initParam()
  },
  watch: {
    // 'params.subject': function (val) {
    //   console.log('>>>> subject = ' + val)
    //   console.log('>>>> subject = ' + val.length)
    // },
    // 'params.message': function (val) {
    //   console.log('>>>> message = ' + val)
    //   console.log('>>>> message = ' + val.length)
    // }
  },
  methods: {
    // init parameter
    initParam () {
      this.params = { ...this.params, ...this.parentInfo }
    },
    // 이메일 입력여부 체크
    checkValidationEmail (mailVal) {
      const frm = document.querySelector('#mailFrm')
      const elem = frm.querySelector('#' + mailVal)
      const val = elem.value
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(val)) {
        // 값이 없을 경우
        msg = this.$t('art.CMATK089') + this.$t('tem.CMAT002') // e-mail 주소를 입력하세요.
        isOk = false
      } else {
        // 이메일 형식 체크
        if (!this.$ekmtcCommon.checkEmail(val)) {
          msg = this.$t('msg.VOSD100.043') // 이메일 형식에 맞춰서 작성해주세요.
          isOk = false
        }
        // Byte 크기 체크
        if (!this.$ekmtcCommon.checkTextByte(val, 4000)) {
          msg = this.$t('msg.VOSD100.044') // 허용된 Byte 크기를 초과하였습니다.
          isOk = false
        }
      }
      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    // 제목, 내용 입력여부 체크
    checkValidationContents (txt) {
      const frm = document.querySelector('#mailFrm')
      const elem = frm.querySelector('#' + txt)
      const val = elem.value
      let isOk = true
      let msg = ''
      if (this.$ekmtcCommon.isEmpty(val)) {
        if (txt === 'subject') {
          msg = this.$t('msg.VOSD100_M5.073') + this.$t('msg.VOSD100_M5.057') // 제목을 입력해 주세요.
        } else {
          msg = this.$t('msg.VOSD100_M5.074') + this.$t('msg.VOSD100_M5.057') // 내용을 입력해 주세요.
        }
        isOk = false
      }
      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    // 메일 보내기
    sendEmail () {
      let isOk = true
      // 이메일 유효 체크
      if (!this.checkValidationEmail('toEmlAddr')) {
        isOk = false
      }
      // 이메일 유효 체크
      if (!this.checkValidationEmail('fromEmlAddr')) {
        isOk = false
      }
      // 제목 유효 체크
      if (!this.checkValidationContents('subject')) {
        isOk = false
      }
      // 내용 유효 체크
      if (!this.checkValidationContents('message')) {
        isOk = false
      }
      console.log('>>>>>> sendEmail = ' + isOk)
      console.log(this.params)
      if (isOk) {
        emailSchedule.sendMail(this.params)
          .then(response => {
            if (response.headers.respcode === 'C0000') {
              this.$ekmtcCommon.alertDefault(this.$t('art.CMATK146')) // 메일이 전송되었습니다.
              this.$emit('close')
            } else {
              this.$ekmtcCommon.alertDefault(this.$t('msg.VOSD100.045')) // 저장에 실패하였습니다.
            }
          })
      }
    }
  }
}
</script>
