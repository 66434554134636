<template>
  <div class="working_detail_area">
    <div class="flex_box">
      <h1 class="page_title">
        {{ $t('menu.MENU03.000') }}
      </h1>
      <span class="ml_auto mt20">
        <a class="button md print" @click="screenPrint"><span class="btn_icon print"></span>{{ $t('msg.CSBK100.243') }}</a>
      </span>
    </div>
    <e-tab ref="tab" @tabName="getTabName" page-type="DETAIL">
      <e-tab-item title="Booking" name="booking-edit" path="/working/detail" :selected="true" :disabled="false">
        <booking-new-sr
          v-if="tab === 'booking-edit' && bookingType === 'S'"
          :detail-bkg-no="detailBkgNo"
          :detail-sr-rno="detailSrRno"
          ref="bkg"
        ></booking-new-sr>
        <booking-new
          v-if="tab === 'booking-edit' && bookingType === 'B'"
          :detail-bkg-no="detailBkgNo"
          :sr-cncl-yn="srCnclYn"
          ref="bkg"
        ></booking-new>
      </e-tab-item>
      <e-tab-item title="B/L" name="bl" path="/working/detail" :disabled="tabDisabled">
        <working-bl-main
          v-if="tab === 'bl'"
          :detail-bkg-no="detailBkgNo"
          :detail-sr-rno="detailSrRno"
          :detail-new-sr-rno="detailNewSrRno"
          :detail-bl-no="detailBlNo"
          :detail-arr-bkg-no="detailArrBkgNo"
          :sr-cncl-yn="srCnclYn"
          @after="fnAddNewSr"
          ref="bl"
        ></working-bl-main>
      </e-tab-item>
      <e-tab-item title="Freight" name="freight" path="/working/detail" :disabled="checkFreghit()">
        <freight-bl
          v-if="tab === 'freight'"
          :parent-data="freightReqData"
          :detail-bkg-no="detailBkgNo"
          :detail-sr-rno="detailSrRno"
          :detail-bl-no="detailBlNo"
          ref="freight"
        />
      </e-tab-item>
      <e-tab-item title="Free Time" name="free-time" path="/working/detail" :disabled="tabDisabled">
        <freetime-request
          v-if="tab === 'free-time'"
          :parent-data="freetimeReqData"
          ref="workingFreetimeReq"
        />
      </e-tab-item>
      <e-tab-item title="Certificate" name="bl-certificate" path="/working/detail" :disabled="blDisabled">
        <bl-certificate
          v-if="tab === 'bl-certificate'"
          :detail-bkg-no="detailBkgNo"
          :bl-no="detailBlNo || detailSrRno"
          ref="blCertificate"
        >
        </bl-certificate>
      </e-tab-item>
    </e-tab>
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePopup"
        @callback="selectFunc"
      />
    </win-layer-pop>
    <div id="pdf_area">
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import ETabItem from '@/components/common/ETabItem'
import ETab from '@/components/common/ETab'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import store from '@/store/index'
export default {
  name: 'WorkingDetail',
  components: {
    ETabItem,
    ETab,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'booking-new': () => import('@/pages/trans/BookingNew'),
    'booking-new-sr': () => import('@/pages/trans/BookingNewSr'),
    'working-bl-main': () => import('@/pages/trans/WorkingBLMain'),
    'freight-bl': () => import('@/pages/trans/FreightBL'),
    'bl-certificate': () => import('@/pages/trans/BlCertificate'),
    'freetime-request': () => import('@/pages/settle/FreeTimeRequest'),
    'bl-modity-noti-pop': () => import('@/pages/trans/popup/BLModifyNotiPop'),
    EBreadcrumbs
  },
  props: {
    bkgNo: {
      type: String,
      default: ''
    },
    blNo: {
      type: String,
      default: ''
    },
    srRno: {
      type: String,
      default: ''
    },
    newSrRno: {
      type: String,
      default: ''
    },
    arrBkgNo: {
      type: Array,
      default: () => {
        return []
      }
    },
    srCnclYn: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tab: 'booking-edit',
      bookingType: 'B',
      customComponent: null,
      popParams: {},
      selectFunc: {},
      detailBkgNo: '',
      detailBlNo: '',
      detailSrRno: '',
      detailNewSrRno: '',
      detailArrBkgNo: [],
      tabDisabled: false,
      blDisabled: false,
      freetimeReqData: {
        isWorking: true
      },
      freightReqData: {
        isWorking: true
      },
      blPopYn: '',
      frtDisabled: false,
      dsmYn: true //process.env.VUE_APP_MODE !== 'PRD' // JamSin DSM yn
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    detailBlNo: function () {
      //
    },
    $route (to, from) {
      this.tab = to.name
      if (this.$ekmtcCommon.isNotEmpty(to.params.bkgNo)) {
        this.detailBkgNo = to.params.bkgNo
      }

      if (this.$ekmtcCommon.isNotEmpty(to.params.blNo)) {
        this.detailBlNo = to.params.blNo
      }

      if (this.$ekmtcCommon.isNotEmpty(to.params.srRno)) {
        this.detailSrRno = to.params.srRno
      }

      if (this.$ekmtcCommon.isNotEmpty(to.params.newSrRno)) {
        this.detailNewSrRno = this.newSrRno
      }

      if (to.params.arrBkgNo !== undefined && to.params.arrBkgNo.length > 0) {
        for (const bkgNo of to.params.arrBkgNo) {
          this.detailArrBkgNo.push(bkgNo)
        }
      }

      this.isTabDisabled()
      this.$refs.tab.selectTab({ name: to.name })
    }
  },
  created () {
    console.log('created', this.$route.name)
    this._ = require('lodash')
    if (this.dsmYn) {
      if (Object.keys(this.$route.query).length > 0) { // JamSin DSM 대안항로 url로 연결된 경우 url 부킹번호를 셋팅해서 BookingNew 로 이동
        const queryVo = this.$route.query
        if (queryVo !== null && queryVo !== undefined) {
          if (queryVo.dsmType === '020') {
            this.bkgNo = queryVo.bkgNo
          }
        }
      }
    }

    this.tab = this.$route.name
    if (this.$ekmtcCommon.isNotEmpty(this.bkgNo)) {
      this.detailBkgNo = this.bkgNo
    }

    if (this.$ekmtcCommon.isNotEmpty(this.blNo)) {
      this.detailBlNo = this.blNo
      if (this.memberDetail.bkgPlcCd === 'SHA' || this.memberDetail.bkgPlcCd === 'SZP' || this.auth.serviceLang === 'CHN') {
        this.detailSrRno = this.blNo // JamSin SR이 없으면 BL로 대처 하고 있으면 아래에서 넣어줌.
      }
    }

    if (this.$ekmtcCommon.isNotEmpty(this.srRno)) {
      this.detailSrRno = this.srRno
    }

    if (this.$ekmtcCommon.isNotEmpty(this.newSrRno)) {
      this.detailNewSrRno = this.newSrRno
    }

    if (this.arrBkgNo !== undefined && this.arrBkgNo.length > 0) {
      for (const bkgNo of this.arrBkgNo) {
        this.detailArrBkgNo.push(bkgNo)
      }
    }

     // 최초 상세 페이지 진입이 b/l 탭이고, b/l이 제출된 상태이면 on going list에서 b/l 수정 확인 팝업을 통해 들어온 것이므로
     // 탭 이동 시 팝업을 띄우지 않기 위해 플래그를 변경해준다.
     // 해당 플래그는 ETab.vue에서 Y일 때는 팝업을 띄우지 않도록 되어있다.
    if (this.tab === 'bl' && this.$ekmtcCommon.isNotEmpty(this.detailBlNo)) {
      this.blPopYn = 'Y'
    }

    this.isTabDisabled()

    this.$nextTick(() => {
      this.$refs.tab.selectTab({ name: this.$route.name })
    })

    if (this.memberDetail.bkgPlcCd === 'SHA' || this.memberDetail.bkgPlcCd === 'SZP' || this.auth.serviceLang === 'CHN') {
      this.bookingType = 'S'
    } else {
      this.bookingType = 'B'
    }
  },
  mounted () {
    //this.tab = this.$route.name
    //this.$refs.tab.selectTab({ name: this.$route.name })
  },
  methods: {
    getTabName (name) {
      if (name === 'free-time') {
        this.setFreetimeReqData()
      }
      if (name === 'freight') {
        this.setFreightData()
      }
    },
    setFreightData () {
      this.freightReqData = {
        isWorking: true,
        eiCatCd: store.state.workingEiCatCd
      }
      console.log('freight > Tab')
    },
    setFreetimeReqData () {
      console.log('free-time > Tab', this.detailBlNo)
      this.freetimeReqData = {
        isWorking: true
      }
      let params = {
        eiCatCd: store.state.workingEiCatCd,
        dtKnd: 'BL',
        num: this.detailBlNo,
        blNo: '',
        cntrNo: ''
      }
      this.$store.commit('settle/updateFreetimeParams', params)
    },
    fnBeforSreen (ele) {
      const options = {
        cssSelector: '.capture_area'
      }

      this.$ekmtcCommon.screenToPrintForBooking(options).then(res => {
        if (res === undefined || res === null) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.244'))
        }
        this.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
      }).catch(() => {
        this.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
      })
    },
    screenPrint () {
      const ele = document.querySelector('.capture_area')
      this.$ekmtcCommon.elemAddClass(ele, 'screen_shot')
      if (this.tab === 'booking-edit') {
        const bkg = this.$refs.bkg
        const scheduleEditYn = bkg.regParams.scheduleEditYn

        if (scheduleEditYn === 'Y') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.245'))
          return
        }
        this.addTitleHtml()
        this.fnBeforSreen(ele)
      } else if (this.tab === 'bl') {
        this.$refs.bl.print()
      } else if (this.tab === 'bl-certificate') {
        this.$refs.blCertificate.fnPreview()
      }
    },
    addTitleHtml () {
      const bkg = this.$refs.bkg
      const bkgStsCd = bkg.regParams.bkgStsCd
      let bkgStsNm = 'Booking Status : '
      if (bkgStsCd === '01' || bkgStsCd === '04') {
        bkgStsNm += this.$t('msg.CSBK100.023')
      } else if (bkgStsCd === '00' || bkgStsCd === '02' || bkgStsCd === '05') {
        bkgStsNm += this.$t('msg.CSBK100.022')
      } else if (bkgStsCd === '03') {
        bkgStsNm += this.$t('msg.CSBK100.022')
      } else {
        bkgStsNm += '[Code : ' + bkgStsCd + ']'
      }

      let title = '<div class="doc_bkg_info content_box mb10">'
      title += '<div class="doc_title">Booking Detail</div>'
      title += '<div class="bkg_info">'
      title += '<span>Booking No : ' + this.bkgNo + '</span>'
      title += '<span class="span_statusnm">' + bkgStsNm + '</span>'
      title += '</div>'
      title += '</div>'
      document.querySelector('#pdf_area').innerHTML = title
    },
    isTabDisabled () {
      let isDisabled = false

      if (this.$ekmtcCommon.isEmpty(this.detailBlNo) && this.$ekmtcCommon.isEmpty(this.detailSrRno)) {
        isDisabled = true
      }

      if (this.$ekmtcCommon.isEmpty(this.detailBlNo) && this.memberDetail.userCtrCd === 'CN') {
        isDisabled = true
      }

      if (this.$ekmtcCommon.isEmpty(this.detailBlNo)) {
        this.blDisabled = true
      } else {
        this.blDisabled = false
      }

      if (this.memberDetail.userCtrCd !== 'KR') {
        this.frtDisabled = true
      } else {
        this.frtDisabled = false
      }

      this.tabDisabled = isDisabled
    },
    checkFreghit () {
      let disabled = false

      // if (this.frtDisabled) { // 국가가 KR이 아닐때
      //   disabled = true
      // } else {
        disabled = this.tabDisabled
      // }

      return disabled
    },
    fnAddNewSr (srNo) { // sr 등록 후 탭 활성화
      this.detailSrRno = srNo
      this.isTabDisabled()
    },
    compareCheck () {
      const activeTab = this.tab
      const pageInfo = {
        'booking-edit': { ref: this.$refs.bkg },
        bl: { ref: this.$refs.bl },
        freight: { ref: this.$refs.freight },
        'free-time': { ref: this.$refs.workingFreetimeReq },
        'bl-certificate': { ref: this.$refs.blCertificate }
      }

      const page = pageInfo[activeTab].ref
      let isCheck = true

      if (page !== null && typeof page.compareCheck === 'function') {
        const res = page.compareCheck()

        if (res) {
          isCheck = false
        }
      }

      return isCheck
    },
    fnNoticePop () {
      this.selectFunc = this.callBackNotice
      this.customComponent = 'bl-modity-noti-pop'
      this.$ekmtcCommon.layerOpen('.working_detail_area > .popup_dim')
    },
    callBackNotice () {
      this.closePopup()
      this.blPopYn = 'Y'
      this.$router.push({ path: '/working/detail/bl' }).catch(() => {})
    },
    closePopup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.working_detail_area > .popup_dim')
    }
  }
}
</script>
