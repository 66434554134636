import Send from '../../trans.client.js'

export default {
  findVesselTime () {
    return Send({
      url: '/trans/upload-flat-file',
      method: 'get'
    })
  },
  findMasterBl (masterBlNo) {
    return Send({
      url: '/trans/upload-flat-file/' + masterBlNo,
      method: 'get'
    })
  },
  uploadExcelFlatfile (fileInfo) {
    return Send({
      url: 'trans/upload-flat-file/upload',
      method: 'post',
      data: fileInfo
    })
  }
}
