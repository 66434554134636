<template>
  <div class="position_relative">
    <input type="text"
           v-model="text"
           :id="id"
           :name="name"
           :maxlength="maxlength"
           :numberOnly="numberOnly"
           @blur="[onBlur($event),onInput($event)]"
    />
  </div>
</template>

<script>
export default {
  name: 'ETransStringCheckCneeInput',
  props: {
    id: { type: String, required: true },
    isUpperCase: { type: Boolean, default: false, required: false },
    isRequired: { type: Boolean, default: false, required: false },
    messageRequired: { type: String, default: '', required: false },
    maxlength: { type: String, default: '', required: false },
    name: { type: String, default: '', required: false },
    value: { type: String, default: '', required: false },
    isJpPopMsg: { type: Boolean, default: false, required: false },
    numberOnly: { type: Boolean, default: false, required: false }
  },
  data () {
    return {
      elemId: '',
      text: '',
      rtnText: '',
      rtnNumber: 0,
      interval: undefined
    }
  },
  watch: {
    value () {
      if (this.text !== this.$props.value) {
        this.text = this.$props.value
      }
    }
  },
  created () {
    this.text = this.$props.value
  },
  methods: {
    onInput (e) {
      const val = this.isUpperCase ? e.target.value.toUpperCase() : e.target.value
      const arr = []
      let cnt = 0
      let str
      let c
      const picFlag = (e.target.id === 'actShprCstPicNm' || e.target.id === 'cneCstPicNm' || e.target.id === 'ntifCstPicNm') || false

      for (let i = 0; i < val.length; i++) {
        str = val.charAt(i)
        c = val.charCodeAt(i)
        if ('?~`$_|{}[]!^\\"\\='.indexOf(str) < 0 && ((c >= 0 && c <= 91) || (c >= 93 && c <= 127))) {
          if (picFlag && (c >= 48 && c <= 57)) { //담당자는 숫자 입력 안되게 처리.
            cnt++
          } else {
            if (this.numberOnly) {
              if ((c >= 48 && c <= 57)) {
                arr.push('' + str)
              } else {
                cnt++
              }
            } else {
              arr.push('' + str)
            }
          }
        } else {
          cnt++
        }
      }

      const THIS = this
      const elem = document.querySelector('#' + this.id)

      if (cnt > 0) {
        if (picFlag) {
          this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK406')) // 특수문자, 숫자 또는 한글을 사용할 수 없습니다.
        } else {
          this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK215')) // 특수문자 또는 한글을 사용할 수 없습니다.
        }
        e.target.value = arr.join('')
        this.text = arr.join('')
        this.$emit('input', arr.join(''))

        if (this.interval !== undefined) {
          clearInterval(this.interval)
          this.interval = undefined
        }

        this.interval = setInterval(() => {
          THIS.$ekmtcCommon.hideErrorTooltip(elem)
          clearInterval(this.interval)
          this.interval = undefined
        }, 3000)

        return
      }
      e.target.value = val
      this.text = val
      this.$emit('input', val)

      let isOk = true
      let msg = ''

      if (this.isRequired && val === '') {
        isOk = false
        msg = this.messageRequired
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
    },
    onBlur (e) {
      if (this.isJpPopMsg) {
        this.$emit('blur', e.target.value, this.id)
      } else {
        this.$emit('blur', e.target.value)
      }
    }
  }
}
</script>
