<template>
  <div class="content">
    <e-breadcrumbs />
    <template v-if="isMainTab">
      <h1 class="page_title">{{ $t('menu.MENU03.000') }}</h1>
      <e-tab ref="tab">
        <e-tab-item :title="$t('menu.MENU03.010')" name="progress" path="/working" :selected="true">
          <working-progress v-if="routeName === '' || routeName === 'progress'"></working-progress>
        </e-tab-item>
        <e-tab-item :title="$t('menu.MENU03.020')" name="unsolved" path="/working">
          <working-unsolved v-if="routeName === 'unsolved'"></working-unsolved>
        </e-tab-item>
        <e-tab-item :title="$t('menu.MENU03.030')" name="my-request" path="/working">
          <working-my-request v-if="routeName === 'my-request'"></working-my-request>
        </e-tab-item>
        <!-- <e-tab-item v-if="prdYn" :title="$t('menu.MENU03.040')" name="rf-data" path="/working">
          <working-iot-rf-data v-if="routeName === 'rf-data'"></working-iot-rf-data>
        </e-tab-item> -->
        <!-- <e-tab-item v-if="prdYn" :title="$t('menu.MENU03.040')" name="rf-data" path="/working">
          <working-iot-rf-data1 v-if="routeName === 'rf-data'"></working-iot-rf-data1>
        </e-tab-item> -->
        <!-- <e-tab-item :title="$t('men.CSDO900.010')" name="upload-flat-file" path="/working">
          <upload-flat-file v-if="routeName === 'upload-flat-file'"></upload-flat-file>
        </e-tab-item>
        <e-tab-item :title="$t('men.CSDBL100.010')" name="container-return" path="/working">
          <container-return v-if="routeName === 'container-return'" :detail-bl-no="detailBlNo"></container-return>
        </e-tab-item> -->
        <!-- <e-tab-item :title="$t('men.CSBK110.010')" name="pickup" path="/working">
          <working-pickup></working-pickup>
        </e-tab-item> -->
      </e-tab>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>

import ETabItem from '@/components/common/ETabItem'
import ETab from '@/components/common/ETab'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'

export default {
  name: 'WorkingMain',
  components: {
    ETabItem,
    ETab,
    EBreadcrumbs,
    WorkingProgress: () => import('@/pages/trans/WorkingProgress'),
    // WorkingIotRfData: () => import('@/pages/trans/WorkingIotRfData'),
    // WorkingIotRfData1: () => import('@/pages/trans/WorkingIotRfData1'),
    WorkingUnsolved: () => import('@/pages/trans/WorkingUnsolved'),
    WorkingMyRequest: () => import('@/pages/trans/WorkingMyRequest'),
    ContainerReturn: () => import('@/pages/trans/ContainerReturn'),
    PickUpInfo: () => import('@/pages/trans/PickUpInfo'),
    UploadFlatFile: () => import('@/pages/trans/UploadFlatFile')
  },
  data () {
    return {
      isMainTab: true,
      routeName: '',
      detailBlNo: '',
      prdYn: process.env.VUE_APP_MODE !== 'PRD'
    }
  },
  watch: {
    $route (to, from) {
      this.routeName = to.name
      const arrDetailName = ['detail', 'pickup-detail', 'bl', 'bl-certificate', 'booking-edit', 'freight', 'free-time']
      if (arrDetailName.indexOf(to.name) > -1) {
        this.isMainTab = false
      } else {
        this.isMainTab = true
      }

      if (this.$refs.tab !== null && this.$refs.tab !== undefined) {
        this.$refs.tab.selectTab({ name: to.name, params: to.params })
      } else {
        const THIS = this
        this.$nextTick(() => {
          if (THIS.$refs.tab !== null && THIS.$refs.tab !== undefined) {
            THIS.$refs.tab.selectTab({ name: to.name, params: to.params })
          }
        })
      }

      if (this.$ekmtcCommon.isNotEmpty(to.params.blNo)) {
        this.detailBlNo = to.params.blNo
      }
    }
  },
  created () {
    this.routeName = this.$route.name || ''
    console.log('-- WorkingMain --', this.routeName)
  },
  mounted () {
    const arrDetailName = ['detail', 'pickup-detail', 'bl', 'bl-certificate', 'booking-edit', 'freight', 'free-time']
    if (arrDetailName.indexOf(this.$route.name) > -1) {
      this.isMainTab = false
    } else {
      this.isMainTab = true
    }
    this.$refs.tab.selectTab({ name: this.$route.name, params: this.$route.params })
  },
  methods: {
  }
}

</script>
<style>
body {
  min-width:100% !important;
}
</style>
