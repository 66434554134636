<template>
  <div class="content resize pickup_area">
    <e-breadcrumbs />
    <div class="flex_box mt20">
      <h1 class="page_title">{{ $t('msg.ONEX100G010.002') }}</h1><!-- Pickup 정보 변경 -->
      <span class="ml_auto mt20">
        <p class="txt_desc asterisk">{{ $t('msg.ONEX100G010.003') }}</p><!-- 로그인 없이 Pick-up 일시와 장소를 수정하실 수 있습니다. -->
      </span>
    </div>
    <form id="frm" @submit.prevent>
      <div class="content_box beforetab" v-if="saveFlag === 'PICKUP'">
        <table class="tbl_search">
          <colgroup>
            <col style="width:130px">
            <col style="width:20%">
            <col style="width:10%">
            <col style="width:auto">
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">Booking No</th>
              <td id="area_input_bkgNo">
                <input
                  v-model="regParams.bkgNo"
                  type="text"
                  id="bkgNo"
                  @keyup="fnValidationBkgNo()"
                />
              </td>
              <td>
                <a
                  class="button blue sh"
                  href="#"
                  @click.prevent="initEdit()"
                >{{ $t('msg.ONEX050T010.005') }}</a><!-- 검색 -->
              </td>
              <td class="position_relative">
                <span v-if="$ekmtcCommon.isNotEmpty(statusMsg)" :class="'box_info ' + statusClass">
                  <span class="note">{{ statusMsg }}</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content_box mt10">
        <!-- content_box -->
        <table class="tbl_row">
          <colgroup>
            <col width="150"><col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.ONEX100G010.004') }}</th><!-- Booking Shipper -->
              <td>{{ regParams.bkgShprCstEnm }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONEX100G010.005') }}</th><!-- 운송사 -->
              <td>{{ regParams.tfcoCstNm }}</td>
            </tr>
          </tbody>
        </table>

        <div class="flex_box mt20">
          <h2 class="content_title" v-if="regParams.mtyPkupUsagYn !== 'Y'">
            {{ $t('msg.CSBK100.151') }} <!-- 스케줄 -->
          </h2>
        </div>

        <booking-schedule-info
          v-if="regParams.mtyPkupUsagYn !== 'Y'"
          :bkg-no="regParams.bkgNo"
          :save-flag="saveFlag"
          :except-watch-bkg-no="true"
          :search-in-pick-up-info="searchInPickUpInfo"
          :is-pickup="true"
        />

        <div class="flex_box mt20">
          <h2 class="content_title">
            {{ $t('msg.CSBK100.040') }} <!-- Container Pick up -->
          </h2> <!-- UO640-->
        </div>
        <table class="tbl_col">
          <colgroup>
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:10.5%">
            <col style="width:16%">
            <col style="width:10%">
            <col style="width:auto">
          </colgroup>
          <thead>
            <tr>
              <th colspan="4">{{ $t('msg.ONEX100G010.006') }}</th><!-- CONTAINER -->
              <th rowspan="2">{{ $t('msg.CSBK100.068') }}</th> <!-- 특수화물 구분 -->
              <th v-if="regParams.polShtmCd === '01'" rowspan="2">{{ $t('msg.CSBK100.069') }}</th> <!-- pick up 일시 -->
              <th v-if="regParams.polShtmCd === '01'" rowspan="2">{{ $t('msg.CSBK100.070') }}</th> <!-- pick up 장소 -->
              <th v-if="regParams.polShtmCd === '02'" rowspan="2">{{ $t('msg.CSBK100.073') }}</th>
              <th v-if="regParams.polShtmCd === '02' && lang === 'KOR'" rowspan="2">{{ $t('msg.CSBK100.164') }}<br>{{ $t('msg.CSBK100.164_1') }}</th> <!-- 쇼링 업체<br>(쇼링업체명 기재) -->
              <th v-if="regParams.polShtmCd === '02' && lang !== 'KOR'" rowspan="2"></th>
            </tr>
            <tr>
              <th>Size</th>
              <th>
                Type
                <button
                  class="tbl_icon help ml5"
                  type="button"
                  @click="openPopup('container-pop')"
                >
                  <span class="offscreen">{{ $t('msg.ONEX100G010.007') }}</span><!-- 도움말 -->
                </button>
              </th>
              <th>Q'ty</th>
              <th>{{ $t('msg.CSBK100.071') }}</th> <!-- 화주<br>컨테이너 -->
            </tr>
          </thead>
          <tbody
            v-for="(vo, idx) in regParams.containerList"
            :key="'tr_container_'+ idx"
          >
            <tr>
              <td>
                <select
                  v-model="vo.cntrSzCd"
                  :id="'cntrSzCd_' + idx"
                  readonly
                >
                  <option
                    v-for="option in cntSzList"
                    :key="option.cd"
                    :value="option.cd"
                  >
                    {{ option.cdNm }}
                  </option>
                </select>
              </td>
              <td>
                <select
                  v-model="vo.cntrTypCd"
                  :id="'cntrTypCd_' + idx"
                  readonly
                >
                  <option value="">---------------------</option>
                  <option
                    v-for="option in vo.subCntrTypList"
                    :key="option.cd"
                    :value="option.cd"
                  >
                    {{ option.cdNm }}
                  </option>
                </select>
              </td>
              <td>
                <e-input-number
                  v-model="vo.cntrQty"
                  :id="'cntrQty_' + idx"
                  readonly
                >
                </e-input-number>
              </td>
              <td>
                <input
                  :id="'shprVanYn_' + idx"
                  type="checkbox"
                  v-model="vo.shprVanYn"
                  true-value="Y"
                  false-value="N"
                  disabled
                >
                <label :for="'shprVanYn_' + idx"><span class="offscreen">{{ $t('msg.ONEX100G010.008') }}</span></label><!-- 화주 컨테이너 체크 -->
              </td>
              <td>
                <select
                  v-model="vo.cgoTypCd"
                  :id="'cgoTypCd_' + idx"
                  readonly
                >
                  <option
                    v-if="vo.cntrTypCd !== 'OT' && vo.cntrTypCd !== 'FR' && vo.cntrTypCd !== 'SR'"
                    value=""
                  >
                    ---------------------------------------
                  </option>
                  <option
                    v-for="option in vo.subCgoTypList"
                    :key="option.cd"
                    :value="option.cd"
                  >
                    {{ option.cdNm }}
                  </option>
                </select>
                <div
                  v-if="$ekmtcCommon.isNotEmpty(vo.apvNo)"
                  class="danger_num"
                >
                  <div>{{ $t('msg.CSBK100.165') }}</div>
                  <div class="color_red">
                    {{ vo.apvNo }}
                  </div>
                </div>
              </td>
              <td>
                <div class="input_calendar">
                  <e-date-picker
                    v-model="vo.pcupReqDt"
                    :id="'pcupReqDt_' + idx"
                    @input="checkValidationCntr('pcupReqDt', idx, 'KEYUP')"
                    :disabled="vo.shprVanYn === 'Y'"
                  >
                    <select
                      class="mt5 col_8"
                      v-if="lang !== 'KOR'"
                      :id="'pcupReqTm_' + idx"
                      v-model="vo.pcupReqTm"
                    >
                      <option value="09">09:00</option>
                      <option value="10">10:00</option>
                      <option value="11">11:00</option>
                      <option value="12">12:00</option>
                      <option value="13">13:00</option>
                      <option value="14">14:00</option>
                      <option value="15">15:00</option>
                      <option value="16">16:00</option>
                      <option value="17">17:00</option>
                    </select>
                  </e-date-picker>
                </div>
              </td>
              <td>
                <div v-if="cstCatCd !== 'Y'">
                  <!-- SOC 화주가 아닐때 -->
                  <div v-if="lang !== 'KOR' && regParams.polShtmCd === '01'">
                    <p>
                      <input
                        v-model="vo.pickUpPlace"
                        :id="'pcupCyCd_' + idx"
                        type="text"
                        placeholder="Company Name"
                        readonly
                        :disabled="vo.shprVanYn === 'Y'"
                        @click="getPickupPlace(idx)"
                      >
                    </p>
                  </div>
                  <div
                    v-if="lang === 'KOR' && regParams.polShtmCd === '01'"
                    class="position_relative"
                  >
                    <p>
                      <input
                        v-model="vo.pickUpPlace"
                        :id="'pcupCyCd_' + idx"
                        type="text"
                        placeholder="Company Name"
                        readonly
                        :disabled="vo.shprVanYn === 'Y'"
                        @click="getPickupPlace(idx)"
                      >
                    </p>
                    <p style="margin:5px 0;">
                      <input
                        v-model="vo.pickUpMan"
                        :id="'pickUpMan_' + idx"
                        type="text"
                        readonly
                        :disabled="vo.shprVanYn === 'Y'"
                        :placeholder="$t('msg.CSBK100.129')"
                      >
                    </p>
                    <p>
                      <input
                        v-model="vo.pickUpTel"
                        :id="'pickUpTel_' + idx"
                        type="text"
                        readonly
                        :disabled="vo.shprVanYn === 'Y'"
                        :placeholder="$t('msg.CSBK100.161')"
                      >
                    </p>
                  </div>
                  <div v-if="lang === 'KOR' && regParams.polShtmCd !== '01' && idx === 0">
                    <select
                      v-model="regParams.cfsFixdTypCd"
                      class="col_4"
                      readonly
                    >
                      <option value="01">{{ $t('msg.CSBK100.047') }}</option> <!-- Line -->
                      <option value="02">{{ $t('msg.CSBK100.148') }}</option> <!-- 자가 -->
                      <option value="03">{{ $t('msg.CSBK100.166') }}</option> <!-- 쇼링없음 -->
                    </select>
                    <input
                      v-model="regParams.cfsFixdEntrNm"
                      :id="'cfsFixdEntrNm_' + idx"
                      type="text"
                      class="col_5"
                      readonly
                    >
                  </div>
                </div>
                <div v-if="cstCatCd === 'Y'">
                  <!-- SOC 화주일때 -->
                  <p>
                    <input
                      v-model="vo.pickUpPlace"
                      type="text"
                      placeholder="Company Name"
                      readonly
                      :disabled="vo.shprVanYn === 'Y'"
                      @click="getPickUpPlace()"
                    >
                  </p>
                </div>
              </td>
            </tr>
            <tr v-if="vo.cntrTypCd === 'RF' || vo.cntrTypCd === 'RH'">
              <td
                class="text_left btn_responsive"
                colspan="2"
              >
                <input
                  id="chk12"
                  type="checkbox"
                  v-model="vo.chkNOR"
                  true-value="Y"
                  false-value="N"
                  readonly
                ><label for="chk12">NOR(Non Operating Reefer)</label>&nbsp;
                <span class="position_relative span_tooltip">
                  <button
                    class="tbl_icon help"
                    @click="$ekmtcCommon.showTooltip($event)"
                  />
                  <div class="tooltip_wrap short position_absolute"><!-- tooltip_wrap   -->
                    <div class="cont"><!-- cont -->
                      <button
                        type="button"
                        class="close"
                        @click="$ekmtcCommon.hideTooltip($event)"
                      >close</button>
                      <ul class="bul_list_sm t2">
                        <li>
                          {{ $t('msg.CSBK100.143') }}
                        </li>
                      </ul>
                    </div><!-- cont -->
                  </div><!-- tooltip_wrap // -->
                </span>
              </td>
              <th scope="row">{{ $t('msg.CMBK100_M1.025') }}</th>
              <td>
                <e-input-number
                  :id="'rfSetupTmpr_' + idx"
                  v-model="vo.rfSetupTmpr"
                  :class="'col_4'"
                  :is-minus="true"
                >
                </e-input-number>
                <select
                  v-if="usrCtrCd !== 'KR' && vo.chkNOR !== 'Y'"
                  v-model="vo.rfTmprUnitCd"
                  class="col_5"
                  readonly
                >
                  <option value="C">℃</option>
                  <option value="F">℉</option>
                </select>
                <span
                  v-if="usrCtrCd === 'KR' || vo.chkNOR === 'Y'"
                  class="d_inline"
                >℃</span>
              </td>
              <th scope="row">VENT</th>
              <td>
                <select
                  v-model="vo.cntrRfStsCd"
                  :id="'cntrRfStsCd_' + idx"
                  readonly
                >
                  <option
                    v-for="cvo in cntrRfStsList"
                    :key="cvo.cd"
                    :value="cvo.cd"
                  >
                    {{ cvo.cdNm }}
                  </option>
                </select>
              </td>
              <th scope="row">
                IoT Container&nbsp;
                <span class="position_relative span_tooltip">
                  <button
                    class="tbl_icon help"
                    @click="$ekmtcCommon.showTooltip($event)"
                  />
                  <div class="tooltip_wrap short position_absolute">
                    <!-- tooltip_wrap   -->
                    <div class="cont">
                      <!-- cont -->
                      <button
                        type="button"
                        class="close"
                        @click="$ekmtcCommon.hideTooltip($event)"
                      >
                        close
                      </button>
                      <ul class="bul_list_sm t2">
                        <li>
                          Iot 컨테이너
                        </li>
                      </ul>
                    </div>
                    <!-- cont -->
                  </div>
                  <!-- tooltip_wrap // -->
                </span>
              </th>
              <td>
                <input
                  :id="'iotEqpmYn_' + idx"
                  type="checkbox"
                  disabled
                  :checked="vo.iotEqpmYn === 'Y' ? true : false"
                  v-model="vo.iotEqpmYn"
                  true-value="Y"
                  false-value="N"
                />
                <label :for="'iotEqpmYn_' + idx"></label>
              </td>
            </tr>
            <tr v-if="vo.cntrTypCd === 'RF' || vo.cntrTypCd === 'RH'">
              <td colspan="6">
                <div class="select_opt_area">
                  <div class="desc_list">
                    <ul>
                      <li v-show="usrCtrCd !== 'JP'">{{ $t('msg.CSBK100.168_1') }}</li>
                      <li v-show="usrCtrCd !== 'JP'">{{ $t('msg.CSBK100.168_2') }}</li>
                      <li>{{ $t('msg.CSBK100.168_3') }}</li>
                      <li>{{ $t('msg.CSBK100.168_4') }} <a class="text_decoration" style="cursor: pointer;" href="/#/common/pds?messageIdSeq=CKR362&groupId=362" target="_blank">{{ $t('msg.CSBK100.168_5') }}</a></li>
                    </ul>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <a
                  href="#"
                  v-if="lang === 'KOR'"
                  class="button sm gray"
                  @click.prevent="openRfHpmgSite(vo.rfHmpgUrl)"
                >{{ $t('msg.CSBK100.167') }}</a> <!-- 냉동 Pick Up 정보 확인 (Terminal Site) -->
              </td>
            </tr>
            <tr v-if="vo.cgoTypCd !== '' && '01|02|03|06'.indexOf(vo.cgoTypCd) > -1">
              <td
                colspan="8"
                style="text-align:left"
              >
                <!-- dg s -->
                <booking-table-dg
                  v-if="vo.cgoTypCd === '01'"
                  ref="dg"
                  :parent-info="vo"
                  @vanningChk="vanningChk"
                  :cas-no-port-yn="casNoPortYn"
                  :tel-ctr-no="usrInfo.telCtrNo"
                  :disabled-yn="disabledYn"
                  :unno-disabled-yn="unnoDisabledYn"
                  :lang="lang"
                  :save-flag="saveFlag"
                />
                <!-- dg e -->

                <!-- oog s -->
                <booking-table-oog
                  v-if="vo.cgoTypCd === '02' || (vo.cgoTypCd === '03' && (vo.cntrTypCd !== 'HC' && vo.cntrTypCd !== '45'))"
                  ref="oog"
                  :cgo-typ-cd="vo.cgoTypCd"
                  :parent-info="vo"
                  :kmd-vsl-check="kmdVslCheck"
                  :empty-flag="regParams.emptyFlag"
                  :lang="lang"
                  :save-flag="saveFlag"
                />
                <!-- oog e -->

                <!-- flexi s -->
                <booking-table-flexi
                  v-if="vo.cgoTypCd === '06'"
                  ref="flexi"
                  :parent-info="vo"
                  :lang="lang"
                  :save-flag="saveFlag"
                  @fbCatInfo="callFbCatInfo"
                />
                <!-- flexi e -->
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="usrCtrCd === 'TH'"
          class="mt20"
        >
          <table class="tbl_col">
            <colgroup>
              <col width="20%">
              <col width="80%">
            </colgroup>
            <tbody>
              <tr>
                <th>Return Date</th>
                <td class="text_left">
                  <e-date-picker
                    v-model="regParams.returnDate"
                  >
                  </e-date-picker>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="vanningYn === 'Y'"
          class="mt20"
        >
          <h2 class="content_title">Charcoal Info.</h2>
          <table class="tbl_row mt10">
            <colgroup>
              <col style="width:25%">
              <col style="width:25%">
              <col style="width:25%">
              <col style="width:25%">
            </colgroup>
            <tbody>
              <tr>
                <th scope="col">B/L Shipper</th>
                <td>
                  <select
                    id="chclActShprCstNo"
                    v-model="regParams.chclActShprCstNo"
                  >
                    <option value="">Select</option>
                    <option v-for="cvo in chclActList" :key="cvo.actShprCstNo" :value="cvo.actShprCstNo">{{ cvo.actShprCstNm }}</option>
                  </select>
                </td>
                <th scope="col">Vanning Surveyor.</th>
                <td>
                  <select
                    id="vaniSurvCstNo"
                    v-model="regParams.vaniSurvCstNo"
                    :disabled="vanningSurvYn === 'N'"
                  >
                    <option value="">Select</option>
                    <option v-for="cvo in chclActList" :key="cvo.vaniSurvCstNo" :value="cvo.vaniSurvCstNo">{{ cvo.vaniSurvCstNm }}</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="msdsCheck === 'Y' && regParams.podPortCd === 'SHA'"
          class="desc_list mt20"
        >
          <ul>
            <li>To Shanghai, a A MSDS is required. Please upload the file.</li>
          </ul>
        </div>
        <div
          v-if="msdsCheck === 'Y' && regParams.podPortCd !== 'SHA'"
          class="desc_list mt20"
        >
          <ul>
            <li>To S.China or Ningbo, a A MSDS in CHINESE is required. Please upload the file.</li>
          </ul>
        </div>
        <div
          v-if="consigneeYn === 'Y'"
          class="mt20"
        >
          <h2 class="content_title text_left">{{ $t('msg.CSBK100.169') }}</h2> <!-- Consignee 연락처 -->
          <table class="tbl_col t2">
            <colgroup>
              <col style="width:20%">
              <col style="width:20%">
              <col style="width:20%">
              <col style="width:40%">
            </colgroup>
            <tbody>
              <tr>
                <th scope="col">{{ $t('msg.CSBK100.129') }}</th> <!-- 담당자 -->
                <th scope="col">{{ $t('msg.CSBK100.148') }}</th> <!-- 전화번호 -->
                <th scope="col">{{ $t('msg.CSBK100.170') }}<span class="txt">({{ $t('msg.CSBK100.162') }})</span></th> <!-- FAX번호(선택) -->
                <th scope="col">email<span class="txt">({{ $t('msg.CSBK100.162') }})</span></th> <!-- email(선택) -->
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    v-model="regParams.cnePicNm"
                    id="cnePicNm"
                    maxlength="100"
                    @input="inputConsignee"
                  >
                </td>
                <td>
                  <input
                    type="text"
                    v-model="regParams.cnePicTelNo"
                    id="cnePicTelNo"
                    maxlength="30"
                    @input="inputConsignee"
                  >
                </td>
                <td>
                  <input
                    type="text"
                    v-model="regParams.cnePicFaxNo"
                    maxlength="30"
                    @input="inputConsignee"
                  >
                </td>
                <td>
                  <input
                    type="text"
                    v-model="regParams.cnePicEmlAddr"
                    maxlength="100"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <table
          v-if="regParams.mtyPkupUsagYn !== 'Y' && usrCtrCd !== 'VN'"
          class="tbl_row mt20"
        >
          <colgroup>
            <col style="width:10%">
            <col style="width:40%">
            <col style="width:10%">
            <col style="width:40%">
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">{{ $t('msg.SCHD010P040.022') }}</th> <!-- 서류 마감 -->
              <td>{{ txtDocCls }}</td>
              <th scope="row">{{ $t('msg.CSBK100.075') }}</th> <!-- 모선마감 담당자 -->
              <td>{{ txtDocPic }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('msg.SCHD010P040.028') }}</th> <!-- 반입 마감 -->
              <td>{{ txtCgoCls }}</td>
              <th scope="row">{{ $t('msg.SCHD010P040.031') }}</th> <!-- 반입지/담당자  -->
              <td>{{ txtPolTrmlInfo }}<br>{{ whCodeInfo }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('msg.SCHD010P040.026') }}</th> <!-- EDI 신고마감 -->
              <td>{{ txtMfCls }}</td>
              <th scope="row">MRN NO.</th> <!-- MRN / Call Sign -->
              <td>{{ mrnNo }}</td>
            </tr>
          </tbody>
        </table>
        <div class="desc_list" v-if="lang === 'KOR'">
          <ul>
            <li class="color_blue">{{ $t('msg.CSBK100.175') }}<br>{{ $t('msg.CSBK100.176') }}</li>
          </ul>
        </div>

        <table class="tbl_row mt20" v-if="regParams.podCtrCd === 'IR'">
          <!-- 도착지 이란일 경우 생성 -->
          <colgroup>
            <col style="width:25%">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">B/L SHIPPER(ENGLISH ONLY)</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.actShprCstNm"
                  id="ir_actShprCstNm"
                  type="text"
                  maxlength="100"
                  @input="changeActShprCstNm"
                >
                <span
                  :class="$ekmtcCommon.isNotEmpty(regParams.iranBookingInfo.actShprCstNmC) && regParams.iranBookingInfo.actShprCstNmC !== 'N' ? '' : 'd_none'"
                  id="ir_actShprCstNmC"
                >
                  {{ $t('msg.CSBK100.115') }}
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">NAME OF CONSIGNEE</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.cneCstNm"
                  id="ir_cneCstNm"
                  type="text"
                  maxlength="100"
                  @input="regParams.iranBookingInfo.cneCstNm=regParams.iranBookingInfo.cneCstNm.toUpperCase()"
                >
                <span
                  :class="$ekmtcCommon.isNotEmpty(regParams.iranBookingInfo.cneCstNmC) && regParams.iranBookingInfo.cneCstNmC !== 'N' ? '' : 'd_none'"
                  id="ir_cneCstNmC"
                >
                  {{ $t('msg.CSBK100.115') }}
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">END USER</th>
              <td>
                <div class="tbl_form position_relative">
                  <span class="dv col_5">
                    <input
                      v-model="regParams.iranBookingInfo.endUsrNm"
                      id="ir_endUsrNm"
                      type="text"
                      maxlength="100"
                      @input="regParams.iranBookingInfo.endUsrNm=regParams.iranBookingInfo.endUsrNm.toUpperCase()"
                    >
                    <span
                      :class="$ekmtcCommon.isNotEmpty(regParams.iranBookingInfo.endUsrNmC) && regParams.iranBookingInfo.endUsrNmC !== 'N' ? '' : 'd_none'"
                      id="ir_endUsrNmC"
                    >
                      {{ $t('msg.CSBK100.115') }}
                    </span>
                  </span>
                  <span class="dv">
                    <input type="checkbox" id="sameas" class="ml20" @click="sameConsigneeName"><label for="sameas">SAME AS CONSIGNEE</label>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">END USER INFORMATION</th>
              <td>
                <div class="tbl_form">
                  <span class="dv col_2">
                    <input
                      v-model="regParams.iranBookingInfo.endUsrTelNo"
                      id="ir_endUsrTelNo"
                      type="text"
                      placeholder="phone no"
                      maxlength="25"
                    >
                  </span>
                  <span class="dv col_3">
                    <input
                      v-model="regParams.iranBookingInfo.endUsrEmlAddr"
                      id="ir_endUsrEmlAddr"
                      type="text"
                      placeholder="email"
                      maxlength="100"
                    >
                  </span>
                  <span class="dv">
                    <input
                      v-model="regParams.iranBookingInfo.endUsrAddr"
                      id="ir_endUsrAddr"
                      type="text"
                      placeholder="address"
                      maxlength="100"
                    >
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">NAME OF COMMODITY</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.cmdtNm"
                  id="ir_cmdtNm"
                  type="text"
                  maxlength="100"
                  @input="regParams.iranBookingInfo.cmdtNm=regParams.iranBookingInfo.cmdtNm.toUpperCase()"
                >
                <span
                  :class="$ekmtcCommon.isNotEmpty(cmdtNmC) && cmdtNmC !== 'N' ? '' : 'd_none'"
                  id="ir_cmdtNmC"
                >
                  {{ $t('msg.CSBK100.116') }}
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">HS CODE</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.hsCd"
                  id="ir_hsCd"
                  type="text"
                  maxlength="10"
                >
                <span
                  :class="$ekmtcCommon.isNotEmpty(hsCdC) && hsCdC !== 'N' ? '' : 'd_none'"
                  id="ir_hsCdC"
                >
                  {{ $t('msg.CSBK100.117') }}
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">PURPOSE OF USE</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.useDestCont"
                  id="ir_useDestCont"
                  type="text"
                >
              </td>
            </tr>
            <tr>
              <th scope="row">LOI</th>
              <td>
                <a class="button sm" href="#">LOI DownLoad</a>
                <span class="color_red font_12 ml10">{{ $t('msg.CSBK100.177') }}</span> <!-- 부킹 후 제출(업로드) 부탁드립니다. -->
              </td>
            </tr>
            <tr>
              <th scope="row">SHIPPER REMARK</th>
              <td>
                <textarea
                  v-model="regParams.iranBookingInfo.reqCont"
                  id="reqCont"
                >
                </textarea>
              </td>
            </tr>
            <tr>
              <th scope="row">KMTC REMARK</th>
              <td>
                {{ regParams.iranBookingInfo.apvCont }}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mt20 div_req_area" v-if="lang !== 'KOR'">
          <h2 class="content_title">Request</h2>
          <table class="tbl_row">
            <colgroup>
              <col width="15%">
              <col width="85%">
            </colgroup>
            <tbody>
              <tr>
                <th>CY(Depot) Remark</th>
                <td>
                  <div class="position_relative">
                    <textarea
                      id="dorOdrRmk"
                      v-model="regParams.dorOdrRmk"
                    ></textarea>
                    <div class="div_req_byte">
                      <span class="color_black font_medium" id="byte_dorOdrRmk">0</span>
                      <span class="color_lightgray">/</span>
                      <span class="color_lightgray">2,000</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Document Remark</th>
                <td>
                  <textarea
                    v-if="usrCtrCd !== 'TH'"
                    v-model="regParams.returnDate"
                  >
                  </textarea>
                  <textarea
                    v-if="usrCtrCd === 'TH'"
                    v-model="returnDateTH"
                    readonly
                  >
                  </textarea>
                </td>
              </tr>
              <tr>
                <th>Operation Remark</th>
                <td>
                  <div class="position_relative">
                    <textarea
                      id="oprRmk"
                      v-model="regParams.oprRmk"
                    >
                    </textarea>
                    <div class="div_req_byte">
                      <span class="color_black font_medium" id="byte_oprRmk">0</span>
                      <span class="color_lightgray">/</span>
                      <span class="color_lightgray">2,000</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> <!-- end content_box -->

      <div v-if="saveFlag === 'PICKUP'">
        <div class="mt10 text_right">
          <a
            class="button blue lg mr5"
            @click="confirmPickup()"
          >{{ $t('msg.ONEX100G010.001') }}</a> <!-- 변경 -->
          <!-- <a class="button gray lg">{{ $t('msg.CSBK100.179') }}</a> -->
        </div>
      </div>
    </form>
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePoup"
        @selectFunc="selectFunc"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import { rootComputed } from '@/store/helpers'
import commons from '@/api/services/commons'
import trans from '@/api/rest/trans/trans'
import bookings from '@/api/rest/trans/bookings'
import transCommon from '@/api/rest/trans/transCommon'
import auth from '@/api/services/auth'
import schedule from '@/api/rest/schedule/schedule'
import BookingTableDg from '@/components/trans/BookingTableDg.vue'
import BookingTableOog from '@/components/trans/BookingTableOog'
import BookingTableFlexi from '@/components/trans/BookingTableFlexi'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import PickUpConfirmPop from '@/pages/trans/popup/PickUpConfirmPop'

import EInputNumber from '@/components/common/EInputNumber'
import EDatePicker from '@/components/common/EDatePicker'
import EAutoCompleteCommodity from '@/components/common/EAutoCompleteCommodity'
import EAutoCompleteCstEnm from '@/components/common/EAutoCompleteCstEnmV2'
import BookingScheduleInfo from '@/components/trans/BookingScheduleInfo'

export default {
  name: 'BookingNew',
  components: {
    EBreadcrumbs,
    EInputNumber,
    EDatePicker,
    BookingTableDg,
    BookingTableOog,
    BookingTableFlexi,
    WinLayerPop,
    EAutoCompleteCommodity,
    EAutoCompleteCstEnm,
    PickUpConfirmPop,
    BookingScheduleInfo,
    'container-pop': () => import('@/pages/trans/popup/ContainerPop'),
    'pick-up-search-pop': () => import('@/pages/trans/popup/PickUpSearchPop')
  },
  props: {
    detailBkgNo: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      isExistBkgNo: 'N',
      searchInPickUpInfo: false,
      // propsBkgNo: '',
      customComponent: null,
      usrCtrCd: 'KR',
      lang: 'KOR',
      usrInfo: {
        usrTel: '',
        usrFax: '',
        telCtrNo: ''
      },
      userHkSg: 'N',
      statusMsg: '',
      statusClass: 'cancel',
      schdBtnYn: 'Y', // 스케줄 선택 버튼 노출 여부
      saveFlag: 'PICKUP',
      cstCatCd: 'N',
      fwdrCstCd: '',
      cstNo: '',
      commonCodes: {},
      cntSzList: [],
      cntrRfStsList: [],
      // zip1List: [],
      customerList: [],
      chclActList: [],
      // cstInfoList: [],
      // factoryInfoList: [],
      cn4List: [],
      popParams: {},
      vanningYn: 'N',
      vanningSurvYn: 'Y',
      selectFunc: null,
      txtDocCls: '', // 서류마감
      txtDocPic: '', // 모선마감 담당자
      txtMfCls: '', // EDI 신고마감
      txtEdiPic: '', // 세관마감 담당자
      txtCyOepnDt: '', // CY Open
      txtCfsCloseDt: '', // CFS 반입마감
      txtCgoCls: '', // 반입마감
      txtCfsPlace: '', // CFS 반입장소
      txtPolTrmlInfo: '', // 반입지/담당자
      whCodeInfo: '',
      txtAclCode: '', // ACL 통지처 코드
      mrnNo: '', // MRN
      callSign: '', // CALL SIGN
      txtEmptyCntrOrdPlace: '', // Empty Container Pick up order 제출처
      txtEmptyCntrPlace: '', // Empty container Pick up 장소
      srRest: '',
      localDtm: '',
      vslRest: '',
      cgoRest: '',
      eta: '',
      etd: '',
      cfsCls: '',
      cgoCls: '',
      bkgCloseYn: '',
      rfTeuCount: '',
      cfsRmkYn: '',
      cfsRmkMsg: '',
      kmdVslCheck: '',
      pickDateSgMy: '',
      pickPlaceNm: '',
      pickPlaceCd: '',
      legTermHPNT: '',
      legTermHKM: '',
      templateNo: '',
      msdsCheck: '',
      dgOogFileYn: '',
      flexiFileYn: '',
      rfPlugOverYn: 'N',
      bkgDt: '',
      serverYear: '',
      serverMonth: '',
      serverDay: '',
      serverHour: '',
      serverMin: '',
      serverSecond: '',
      consigneeYn: '',
      cmdtNmC: '',
      hsCdC: '',
      schdDetail: null,
      schdLegList: [], // 스케줄 leg 리스트
      bkgVslDocYn: '',
      vslCheck: '',
      disabledYn: '', // 수정화면 항목 disabled 여부
      unnoDisabledYn: '',
      bookingSpecial: '', // 특수화물 부킹 여부
      bkgDocOver: 'N',
      oldContainerList: [],
      cntrEditYn: 'N',
      bkgPlcCd: '',
      jpFwdrList: [],
      jpBkgShprList: [],
      vaniEmpty: '',
      // vocYn: '',
      cntrTypeList: [
        { cdNm: 'Dry', cd: 'GP', rmk: '20/40' },
        { cdNm: 'HIGH CUBE', cd: 'HC', rmk: '40/45' },
        { cdNm: 'REEFER', cd: 'RF', rmk: '20' },
        { cdNm: 'REEFER HIGH CUBE', cd: 'RH', rmk: '40' },
        { cdNm: 'TANK', cd: 'TK', rmk: '20/40' },
        { cdNm: 'OPEN TOP', cd: 'OT', rmk: '20/40' },
        { cdNm: 'FLAT RACK', cd: 'FR', rmk: '20/40' },
        { cdNm: 'SUPEwR RACK', cd: 'SR', rmk: '40' }
      ],
      cgoTypCdList: [
        { cdNm: '(OOG)Out of Gauge', cd: '02', rmk: 'OT/FR/SR' },
        { cdNm: 'In Gauge', cd: '03', rmk: 'OT/FR/SR/HC' },
        { cdNm: this.$t('js.booking.010'), cd: '01', rmk: 'GP/HC/RF/RH/OT/TK' },
        { cdNm: 'Flexi Bag', cd: '06', rmk: 'GP' }
      ],
      picNmh: '',
      docPicNmh: '',
      cboFactory: '',
      casNoPortYn: 'N',
      isKg: false,
      isTon: false,
      grsWtTon: '',
      grsWtKg: '',
      returnDateTH: '',
      facNmTemp: '',
      zip1Temp: '',
      tfcoCstNmTemp: '',
      facPicNmTemp: '',
      facCtfcNoTemp: '',
      owtrYnTemp: '',
      pcupReqDtTemp: '',
      pcupReqTmTemp: '',
      regParams: {
        bkgNo: '',
        sumValue: '',
        mtyPkupUsagYn: 'N',
        cstBidgMgrNo: '',
        bizPicNm: '',
        bizPicEmlAddr: '',
        bizPicTelPlcNo: '',
        bizPicTelOfcNo: '',
        bizPicFaxPlcNo: '',
        bizPicFaxOfcNo: '',
        bizPicMbpBsnNo: '',
        bizPicMbpOfcNo: '',
        docPicNm: '',
        docPicTelPlcNo: '',
        docPicTelNo: '',
        docPicFaxPlcNo: '',
        docPicFaxNo: '',
        docPicMbpBsnNo: '',
        docPicMbpNo: '',
        docPicEmlAddr: '',
        etxblEmlAddr: '',
        grsWt: '',
        wtCvsCd: '',
        polPortCd: '',
        polCtrCd: '',
        polTrmlCd: '',
        podCtrCd: '',
        podPortCd: '',
        podTrmlCd: '',
        polShtmCd: '01',
        podShtmCd: '01',
        frtPncCd: 'P',
        cneCstEnm: '',
        cmdtDsc: '',
        owtrYn: 'N',
        // tfcoCstNm: 'KCTC',
        // tfcoCstNo: 'TRK001',
        tfcoCstNm: '',
        tfcoCstNo: '',
        emptyFlag: 'F',
        zip1: '',
        zip2: '',
        facNm: '',
        facPicNm: '',
        facCtfcNo: '',
        facPicEmlAddr: '',
        cnePicNm: '',
        cnePicTelNo: '',
        cnePicFaxNo: '',
        cnePicEmlAddr: '',
        cfsFixdTypCd: '',
        cfsFixdEntrNm: '',
        actShprCstEnm: '',
        consYn: 'N',
        repsCont: '',
        docmRmk: '',
        rtnCgoYn: 'N',
        impBlNo: '',
        chclActShprCstNo: '',
        vaniSurvCstNo: '',
        expcYn: '',
        cfsCy: '',
        schFrtAppNo: '',
        schLogDtm: '',
        casNoPortYn: '',
        dpstAcCd: '',
        stuffPlceCd: '',
        bkgShprCstNo: '',
        bkgShprCstEnm: '',
        fwdrCstNo: '',
        fwdrCstEnm: '',
        fwdEdiCd: '',
        dorOdrRmk: '',
        oprRmk: '',
        returnDate: '',
        polEtd: '',
        polEta: '',
        podEta: '',
        megNumYn: '',
        railData: '',
        legTrmlFixdYn: '',
        pickupYn: '',
        containerList: [],
        iranBookingInfo: {
          actShprCstNm: '',
          actShprCstNmC: '',
          cneCstNm: '',
          cneCstNmC: '',
          endUsrNm: '',
          endUsrNmC: '',
          endUsrTelNo: '',
          endUsrEmlAddr: '',
          cmdtNm: '',
          hsCd: '',
          useDestCont: '',
          reqCont: '',
          apvCont: ''
        }
      },
      relYn: true
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    if (this.auth !== undefined) {
      this.lang = this.$ekmtcCommon.isNotEmpty(this.auth.serviceLang) ? this.auth.serviceLang : 'KOR'
      this.cstCatCd = this.auth.cstCatCd

      if (this.usrCtrCd === 'SG' || this.usrCtrCd === 'HK') {
        this.userHkSg = 'Y'
      }
    }

    if (this.memberDetail !== undefined) {
      this.fwdrCstCd = this.memberDetail.fwdrCstCd
      this.cstNo = this.memberDetail.cstCd
      this.bkgPlcCd = this.memberDetail.bkgPlcCd
      this.usrCtrCd = this.memberDetail.userCtrCd
    }

    // this.propsBkgNo = this.detailBkgNo
    if (this.detailBkgNo) {
      this.regParams.bkgNo = this.detailBkgNo
    }

    // [s] 2021.12.21 - router.query 작업
    if (Object.keys(this.$route.query).length > 0) {
      const vo = this.$route.query

      if (vo != null && this.$ekmtcCommon.isNotEmpty(vo.keyword)) {
        // this.propsBkgNo = vo.keyword
        this.regParams.bkgNo = vo.keyword
      }
    }
    // [e] 2021.12.21 - router.query 작업

    // if (this.$ekmtcCommon.isNotEmpty(this.propsBkgNo)) {
    // if (this.$ekmtcCommon.isNotEmpty(this.regParams.bkgNo)) {
    //   this.saveFlag = 'PICKUP'
    //   this.initEdit()
    // }

    this.initParams()
    this.initCommCodes()

    // if (this.lang === 'JPN') {
    //   this.initFwdrInfo()
    // }
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  mounted () {
    if (this.$ekmtcCommon.isNotEmpty(this.regParams.bkgNo)) {
      // this.saveFlag = 'PICKUP'
      setTimeout(() => {
        this.initEdit()
      }, 300)
      //this.initEdit()
    }
    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })
  },
  methods: {
    handleKeydown (e) {
      // JamSin
      if (this.relYn) {
        if (e.key === 'Enter') {
          this.initEdit()
        }
      }
    },
    initParams () {
      const regParams = this.regParams
      if (this.usrCtrCd === 'IN') {
        regParams.dpstAcCd = '01'
        regParams.stuffPlceCd = '01'
      }
    },
    async initCommCodes () {
      const arrCdId = ['01031', '01237', '01238', '01291', '01043']
      await Promise.all([
        // auth.info(this.auth.jwt).then(res => {
        //   const result = res.data

        //   if (this.saveFlag === 'ADD') {
        //     this.initUserInfo(result)
        //   }
        // }),
        commons.getCommonsCodesCommon(arrCdId).then(res => {
          this.commonCodes = res
          this.cntSzList = this.commonCodes['01031']
          this.cntrRfStsList = this.commonCodes['01291']

          if (this.usrCtrCd === 'TH') {
            const cntrRfStsList = this.cntrRfStsList
            this.cntrRfStsList = cntrRfStsList.filter(vo => vo.cd >= 20 || vo.cd === '01')
          }

          if (this.lang === 'KOR') {
            this.cntrTypeList = this.cntrTypeList.filter(vo => vo.cd !== 'SR')
          }

          const regParams = this.regParams
          const containerList = regParams.containerList

          containerList.forEach(c => {
            this.setCntrTypList(c)
            this.setCgoTypList(c)
          })
        }),
        commons.getPlcList('CN4').then(res => {
          this.cn4List = res.data
        }),
        commons.getServerTime().then(res => {
          this.serverYear = res.data.substring(0, 4)
          this.serverMonth = res.data.substring(4, 6)
          this.serverDay = res.data.substring(6, 8)
          this.serverHour = res.data.substring(8, 10)
          this.serverMin = res.data.substring(10, 12)
          this.serverSecond = res.data.substring(12, 14)
        })
      ])
    },

    async initEdit () {
      // [s] 2021.12.21 - router.query 작업
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.replace({ query: {} })
      }
      // [e] 2021.12.21 - router.query 작업

      this.searchInPickUpInfo = true
      // 검색조건에 공백을 제거 후 처리한다.(SQL또는 api 콜하는 쪽에서 할지 고민..)
      this.regParams.bkgNo = this.regParams.bkgNo.trim()

      if (this.fnValidationBkgNo()) {
        let result = null
        // await trans.getPickupInfo(this.propsBkgNo).then(res => {
        await trans.getPickupInfo(this.regParams.bkgNo).then(async res => {
          result = res.data
          console.log('result', result)
          if (this.$ekmtcCommon.isEmpty(result)) {
            //검색조건을 제외하고 초기화
            result = { bkgNo: this.regParams.bkgNo }
            this.setDetailInfo(result)
            this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
          } else {
            this.setDetailInfo(result)
            this.regParams.hidCS008I = result.makecs008i
            this.regParams.schFrtAppNo = result.frtAppNo
            this.localDtm = result.localDtm

            //Temp 설정
            this.facNmTemp = result.facNm || ''
            this.tfcoCstNmTemp = result.tfcoCstNm || ''
            this.facPicNmTemp = result.facPicNm || ''
            this.facCtfcNoTemp = result.facCtfcNo || ''
            this.owtrYnTemp = result.owtrYn || ''
            this.containerList = result.containerList

            if (this.usrCtrCd !== 'KR') {
              if (this.usrCtrCd !== 'TH') {
                this.regParams.returnDate = result.docmRmk
              } else {
                this.regParams.returnDate = result.docmRmk.replace('Return: ', '')
                this.returnDateTH = result.docmRmk
              }
            }

            this.isExistBkgNo = 'Y'
            let searchFlag = true
            const TH = this
            const params = {
              asBkgNo: this.regParams.bkgNo,
              asVoyNo: this.regParams.voyNo,
              asVslCd: this.regParams.vslCd,
              asPolPortCd: this.regParams.polPortCd,
              asPodPortCd: this.regParams.podPortCd
            }

            await schedule.checkBkgRestriction(params).then(async res1 => { // 부킹 제약사항 별도 조회
                const restriction = res1.data
                if (restriction !== undefined && restriction.msg === 'Y') {
                  result.srRest = restriction.srRest
                  result.localDtm = restriction.localDtm
                  result.cgoRest = restriction.cgoRest
                  result.vslRest = restriction.vslRest
                  this.scheduleCloseInfo(result, 'EDIT')

                  if (TH.regParams.polShtmCd === '02') {
                    this.setCfsCodeInfo() // CFS 반입지 담당자 정보를 다시 가져온다
                  }

                  let docCloseTime = TH.cgoRest // 서류마감일시
                  const localDate = TH.$ekmtcCommon.getStrToDate(TH.localDtm) //현재시간
                  let docDate = ''

                  if (this.$ekmtcCommon.isEmpty(docCloseTime)) {
                    docCloseTime = TH.regParams.polEta

                    //입항일에서 -12시간을 한다.
                    docDate = new Date(Number(docCloseTime.substring(0, 4)), Number(docCloseTime.substring(4, 6)) - 1, Number(docCloseTime.substring(6, 8)), Number(docCloseTime.substring(8, 10) - 12), Number(docCloseTime.substring(10, 12)))

                    /******************************************************
                     * 입항시간이 17~24시 사이에 있을 경우 : 서류마감시간 = 입항일-1일 17시
                     * 입항시간이 24~09시 사이에 있을 경우 : 서류마감시간 = 입항일 17시
                     * 그외 서류마감시간 = 입항시간
                     ******************************************************/
                    if (docDate.getHours() > 17 && docDate.getHours() < 24) {
                      docDate = new Date(Number(docCloseTime.substring(0, 4)), Number(docCloseTime.substring(4, 6)) - 1, Number(docCloseTime.substring(6, 8)) - 1, 17, Number(docCloseTime.substring(10, 12)))
                    } else if (docDate.getHours() <= 24 && docDate.getHours() < 9) {
                      docDate = new Date(Number(docCloseTime.substring(0, 4)), Number(docCloseTime.substring(4, 6)) - 1, Number(docCloseTime.substring(6, 8)), 17, Number(docCloseTime.substring(10, 12)))
                    }
                  } else {
                    docDate = new Date(Number(docCloseTime.substring(0, 4)), Number(docCloseTime.substring(4, 6)) - 1, Number(docCloseTime.substring(6, 8)), Number(docCloseTime.substring(8, 10)), Number(docCloseTime.substring(10, 12))) //서류마감시한
                  }

                  if (TH.$ekmtcCommon.isNotEmpty(docCloseTime)) {
                    if (docDate <= localDate) { // 서류마감 이전 : CP 증량 가능 여부
                      TH.bkgDocOver = 'Y'
                    }
                  }
                } else {
                  searchFlag = false
                }
              }).catch((err) => {
                console.log(err)
                searchFlag = false
              }).finally(() => {
                if (!searchFlag) {
                  TH.$ekmtcCommon.alertDefault(TH.$t('js.common.005'))
                }
              })
          }
        }).catch(err => {
          console.log(err)
          this.$ekmtcCommon.alertDefault(this.$t('js.common.005')) // 조회에 실패했습니다. 관리자에게 문의해주세요.
          this.isExistBkgNo = 'N'
        })

        if (this.regParams.mtyPkupUsagYn !== 'Y') {
          this.scheduleCloseInfo(result, 'PICKUP')

          if (this.regParams.polShtmCd === '02') {
            this.setCfsCodeInfo() // CFS 반입지 담당자 정보를 다시 가져온다
          }

          this.initStatus(result)

          const docCloseTime = this.cgoRest // 서류마감일시
          const localDate = this.$ekmtcCommon.getStrToDate(this.localDtm) //현재시간
          const docDate = this.$ekmtcCommon.getStrToDate(this.docCloseTime)

          if (this.$ekmtcCommon.isNotEmpty(docCloseTime)) {
            if (docDate <= localDate) {
              this.bkgDocOver = 'Y'
            }
          } else {
            this.bkgDocOver = 'N'
          }

          if (this.$ekmtcCommon.isNotEmpty(this.regParams.rfTeuCount)) {
            this.rfTeuCount = this.regParams.rfTeuCount
          } else {
            this.rfTeuCount = ''
          }
        }

        this.oldContainerList = this.regParams.containerList

        //000001341573 [eKMTC] 메인화면 - '픽업지 변경' 기능 제한 요청
        //픽업 변경 가능 여부 체크
        if (this.regParams.containerList.length > 0) {
          let isChangePickupDisabled = false
          $.each(this.regParams.containerList, function (i, ct) {
            if (ct.shprVanYn === 'Y' || (ct.cntrTypCd === 'RF' || ct.cntrTypCd === 'RH')) {
              isChangePickupDisabled = true

                $('[id*=pcupReqDt_]').attr('disabled', true)
                $('[id*=pcupReqTm_]').attr('disabled', true)
                $('[id*=pcupCyCd_]').attr('disabled', true)
                $('[id*=pickUpMan_]').attr('disabled', true)
                $('[id*=pickUpTel_]').attr('disabled', true)
                return false
            }
          })
          if (isChangePickupDisabled === false) {
            $('[id*=pcupReqDt_]').removeAttr('disabled')
            $('[id*=pcupReqDt_]').next().removeAttr('disabled')
            $('[id*=pcupReqTm_]').removeAttr('disabled')
            $('[id*=pcupCyCd_]').removeAttr('disabled')
            $('[id*=pickUpMan_]').removeAttr('disabled')
            $('[id*=pickUpTel_]').removeAttr('disabled')
          }
        }
      }

      this.searchInPickUpInfo = false
    },
    initStatusEng () {
      const regParams = this.regParams
      const srRest = this.srRest

      if (this.lang === 'JPN') {
        let cgoFlag = false

        regParams.containerList.forEach(c => {
          if (this.$ekmtcCommon.isNotEmpty(c.cntrTypCd) && 'RF|RH|OT|SR|FR'.indexOf(c.cntrTypCd) > -1) {
            cgoFlag = true
          }
        })

        if (srRest !== 'C' && !cgoFlag) {
          return
        }
      } else if (this.usrCtrCd === 'XXX') {
        if (srRest !== 'C' && this.bkgDocOver !== 'Y') {
          return
        }
      }

      // const inputEl = document.getElementsByTagName('input')
      const inputEl = document.querySelectorAll('#frm input')
      Array.from(inputEl).forEach(el => {
        el.disabled = true
      })

      const selectEl = document.getElementsByTagName('select')
      Array.from(selectEl).forEach(el => {
        el.disabled = true
      })

      const textEl = document.getElementsByTagName('textarea')
      Array.from(textEl).forEach(el => {
        el.disabled = true
      })

      this.schdBtnYn = 'N'
      this.disabledYn = 'Y'
      this.unnoDisabledYn = 'Y'
    },
    initStatus () {
      const regParams = this.regParams
      if (this.lang !== 'KOR') {
        if (regParams.bkgStsCd === '01' && this.vaniEmpty !== 'Y') {
          this.initStatusEng()
        }
        return
      }

      // const inputEl = document.getElementsByTagName('input')
      const inputEl = document.querySelectorAll('#frm input')
      Array.from(inputEl).forEach(el => {
        //el.disabled = true

        const id = el.getAttribute('id')

        if (id !== null) {
          const idNm = id.indexOf('_') > -1 ? id.split('_')[0] : id
          // let checkNm = 'pcupReqDt|pcupCyCd|pickUpMan|pickUpTel'
          let checkNm = 'pcupReqDt|pcupCyCd|pickUpMan|pickUpTel|bkgNo'
          if ((checkNm.indexOf(idNm) > -1)) {
            //el.disabled = false
          } else {
            el.disabled = true
          }
        } else {
          el.disabled = true
        }
      })

      const selectEl = document.getElementsByTagName('select')
      Array.from(selectEl).forEach(el => {
        el.disabled = true
      })

      const checkEl = document.getElementsByTagName('checkbox')
      Array.from(checkEl).forEach(el => {
        el.disabled = true
      })

      const textEl = document.getElementsByTagName('textarea')
      Array.from(textEl).forEach(el => {
        el.readonly = true
      })
    },
    checkValidationVanningInfo (id) {
      let isOk = true
      const frm = document.querySelector('#frm')
      const selector = frm.querySelector('#' + id)

      this.$ekmtcCommon.hideErrorTooltip(selector)
      if (selector.disabled !== true && this.$ekmtcCommon.isEmpty(selector.value)) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      return isOk
    },
    changeFactoryInfo (e) {
      const value = e.target.value
      const regParams = this.regParams

      if (value !== '') {
        const arrValues = value.split('$')

        if (arrValues.length > 0) {
          regParams.facPicNm = arrValues[0]
          regParams.facCtfcNo = arrValues[1]
          regParams.facNm = arrValues[2]
        }
      }
    },
    setDetailInfo (result) {
      const mstDefault = {
        mtyPkupUsagYn: 'N',
        cstBidgMgrNo: '',
        docPicNm: '',
        docPicTelPlcNo: '',
        docPicTelNo: '',
        docPicFaxPlcNo: '',
        docPicFaxNo: '',
        docPicMbpBsnNo: '',
        docPicMbpNo: '',
        docPicEmlAddr: '',
        etxblEmlAddr: '',
        grsWt: '',
        polPortCd: '',
        polCtrCd: '',
        polTrmlCd: '',
        podCtrCd: '',
        podPortCd: '',
        podTrmlCd: '',
        polShtmCd: '01',
        podShtmCd: '01',
        frtPncCd: 'P',
        cneCstEnm: '',
        cmdtDsc: '',
        owtrYn: '',
        tfcoCstNm: '',
        tfcoCstNo: '',
        emptyFlag: 'F',
        zip1: '',
        zip2: '',
        facNm: '',
        facPicNm: '',
        facCtfcNo: '',
        facPicEmlAddr: '',
        cnePicNm: '',
        cnePicTelNo: '',
        cnePicFaxNo: '',
        cnePicEmlAddr: '',
        cfsFixdTypCd: '01',
        cfsFixdEntrNm: this.$t('js.booking.029'),
        actShprCstEnm: '',
        consYn: 'N',
        repsCont: '',
        docmRmk: '',
        rtnCgoYn: 'N',
        impBlNo: '',
        chclActShprCstNo: '',
        vaniSurvCstNo: '',
        expcYn: '',
        cfsCy: '',
        schFrtAppNo: '',
        schLogDtm: '',
        casNoPortYn: '',
        dpstAcCd: '',
        stuffPlceCd: '',
        bkgShprCstNo: '',
        fwdrCstNo: '',
        fwdEdiCd: '',
        dorOdrRmk: '',
        oprRmk: '',
        returnDate: '',
        containerList: [],
        iranBookingInfo: {}
      }

      if (result !== undefined && result !== null) {
        const frm = document.querySelector('#frm')
        this.$ekmtcCommon.hideErrorTooltipAll(frm)

        if (frm.querySelector('#area_input_bkgNo .tooltip_essen_box') !== null) {
          frm.querySelector('#area_input_bkgNo .tooltip_essen_box').style.display = 'block'
        }

        this.regParams = {}
        this.regParams = { ...mstDefault, ...result }
        if (this.lang === 'KOR' && (result.polShtmCd === '01' || result.polShtmCd === '03')) {
          if (result.dorOdrRmk !== undefined && result.dorOdrRmk.indexOf('||') > -1) {
            const rmk = result.dorOdrRmk.split('||')
            if (rmk !== undefined) {
              const arrZip = rmk[0].split(',')
              if (arrZip !== undefined && arrZip.length > 0) {
                this.regParams.zip1 = arrZip[0]
                this.regParams.zip1Temp = arrZip[0]
                if (arrZip.length > 1) {
                  this.regParams.zip2 = arrZip[1]
                }
              }
            }
          }
        }

        const apvStsList = this.commonCodes['01043']

        this.regParams.containerList.forEach((info, idx) => {
          this.setCntrTypList(info)
          this.setCgoTypList(info)
          if (info.cgoTypCd === '05') {
            info.chkNOR = 'Y'
            info.cgoTypCd = ''
          }
          info.pcupReqDtOld = ''
          info.pcupCyCdTemp = ''
          info.apvNo = ''

          if (this.$ekmtcCommon.isEmpty(info.cgoTypCd)) {
            info.cgoTypCd = ''
          }

          if (this.$ekmtcCommon.isNotEmpty(info.pcupCyNm)) {
            info.pickUpPlace = info.pcupCyNm
          }

          if (this.$ekmtcCommon.isNotEmpty(info.pcupCyCd)) {
            info.pcupCyCdBak = info.pcupCyCd
            info.pcupCyCdTemp = info.pcupCyCd
          }

          if (this.$ekmtcCommon.isNotEmpty(info.pcupPic)) {
            info.pickUpMan = info.pcupPic.split(',')[0]
            info.pickUpTel = info.pcupPic.split(',')[1]
          }

          if (this.$ekmtcCommon.isNotEmpty(info.pcupReqDt)) {
            info.pcupReqDtBak = info.pcupReqDt
            info.pcupReqDtOld = info.pcupReqDt
          }

          if (this.$ekmtcCommon.isNotEmpty(info.pcupReqTm) && info.pcupReqTm.length > 2) {
            info.pcupReqTm = info.pcupReqTm.substring(0, 2)
          }

          if (this.$ekmtcCommon.isNotEmpty(info.cntrTypCd) &&
              'RF|RH'.indexOf(info.cntrTypCd) > -1 &&
              this.$ekmtcCommon.isNotEmpty(info.reqConts) &&
              info.reqConts.indexOf('|||') > -1
            ) {
            const splitInfo = info.reqConts.split('|||')
            const apvNo = splitInfo[0]
            const reqConts = splitInfo[1]

            info.apvNo = apvNo
            info.reqConts = reqConts
          }

          if (this.$ekmtcCommon.isNotEmpty(info.apvStsCd)) {
            const apvStsInfo = apvStsList.filter(vo => vo.cd === info.apvStsCd)
            if (apvStsInfo !== undefined && apvStsInfo.length > 0) {
              info.apvStsNm = apvStsInfo[0].cdNm
            }
          }

          if (
              (this.$ekmtcCommon.isNotEmpty(info.cntrTypCd) && 'RF|RH|OT|FR|SR|TK'.indexOf(info.cntrTypCd) > -1) ||
              this.$ekmtcCommon.isNotEmpty(info.cgoTypCd) ||
              this.regParams.owtrYn === 'N' ||
            this.regParams.bkgStsCd === '03'
          ) {
            this.bookingSpecial = 'Y'
          }

          if (
            this.saveFlag === 'PICKUP' && this.$ekmtcCommon.isNotEmpty(info.vaniYn) &&
            this.$ekmtcCommon.isEmpty(info.vaniDt) &&
            this.$ekmtcCommon.isEmpty(info.reqRno)
          ) {
            this.vaniEmpty = 'Y'
          }
        })

        if (this.$ekmtcCommon.isNotEmpty(result.grsWt)) {
          const type = result.wtCvsCd === 'K' ? 'KG' : 'TON'
          if (type === 'TON') {
            this.grsWtTon = result.grsWt
          } else {
            this.grsWtKg = result.grsWt
          }

          this.changeGrsWt(result.grsWt, type)
        }
      }
    },
    customerSearch (e) {
      const keyword = e
      const TH = this
      commons.getCommonsCodesCustomer(keyword, this.usrCtrCd).then((data) => {
        const list = data.data
        if (list === undefined || list === null || list.length === 0) {
          TH.customerList = []
        } else {
          TH.customerList = list.map(vo => vo.cdNm)
        }
      })
    },
    changeDocInfo () {
      const regParams = this.regParams

      this.docPicNmh = this.picNmh
      regParams.docPicNm = regParams.bizPicNm
      regParams.docPicTelPlcNo = regParams.bizPicTelPlcNo
      regParams.docPicTelNo = regParams.bizPicTelOfcNo
      regParams.docPicFaxPlcNo = regParams.bizPicFaxPlcNo
      regParams.docPicFaxNo = regParams.bizPicFaxOfcNo
      regParams.docPicMbpBsnNo = regParams.bizPicMbpBsnNo
      regParams.docPicMbpNo = regParams.bizPicMbpOfcNo
      regParams.docPicEmlAddr = regParams.bizPicEmlAddr
    },
    changeCmdtDsc (vo) {
      this.regParams.cmdtDsc = vo.cdNm

      if (this.$ekmtcCommon.isNotEmpty(vo.cdNm)) {
        const frm = document.querySelector('#frm')
        const selector = frm.querySelector('#cmdtDsc')
        this.$ekmtcCommon.hideErrorTooltip(selector)
      }
    },
    setCntrTypList (cvo) {
      const TH = this
      let subCntrTypList = []
      if (TH.regParams.mtyPkupUsagYn !== 'Y') {
        subCntrTypList = this.cntrTypeList.filter(vo => vo.rmk.indexOf(cvo.cntrSzCd) > -1)
      } else {
        subCntrTypList = this.cntrTypeList.filter(vo => vo.rmk.indexOf(cvo.cntrSzCd) > -1 && (vo.cd === 'GP' || vo.cd === 'HC'))
      }

      TH.$set(cvo, 'subCntrTypList', subCntrTypList)
    },
    setCgoTypList (cvo) {
      const regParams = this.regParams
      const polShtmCd = regParams.polShtmCd

      let subCgoTypList = []

      if (cvo.cntrTypCd !== '') {
        if (regParams.mtyPkupUsagYn !== 'Y') {
          subCgoTypList = this.cgoTypCdList.filter(vo => vo.rmk.indexOf(cvo.cntrTypCd) > -1)
          if (polShtmCd === '02' && regParams.polPortCd !== 'USN') {
            // 출발지가 울산인 경우 CFS booking도 위험물 선적 가능
            // 이외 CFS는 위험물 선택 불가
            subCgoTypList = this.cgoTypCdList.filter(vo => vo.rmk.indexOf(cvo.cntrTypCd) > -1 && vo.cd !== '01')
          }

          if (cvo.cntrSzCd === '40' && cvo.cntrTypCd === 'GP') {
            subCgoTypList = this.cgoTypCdList.filter(vo => vo.rmk.indexOf(cvo.cntrTypCd) > -1 && vo.cd !== '06')
          }

          if (this.lang !== 'KOR') {
            if (cvo.cntrSzCd === '40' && cvo.cntrTypCd === 'HC') {
              subCgoTypList = this.cgoTypCdList.filter(vo => vo.rmk.indexOf(cvo.cntrTypCd) > -1 && vo.cd !== '03')
            }
          }
        }

        this.$set(cvo, 'subCgoTypList', subCgoTypList)
      }
    },
    getPickupPlace (idx) {
      const TH = this
      const regParams = TH.regParams
      if (this.saveFlag !== 'PICKUP' && (TH.usrCtrCd !== 'ID' && TH.usrCtrCd !== 'IN' && TH.usrCtrCd !== 'VN') && (regParams.owtrYn === 'N' && regParams.mtyPkupUsagYn !== 'Y' && this.lang === 'KOR')) {
        return
      }

      const cvo = this.regParams.containerList[idx]
      // 픽업 부킹 등록 -> 수정일 때 기존 등록 내용 변경 못하도록 처리
      if (this.$ekmtcCommon.isNotEmpty(cvo.pcupReqDtOld) && this.$ekmtcCommon.isNotEmpty(cvo.pcupCyCd) && regParams.mtyPkupUsagYn === 'Y') {
        return
      }

      if (this.lang === 'KOR' && regParams.owtrYn === 'Y' && regParams.emptyFlag === 'E' && cvo.shprVanYn === 'Y') {
        return
      }

      if (this.$ekmtcCommon.isEmpty(cvo.cntrSzCd)) {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.009'))
        return
      }

      if (this.$ekmtcCommon.isEmpty(cvo.cntrTypCd)) {
        this.$ekmtcCommon.alertDefault(this.$t('js.bookingValidation.006'))
        return
      }

      if (this.$ekmtcCommon.isEmpty(cvo.pcupReqDt)) {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.021'))
        return
      }

      if (cvo.pcupReqDt.length !== 8) {
        this.$ekmtcCommon.alertDefault(this.$t('js.bookingValidation.022'))
        return
      }

      const polPortCd = this.$ekmtcCommon.isNotEmpty(this.regParams.polPortCd) ? this.regParams.polPortCd : 'USN'
      this.popParams = {
        cntrSize: cvo.cntrSzCd,
        cntrType: cvo.cntrTypCd,
        polPortCd: polPortCd,
        pickupDate: cvo.pcupReqDt,
        selectRow: idx,
        mtyPkupUsagYn: this.regParams.mtyPkupUsagYn,
        iotCntrYn: cvo.iotEqpmYn
      }

      this.selectFunc = this.pickUpSelect

      this.openPopup('pick-up-search-pop')
    },
    pickUpSelect (obj) {
      const containerVo = this.regParams.containerList[obj.selectIdx]

      containerVo.pickUpPlace = obj.cyNm
      containerVo.pickUpMan = obj.picNm
      containerVo.pickUpTel = obj.picTel
      containerVo.pcupCyCd = obj.cyCd
      containerVo.pcupReqDtBak = containerVo.pcupReqDt
      containerVo.rfHpmgUrl = obj.rfHpmgUrl

      this.checkValidationCntr('pcupCyCd', obj.selectIdx, 'KEYUP')
    },
    setCntrPickInfo (cvo) {
      const regParams = this.regParams
      const polPortCd = regParams.polPortCd
      const zip1 = regParams.zip1

      cvo.pickUpPlace = ''
      cvo.pickUpMan = ''
      cvo.pickUpTel = ''
      cvo.pcupCyCd = ''

      if ((regParams.polShtmCd === '01' || regParams.polShtmCd === '03') && regParams.owtrYn === 'N' && this.lang === 'KOR') {
        cvo.pickUpPlace = 'KCTC'
        if (this.$ekmtcCommon.isNotEmpty(zip1) && this.$ekmtcCommon.isNotEmpty(cvo.cntrTypCd)) {
          if (polPortCd === 'PUS' || polPortCd === 'PNC') {
            if (zip1 === this.$t('js.booking.004') || zip1 === this.$t('js.booking.005') || zip1 === this.$t('js.booking.006') || zip1 === this.$t('js.booking.007')) {
              if (cvo.cntrTypCd === 'GP' || cvo.cntrTypCd === 'HC') {
                cvo.pcupCyCd = 'KBGK'
              } else {
                cvo.pcupCyCd = 'ICT:HBCT:UAM'
              }
            } else {
              if (cvo.cntrTypCd === 'GP' || cvo.cntrTypCd === 'HC') {
                cvo.pcupCyCd = 'UAM'
              } else {
                cvo.pcupCyCd = 'HBCT:UAM'
              }
            }

            if (cvo.pcupCyCd === 'UAM') {
              if (cvo.cntrTypCd === 'HC' && cvo.cntrSzCd === '40') {
                cvo.pcupCyCd = 'HBCT'
              }

              if (cvo.cntrTypCd === 'GP') {
                cvo.pcupCyCd = 'HBCT'
              }
            }
          } else if (polPortCd === 'INC') {
            if (zip1 === this.$t('js.booking.004') || zip1 === this.$t('js.booking.005') || zip1 === this.$t('js.booking.006') || zip1 === this.$t('js.booking.007')) {
              if (cvo.cntrTypCd === 'GP' || cvo.cntrTypCd === 'HC') {
                cvo.pcupCyCd = 'KINC'
              } else {
                cvo.pcupCyCd = 'ICT:IKEC'
              }
            } else {
              if (cvo.cntrTypCd === 'GP' || cvo.cntrTypCd === 'HC') {
                cvo.pcupCyCd = 'IKEC'
              } else {
                cvo.pcupCyCd = 'ICT:IKEC'
              }
            }
          } else if (polPortCd === 'KAN') {
            if (zip1 === this.$t('js.booking.004') || zip1 === this.$t('js.booking.005') || zip1 === this.$t('js.booking.006') || zip1 === this.$t('js.booking.007')) {
              if (cvo.cntrTypCd === 'GP' || cvo.cntrTypCd === 'HC') {
                cvo.pcupCyCd = 'KBGK'
              } else {
                cvo.pcupCyCd = 'KIT'
              }
            } else {
              cvo.pcupCyCd = 'KIT'
            }
          } else {
            if (zip1 === this.$t('js.booking.004') || zip1 === this.$t('js.booking.005') || zip1 === this.$t('js.booking.006') || zip1 === this.$t('js.booking.007')) {
              if (cvo.cntrTypCd === 'GP' || cvo.cntrTypCd === 'HC') {
                cvo.pcupCyCd = 'KBGK'
              } else {
                cvo.pcupCyCd = 'UNCT'
              }
            } else {
              cvo.pcupCyCd = 'UNCT'
            }
          }

          if (cvo.cntrTypCd === 'RF' || cvo.cntrTypCd === 'RH' || cvo.cntrTypCd === 'OT' || cvo.cntrTypCd === 'FR') {
            if (polPortCd === 'INC') {
              cvo.pcupCyCd = 'ICT'
            } else {
              cvo.pcupCyCd = 'HBCT'
            }
          }

          const params = {
            pcupCyCd: cvo.pcupCyCd,
            cntrSzCd: cvo.cntrSzCd,
            cntrTypCd: cvo.cntrTypCd,
            polPortCd: polPortCd
          }

          trans.getPickupOne(params).then((res) => {
            const result = res.data
            if (result !== null && result !== undefined) {
              result.forEach(c => {
                if (Number(c.rstChk) === 0 && this.$ekmtcCommon.isNotEmpty(c.cyCd)) {
                  cvo.pcupCyCd = c.cyCd
                } else if (Number(c.rstChk) === 1 && cvo.cntrTypCd === 'SR') {
                  this.$ekmtcCommon.alertDefault(this.$t('js.booking.032'))
                  cvo.cntrTypCd = ''
                }
              })
            }
          })
        }
      }
    },
    changeGrsWt (value, type) {
      if (this.$ekmtcCommon.isEmpty(value)) {
        this.isKg = false
        this.isTon = false
        this.regParams.grsWt = ''
        this.regParams.wtCvsCd = ''
        this.grsWtKg = ''
        this.grsWtTon = ''
      } else {
        if (type === 'TON') {
          const ton = this.grsWtTon
          const kg = Number(ton) * 1000

          this.grsWtKg = kg
          this.regParams.grsWt = ton
          this.regParams.wtCvsCd = 'T'
          this.isKg = false
          this.isTon = true
        } else {
          const kg = this.grsWtKg
          const ton = (Number(kg) * 1000) / 1000000
          this.grsWtTon = ton

          this.regParams.grsWt = kg
          this.regParams.wtCvsCd = 'K'

          this.isKg = true
          this.isTon = false
        }
      }
    },
    checkValidationCntrAll () {
      let isOk = true
      const prefix = ['cntrTypCd', 'cntrQty', 'pcupReqDt', 'pcupCyCd', 'cfsFixdEntrNm']
      const containerList = this.regParams.containerList

      containerList.forEach((c, idx) => {
        prefix.forEach(el => {
          const result = this.checkValidationCntr(el, idx, 'SAVE')
          if (!result) {
            isOk = false
            return false
          }
        })
      })

      return isOk
    },
    checkValidationCntr (prefix, idx, flag) {
      let isOk = true
      const frm = document.querySelector('#frm')
      const id = prefix + '_' + idx
      const selector = frm.querySelector('#' + id)
      const regParams = this.regParams
      const cntrInfo = regParams.containerList[idx]

      this.$ekmtcCommon.hideErrorTooltip(selector)
      if ((regParams.polShtmCd === '01' || regParams.polShtmCd === '03') && prefix === 'cfsFixdEntrNm') {
        return true
      } else if (regParams.polShtmCd === '02' && prefix === 'pcupCyCd') {
        return true
      }

      if (cntrInfo.shprVanYn === 'Y') {
        if (prefix === 'pcupCyCd') {
          return true
        }
      }

      if (prefix === 'cntrTypCd' && this.$ekmtcCommon.isEmpty(selector.value)) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      if (prefix === 'cntrQty' && (this.$ekmtcCommon.isEmpty(selector.value.trim()) || Number(selector.value) < 1)) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
        isOk = false

        if (!this.fnPickupChk()) return false
      }

      if (prefix === 'pcupReqDt' && ((this.$ekmtcCommon.isEmpty(selector.value) && cntrInfo.shprVanYn !== 'Y') || this.$ekmtcCommon.isNotEmpty(selector.value))) {
        const pcupReqDt = cntrInfo.pcupReqDt
        const pcupReqDate = this.$ekmtcCommon.getStrToDate(pcupReqDt)
        const today = new Date(Number(this.serverYear), Number(this.serverMonth) - 1, Number(this.serverDay), Number(this.serverHour), Number(this.serverMin), Number(this.serverSecond))
        const today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate())

        if (this.$ekmtcCommon.isNotEmpty(cntrInfo.pcupReqDtOld)) {
          if (pcupReqDt.length === 8 && cntrInfo.pcupReqDtOld.length === 8 && pcupReqDt === cntrInfo.pcupReqDtOld) {
            return true
          }
        }

        if (this.lang === 'KOR') {
          if (pcupReqDate < today1) {
            if (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.restriction.011'))
            } else {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.restriction.010'))
            }
            return false
          }
        }

        if (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') {
          if (this.$ekmtcCommon.isEmpty(selector.value) && cntrInfo.cntrTypCd !== 'TK' && cntrInfo.shprVanYn !== 'Y') {
            if (regParams.polCtrCd === 'PH') {
              return true
            }

            if (regParams.polCtrCd === 'SG' || regParams.polCtrCd === 'MY' || regParams.polCtrCd === 'JP') {
              cntrInfo.pcupReqDt = this.pickDateSgMy
              return true
            }

            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            return false
          } else if (cntrInfo.pcupReqDt.length < 8) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.bookingValidation.009'))
            return false
          } else {
            if (this.usrCtrCd === 'KR') {
              const numDay = this.$ekmtcCommon.getDateDayOfWeek(selector.value)

              if (cntrInfo.cntrTypCd === 'RH' || cntrInfo.cntrTypCd === 'RF') {
                if (numDay === 6) {
                  if (cntrInfo.pcupCyCd !== 'HBCT' && cntrInfo.pcupCyCd !== 'ICT') {
                    // RF booking : Pick Up일자가 토요일일때 HBCT, ICT 인 경우만 저장 가능 / 그 외 저장 불가
                    this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.184'))
                    return false
                  }
                } else if (numDay === 0) {
                  this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.185')) // RF booking : Pick Up일자가 일요일일때 저장 불가
                  return false
                }
              }
            }

            if (!this.$ekmtcCommon.isValidDate(pcupReqDt, 8)) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.bookingValidation.022'))
              return false
            }

            if (regParams.polCtrCd === 'KR' && regParams.mtyPkupUsagYn !== 'Y') {
              const eta = regParams.polEta.substring(0, 8)
              const etaDate = new Date(eta.substring(0, 4), Number(eta.substring(4, 6)), Number(eta.substring(6, 8)) - 3)
              const pickDate = new Date(pcupReqDt.substring(0, 4), Number(pcupReqDt.substring(4, 6)), Number(pcupReqDt.substring(6, 8)))
              etaDate.setDate(etaDate.getDate() - 14)

              if (this.$ekmtcCommon.isNotEmpty(cntrInfo.pcupReqDtOld)) {
                if (pcupReqDt !== cntrInfo.pcupReqDtOld) {
                  if (etaDate > pickDate) {
                    // PICK UP DATE는 입항일로부터 14일 이내로만 변경이 가능합니다. 14일 이전으로 변경을 원하시는 경우 담당 영업사원에게 개별 문의를 부탁드립니다.
                    //this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.186'))
                    //return false
                  }
                }
              } else {
                if (etaDate > pickDate) {
                  // PICK UP DATE는 입항일로부터 14일 이내로만 변경이 가능합니다. 14일 이전으로 변경을 원하시는 경우 담당 영업사원에게 개별 문의를 부탁드립니다.
                  //this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.186'))
                  //return false
                }
              }
            }
          }
        } else {
          if (this.$ekmtcCommon.isEmpty(selector.value) && cntrInfo.cntrTypCd !== 'TK' && cntrInfo.shprVanYn !== 'Y') {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            return false
          } else if (cntrInfo.pcupReqDt.length < 8) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.bookingValidation.024'))
            return false
          } else {
            if (!this.$ekmtcCommon.isValidDate(cntrInfo.pcupReqDt, 8)) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.bookingValidation.024'))
              return false
            }
          }
        }

        if (this.regParams.mtyPkupUsagYn !== 'Y' && !this.fnPickupDateChk(cntrInfo, document.querySelector('#pcupReqDt_' + idx), flag)) {
          return false
        }
      }

      if (prefix === 'pcupCyCd' && this.$ekmtcCommon.isEmpty(cntrInfo.pcupCyCd)) {
        if (cntrInfo.cntrTypCd !== 'TK' && cntrInfo.shprVanYn !== 'Y') {
          if (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.bookingValidation.010'))
            isOk = false
          }
        }
      }

      if (prefix === 'pcupCyCd' && this.$ekmtcCommon.isNotEmpty(cntrInfo.pcupCyCd)) {
        if (idx !== 0) {
          const firstCntr = this.regParams.containerList[0]

          if (this.$ekmtcCommon.isNotEmpty(firstCntr.pcupCyCd)) {
            const selector1 = frm.querySelector('#pcupCyCd_' + idx)
            this.$ekmtcCommon.hideErrorTooltip(selector1)
            if (cntrInfo.shprVanYn !== 'Y' && firstCntr.pcupCyCd !== cntrInfo.pcupCyCd) {
              this.$ekmtcCommon.showErrorTooltip(selector1, this.$t('msg.CSBK100.188'))
              isOk = false
            }
          }
        }
      }

      if (prefix === 'cfsFixdEntrNm' && this.regParams.cfsFixdTypCd !== '03' && this.$ekmtcCommon.isEmpty(this.regParams.cfsFixdEntrNm)) {
        if (cntrInfo.cntrTypCd !== 'TK' && cntrInfo.shprVanYn !== 'Y') {
          if (regParams.polShtmCd === '02') {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.bookingValidation.028'))
            isOk = false
          }
        }
      }

      return isOk
    },
    fnPickupDateChk (cvo, selector, flag) { // flag가 save일 경우에만 확인창 띄움.
      const regParams = this.regParams
      const pcupReqDt = cvo.pcupReqDt
      const polEta = regParams.polEta
      if (this.$ekmtcCommon.isEmpty(pcupReqDt)) return false

      let pcupReqTm = ''
      if (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') {
        if (this.$ekmtcCommon.isEmpty(cvo.pcupReqTm)) {
          pcupReqTm = '09'
        }
      } else {
        pcupReqTm = '09'
      }

      const dayNum = this.$ekmtcCommon.getDateDayOfWeek(pcupReqDt)

      if (this.lang === 'KOR' || this.usrCtrCd === 'IN') {
        const pcupReqDate = this.$ekmtcCommon.getStrToDate(pcupReqDt)
        let bkgDate = new Date(this.bkgDt.substring(0, 4), Number(this.bkgDt.substring(4, 6)) - 1, Number(this.bkgDt.substring(6, 8)) + 1)

        const today = new Date(Number(this.serverYear), Number(this.serverMonth) - 1, Number(this.serverDay), Number(this.serverHour), Number(this.serverMin), Number(this.serverSecond))
        const today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        const today2 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)

        if (this.usrCtrCd === 'IN') {
          let podEtd = new Date(Number(this.etd.substring(0, 4)), Number(this.etd.substring(4, 6)) - 1, Number(this.etd.substring(6, 8)))

          if (this.$ekmtcCommon.isNotEmpty(regParams.railData)) {
            podEtd = new Date(Number(this.etd.substring(0, 4)), Number(this.etd.substring(4, 6)) - 1, Number(this.etd.substring(6, 8)) + 3)
          }

          if (podEtd <= pcupReqDate) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.booking.018'))
            this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
            return false
          }
        } else if (this.lang === 'KOR') {
          let cgoDate = ''
          if (this.$ekmtcCommon.isNotEmpty(this.cgoRest) && this.cgoRest.length >= 8) {
            cgoDate = this.cgoRest.substring(0, 8)
          } else {
            if (this.$ekmtcCommon.isNotEmpty(polEta) && polEta.length >= 8) {
              cgoDate = polEta.substring(0, 8)
            }
          }

          if (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') {
            const cgo = this.$ekmtcCommon.getStrToDate(cgoDate)
            const todayValue = this.$ekmtcCommon.getDateToStrDelim(today2, '')
            if (regParams.owtrYn === 'N') {
              const bkgDtValue = this.$ekmtcCommon.getDateToStrDelim(bkgDate, '')

              if (pcupReqDate < today2) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.restriction.009'))
                return false
              }

              const toDate = this.$ekmtcCommon.getDateToStrDelim(today2, '')
              if (pcupReqDt === toDate && Number(today1.getHours()) >= 15) {
                this.$ekmtcCommon.alertDefault(this.$ekmtcCommon.changeDatePattern(pcupReqDt, 'KO') + this.$t('js.booking.015') + '\n\n' + this.$t('js.booking.016'))
                return false
              }

              if ((cvo.cntrTypCd === 'RF' || cvo.cntrTypCd === 'RH') && pcupReqDate < today2) {
                this.$ekmtcCommon.alertDefault(cvo.cntrTypCd + this.$t('msg.CSBK100.189')) //컨테이너는 당일 픽업이 불가합니다.
                return false
              }
            } else {
              if (pcupReqDate < today1 && pcupReqDt.length === 8) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.booking.017'))
                return false
              }
            }

            if (cvo.cntrTypCd === 'RH' || cvo.cntrTypCd === 'RF' || cvo.cntrTypCd === 'OT' || cvo.cntrTypCd === 'FR' || cvo.cntrTypCd === 'SR') {
              let today3 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2)

              if (cgo < today3) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.booking.018'))
                this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
                return false
              }
            }

            if (this.$ekmtcCommon.isNotEmpty(pcupReqDt)) {
              if (this.$ekmtcCommon.isNotEmpty(this.cgoRest) && this.cgoRest.length >= 8) {
                const cgoDate = this.$ekmtcCommon.getStrToDate(this.cgoRest.substring(0, 8))
                if (cvo.cntrTypCd === 'RF' || cvo.cntrTypCd === 'RH') {
                  if (this.$ekmtcCommon.isNotEmpty(cgoDate) && cgoDate <= pcupReqDate) {
                    this.$ekmtcCommon.alertDefault(cvo.cntrTypCd + this.$t('msg.CSBK100.190')) // 컨테이너는 서류마감일 하루 전까지 입력 가능합니다.
                    this.$ekmtcCommon.showErrorTooltip(selector, cvo.cntrTypCd + this.$t('msg.CSBK100.190'))
                    return false
                  }
                } else {
                  if (this.$ekmtcCommon.isNotEmpty(cgoDate) && cgoDate < pcupReqDate) {
                    this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.190'))
                    return false
                  }
                }
              } else {
                const etaDate = this.$ekmtcCommon.getStrToDate(this.regParams.polEta)

                if (this.$ekmtcCommon.isNotEmpty(etaDate) && etaDate < pcupReqDate && pcupReqDt.length >= 8) {
                  this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.restriction.006'))
                  return false
                }
              }
            }

            if (regParams.owtrYn === 'N') {
              if (this.$ekmtcCommon.isNotEmpty(cgo) && cgo < today2) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.booking.018'))
                this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
                return false
              } else if (this.$ekmtcCommon.isNotEmpty(cgoDate) && todayValue > cgoDate) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.booking.018'))
                this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
                return false
              } else if (this.$ekmtcCommon.isNotEmpty(cgoDate) && todayValue === cgoDate) {
                if (Number(today.getHours()) >= 15) {
                  this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.booking.018'))
                  this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
                  return false
                }
              }
            } else {
              if (cgo < today1) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.booking.018'))
                this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
                return false
              }
            }
          } else if (regParams.polShtmCd === '02') {
            const etaDate = this.$ekmtcCommon.getStrToDate(this.regParams.polEta)
            const pcupReqDate = this.$ekmtcCommon.getStrToDate(pcupReqDt + pcupReqTm)
            //const cgo = this.$ekmtcCommon.getStrToDate(cgoDate)

            if (this.$ekmtcCommon.isNotEmpty(etaDate) && etaDate < pcupReqDate) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.restriction.020'))
              return false
            }

            /*
            if (cgo < pcupReqDate) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.booking.018'))
              this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
              return false
            }
            */
          }
        }

        if (this.lang === 'KOR' && flag === 'SAVE') {
          if (Number(dayNum) === 6 || Number(dayNum) === 0) {
            this.$ekmtcCommon.alertDefault(this.$t('js.restriction.008'))
          } else {
            schedule.getHolidayYn(pcupReqDt).then(res => {
              const result = res.data
              if (result !== undefined && result !== null) {
                if (result === 'Y') {
                  this.$ekmtcCommon.alertDefault(this.$t('js.restriction.008'))
                }
              }
            })
          }
        }
      }

      return true
    },
    fnPickupChk () {
      if (this.lang !== 'KOR') return true
      const regParams = this.regParams
      const cntrList = regParams.containerList

      let flag = true

      if (regParams.polShtmCd === '02' || regParams.owtrYn !== 'Y') return true

      cntrList.forEach(async (c) => {
        if (this.$ekmtcCommon.isNotEmpty(c.cntrTypCd)) {
          const pcupReqDtBak = c.pcupReqDtBak
          const pcupReqDt = c.pcupReqDt

          if (this.$ekmtcCommon.isNotEmpty(pcupReqDtBak) && pcupReqDt !== pcupReqDtBak) {
            const onConfirm = () => {
              c.pickUpPlace = ''
              c.pickUpMan = ''
              c.pickUpTel = ''
              c.pcupCyCd = ''
              c.pcupReqDtBak = ''
            }

            const onClose = () => {
              if (pcupReqDt.length !== 8) {
                c.pickUpPlace = ''
                c.pickUpMan = ''
                c.pickUpTel = ''
                c.pcupCyCd = ''
                c.pcupReqDtBak = ''
              } else {
                c.pcupReqDt = pcupReqDtBak
              }
            }
            this.$ekmtcCommon.confirmCallBack(this.$t('js.booking.020'), onConfirm, onClose)

            flag = false
            return flag
          }
        }
      })

      return flag
    },
    openRfHpmgSite (rfHpmgUrl) {
      rfHpmgUrl = ''
      if (this.$ekmtcCommon.isEmpty(rfHpmgUrl)) {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.033'))
        return
      }

      window.open(rfHpmgUrl)
    },
    setCharcoalInfo (obj) {
      if (obj.cnt1361 === 'Y' || obj.cnt1362 === 'Y') {
        this.vanningYn = 'Y'
      }
      let imdgNo = ''
      if (obj.cnt1361 !== 'Y' && obj.cnt1362 === 'Y') {
        this.vanningSurvYn = 'N'
        imdgNo = '1362'
      } else {
        this.vanningSurvYn = 'Y'
        imdgNo = '1361'
      }

      const params = {
        polPortCd: this.regParams.polPortCd,
        etd: this.etd,
        imdgNo: imdgNo
      }

      trans.getChclAct(params).then((res) => {
        this.chclActList = res.data
      })
    },
    iranInputCheck (flag) {
      const params = {
        kndCd: '',
        hsCd: '',
        cont: ''
      }

      const frm = document.querySelector('#frm')
      const selector = frm.querySelector('#ir_' + flag)
      const iranInfo = this.regParams.iranBookingInfo

      if (flag === 'actShprCstNm' || flag === 'cneCstNm' || flag === 'endUsrNm') {
        params.kndCd = '1'
        params.cont = selector.value
      } else if (flag === 'cmdtNm') {
        params.kndCd = 2
        params.cont = selector.value
      } else if (flag === 'hsCd') {
        params.kndCd = 3
        params.hsCd = selector.value
      }

      transCommon.getIranCheck(params).then(res => {
        const result = res.data

        let clsNm = result === 'Y' ? 'ir_red' : 'ir_black'

        if (result === 'R') {
          clsNm = ''
        }

        if (flag === 'actShprCstNm') {
          iranInfo.actShprCstNmC = result
        } else if (flag === 'cneCstNm') {
          iranInfo.cneCstNmC = result
        } else if (flag === 'endUsrNm') {
          iranInfo.endUsrNmC = result
        } else if (flag === 'cmdtNm') {
          this.cmdtNmC = result
        } else if (flag === 'hsCd') {
          this.hsCdC = result
        }

        if (result === 'N') {
          return
        }

        const msgSelector = frm.querySelector('#ir_' + flag + 'C')
        msgSelector.classList.remove(...msgSelector.classList)
        msgSelector.classList.add(clsNm)
      })
    },
    searchSchedule () {
      this.selectFunc = this.callbackSearchSchedule

      this.popParams = {
        ScheduleLegPop: 'Y',
        podCtrCd: this.regParams.podCtrCd,
        podPlcCd: this.regParams.podPortCd,
        polCtrCd: this.regParams.polCtrCd,
        polPlcCd: this.regParams.polPortCd
      }

      this.openPopup('ScheduleLegPop')
    },
    callbackSearchSchedule (obj) {
      if (obj.params !== undefined) {
        if (this.lang === 'KOR') {
          this.setScheduleInfoKOR(obj.params)
        } else {
          this.setScheduleInfo(obj.params)
        }
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('js.common.005')) // 조회에 실패했습니다. 관리자에게 문의해주세요.
      }

      if (obj.detail !== undefined) {
        this.setScheduleLegInfo(obj.detail)
      }
    },
    setScheduleLegInfo (obj) {
      const newLegList = []
      const newObject = {
        vslNm: obj.vslNm1,
        voyNo: obj.voyNo,
        rteCd: obj.rteCd,
        polNm: obj.polNm,
        podNm: obj.pod1Nm,
        transitTime: obj.transitTime1,
        polEtbDT: obj.polEtbDT,
        etdDT: obj.etdDT,
        polTml: obj.polTml,
        etaDT: obj.etaDT,
        podTml: obj.podTml
      }

      newLegList.push(newObject)

      if (this.$ekmtcCommon.isNotEmpty(obj.polEtbDT2)) {
        const vo = {
          vslNm: obj.vslNm2,
          voyNo: obj.voyNo2,
          rteCd: obj.rteCd2,
          polNm: obj.pod1Nm,
          podNm: obj.pod2Nm,
          polEtbDT: obj.polEtbDT2,
          polTml: obj.polTml2,
          etaDT: obj.etaDT2,
          podTml: obj.podTml2,
          transitTime: obj.transitTime2
        }

        newLegList.push(vo)
      }

      if (this.$ekmtcCommon.isNotEmpty(obj.polEtbDT3)) {
        const vo = {
          vslNm: obj.vslNm3,
          voyNo: obj.voyNo3,
          rteCd: obj.rteCd3,
          polNm: obj.pod2Nm,
          podNm: obj.pod3Nm,
          polEtbDT: obj.polEtbDT3,
          polTml: obj.polTml3,
          etaDT: obj.etaDT3,
          podTml: obj.podTml3,
          transitTime: obj.transitTime3
        }

        newLegList.push(vo)
      }

      if (this.$ekmtcCommon.isNotEmpty(obj.polEtbDT4)) {
        const vo = {
          vslNm: obj.vslNm4,
          voyNo: obj.voyNo4,
          rteCd: obj.rteCd4,
          polNm: obj.pod3Nm,
          podNm: obj.pod4Nm,
          polEtbDT: obj.polEtbDT4,
          polTml: obj.polTml4,
          etaDT: obj.etaDT4,
          podTml: obj.podTml4,
          transitTime: obj.transitTime4
        }

        newLegList.push(vo)
      }

      this.$set(this, 'schdLegList', newLegList)
    },
    scheduleCloseInfo (obj, loadFlag) {
      if (this.$ekmtcCommon.isNotEmpty(obj.docCls)) {
        if (this.lang === 'KOR' || this.lang === 'JPN') {
          this.txtDocCls = this.$ekmtcCommon.changeDatePattern(obj.docCls, '-')
        } else {
          this.txtDocCls = this.$ekmtcCommon.changeDatePatternEng(obj.docCls, '-')
        }
        if (obj.docCls.length >= 8) {
          this.cgoRest = obj.docCls.substring(0, 8)
        }
      } else {
        this.txtDocCls = ''
        this.cgoRest = ''
      }

      if (this.$ekmtcCommon.isNotEmpty(obj.cgoCls)) {
        if (this.lang === 'KOR' || this.lang === 'JPN') {
          this.txtCgoCls = this.$ekmtcCommon.changeDatePattern(obj.cgoCls, '-')
        } else {
          this.txtCgoCls = this.$ekmtcCommon.changeDatePatternEng(obj.cgoCls, '-')
        }

        this.cgoCls = obj.cgoCls
      } else {
        this.txtCgoCls = ''
        this.cgoCls = ''
      }

      if (this.$ekmtcCommon.isNotEmpty(obj.mfCls)) {
        if (this.lang === 'KOR' || this.lang === 'JPN') {
          this.txtMfCls = this.$ekmtcCommon.changeDatePattern(obj.mfCls, '-')
        } else {
          this.txtMfCls = this.$ekmtcCommon.changeDatePatternEng(obj.mfCls, '-')
        }
      } else {
        this.txtMfCls = ''
      }

      this.cfsCls = obj.cfsCls
      if (this.$ekmtcCommon.isNotEmpty(obj.cfsCls)) {
        if (this.lang === 'KOR' || this.lang === 'JPN') {
          this.txtCfsCloseDt = this.$ekmtcCommon.changeDatePattern(obj.cfsCls, '-')
        } else {
          this.txtCfsCloseDt = this.$ekmtcCommon.changeDatePatternEng(obj.cfsCls, '-')
        }
      } else {
        this.txtCfsCloseDt = ''
      }

      let polTrmlInfo = ''

      polTrmlInfo += this.$ekmtcCommon.isNotEmpty(obj.polTrmlCd) ? obj.polTrmlCd : ''
      if (this.regParams.polShtmCd === '02') {
        // polTrmlInfo = obj.polTrmlCd + '/' + obj.cfsPic
        polTrmlInfo += this.$ekmtcCommon.isNotEmpty(obj.cfsPic) ? '/' + obj.polTrmlCd : ''
      } else {
        polTrmlInfo += this.$ekmtcCommon.isNotEmpty(obj.cyEnm) ? '(' + obj.cyEnm + ') ' : ''

        if (this.$ekmtcCommon.isNotEmpty(obj.cgoPic)) {
          polTrmlInfo += '/ ' + obj.cgoPic
        }
      }

      if (this.$ekmtcCommon.isNotEmpty(obj.whCode)) {
        this.whCodeInfo = this.$t('msg.CSBK100.218') + obj.whCode
      } else {
        this.whCodeInfo = ''
      }

      if (this.$ekmtcCommon.isNotEmpty(obj.cllPic)) {
        this.txtDocPic = obj.cllPic
      } else {
        this.txtDocPic = ''
      }

      if (this.$ekmtcCommon.isNotEmpty(obj.ediPic)) {
        this.txtEdiPic = obj.ediPic
      } else {
        this.txtEdiPic = ''
      }

      this.txtPolTrmlInfo = polTrmlInfo

      this.docPic = obj.docPic
      this.mrnNo = obj.mrnNo
      this.callSign = obj.callSign

      this.srRest = obj.srRest
      this.localDtm = obj.localDtm
      this.vslRest = obj.vslRest
      this.cgoRest = obj.cgoRest
      this.eta = obj.eta + (obj.etaTm !== undefined ? obj.etaTm : '')
      this.etd = obj.etd + (obj.etdTm !== undefined ? obj.etdTm : '')

      const regParams = this.regParams

      if (loadFlag === 'PICKUP') {
        regParams.podEta = obj.eta !== undefined && obj.eta.length > 8 ? obj.eta.substring(0, 8) : obj.eta
        regParams.bkgDt = obj.bkgDt
      } else {
        regParams.podEta = obj.finalEta !== undefined && obj.finalEta.length > 8 ? obj.finalEta.substring(0, 8) : obj.finalEta
        regParams.bkgDt = obj.localDtm.length > 8 ? obj.localDtm.substring(0, 8) : obj.localDtm
      }

      regParams.polEtd = obj.etd > 8 ? obj.etd.substring(0, 8) : obj.etd

      this.bkgCloseYn = obj.bkgCloseYn === 'Y' ? 'CHECK' : ''
    },
    setScheduleCommon (obj) {
      const regParams = this.regParams
      const polShtmCd = regParams.polShtmCd
      regParams.vslCd = obj.vslCd
      regParams.polPortCd = obj.polPortCd
      regParams.podPortCd = obj.podPortCd
      regParams.polCtrCd = obj.polCtrCd
      regParams.podCtrCd = obj.podCtrCd
      regParams.polTrmlCd = obj.polTrmlCd
      regParams.podTrmlCd = obj.podTrmlCd
      regParams.voyNo = obj.voyNo
      regParams.hidCS008I = obj.hidCS008I
      regParams.scheduleEditYn = 'Y'
      regParams.schFrtAppNo = obj.frtAppNo
      regParams.schLogDtm = obj.localDtm
      regParams.expcYn = obj.bkgCloseYn === 'Y' ? 'Y' : 'N'

      obj.cyEnm = obj.polTrmlNm

      this.scheduleCloseInfo(obj, 'NEW')

      if (this.lang !== 'KOR' && this.usrCtrCd !== 'IN') {
        this.pickDateSgMy = obj.prrmSgEtd
      }

      //오늘 날짜 구하기
      // RF 부킹체크
      let rfCheck = 'N'
      regParams.containerList.forEach(c => {
        if (c.cntrTypCd === 'RF' || c.cntrTypCd === 'RH') {
          rfCheck = 'Y'
        }
      })
      if (rfCheck === 'Y') {
        this.setRfTeuCount()
      }
      if (polShtmCd === '01' || polShtmCd === '03') {
        if (regParams.owtrYn === 'N') {
          regParams.zip1 = ''
        }
        regParams.containerList.forEach(c => {
          c.pickUpPlace = ''
          c.pcupCyCd = ''
          c.pcupReqDt = ''
          c.pickUpMan = ''
          c.pickUpTel = ''
        })
      } else if (polShtmCd === '02') {
        this.setCfsCodeInfo()
      }

      this.fnDgShipCheck()

      if (regParams.hidCS008I.indexOf('SHA') > -1) {
        this.fnShaTsMsg()
      }
    },
    setScheduleInfo (obj) {
      if (obj !== null && obj !== undefined) {
        const regParams = this.regParams
        regParams.polEtd = obj.etd
        regParams.polEta = obj.eta + obj.etaTm

        // 운송 구분 CY, SOC 화주가 아닐 경우
        if (regParams.polShtmCd === '01' && this.cstCatCd !== 'Y') {
          if (this.$ekmtcCommon.isNotEmpty(obj.podCtrCd) && 'IN|PK|BD|RU|IR|LK|AE'.indexOf(obj.podCtrCd) > -1) {
            regParams.dorOdrRmk = 'BOLT SEAL CHECK'
            document.querySelector('#dorOdrRmk').readonly = true
          } else {
            regParams.dorOdrRmk = ''
            document.querySelector('#dorOdrRmk').readonly = false
          }
        }

        this.setScheduleCommon(obj)

        const params = {
          polCtrCd: regParams.polCtrCd,
          polPortCd: regParams.polPortCd
        }
        trans.getPickUpSet(params).then(res => {
          const data = res.data
          this.pickPlaceCd = data.cyCd
          this.pickPlaceNm = data.cyNm

          this.setPickPlaceInfo()
        })

        this.vanningChk()

        this.termCheckHPNT()

        if (obj.polPortCd !== 'PGU') {
          regParams.fwdEdiCd = ''
        }

        if (obj.podCtrCd === 'TH') {
          const params = {
            polCtrCd: regParams.polCtrCd,
            polPortCd: regParams.polPortCd,
            polTrmlCd: regParams.polTrmlCd,
            hidCS008I: regParams.hidCS008I
          }

          trans.getGateInfoSet(params).then(res => {
            const rtn = res.data

            if (rtn.result !== 'N') {
              this.txtPolTrmlInfo = rtn.result
            }
          })
        }

        transCommon.getCasNoPortCheck(regParams.hidCS008I).then(res => {
          const rtnStr = res.data
          this.casNoPortYn = rtnStr === 'Y' ? 'Y' : 'N'
        })
      }
    },
    vanningChk () {
      const TH = this
      const dg = TH.$refs.dg

      let cnt1361 = 'N'
      let cnt1362 = 'N'
      if (dg !== undefined) {
        dg.forEach(c => {
          const dgInfo = c.dgInfo.containerDGList

          dgInfo.forEach(d => {
            if (d.imdunNo === '1361') {
              cnt1361 = 'Y'
            }

            if (d.imdunNo === '1362') {
              cnt1362 = 'Y'
            }
          })
        })
      }

      if (cnt1361 === 'Y' || cnt1362 === 'Y') {
        const obj = {
          cnt1361: cnt1361,
          cnt1362: cnt1362
        }

        this.setCharcoalInfo(obj)
      }
    },
    setScheduleInfoKOR (obj) {
      if (obj !== null && obj !== undefined) {
        const TH = this
        const regParams = this.regParams

        regParams.polEtd = obj.etd
        regParams.polEta = obj.eta + obj.etaTm

        // 운송 구분 CY, SOC 화주가 아닐 경우
        if (regParams.polShtmCd === '01' && this.cstCatCd !== 'Y') {
          if (this.$ekmtcCommon.isNotEmpty(obj.podCtrCd) && 'IN|PK|BD|RU|IR|LK|AE'.indexOf(obj.podCtrCd) > -1) {
            regParams.docmRmk = 'BOLT SEAL CHECK'
            document.querySelector('#docmRmk').readonly = true
          } else {
            regParams.docmRmk = ''
            document.querySelector('#docmRmk').readonly = false
          }
        }

        this.setScheduleCommon(obj)

        trans.getKmdVslCheck(regParams.hidCS008I).then(res => {
          const rtnStr = res.data
          this.kmdVslCheck = rtnStr

          let oogYn = 'N'
          regParams.containerList.forEach((c, idx) => {
            if (c.cntrTypCd === 'OT' || c.cntrTypCd === 'FR' || c.cntrTypCd === 'SR') {
              oogYn = 'Y'
            }
          })

          if (oogYn === 'Y') {
            this.oogLoiPop(rtnStr)
          }
        })

        transCommon.getCasNoPortCheck(regParams.hidCS008I).then(res => {
          const rtnStr = res.data
          this.casNoPortYn = rtnStr === 'Y' ? 'Y' : 'N'
        })
      }
    },
    clearSchedule () {
      if (this.lang === 'KOR') {
        document.querySelector('#docmRmk').readonly = false
      } else {
        document.querySelector('#dorOdrRmk').readonly = false
      }

      this.txtPolTrmlInfo = ''
      this.pickDateSgMy = ''
      this.cfsRmkYn = ''
      this.txtDocCls = '' // 서류마감
      this.txtDocPic = '' // 모선마감 담당자
      this.txtMfCls = '' // EDI 신고마감
      this.txtEdiPic = '' // 세관마감 담당자
      this.txtCyOepnDt = '' // CY Open
      this.txtCfsCloseDt = '' // CFS 반입마감
      this.txtCgoCls = '' // 반입마감
      this.txtCfsPlace = '' // CFS 반입장소
      this.txtPolTrmlInfo = '' // 반입지/담당자
      this.whCodeInfo = ''
      this.txtAclCode = '' // ACL 통지처 코드
      this.mrnNo = '' // MRN
      this.callSign = '' // CALL SIGN
      this.txtEmptyCntrOrdPlace = '' // Empty Container Pick up order 제출처
      this.txtEmptyCntrPlace = '' // Empty container Pick up 장소
      this.srRest = ''
      this.localDtm = ''
      this.vslRest = ''
      this.cgoRest = ''
      this.eta = ''
      this.etd = ''
      this.cfsCls = ''
      this.bkgCloseYn = ''
      this.schdLegList = []
    },
    termCheckHPNT () {
      const hidCS008I = this.regParams.hidCS008I

      let pod = hidCS008I.split('@_@')
      for (let i = 1; i < pod.length; i++) {
        const legSplit = pod[i].split(':')
        if ((this.$ekmtcCommon.isNotEmpty(legSplit[11]) && 'HPNT|HJNC'.indexOf(legSplit[11]) > -1) ||
            (this.$ekmtcCommon.isNotEmpty(legSplit[12]) && 'HPNT|HJNC'.indexOf(legSplit[12]) > -1)) {
          this.legTermHPNT = 'Y'
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.089') + '\n' + this.$t('msg.CSBK100.090'))
        }

        if (legSplit[1] === 'HKM' || legSplit[3] === 'HKM') {
          this.legTermHKM = 'Y'
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.092') + '\n' + this.$t('msg.CSBK100.093'))
        }
      }
    },
    setPickPlaceInfo () {
      const containerList = this.regParams.containerList

      containerList.forEach(c => {
        if (c.shprVanYn !== 'Y') {
          c.pickUpPlace = this.pickPlaceNm
          c.pcupCyCd = this.pickPlaceCd

          if (this.$ekmtcCommon.isNotEmpty(this.usrCtrCd) && 'SG|MY|JP'.indexOf(this.usrCtrCd) > -1 && this.regParams.polShtmCd === '01') {
            c.pcupReqDt = this.pickDateSgMy
          }
        }
      })
    },
    setCfsCodeInfo () {
      const regParams = this.regParams
      const arrCntrTypCd = []
      regParams.containerList.forEach(c => {
        arrCntrTypCd.push(c.cntrTypCd)
      })
      const params = {
        polPortCd: regParams.polPortCd,
        podPortCd: regParams.podPortCd,
        podCtrCd: regParams.podCtrCd,
        vslCd: regParams.vslCd,
        voyNo: regParams.voyNo,
        polTrmlCd: regParams.polTrmlCd,
        arrCntrTypCd: arrCntrTypCd.join(','),
        conCode: 'Y'
      }
      trans.getCfsInfo(params).then(res => {
        const data = res.data
        if (res.data !== undefined) {
          if (data.cfsRmkYn === 'Y') {
            this.txtPolTrmlInfo = this.$t('msg.CSBK100.128') + ' : ' + data.cfsNm +
                      ' /\n' + this.$t('msg.CSBK100.129') + ' : ' + data.picNm +
                      ' / TEL : ' + data.telNo +
                      ' / FAX : ' + data.faxNo +
                      '\n' + data.cfsRmk
            this.cfsRmkYn = 'Y'
            this.cfsRmkMsg = data.cfsRmk
          } else {
            this.txtPolTrmlInfo = this.$t('msg.CSBK100.128') + ' : ' + data.cfsNm +
                      ' /\n' + this.$t('msg.CSBK100.129') + ' : ' + data.picNm +
                      ' / TEL : ' + data.telNo +
                      ' / FAX : ' + data.faxNo
            this.cfsRmkYn = ''
          }
          if (this.lang === 'KOR' && this.$ekmtcCommon.isNotEmpty(data.cfsCd)) {
            let cfsCd = data.cfsCd
            if (cfsCd === 'KECS') {
              cfsCd = 'IKEC'
            } else if (cfsCd === 'KNCY') {
              cfsCd = 'UAM'
            }
            regParams.cfsCy = cfsCd
          }
        }
      })
    },
    oogLoiPop (kmdVslCheck) {
      if (this.lang === 'KOR') {
        this.popParams = {
          kmdVslCheck: kmdVslCheck
        }

        this.selectFunc = ''

        this.openPopup('OogLoiPop')
      }
    },
    setRfTeuCount () {
      const regParams = this.regParams
      const params = {
        vslCd: regParams.vslCd,
        voyNo: regParams.voyNo,
        polCtrCd: regParams.polCtrCd
      }

      trans.getReeferYnCheck(params).then(res => {
        const result = res.data

        let rfTeuCount = ''
        const len = result.length
        result.forEach((c, idx) => {
          rfTeuCount += c

          if (idx !== (len - 1)) {
            rfTeuCount += ':'
          }
        })

        this.rfTeuCount = rfTeuCount
      })
    },
    fnDgShipCheck () { // 닝보, 대련, 상해항 consignee 입력창 노출 및 surabaya 안내 메시지 출력. MSDS 필수 체크
      const regParams = this.regParams
      let dgCnt = 0
      regParams.containerList.forEach(c => {
        if (c.cgoTypCd === '01') {
          dgCnt++
        }
      })

      if ((regParams.polPortCd === 'SUB' || regParams.podPortCd === 'SUB') && dgCnt > 0) {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.024'))
      }

      const msdsCheck = this.fnMSDSCheck()
      this.consigneeYn = 'N'
      this.msdsCheck = 'N'
      if (dgCnt > 0) {
        if (this.$ekmtcCommon.isNotEmpty(regParams.podPortCd) && 'DLC|NBO|SHA'.indexOf(regParams.podPortCd) > -1) {
          this.consigneeYn = 'Y'
        }

        if (msdsCheck === 'Y') {
          this.msdsCheck = 'Y'
        }
      }
    },
    fnMSDSCheck () {
      let msdsCheck = 'N'
      const regParams = this.regParams

      if (this.$ekmtcCommon.isNotEmpty(regParams.hidCS008I) && this.$ekmtcCommon.isNotEmpty(regParams.podPortCd)) {
        if (regParams.hidCS008I.indexOf('FDR:HKG') > -1 || regParams.podPortCd === 'NBO' || regParams.podPortCd === 'SHA') {
          if (regParams.hidCS008I.indexOf('FDR:HKG') > -1) {
            this.cn4List.forEach(c => {
              if (c.plcCd === regParams.podPortCd) {
                msdsCheck = 'Y'
              }
            })
          } else {
            msdsCheck = 'Y'
          }
        }
      }

      return msdsCheck
    },
    fnShaTsMsg () {
      const podPortCd = this.regParams.podPortCd
      const hidCS008I = this.regParams.hidCS008I
      if (podPortCd === 'SHA') {
        this.$ekmtcCommon.alertDefault('js.booking.034')
        return
      }
      let cnt = 0
      let podSha = hidCS008I.split('@_@')
      for (let i = 0; i < podSha.length - 1; i++) {
        if (cnt > 0) break

        const podShats = podSha[i].split(':')
        if (podShats[3] === 'SHA') {
          this.$ekmtcCommon.alertDefault('js.booking.034')
          cnt++
        }
      }
    },
    checkRtnCgoYn () {
      const rtnCgoYn = this.regParams.rtnCgoYn

      if (rtnCgoYn !== 'Y') {
        this.regParams.impBlNo = ''
      }
    },
    checkVanningInfo (cvo) {
      const TH = this
      const regParams = TH.regParams
      let isOk = true
      const dgList = cvo.containerDGList
      const frm = document.querySelector('#frm')

      if (cvo.vanningDtYn === 'Y') {
        const selector = frm.querySelector('#vaniDt_' + cvo.cntrSeq)
        TH.$ekmtcCommon.hideErrorTooltip(selector)
        if (regParams.polCtrCd !== 'JP') {
          if (TH.$ekmtcCommon.isEmpty(cvo.vaniDt)) {
            TH.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
          // TODO : vanning certi check
        }
      }

      return isOk
    },
    async rtnPhbtChrCheck (flag) {
      const regParams = this.regParams
      const etd = this.$ekmtcCommon.isNotEmpty(regParams.etd) ? regParams.etd.substring(0, 8) : ''
      const eta = this.$ekmtcCommon.isNotEmpty(regParams.eta) ? regParams.eta.substring(0, 8) : ''
      const params = {
        bkgNo: regParams.bkgNo,
        etd: etd,
        eta: eta,
        porCtrCd: regParams.polCtrCd,
        porPortCd: regParams.polPortCd,
        polCtrCd: regParams.polCtrCd,
        polPortCd: regParams.polPortCd,
        podCtrCd: regParams.podCtrCd,
        podPortCd: regParams.podPortCd,
        podTrmlCd: regParams.podTrmlCd,
        dlyCtrCd: regParams.podCtrCd,
        dlyPortCd: regParams.dlyPortCd,
        hidCS008I: regParams.hidCS008I,
        cmdtDsc: regParams.cmdtDsc,
        scheduleEditYn: regParams.scheduleEditYn,
        uiFlag: flag
      }

      let isOk = true

      await trans.getRtnPhbtChrChk(params).then(async res => {
        const result = res.data

        if (result.rtnCode === 'Y') {
          this.$ekmtcCommon.alertDefault(result.rtnMessage)
          isOk = false
        } else if (result.rtnCode === 'A') {
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: result.rtnMessage, useConfirmBtn: true })) {
            isOk = false
          }
        }
      })

      return isOk
    },
    async pickupDateRestJp () {
      const regParams = this.regParams
      const cntrInfo = regParams.containerList
      const arrPcupReqDt = []

      cntrInfo.forEach(c => {
        if (this.$ekmtcCommon.isNotEmpty(c.pcupReqDt)) {
          arrPcupReqDt.push(c.pcupReqDt)
        }
      })

      if (arrPcupReqDt.length > 0) {
        await trans.pickupDateRestJp(arrPcupReqDt).then(res => {
          const result = res.data

          if (result === 'Y') {
            this.ekmtcCommon.alertDefault('日祭日はゲートクローズしております。\nコンテナピックアップは平日に設定願います。\n＊ 土曜日の受入体制につきましては、お手数ですが\n各港代理店にお問合わせ下さい。')
          }
        })
      }
    },
    async checkBooking () {
      let isOk = true
      const regParams = this.regParams
      await bookings.validationBooking(regParams).then(async res => {
        const result = res.data

        if (result !== undefined) {
          const apcl = result.apcl
          const remark = result.remark

          if (apcl === 'Y' && this.$ekmtcCommon.isEmpty(remark)) {
            this.$ekmtcCommon.alertDefault(this.$t('art.CMATK186') + this.$t('art.CMATK185'))
            isOk = false
          } else if (apcl === 'Y' && this.$ekmtcCommon.isNotEmpty(remark)) {
            this.$ekmtcCommon.alertDefault(remark)
            isOk = false
          } else if (apcl === 'A' && this.$ekmtcCommon.isEmpty(remark)) {
            this.$ekmtcCommon.alertDefault(this.$t('art.CMATK186') + this.$t('art.CMATK185'))
            isOk = false
          } else if (apcl === 'A' && this.$ekmtcCommon.isNotEmpty(remark)) {
            if (!await this.$ekmtcCommon.asyncAlertMessage({ message: remark + '\n' + this.$t('msg.CSBK100.114'), useConfirmBtn: true })) {
              isOk = false
            }
          }
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('art.CMATK186') + this.$t('art.CMATK185'))
          isOk = false
        }
      })

      return isOk
    },
    async checkBookingDG () {
      const TH = this
      let isOk = false
      await bookings.validationBookingDG(TH.regParams).then(res2 => {
        const result = res2.data

        if (result !== undefined) {
          if (result.rtnCode === 'Y' && this.$ekmtcCommon.isNotEmpty(result.rtnMessage)) {
            this.$ekmtcCommon.alertDefault(result.rtnMessage)
            isOk = false
          } else {
            const containerInfo = result.containerInfo

            containerInfo.forEach(c => {
              TH.regParams.containerList.forEach(o => {
                if (c.cntrSeq === o.cntrSeq) {
                  o.scgRno = c.scgRno
                }
              })
            })

            isOk = true
          }
        }
      })

      return isOk
    },
    async checkBookingOOG () {
      const TH = this
      let isOk = true

      await bookings.validationBookingOOG(TH.regParams).then(res => {
        const result = res.data

        if (result !== undefined) {
          if (result.rtnCode === 'Y') {
            this.$ekmtcCommon.alertDefault(result.rtnMessage)
            isOk = false
          }
        }
      })

      return isOk
    },
    async checkBookingRF () {
      const TH = this
      let isOk = true

      await bookings.validationBookingRF(TH.regParams.hidCS008I).then(res => {
        const result = res.data

        if (result.rtnCode === 'Y') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.131'))
          isOk = false
        }
      })

      return isOk
    },
    async fnBkgCstMaxControl (flag) {
      const regParams = this.regParams
      const cntrList = regParams.containerList
      let sumQty20 = 0
      let sumQty40 = 0
      let sumTeu = 0
      let cgoYn = 'N'

      cntrList.forEach(c => {
        if (flag === 'ADD') {
          if (c.cntrSzCd === '20') {
            sumQty20 += Number(c.cntrQty)
            sumTeu += Number(c.cntrQty)
          } else if (c.cntrSzCd === '40') {
            sumQty40 += Number(c.cntrQty)
            sumTeu += Number(c.cntrQty) * 2
          }

          if (c.cntrSzCd === '45' || (c.cntrTypCd !== 'GP' && c.cntrTypCd !== 'HC')) {
            cgoYn = 'Y'
          }
        } else if (flag === 'PICKUP') {
          if (c.cntrSzCd === '45' || (c.cntrTypCd !== 'GP' && c.cntrTypCd !== 'HC')) {
            if (c.cntrSzCd === '20') {
              sumQty20 += Number(c.cntrQty)
              sumTeu += Number(c.cntrQty)
            } else if (c.cntrSzCd === '40') {
              sumQty40 += Number(c.cntrQty)
              sumTeu += Number(c.cntrQty) * 2
            }
          }
        }
      })

      if (cgoYn === 'Y') {
        return true
      }

      const params = {
        vslCd: regParams.vslCd,
        voyNo: regParams.voyNo,
        porCtrCd: regParams.polCtrCd,
        dlyCtrCd: regParams.podCtrCd,
        porPlcCd: regParams.polPortCd,
        dlyPlcCd: regParams.podPortCd,
        hidCS008I: regParams.hidCS008I,
        scheduleEditYn: regParams.scheduleEditYn,
        bkgNo: regParams.bkgNo,
        uiFlag: flag,
        sumQty20: sumQty20,
        sumQty40: sumQty40,
        sumTeu: sumTeu
      }

      await bookings.bkgCstMaxControlChk(params).then(res => {
        const result = res.data

        if (result !== undefined && result.rtnCode === 'Y') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.194')) // 담당영업사원에게 확인바랍니다.
          return false
        } else {
          return true
        }
      })
    },
    openPopup (compNm) {
      this.customComponent = compNm

      this.$ekmtcCommon.layerOpen('.pickup_area > .popup_dim')
    },
    closePoup (vo) {
      this.customComponent = null
      this.selectFunc = null
      this.$ekmtcCommon.layerClose('.pickup_area > .popup_dim')

      // 데이터 재조회
      if (vo && vo.saveFlag) {
        this.initEdit()
      }
    },
    callFbCatInfo (returnVo) {
      this.fbCat01 = returnVo.fbCat01
      this.fbCat02 = returnVo.fbCat02
      this.fbCat03 = returnVo.fbCat03
      this.fbCat04 = returnVo.fbCat04
      this.fbCat05 = returnVo.fbCat05
    },
    openNotice (title, msg) {
      let obj = {
        alertType: 'normal',
        showXclose: true,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        title: title,
        message: msg,
        type: 'success'
      }
      this.$ekmtcCommon.this.$ekmtcCommon.alertDefault(obj)
    },
    checkIranValidationAll () {
      let isOk = true
      const prefix = ['actShprCstNm', 'cneCstNm', 'endUsrNm', 'cmdtNm', 'hsCd', 'useDestCont']

      prefix.forEach(el => {
        if (!checkIranValidation(el)) {
          isOk = false
        }
      })

      return isOk
    },
    checkIranValidation (flag) {
      let isOk = true
      const frm = document.querySelector('#frm')
      const elemActShprCstNm = frm.querySelector('#ir_actShprCstNm')
      const elemCneCstNm = frm.querySelector('#ir_cneCstNm')
      const elemEndUsrNm = frm.querySelector('#ir_endUsrNm')
      const elemHsCd = frm.querySelector('#ir_hsCd')
      const selector = frm.querySelector('#ir_' + flag)

      this.$ekmtcCommon.hideErrorTooltip(selector)

      const selectorInfo = {
        actShprCstNm: { message: 'B/L SHIPPER.', upperCase: 'Y' },
        cneCstNm: { message: 'NAME OF CONSIGNEE.', upperCase: 'Y' },
        endUsrNm: { message: 'END USER.', upperCase: 'Y' },
        cmdtNm: { message: 'NAME OF COMMODITY.', upperCase: 'Y' },
        hsCd: { message: 'HS CODE.' },
        useDestCont: { message: 'PURPOSE OF USE.' }
      }

      if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
        this.$ekmtcCommon.showErrorTooltip(selector, 'Please input the ' + selectorInfo[flag].message)
        isOk = false
      }

      const elemActShprCstNmC = document.querySelector('#ir_actShprCstNmC')
      const elemCneCstNmC = document.querySelector('#ir_cneCstNmC')
      const elemEndUsrNmC = document.querySelector('#ir_endUsrNmC')
      const elemHsCdC = document.querySelector('#ir_hsCdC')
      const iranInfo = this.regParams.iranBookingInfo
      if (elemActShprCstNmC.style.display !== 'none' && iranInfo.actShprCstNmC === 'Y') {
        this.$ekmtcCommon.showErrorTooltip(elemActShprCstNm, 'Please Check the B/L SHIPPER.')
        isOk = false
      }

      if (elemCneCstNmC.style.display !== 'none' && iranInfo.cneCstNmC === 'Y') {
        this.$ekmtcCommon.showErrorTooltip(elemCneCstNm, 'Please Check the NAME OF CONSIGNEE.')
        isOk = false
      }

      if (elemEndUsrNmC.style.display !== 'none' && iranInfo.endUsrNmC === 'Y') {
        this.$ekmtcCommon.showErrorTooltip(elemEndUsrNm, 'Please Check the END USER.')
        isOk = false
      }

      if (elemHsCdC.style.display !== 'none' && this.hsCdC === 'Y') {
        this.$ekmtcCommon.showErrorTooltip(elemHsCd, 'Please check HS Code.')
        isOk = false
      }

      if (isOk) {
        if (
          (elemActShprCstNmC.style.display !== 'none' && iranInfo.actShprCstNmC === 'A') ||
          (elemCneCstNmC.style.display !== 'none' && iranInfo.cneCstNmC === 'A') ||
          (elemEndUsrNmC.style.display !== 'none' && iranInfo.endUsrNmC === 'A')
        ) {
          const onConfirm = () => {
            isOk = false
          }

          // Saction List에 포함된 업체가 존재합니다.\n계속 진행하시겠습니까?
          this.$ekmtcCommon.confirmCallBack(this.$t('msg.CSBK100.195'), onConfirm, undefined)
        }
      }

      return isOk
    },
    sameConsigneeName (e) {
      const iranInfo = this.regParams.iranBookingInfo
      if (e.target.checked) {
        iranInfo.endUsrNm = iranInfo.cneCstNm
      } else {
        iranInfo.endUsrNm = ''
      }
    },
    changeActShprCstNm (e) {
      this.$ekmtcCommon.onlyEngValue(e)
      this.regParams.iranBookingInfo.actShprCstNm = this.regParams.iranBookingInfo.actShprCstNm.toUpperCase()
    },
    fnVslStsCdCheck () {
      const TH = this
      let sizeTypeQty = 'N'
      const regParams = TH.regParams

      let oldSize = 0
      let oldType = 0
      let oldQty = 0
      regParams.containerList.forEach((c, idx) => {
        const oldCntr = TH.oldContainerList[idx]

        if (oldCntr !== undefined) {
          oldSize = oldCntr.cntrSzCd
          oldType = oldCntr.cntrTypCd
          oldQty = oldCntr.cntrQty

          if (Number(c.cntrSzCd) > Number(oldSize)) {
            sizeTypeQty = 'Y'
          } else if (c.cntrTypCd !== oldType && (c.cntrTypCd !== 'GP' || c.cntrTypCd !== 'HC')) {
            sizeTypeQty = 'Y'
          } else if (Number(c.cntrQty) > Number(oldQty)) {
            sizeTypeQty = 'Y'
          }
        }
      })

      return sizeTypeQty
    },
    inputConsignee (e) {
      const value = e.target.value
      e.target.value = value.replace(/[^a-z|A-Z|0-9]/gi, '')
    },
    async confirmPickup () {
      if (this.isExistBkgNo === 'N') {
        const elem = document.querySelector('#frm #bkgNo')
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: '존재하지 않는 Booking No입니다.\n다시 검색해 주세요.' })) {
          elem.focus()
          return
        }
      }

      let isOk = true
      const prefix = ['cntrTypCd', 'cntrQty', 'pcupReqDt', 'pcupCyCd']
      const regParams = this.regParams
      const containerList = regParams.containerList

      containerList.forEach((c, idx) => {
        prefix.forEach(el => {
          if (!this.checkValidationCntr(el, idx, 'SAVE')) {
            isOk = false
          }
        })
      })

      if (isOk) {
        const arrParamCntr = []
        containerList.forEach((c, idx) => {
          const obj = {
            cgoTypCd: c.cgoTypCd,
            cntrQty: c.cntrQty,
            cntrTypCd: c.cntrTypCd,
            cntrSzCd: c.cntrSzCd,
            pcupReqDt: this.$ekmtcCommon.changeDatePattern(c.pcupReqDt, '-')
          }
          arrParamCntr.push(obj)
        })

        this.popParams = {
          bkgNo: regParams.bkgNo,
          facNm: regParams.facNm,
          facNmTemp: this.facNmTemp,
          zip1: regParams.zip1,
          zip1Temp: regParams.zip1Temp,
          tfcoCstNm: regParams.tfcoCstNm,
          tfcoCstNmTemp: this.tfcoCstNmTemp,
          facPicNm: regParams.facPicNm,
          facPicNmTemp: this.facPicNmTemp,
          facCtfcNo: regParams.facCtfcNo,
          facCtfcNoTemp: this.facCtfcNoTemp,
          owtrYn: regParams.owtrYn,
          owtrYnTemp: this.owtrYnTemp,
          containerList: regParams.containerList
        }

        this.selectFunc = ''

        this.openPopup('PickUpConfirmPop')
      }
    },
    fnValidationBkgNo (frm) {
      if (this.isExistBkgNo === 'Y') {
        this.isExistBkgNo = 'N'
      }

      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      elem = frm.querySelector('#bkgNo')
      if (this.$ekmtcCommon.isEmpty(this.regParams.bkgNo)) {
        isOk = false
        msg = 'Booking No를 입력하세요.'
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    }
  }
}
</script>

<style scoped>
  .span_grs {display:inline-block; width:30px; text-align:center}
  .span_rmk {margin-left:23px;}
  .div_len_ck {position:absolute; top:3px; right:125px; width:100px;}
  .auto-area {display:inline-block; width:60%;}
  .scd_btn_area > span:first-child {margin-right: 0px !important;}
  .scd_btn_area > span+span {margin-left: 50px !important;}
  .div_req_byte {text-align:right; margin-top:2px;}
  .div_req_area textarea {min-height: 70px;}
  .ir_red {color: red; font-weight: 700;}
  .ir_black {color: black; font-weight: 700;}
  .txt_jp {margin-top: 5px; margin-left: 0px !important;}
  .jp_notice {font-size: 12px; color: #F2084F;}
  .tooltip_wrap {min-width:250px; right:0; top:24px; display:none; text-align: left;}
</style>
