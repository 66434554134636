var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("e-breadcrumbs"),
      _vm.isMainTab
        ? [
            _c("h1", { staticClass: "page_title" }, [
              _vm._v(_vm._s(_vm.$t("menu.MENU03.000"))),
            ]),
            _c(
              "e-tab",
              { ref: "tab" },
              [
                _c(
                  "e-tab-item",
                  {
                    attrs: {
                      title: _vm.$t("menu.MENU03.010"),
                      name: "progress",
                      path: "/working",
                      selected: true,
                    },
                  },
                  [
                    _vm.routeName === "" || _vm.routeName === "progress"
                      ? _c("working-progress")
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "e-tab-item",
                  {
                    attrs: {
                      title: _vm.$t("menu.MENU03.020"),
                      name: "unsolved",
                      path: "/working",
                    },
                  },
                  [
                    _vm.routeName === "unsolved"
                      ? _c("working-unsolved")
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "e-tab-item",
                  {
                    attrs: {
                      title: _vm.$t("menu.MENU03.030"),
                      name: "my-request",
                      path: "/working",
                    },
                  },
                  [
                    _vm.routeName === "my-request"
                      ? _c("working-my-request")
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [_c("router-view")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }