<template>
  <div class="content"><!-- content -->
    <div class="location"><!-- location -->
      <ul>
        <li><a href="#" class="home">home</a></li>
        <li><a href="#">진행중인 업무</a></li>
        <li>진행중인 업무</li>
      </ul>
    </div><!-- location -->

    <h1 class="page_title">Upload Flat File</h1>

    <div class="content_box pb15"><!-- content_box -->
      <table class="tbl_search">
        <colgroup>
          <col width="100px">
          <col width="*">
          <col width="110px">
        </colgroup>
        <tbody>
          <tr>
            <th>M.B/L No.</th>
            <td>
              <input type="text" id="" name="" value="KMTC" disabled="disabled" class="wid45 mrm4">
              <input type="text" id="" name="" class="wid120" v-model="masterBlNo">
              <span class="ml2">
                <a class="button blue sh" href="#" @click.prevent="fnSearch">검색</a>
              </span>
            </td>
            <td class="text_right">
            </td>
          </tr>
        </tbody>
      </table>
      <p class="txt_desc none ml10">Please input the master B/L No. for your flatfile.</p>
    </div><!-- content_box // -->

    <div class="content_box pb15 mt10"><!-- content_box -->
      <table class="tbl_search">
        <colgroup>
          <col width="100px"><col width="*">
        </colgroup>
        <tbody>
          <tr>
            <th>Flat File</th>
            <td class="text_left">
              <input type="text" class="upload_name" id="" name="" :value="popupParams && popupParams.length > 0 ? popupParams[0].originalName : ''">
              <a class="button sm gray file" href="#" @click.prevent="uploadPopup('flat-file-upload-pop')">파일첨부</a>
              <!-- <span>
                <a class="button sm" href="#" @click.prevent="fnUploadFile('A')">Upload</a>
              </span> -->
              <span>
                <a class="button sm" href="#" @click.prevent="fnUploadFile('B')">Excel Upload</a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->

    <div class="content_box mt10"><!-- content_box -->
        <table class="tbl_col">
        <colgroup>
          <col width="15%">
          <col width="25%">
          <col width="15%">
          <col width="15%">
          <col width="30%">
        </colgroup>
        <thead>
          <tr>
            <th>Master B/L</th>
            <th>House B/L</th>
            <th>Reg. Date</th>
            <th>Status</th>
            <th>Remark</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="mainArr.length > 0">
          <tr v-for="(item, index) in mainArr" :key="index">
            <td>{{ item.mblNo }}</td>
            <td>
              <p v-html="item.hblNo"></p>
            </td>
            <td>{{ item.reqDt }}</td>
            <td>{{ item.apvStsCdNm }}</td>
            <td>{{ item.rmk }}</td>
          </tr>
          </template>
          <template v-else>
          <tr>
            <td colspan="5">No Data.</td>
          </tr>
          </template>
        </tbody>
      </table>

      <table class="tbl_col mt10">
        <colgroup>
          <col width="33%">
          <col width="33%">
          <col width="34%">
        </colgroup>
        <thead>
          <tr>
            <th>Vessel ETA</th>
            <th colspan="2">Free time flat file submission</th>
          </tr>
          <tr>
            <th>(Based on revised schedule)</th>
            <th>All Port(exluding SIN)</th>
            <th>Inbound shipment from SIN</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in vesselTimeArr" :key="index">
            <td>{{ item.wday }}</td>
            <td>{{ item.closWdayCd }} @{{ item.formattedClosDt }}</td>
            <td>{{ item.sinClosWdayCd }} @{{ item.formattedClosDt }}</td>
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->
    <win-layer-pop class="win-layer-pop">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="layerClose"
        :parent-info="popupParams"
        @set-dext-file-info="arrangeUploadParam"
      />
    </win-layer-pop>
  </div><!-- content // -->
</template>

<script>
import uploadFlatFile from '@/api/rest/trans/uploadFlatFile'

export default {
  name: 'UploadFlatFile',
  components: {
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'flat-file-upload-pop': () => import('@/pages/trans/popup/FlatFileUploadPop')
  },
  data () {
    return {
      masterBlNo: '',
      mainArr: [],
      vesselTimeArr: [],
      customComponent: null,
      popupParams: [],
      uploadFileInfos: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    //하단 VESSEL TIME 초기화
    async init () {
      const result = await uploadFlatFile.findVesselTime()

      this.vesselTimeArr = result.data.map((item) => {
        item.formattedClosDt = item.closDt.substring(0, 2) + ':' + item.closDt.substring(2, 4)
        item.formattedSinClosDt = item.sinClosDt.substring(0, 2) + ':' + item.sinClosDt.substring(2, 4)
        return item
      })
    },
    //검색
    async fnSearch () {
      if (!this.masterBlNo) {
        this.$ekmtcCommon.alertDefault('Please input the master B/L No.')
        return
      }
      const result = await uploadFlatFile.findMasterBl(this.masterBlNo)
      this.mainArr = result.data
    },
    //FLAT FILE UPLOAD
    async fnUploadFile (type) {
      if (!this.masterBlNo) {
        this.$ekmtcCommon.alertDefault('Please input the master B/L No.')
        return
      }

      //FTP UPLOAD : 보류
      if (type === 'A') {
        console.log('ftp upload')
      //EXCEL UPLOAD
      } else {
        const formData = {
          mblNo: this.masterBlNo,
          fileList: this.uploadFileInfos
        }
        const result = await uploadFlatFile.uploadExcelFlatfile(formData)
        this.$ekmtcCommon.alertDefault(result.data)
        this.popupParams = []
      }
    },
    //덱스트 업로드 팝업 띄우기
    uploadPopup (componentNm) {
      this.customComponent = componentNm
      this.$ekmtcCommon.layerOpen('.win-layer-pop')
    },
    //팝업 닫기
    layerClose () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.win-layer-pop')
    },
    //파일추가시 가공
    arrangeUploadParam () {
      this.uploadFileInfos = []
      this.popupParams.map((item) => (item.newFlag = item.new))
      this.uploadFileInfos = this.popupParams
    }
  }
}
</script>
