<template>
  <div
    class="popup_wrap"
    style="width:900px; height:600px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      닫기
    </button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.ONIM070P020.001') }}</h1> <!-- 반납지 변경 -->
      <div class="content_box">
        <table class="tbl_col mt10">
          <colgroup>
            <col style="width:5%">
            <col style="width:11%">
            <col style="width:25%">
            <col style="width:10%">
            <col style="width:15%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:10%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.ONIM070P020.002') }}</th> <!-- 선택 -->
              <th>{{ $t('msg.ONIM070P020.003') }}</th> <!-- Code -->
              <th>{{ $t('msg.ONIM070P020.004') }}</th> <!-- Name -->
              <th>{{ $t('msg.ONIM070P020.005') }}</th> <!-- 담당자 -->
              <th>{{ $t('msg.ONIM070P020.006') }}</th> <!-- 연락처 -->
              <th>{{ $t('msg.ONIM070P020.007') }}</th> <!-- 소재지역 -->
              <th>{{ $t('msg.ONIM070P020.008') }}</th> <!-- 가능여부 -->
              <th>{{ $t('msg.ONIM070P020.009') }}</th> <!-- 불가사유 -->
            </tr>
          </thead>
          <tbody v-if="items.length > 0">
            <tr v-for="(item,index) in items" :key="index">
              <td>
                <input type="radio" name="checkedCy" :id="'checkedCy_' + index" v-model="checkedCy" :value="index">
                <label :for="'checkedCy_' + index"></label>
              </td>
              <td>{{ item.cyCd }}</td>
              <td class="text_left">{{ item.cyEnm }}</td>
              <td>{{ item.cyCd === 'KBGK' ? '장승훈' : item.picEnm }}</td>
              <td>{{ item.cyCd === 'KBGK' ? '031-389-6210,\n 031-389-6209' : item.telNo }}</td>
              <td>{{ item.plcNm }}</td>
              <td :style="{color: item.restYn > 0 ? 'red' : ''}">{{ item.restYn > 0 ? $t('msg.ONIM070P020.010') : $t('msg.ONIM070P020.011')}}</td> <!-- 불가능 or 가능-->
              <td>{{ item.restYn > 0 ? $t('msg.ONIM070P020.012') : ''}}</td> <!-- 하차 제한-->
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="8">No Data</td>
            </tr>
          </tbody>
        </table>
        <div class="mt10 text_center">
          <a class="button blue lg mr3" href="#" @click.prevent="returnChangeRequest">OK</a>
          <a class="button gray lg" href="#" @click.prevent="$emit('close')">Close</a>
        </div>
      </div><!-- content_box // -->
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import containerReturn from '@/api/rest/trans/containerReturn'
import doapi from '@/api/rest/trans/do'
import cp from '@/api/rest/cp/cp'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'ContainerReturn',
  data: function () {
    return {
      items: [],
      checkedCy: '',
      podTrmlCd: ''
    }
  },
  computed: {
    ...rootComputed
  },
  props: {
    propContainerList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  created () {

  },
  mounted () {
    this.getReturnChangeList()
  },
  methods: {
    //반납지 변경 가능 및 불가능 리스트 가져오기
    async getReturnChangeList () {
      console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', this.propContainerList)
      let containerList = this.propContainerList
      let containerNoList = containerList.map((item) => {
        return item.cntrNo
      })

      let cntrDataDetail = containerList[0]
      const params = {
        cntrNoArr: containerNoList, // 선택한 컨테이너들 No.
        bankYn: cntrDataDetail.bankYn,
        bgkRtnYn: cntrDataDetail.bgkRtnYn,
        blNo: cntrDataDetail.blNo,
        cntrNo: cntrDataDetail.cntrNo,
        cntrOwn: cntrDataDetail.cntrOwn,
        cntrSzCd: cntrDataDetail.cntrSzCd,
        cntrTypCd: cntrDataDetail.cntrTypCd,
        cyCd: cntrDataDetail.cyCd,
        cyEnm: cntrDataDetail.cyEnm,
        detAmt: cntrDataDetail.detAmt,
        detDt: cntrDataDetail.detDt,
        dropKrw: cntrDataDetail.dropKrw,
        freeDds: cntrDataDetail.freeDds,
        picEnm: cntrDataDetail.picEnm,
        plcCd: cntrDataDetail.plcCd,
        telNo: cntrDataDetail.telNo,
        cstCd: cntrDataDetail.cstCd,
        leaseCyCnt: cntrDataDetail.leaseCyCnt
      }

      let returnChangeList = await containerReturn.returnChangeList(params)
      const cntrList = this.propContainerList

      let cpResult = null
      if (cntrList.length > 0) {
        for (const c of cntrList) {
          const cpParam = {
            actionCd: 'Q301',
            cntrSize: c.cntrSzCd,
            cntrType: c.cntrTypCd,
            returnDate: c.detDt
          }

          await cp.getCallActionHandler(cpParam).then(res => {
            cpResult = res.data
          })

          if (this.$ekmtcCommon.isNotEmpty(cpResult) && cpResult.result === 'Y') {
            break
          }
        }
      }

      this.items = [...returnChangeList.data.list]
      this.podTrmlCd = returnChangeList.data.podTrmlCd

      if (this.items.length > 0 && this.$ekmtcCommon.isNotEmpty(cpResult) && cpResult.result === 'Y') {
        const impossTrml = cpResult.impossTrml
        const possTrml = cpResult.possTrml

        this.items.forEach(item => {
          if (this.$ekmtcCommon.isNotEmpty(impossTrml)) {
            if (impossTrml.indexOf(item.cyCd) > -1) {
              item.restYn = '1'
            }
          }

          if (this.$ekmtcCommon.isNotEmpty(possTrml)) {
            if (possTrml.indexOf(item.cyCd) > -1) {
              item.restYn = '0'
            }
          }
        })
      }

      //220110 : 정렬우선순위: 가능여부>소재지역>CODE 오름차순
      this.items.sort((a, b) => {
        let restYnA = a.restYn
        let restYnB = b.restYn
        let plcNmA = a.plcNm.toUpperCase()
        let plcNmB = b.plcNm.toUpperCase()
        let cyCdA = a.cyCd.toUpperCase()
        let cyCdB = b.cyCd.toUpperCase()

        if (restYnA > restYnB) return 1
        if (restYnA < restYnB) return -1
        if (plcNmA > plcNmB) return 1
        if (plcNmA < plcNmB) return -1
        if (cyCdA > cyCdB) return 1
        if (cyCdA < cyCdB) return -1

        return 0
      })
    },
    //변경요청
    async returnChangeRequest () {
      //변경요청 팝업에서 선택한 CY
      let targetCy = this.items[this.checkedCy]

      let cyCd = targetCy.cyCd //rest_yn[0]
      let restYn = parseInt(targetCy.restYn) //rest_yn[2]
      let cyPlcCd = targetCy.plcCd //rest_yn[5]
      let restCntrSzTyp = targetCy.restCntrSzTyp === 'N' ? '' : targetCy.restCntrSzTyp

      if (cyCd === 'SNCT' || cyCd === 'HJIT') {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONIM070P020.013') }) //타포트 반출분만 반입가능, 인천 반출분 금지.
      }

      if (restYn !== 0) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070P020.014') + ' (' + restCntrSzTyp.split('||').join(',') + ')') //하차가 제한된 반납지입니다. (xxxx,xxxx,..)
        return
      }

      //Parent에서 선택한 컨테이너들
      let containerList = this.propContainerList

      let dropKRW = 0
      let cntrNo = ''
      let cyCd2 = ''
      let tempTmnl = ''
      let returnFlag = false

      containerList.forEach((container) => {
        let plcCd = container.plcCd

        if (plcCd === 'INC' && cyPlcCd === 'BGK') { //컨테이너 장소가 INC인데 변경장소가 BGK인 경우 부과비용 발생
          dropKRW += Number(container.dropKrw)
        }

        if (containerList.length > 1) {
          if (plcCd !== 'INC' && plcCd !== 'BGK' && (cyPlcCd === 'BGK' || cyPlcCd === 'INC')) { //컨테이너 장소가 인천과 부곡이 아닌데 부곡, 인천으로 변경시 부과비용 발생
            dropKRW += Number(container.dropKrw)
          }
        } else {
          if (plcCd !== 'INC' && (cyPlcCd === 'BGK' || cyPlcCd === 'INC')) {
            dropKRW += Number(container.dropKrw)
          }
        }

        cntrNo += container.cntrNo + ':'
        cyCd2 += cyCd + ':'
        tempTmnl = container.cyCd
        let cntrSzType = container.cntrSzCd + container.cntrTypCd

        if (cntrSzType === '20GP' || cntrSzType === '40GP' || cntrSzType === '40HC') {
          if (this.podTrmlCd === 'BNCT' || this.podTrmlCd === 'HJNC' || this.podTrmlCd === 'HPNT' || this.podTrmlCd === 'PNCT' || this.podTrmlCd === 'PNIT' || this.podTrmlCd === 'BMNT') {
            if (cyPlcCd === 'PNC' && this.podTrmlCd !== cyCd) {
              this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070P020.015')) //해당 컨테이너는 지정된 터미널/CY 로만 엠티 컨테이너 반납 가능합니다
              returnFlag = true
            }
          } else if (this.podTrmlCd === 'ICT' || this.podTrmlCd === 'E1CT' || this.podTrmlCd === 'SNCT' || this.podTrmlCd === 'HJIT') {
            if (cyPlcCd === 'INC' && this.podTrmlCd !== cyCd) {
              this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070P020.015')) //해당 컨테이너는 지정된 터미널/CY 로만 엠티 컨테이너 반납 가능합니다
              returnFlag = true
            }
          }
        }
      })

      if (returnFlag) {
        return
      }

      let invCanYn = ''
      let invIssNo = containerList[0].invIssNo // 10
      let apvStsCd = containerList[0].apvStsCd // 13
      let oldReqRno = ''
      //dropKRW에 따른 분기
      if (Number(dropKRW) > 0) {
        //세션유저아이디에 따라 로그인 페이지 튕기기
        if (!this.auth.userId) {
          //로긴페이지로?
           this.$ekmtcCommon.alertDefault('로그인이 필요합니다.')
          return
        }

        if (invIssNo && apvStsCd !== 'Cancelled') {
          //기 생성된 Invoice No. (xxxx)가 존재합니다. \n 해당 Invoice를 캔슬하시고 새로운 Invoice를 생성하시겠습니까? \n - [확인] 기존 내역 + 신규 내역에 대한 신규 Invoice 발행 \n - [취소] 신규 내역에 대한 별도 Invoice 추가 발행
          const confirmMsg = this.$t('msg.ONIM070P020.016') + ' (' + (containerList.length > 1 ? invIssNo : containerList[0].cntrNo) + ')' + this.$t('msg.ONIM070P020.017') + ' \n' + this.$t('msg.ONIM070P020.018')
          if (await this.$ekmtcCommon.asyncAlertMessage({ message: confirmMsg, useConfirmBtn: true })) {
            invCanYn = 'Y'
            oldReqRno = containerList[0].reqRno
          } else {
            return
          }
        } else {
          //선택하신 컨테이너 No.()외 x건건에 대해 Invoice를 자동으로 생성합니다. \n 항목: Drop off Charge / 예상금액: KRW xxx(VAT 별도)
          const confirmMsg = this.$t('msg.ONIM070P020.021') + (containerList[0].cntrNo) + this.$t('msg.ONIM070P020.022') + ' ' + (Number(containerList.length - 1)) + this.$t('msg.ONIM070P020.023') + '\n' + this.$t('msg.ONIM070P020.024') + Number(dropKRW) + this.$t('msg.ONIM070P020.025')
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: confirmMsg, useConfirmBtn: true })) {
            return
          }
        }
      }

      let saveFlagYn = 'Y'
      let dropKrw = Number(dropKRW) === 0 ? '' : Number(dropKRW)

      let data = {
        blNo: containerList[0].blNo,
        asCntrNoArr: cntrNo,
        cyCdArr: cyCd2,
        invNo: invIssNo,
        dropKrw: dropKrw,
        invCanYn: invCanYn,
        oldReqRno: oldReqRno
      }

      //변경요청 API 호출
      let result = await containerReturn.returnChangeRequest(data)
      let respCode = result.headers.respcode

      //변경요청 완료 후, 최종적인 alert호출 및 KLNET전송 호출
      if (respCode === 'C0000') {
        let sendCheck = true
        let results = result.data
        let alertResult = ''

        results.forEach((result, index) => {
          //alertResult += cntrNo.split(':')[index] + ':'
          console.log(result)
          console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', result.indexOf('00'))
          if (result === '01') {
            sendCheck = false
            alertResult += this.$t('msg.ONIM070P020.026') //Issed D/O doesn\\'t exist (CS133I) .\n Capture this screen and give it to IT team.\n
          } else if (result === '11') {
            sendCheck = false
            alertResult += this.$t('msg.ONIM070P020.027') //Customer Code doesn\\'t exists!! Please contact sales man.
          } else if (result === '02') {
            sendCheck = false
            alertResult += this.$t('msg.ONIM070P020.028') //Error occurs while performing \\'CSDBL100.request4\\'\n Capture this screen and give it to IT team.\n
          } else if (result === '03') {
            sendCheck = false
            alertResult += this.$t('msg.ONIM070P020.029') //Error occurs while performing \\'CSDBL100.request5\\'\n Capture this screen and give it to IT team.\n
          } else if (result === '04') {
            sendCheck = false
            alertResult += this.$t('msg.ONIM070P020.030') //Error occurs while performing \\'CSDBL100.request7\\'\n Capture this screen and give it to IT team.\n
          } else if (result === '05') {
            sendCheck = false
            alertResult += this.$t('msg.ONIM070P020.045') //Issued Invoice 번호가 이미 존재합니다. 요청취소 또는 초기화 바랍니다.\n
          } else if (result === '00' || result.indexOf('00') > -1) {
            //alertResult += 'Saved successfully!\n'
            alertResult = (this.$t('msg.ONIM070P020.031') + '\n') //E-TRANS에 접속하여 반납지 변경 확인 후 COPINO 전송 바랍니다.
          } else if (result.indexOf('13') > -1) {
            //alertResult += 'Saved successfully!\n해당 금액 입금 확인 후 30분 이내 처리 예정입니다. 감사합니다.\n'
            alertResult = (this.$t('msg.ONIM070P020.031') + '\n') //E-TRANS에 접속하여 반납지 변경 확인 후 COPINO 전송 바랍니다.
          }
        })

        //입금할 금액 없을 시 KLNET 전송
        if (results[0].indexOf('13') === -1) {
          let sendResult = await doapi.sendKLNetDo({ blNo: containerList[0].blNo })
          if (sendCheck) {
            if (sendResult.data !== '01') {
              alertResult += ('\n' + this.$t('msg.ONIM070P020.033') + '\n') //Failed to send to KLNET PLISM.
            }
          }
        }

        let reqRno = ''
        let invRno = ''
        if (results[0].indexOf('13') > -1) {
          reqRno = results[0].split('@')[1]
          invRno = results[0].split('@')[2]
        }

        await this.$emit('fnChangeAfterCancel')
        //this.$ekmtcCommon.alertDefault(alertResult)
        await this.$ekmtcCommon.asyncAlertMessage({ message: alertResult })
        this.$emit('close')
        this.$emit('reload')

        saveFlagYn = 'N'
        if (reqRno && invRno) {
          //TO-BE: 추후 모듈 공통화 될수도 있으니 그 때 작업
        }
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070P020.034')) //오류가 발생하였습니다.
      }
    }
  }
}
</script>
