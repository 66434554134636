var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "capture_area" }, [
    _c(
      "form",
      { attrs: { id: "srForm" } },
      [
        _vm.seqNo !== ""
          ? _c(
              "div",
              {
                staticClass: "content_box",
                staticStyle: { "padding-bottom": "10px" },
              },
              [
                _c(
                  "h2",
                  {
                    staticClass: "content_title",
                    staticStyle: { float: "left" },
                  },
                  [_vm._v("S/R No.")]
                ),
                _c(
                  "select",
                  {
                    staticStyle: { width: "150px", "margin-left": "10px" },
                    attrs: { id: "seqNo" },
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: { selected: "" },
                        domProps: { value: _vm.seqNo },
                      },
                      [_vm._v(_vm._s(_vm.seqNo))]
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "content_box",
            staticStyle: { "padding-bottom": "35px" },
          },
          [
            _vm.templateYn == "Y"
              ? _c("div", { staticClass: "booking_call_wrap" }, [
                  _c("div", { staticClass: "call_search" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.393"))),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.templateNo,
                          expression: "templateNo",
                        },
                      ],
                      attrs: { type: "text", placeholder: "SR No." },
                      domProps: { value: _vm.templateNo },
                      on: {
                        keypress: _vm.templateEnter,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.templateNo = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "search_btn01",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.templateSearch()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "offscreen" }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBK100.121"))),
                        ]),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "button blue sh ml15",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.setTemplateInfo()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("btn.CMBTK010")))]
                    ),
                  ]),
                ])
              : _c("div", { staticClass: "booking_call_wrap" }, [
                  _c("div", { staticStyle: { display: "inline-block" } }, [
                    _c(
                      "h2",
                      {
                        staticClass: "content_title",
                        staticStyle: { float: "left" },
                      },
                      [_vm._v("Booking No : " + _vm._s(_vm.detailBkgNo) + " ")]
                    ),
                    _c("div", { staticStyle: { width: "3%", float: "left" } }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn_copy ml3",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnCopy()
                            },
                          },
                        },
                        [_vm._v("copy")]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "line" }, [
                    _c(
                      "div",
                      { staticStyle: { width: "19%", float: "left" } },
                      [
                        _c(
                          "h2",
                          {
                            staticClass: "content_title",
                            staticStyle: { float: "left" },
                          },
                          [_vm._v("S/R No.")]
                        ),
                        _c(
                          "select",
                          {
                            staticStyle: {
                              width: "150px",
                              "margin-left": "10px",
                            },
                            attrs: { id: "srList" },
                            on: {
                              change: function ($event) {
                                return _vm.fnChangeBL($event)
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(this.$t("msg.CSBK100.050"))),
                            ]),
                            _vm._l(_vm.srList, function (vo, idx) {
                              return _c(
                                "option",
                                {
                                  key: "srList_" + idx,
                                  domProps: {
                                    value: vo,
                                    selected: vo == _vm.pageSrRno,
                                  },
                                },
                                [_vm._v(_vm._s(vo))]
                              )
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticStyle: { width: "auto", float: "left" } },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "button sm mr10",
                            on: {
                              click: function ($event) {
                                return _vm.fnCommonBtnAction()
                              },
                            },
                          },
                          [_vm._v("Log " + _vm._s(_vm.$t("btn.CMBTK011")))]
                        ),
                      ]
                    ),
                  ]),
                ]),
          ]
        ),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.001"))),
          ]),
          _c("span"),
          _c("div", { staticClass: "e_mail_notice" }, [
            _c("p", { staticClass: "ic_txt" }, [
              _vm._v(" " + _vm._s(_vm.$t("msg.NEWB020G010.002")) + " "),
            ]),
            _c("span", { staticClass: "inp_form" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.regParams.emailcheck,
                    expression: "regParams.emailcheck",
                  },
                ],
                attrs: { type: "radio", id: "emailcheck_y", value: "Y" },
                domProps: { checked: _vm._q(_vm.regParams.emailcheck, "Y") },
                on: {
                  change: [
                    function ($event) {
                      return _vm.$set(_vm.regParams, "emailcheck", "Y")
                    },
                    function ($event) {
                      return _vm.fnEmailInput()
                    },
                  ],
                },
              }),
              _c(
                "label",
                { staticClass: "mr10", attrs: { for: "emailcheck_y" } },
                [_vm._v("YES")]
              ),
            ]),
            _c("span", { staticClass: "inp_form" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.regParams.emailcheck,
                    expression: "regParams.emailcheck",
                  },
                ],
                attrs: { type: "radio", id: "emailcheck_n", value: "N" },
                domProps: { checked: _vm._q(_vm.regParams.emailcheck, "N") },
                on: {
                  change: [
                    function ($event) {
                      return _vm.$set(_vm.regParams, "emailcheck", "N")
                    },
                    function ($event) {
                      return _vm.fnEmailInput()
                    },
                  ],
                },
              }),
              _c(
                "label",
                { staticClass: "mr10", attrs: { for: "emailcheck_n" } },
                [_vm._v("No")]
              ),
            ]),
          ]),
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.NEWB020G010.003")))]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.tempParams.bizPicEmlAddr,
                          expression: "tempParams.bizPicEmlAddr",
                        },
                      ],
                      staticStyle: { width: "120px" },
                      attrs: { readonly: _vm.isEmailReadonly("select") },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.tempParams,
                              "bizPicEmlAddr",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.fnChangeEmail()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(this.$t("msg.CSBK100.050"))),
                      ]),
                      _vm._l(_vm.srInitData.findEmail, function (str, idx) {
                        return _c(
                          "option",
                          { key: "email_" + idx, domProps: { value: str } },
                          [_vm._v(_vm._s(str))]
                        )
                      }),
                    ],
                    2
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.regParams.bizPicEmlAddr,
                        expression: "regParams.bizPicEmlAddr",
                      },
                    ],
                    staticClass: "wid500",
                    class: _vm.isEmailReadonly("input") ? "readonly" : "",
                    attrs: {
                      type: "text",
                      id: "bizPicEmlAddr",
                      placeholder: _vm.$t("msg.CSBK100.152"),
                      maxlength: "100",
                      readonly: _vm.isEmailReadonly("input"),
                    },
                    domProps: { value: _vm.regParams.bizPicEmlAddr },
                    on: {
                      blur: function ($event) {
                        return _vm.checkValidationOne("bizPicEmlAddr")
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.regParams,
                          "bizPicEmlAddr",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _c("p", { staticClass: "txt_desc" }, [
            _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.150")) + " "),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.004"))),
          ]),
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(1),
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.005"))),
                ]),
                _c("th", { attrs: { colspan: "2", scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.006"))),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c(
                  "td",
                  [
                    _c("e-trans-address-input", {
                      ref: "shprCstEnm",
                      attrs: {
                        id: "shprCstEnm",
                        "is-required": true,
                        "message-required": "Please Input Shipper name.",
                        "is-char": true,
                        "max-length": 50,
                      },
                      on: { input: _vm.checkShpperPOA },
                      model: {
                        value: _vm.regParams.shprCstEnm,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "shprCstEnm", $$v)
                        },
                        expression: "regParams.shprCstEnm",
                      },
                    }),
                  ],
                  1
                ),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(_vm._s(_vm.srInitData.custInfo.cstNm)),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { attrs: { rowspan: "3" } },
                  [
                    _c("e-trans-address-textarea", {
                      ref: "shprCstAddr",
                      attrs: {
                        id: "shprCstAddr",
                        "max-line": 5,
                        "column-length": 50,
                        "is-char": true,
                        "max-length": 2000,
                      },
                      on: { input: _vm.checkShpperPOA },
                      model: {
                        value: _vm.regParams.shprCstAddr,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "shprCstAddr", $$v)
                        },
                        expression: "regParams.shprCstAddr",
                      },
                    }),
                    _vm.isShipperLOIPlc && _vm.isShipperLOI
                      ? _c("div", { staticClass: "shp_msg" }, [
                          _vm.$ekmtcCommon.isEmpty(_vm.saveShipperLOI)
                            ? _c("span", { staticClass: "shp_msg_txt" }, [
                                _vm._v(_vm._s(_vm.$t("msg.CSBL200.222"))),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "d_inline_flex" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button sm mt5",
                                on: {
                                  click: function ($event) {
                                    return _vm.fnCommonBtnAction("TRD_SHIPPER")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.CSBL200.272")))]
                            ),
                            _vm.$ekmtcCommon.isNotEmpty(_vm.saveShipperLOI)
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "btn_doc mt5",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.fnCommonBtnAction(
                                          "TRD_SHIPPER_FILE"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Shipper LOI 첨부 확인")]
                                )
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.isShipperPOA
                      ? _c("div", { staticClass: "shp_msg" }, [
                          _c("div", { staticClass: "d_inline_flex" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button sm mt5",
                                on: {
                                  click: function ($event) {
                                    return _vm.fnCommonBtnAction("POA_SHIPPER")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.CSBL200.287")))]
                            ),
                            _vm.$ekmtcCommon.isNotEmpty(_vm.saveShipperPOA)
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "btn_doc mt5",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.fnCommonBtnAction(
                                          "POA_SHIPPER_FILE"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Power of Attorney 첨부 확인")]
                                )
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.007"))),
                ]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.srInitData.custInfo.pic) + " "),
                ]),
              ]),
              _c("tr", [
                _c("th", { staticClass: "bor-left", attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.008"))),
                ]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.srInitData.custInfo.telPlc) +
                      "-" +
                      _vm._s(_vm.srInitData.custInfo.telOfc) +
                      " "
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", { staticClass: "bor-left", attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.009"))),
                ]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.srInitData.custInfo.faxPlc) +
                      "-" +
                      _vm._s(_vm.srInitData.custInfo.faxOfc) +
                      " "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "mt10" }, [
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(2),
              _c("thead", [
                _c("tr", [
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.010"))),
                  ]),
                  _c("th", { attrs: { colspan: "2", scope: "col" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.011"))),
                  ]),
                ]),
              ]),
              _c("tbody", [
                _c("tr", [
                  _c(
                    "td",
                    [
                      _c("e-trans-address-input", {
                        ref: "cneCstEnm",
                        attrs: {
                          id: "cneCstEnm",
                          "is-required": true,
                          "message-required": "Please Input Consignee name.",
                          "is-char": true,
                          "max-length": 50,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.checkSanction("cneCstEnm")
                          },
                        },
                        model: {
                          value: _vm.regParams.cneCstEnm,
                          callback: function ($$v) {
                            _vm.$set(_vm.regParams, "cneCstEnm", $$v)
                          },
                          expression: "regParams.cneCstEnm",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.012"))),
                  ]),
                  _c(
                    "td",
                    [
                      _c("e-trans-string-check-cnee-input", {
                        attrs: { id: "cnePicNm", maxlength: "100" },
                        model: {
                          value: _vm.regParams.cnePicNm,
                          callback: function ($$v) {
                            _vm.$set(_vm.regParams, "cnePicNm", $$v)
                          },
                          expression: "regParams.cnePicNm",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c(
                    "td",
                    { attrs: { rowspan: "3" } },
                    [
                      _c("e-trans-address-textarea", {
                        ref: "cneCstAddr",
                        attrs: {
                          id: "cneCstAddr",
                          "max-line": 5,
                          "column-length": 50,
                          "is-char": true,
                          "max-length": 2000,
                        },
                        model: {
                          value: _vm.regParams.cneCstAddr,
                          callback: function ($$v) {
                            _vm.$set(_vm.regParams, "cneCstAddr", $$v)
                          },
                          expression: "regParams.cneCstAddr",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.013"))),
                  ]),
                  _c(
                    "td",
                    [
                      _c("e-trans-string-check-cnee-input", {
                        attrs: { id: "cnePicTelNo", maxlength: "30" },
                        model: {
                          value: _vm.regParams.cnePicTelNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.regParams, "cnePicTelNo", $$v)
                          },
                          expression: "regParams.cnePicTelNo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c(
                    "th",
                    { staticClass: "bor-left", attrs: { scope: "row" } },
                    [_vm._v(_vm._s(_vm.$t("msg.NEWB020G010.014")))]
                  ),
                  _c(
                    "td",
                    [
                      _c("e-trans-string-check-cnee-input", {
                        attrs: { id: "cnePicFaxNo", maxlength: "30" },
                        model: {
                          value: _vm.regParams.cnePicFaxNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.regParams, "cnePicFaxNo", $$v)
                          },
                          expression: "regParams.cnePicFaxNo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c(
                    "th",
                    { staticClass: "bor-left", attrs: { scope: "row" } },
                    [_vm._v(_vm._s(_vm.$t("msg.NEWB020G010.015")))]
                  ),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.cnePicEmlAddr,
                          expression: "regParams.cnePicEmlAddr",
                        },
                      ],
                      attrs: {
                        type: "text",
                        id: "cnePicEmlAddr",
                        maxlength: "100",
                      },
                      domProps: { value: _vm.regParams.cnePicEmlAddr },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.regParams,
                              "cnePicEmlAddr",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            return _vm.checkValidationOne("cnePicEmlAddr")
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "mt10" }, [
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(3),
              _c("thead", [
                _c("tr", [
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.016"))),
                  ]),
                  _c("th", { attrs: { colspan: "2", scope: "col" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.017"))),
                  ]),
                ]),
              ]),
              _c("tbody", [
                _c("tr", [
                  _c("td", [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        name: "cneeCopy",
                        id: "cneeCopy",
                      },
                      domProps: { checked: _vm.regParams.consYn === "Y" },
                      on: { click: _vm.changeCnee },
                    }),
                    _c("label", { attrs: { for: "cneeCopy" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.018"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt5" },
                      [
                        _c("e-trans-address-input", {
                          ref: "ntifCstEnm",
                          attrs: {
                            id: "ntifCstEnm",
                            "is-required": true,
                            "message-required": "Please Input Notify name.",
                            "is-char": true,
                            "max-length": 50,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkSanction("ntifCstEnm")
                            },
                          },
                          model: {
                            value: _vm.regParams.ntifCstEnm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "ntifCstEnm", $$v)
                            },
                            expression: "regParams.ntifCstEnm",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.bookingAgent,
                            expression: "regParams.bookingAgent",
                          },
                        ],
                        attrs: {
                          disabled:
                            _vm.srInitData.bkgAgntYn == "Y" ||
                            _vm.memberDetail.bkgPlcCd == "NBO",
                        },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.regParams,
                              "bookingAgent",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _vm.memberDetail.bkgPlcCd == "NBO"
                          ? [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Select"),
                              ]),
                              _c(
                                "option",
                                { domProps: { value: _vm.memberDetail.cstCd } },
                                [_vm._v(_vm._s(_vm.srInitData.custInfo.cstEnm))]
                              ),
                            ]
                          : _vm.srInitData.bkgAgntList != null &&
                            _vm.srInitData.bkgAgntList.length > 0
                          ? _vm._l(
                              _vm.srInitData.bkgAgntList,
                              function (agentVo, idx) {
                                return _c(
                                  "option",
                                  {
                                    key: "agent_" + idx,
                                    domProps: { value: agentVo.cstNo },
                                  },
                                  [_vm._v(_vm._s(agentVo.cstNm))]
                                )
                              }
                            )
                          : [
                              _c(
                                "option",
                                { domProps: { value: _vm.memberDetail.cstCd } },
                                [_vm._v(_vm._s(_vm.memberDetail.cstCd))]
                              ),
                            ],
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c(
                    "td",
                    {
                      attrs: {
                        rowspan: _vm.regParams.fixdCgoYn === "Y" ? "7" : "6",
                      },
                    },
                    [
                      _c("e-trans-address-textarea", {
                        ref: "ntifCstAddr",
                        attrs: {
                          id: "ntifCstAddr",
                          "max-line": 5,
                          "column-length": 50,
                          "is-char": true,
                          "max-length": 2000,
                        },
                        model: {
                          value: _vm.regParams.ntifCstAddr,
                          callback: function ($$v) {
                            _vm.$set(_vm.regParams, "ntifCstAddr", $$v)
                          },
                          expression: "regParams.ntifCstAddr",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "th",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.bkgShprCodAreaYn === "Y",
                          expression: "bkgShprCodAreaYn === 'Y'",
                        },
                      ],
                      attrs: { scope: "row" },
                    },
                    [_vm._v("Booking Shipper Code")]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.bkgShprCodAreaYn === "Y",
                          expression: "bkgShprCodAreaYn === 'Y'",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "tbl_form" }, [
                        _c("div", [
                          _c("span", { staticClass: "pr5" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.bkgShprCstNo,
                                  expression: "regParams.bkgShprCstNo",
                                },
                              ],
                              attrs: {
                                id: "bkgShprCstNo",
                                type: "text",
                                maxlength: 6,
                              },
                              domProps: { value: _vm.regParams.bkgShprCstNo },
                              on: {
                                blur: function ($event) {
                                  return _vm.getBkgShpr()
                                },
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams,
                                      "bkgShprCstNo",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    _vm.regParams.bkgShprCstNo =
                                      _vm.regParams.bkgShprCstNo.toUpperCase()
                                  },
                                ],
                              },
                            }),
                          ]),
                          _c(
                            "span",
                            { staticClass: "col_8" },
                            [
                              _c("e-trans-string-check-input", {
                                attrs: {
                                  id: "bkgShprCstEnm",
                                  "is-upper-case": false,
                                  readonly: true,
                                },
                                model: {
                                  value: _vm.regParams.bkgShprCstEnm,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.regParams,
                                      "bkgShprCstEnm",
                                      $$v
                                    )
                                  },
                                  expression: "regParams.bkgShprCstEnm",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _c("tr", [
                  _c(
                    "th",
                    { staticClass: "bor-left", attrs: { scope: "row" } },
                    [_vm._v(_vm._s(_vm.$t("msg.NEWB020G010.019")))]
                  ),
                  _c(
                    "td",
                    [
                      _c("e-trans-string-check-input", {
                        attrs: {
                          id: "shpmNo",
                          "is-upper-case": true,
                          maxlength: "10",
                        },
                        model: {
                          value: _vm.regParams.shpmNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.regParams, "shpmNo", $$v)
                          },
                          expression: "regParams.shpmNo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c(
                    "th",
                    { staticClass: "bor-left", attrs: { scope: "row" } },
                    [_vm._v(_vm._s(_vm.$t("msg.NEWB020G010.020")))]
                  ),
                  _c(
                    "td",
                    [
                      _c("e-trans-string-check-input", {
                        attrs: {
                          id: "hsCd",
                          "is-upper-case": true,
                          maxlength: "6",
                        },
                        model: {
                          value: _vm.regParams.hsCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.regParams, "hsCd", $$v)
                          },
                          expression: "regParams.hsCd",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c(
                    "th",
                    { staticClass: "bor-left", attrs: { scope: "row" } },
                    [_vm._v(_vm._s(_vm.$t("msg.NEWB020G010.021")))]
                  ),
                  _c(
                    "td",
                    [
                      _c("e-trans-string-check-input", {
                        attrs: {
                          id: "podHsCd",
                          "is-upper-case": true,
                          maxlength: _vm.tempParams.maxlengthPodHsCd,
                          "error-message": _vm.tempParams.hscdErrorMessage,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.chkHscd($event)
                          },
                        },
                        model: {
                          value: _vm.regParams.podHsCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.regParams, "podHsCd", $$v)
                          },
                          expression: "regParams.podHsCd",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c(
                    "th",
                    { staticClass: "bor-left", attrs: { scope: "row" } },
                    [_vm._v(_vm._s(_vm.$t("msg.NEWB020G010.022")))]
                  ),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.chemicalYn,
                          expression: "regParams.chemicalYn",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "chemicalY",
                        name: "chemicalYn",
                        value: "Y",
                        "true-value": "Y",
                        "false-value": "N",
                      },
                      domProps: {
                        checked: _vm._q(_vm.regParams.chemicalYn, "Y"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.checkValidationOne("chemicalYn")
                        },
                        change: function ($event) {
                          return _vm.$set(_vm.regParams, "chemicalYn", "Y")
                        },
                      },
                    }),
                    _vm._m(4),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.chemicalYn,
                          expression: "regParams.chemicalYn",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "chemicalN",
                        name: "chemicalYn",
                        value: "N",
                        "true-value": "N",
                        "false-value": "Y",
                      },
                      domProps: {
                        checked: _vm._q(_vm.regParams.chemicalYn, "N"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.checkValidationOne("chemicalYn")
                        },
                        change: function ($event) {
                          return _vm.$set(_vm.regParams, "chemicalYn", "N")
                        },
                      },
                    }),
                    _vm._m(5),
                    _vm._v("   "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.seqNo == "" &&
                              _vm.regParams.chemicalYn === "Y",
                            expression:
                              "seqNo =='' && regParams.chemicalYn === 'Y'",
                          },
                        ],
                        staticClass: "button sm",
                        class: _vm.isChemicalFileUpload() ? "blue" : "gray",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.openUploadPopup("chemical")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.205")) + " ")]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c(
                    "th",
                    { staticClass: "bor-left", attrs: { scope: "row" } },
                    [_vm._v("Fix Cargo")]
                  ),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.fixdCgoYn,
                          expression: "regParams.fixdCgoYn",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "fixdCgoY",
                        name: "fixdCgoYn",
                        value: "Y",
                        "true-value": "Y",
                        "false-value": "N",
                      },
                      domProps: {
                        checked: _vm._q(_vm.regParams.fixdCgoYn, "Y"),
                      },
                      on: {
                        change: [
                          function ($event) {
                            return _vm.$set(_vm.regParams, "fixdCgoYn", "Y")
                          },
                          _vm.getActShprCst,
                        ],
                      },
                    }),
                    _vm._m(6),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.fixdCgoYn,
                          expression: "regParams.fixdCgoYn",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "fixdCgoN",
                        name: "fixdCgoYn",
                        value: "N",
                        "true-value": "N",
                        "false-value": "Y",
                      },
                      domProps: {
                        checked: _vm._q(_vm.regParams.fixdCgoYn, "N"),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.regParams, "fixdCgoYn", "N")
                        },
                      },
                    }),
                    _vm._m(7),
                  ]),
                ]),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.regParams.fixdCgoYn === "Y",
                        expression: "regParams.fixdCgoYn === 'Y'",
                      },
                    ],
                  },
                  [
                    _c(
                      "th",
                      { staticClass: "bor-left", attrs: { scope: "row" } },
                      [_vm._v("B/L Shipper")]
                    ),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.actShprCstNo,
                              expression: "regParams.actShprCstNo",
                            },
                          ],
                          attrs: { id: "actShprCstNo" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.regParams,
                                  "actShprCstNo",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.checkValidationActShprCst,
                            ],
                          },
                        },
                        [
                          _vm.actShprCstList != null &&
                          _vm.actShprCstList.length > 0
                            ? _vm._l(
                                _vm.actShprCstList,
                                function (actShprCstVo, idx) {
                                  return _c(
                                    "option",
                                    {
                                      key: "actShprCst_" + idx,
                                      domProps: {
                                        value: actShprCstVo.actShprCstNo,
                                      },
                                    },
                                    [_vm._v(_vm._s(actShprCstVo.actShprCstEnm))]
                                  )
                                }
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _vm.testData.testYn == "Y"
          ? _c("div", [
              _c("div", { staticClass: "content_box mt10" }, [
                _c(
                  "select",
                  {
                    attrs: { id: "selectTestData" },
                    on: {
                      change: function ($event) {
                        return _vm.scheduleTest($event)
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(":: 선택 ::"),
                    ]),
                    _vm._l(_vm.testData.schedule, function (tvo, idx) {
                      return _c(
                        "option",
                        { key: "test01_" + idx, domProps: { value: tvo.key } },
                        [_vm._v(_vm._s("[" + tvo.key + "]" + tvo.text))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.023"))),
          ]),
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(8),
            _c("tbody", [
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.024"))),
                ]),
                _c("td", [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.porCtrCd,
                          expression: "regParams.porCtrCd",
                        },
                      ],
                      attrs: { type: "text", readonly: "readonly" },
                      domProps: { value: _vm.regParams.porCtrCd },
                      on: {
                        click: function ($event) {
                          return _vm.searchSchedule()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.regParams,
                            "porCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "mt5" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.porPlcNm,
                          expression: "regParams.porPlcNm",
                        },
                      ],
                      attrs: { type: "text", readonly: "readonly" },
                      domProps: { value: _vm.regParams.porPlcNm },
                      on: {
                        click: function ($event) {
                          return _vm.searchSchedule()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.regParams,
                            "porPlcNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.025"))),
                ]),
                _c("td", [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.dlyCtrCd,
                          expression: "regParams.dlyCtrCd",
                        },
                      ],
                      attrs: { type: "text", readonly: "readonly" },
                      domProps: { value: _vm.regParams.dlyCtrCd },
                      on: {
                        click: function ($event) {
                          return _vm.searchSchedule()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.regParams,
                            "dlyCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "mt5" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.dlyPlcNm,
                          expression: "regParams.dlyPlcNm",
                        },
                      ],
                      attrs: { type: "text", readonly: "readonly" },
                      domProps: { value: _vm.regParams.dlyPlcNm },
                      on: {
                        click: function ($event) {
                          return _vm.searchSchedule()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.regParams,
                            "dlyPlcNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.026"))),
                ]),
                _c("td", [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.polCtrCd,
                          expression: "regParams.polCtrCd",
                        },
                      ],
                      attrs: { type: "text", readonly: "readonly" },
                      domProps: { value: _vm.regParams.polCtrCd },
                      on: {
                        click: function ($event) {
                          return _vm.searchSchedule()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.regParams,
                            "polCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "mt5" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.polPortNm,
                          expression: "regParams.polPortNm",
                        },
                      ],
                      attrs: { type: "text", readonly: "readonly" },
                      domProps: { value: _vm.regParams.polPortNm },
                      on: {
                        click: function ($event) {
                          return _vm.searchSchedule()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.regParams,
                            "polPortNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.027"))),
                ]),
                _c("td", [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.vslNm,
                          expression: "regParams.vslNm",
                        },
                      ],
                      attrs: { type: "text", readonly: "readonly" },
                      domProps: { value: _vm.regParams.vslNm },
                      on: {
                        click: function ($event) {
                          return _vm.searchSchedule()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.regParams, "vslNm", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _vm.seqNo == ""
                    ? _c("div", { staticClass: "search_btn mt5" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.voyNo,
                              expression: "regParams.voyNo",
                            },
                          ],
                          staticClass: "wid80p",
                          attrs: { type: "text", readonly: "readonly" },
                          domProps: { value: _vm.regParams.voyNo },
                          on: {
                            click: function ($event) {
                              return _vm.searchSchedule()
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.regParams,
                                "voyNo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("span", [
                          _c("button", {
                            staticClass: "search_btn01",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.searchSchedule()
                              },
                            },
                          }),
                          _c("span"),
                        ]),
                      ])
                    : _c("div", { staticClass: "search_btn mt5" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.voyNo,
                              expression: "regParams.voyNo",
                            },
                          ],
                          staticClass: "wid80p",
                          attrs: { type: "text", readonly: "readonly" },
                          domProps: { value: _vm.regParams.voyNo },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.regParams,
                                "voyNo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                ]),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.028"))),
                ]),
                _c("td", [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.podCtrCd,
                          expression: "regParams.podCtrCd",
                        },
                      ],
                      attrs: { type: "text", readonly: "readonly" },
                      domProps: { value: _vm.regParams.podCtrCd },
                      on: {
                        click: function ($event) {
                          return _vm.searchSchedule()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.regParams,
                            "podCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "mt5" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.podPortNm,
                          expression: "regParams.podPortNm",
                        },
                      ],
                      attrs: { type: "text", readonly: "readonly" },
                      domProps: { value: _vm.regParams.podPortNm },
                      on: {
                        click: function ($event) {
                          return _vm.searchSchedule()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.regParams,
                            "podPortNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.029"))),
                ]),
                _c("td", [
                  _vm.seqNo == ""
                    ? _c("div", [
                        _c("div", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.lstDestCtrCd,
                                  expression: "regParams.lstDestCtrCd",
                                },
                              ],
                              attrs: { id: "lstDestCtrCd" },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.regParams,
                                      "lstDestCtrCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function ($event) {
                                    return _vm.fnChangeCtrCd(
                                      "lstDestCtrCd",
                                      "onchange"
                                    )
                                  },
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Select a country"),
                              ]),
                              _vm._l(_vm.defaultCtrCd, function (cvo, idx) {
                                return _c(
                                  "option",
                                  {
                                    key: "lstDestCtrCd_" + idx,
                                    domProps: { value: cvo.ctrCd },
                                  },
                                  [_vm._v(_vm._s(cvo.ctrEnm))]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _c("div", { staticClass: "mt5" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.lstDestPlcCd,
                                  expression: "regParams.lstDestPlcCd",
                                },
                              ],
                              attrs: { id: "lstDestPlcCd" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.regParams,
                                    "lstDestPlcCd",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Select Place"),
                              ]),
                              _vm._l(
                                _vm.objPlace["lstDestCtrCd"],
                                function (cvo, idx) {
                                  return _c(
                                    "option",
                                    {
                                      key: "lstDestPlcCd_" + idx,
                                      domProps: { value: cvo.plcCd },
                                    },
                                    [_vm._v(_vm._s(cvo.plcEnmOnly))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                      ])
                    : _c("div", [
                        _c("div", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.lstDestCtrCd,
                                expression: "regParams.lstDestCtrCd",
                              },
                            ],
                            attrs: {
                              type: "text",
                              id: "lstDestCtrCd",
                              readonly: "readonly",
                            },
                            domProps: { value: _vm.regParams.lstDestCtrCd },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.regParams,
                                  "lstDestCtrCd",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "mt5" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.lstDestPlcCd,
                                expression: "regParams.lstDestPlcCd",
                              },
                            ],
                            attrs: {
                              type: "text",
                              id: "lstDestPlcCd",
                              readonly: "readonly",
                            },
                            domProps: { value: _vm.regParams.lstDestPlcCd },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.regParams,
                                  "lstDestPlcCd",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.030"))),
                ]),
                _c("td", [
                  _c("div", [
                    _c("div", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.polShtmCd,
                              expression: "regParams.polShtmCd",
                            },
                          ],
                          attrs: { id: "polShtmCd" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.regParams,
                                  "polShtmCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.changePolShtmCd,
                            ],
                          },
                        },
                        [
                          _vm._l(_vm.commonCodes["01033"], function (cvo, idx) {
                            return [
                              _vm.seqNo == "" ||
                              (_vm.seqNo != "" &&
                                _vm.regParams.polShtmCd == cvo.cd)
                                ? _c(
                                    "option",
                                    {
                                      key: "polShtmCd_" + idx,
                                      domProps: { value: cvo.cd },
                                    },
                                    [_vm._v(_vm._s(cvo.cdNm))]
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "mt5" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.podShtmCd,
                              expression: "regParams.podShtmCd",
                            },
                          ],
                          attrs: { id: "podShtmCd" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.regParams,
                                "podShtmCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _vm._l(_vm.commonCodes["01033"], function (cvo, idx) {
                            return [
                              _vm.seqNo == "" ||
                              (_vm.seqNo != "" &&
                                _vm.regParams.podShtmCd == cvo.cd)
                                ? _c(
                                    "option",
                                    {
                                      key: "podShtmCd_" + idx,
                                      domProps: { value: cvo.cd },
                                    },
                                    [_vm._v(_vm._s(cvo.cdNm))]
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.031"))),
                ]),
                _c("td", [
                  _c("div", [
                    _c("div", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.issCtrCd,
                              expression: "regParams.issCtrCd",
                            },
                          ],
                          attrs: { id: "issCtrCd" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.regParams,
                                  "issCtrCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                _vm.fnChangeCtrCd("issCtrCd", "onchange")
                                _vm.checkValidationOne("issCtrCd")
                              },
                            ],
                          },
                        },
                        [
                          _vm.seqNo == ""
                            ? _c("option", { attrs: { value: "" } }, [
                                _vm._v("select a country"),
                              ])
                            : _vm._e(),
                          _vm._l(_vm.defaultCtrCd, function (cvo, idx) {
                            return [
                              _vm.seqNo == "" ||
                              (_vm.seqNo != "" &&
                                _vm.regParams.issCtrCd == cvo.ctrCd)
                                ? _c(
                                    "option",
                                    {
                                      key: "issCtrCd_" + idx,
                                      domProps: { value: cvo.ctrCd },
                                    },
                                    [_vm._v(_vm._s(cvo.ctrEnm))]
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "mt5" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.issPlcCd,
                              expression: "regParams.issPlcCd",
                            },
                          ],
                          attrs: { id: "issPlcCd" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.regParams,
                                  "issPlcCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.checkValidationOne("issPlcCd")
                              },
                            ],
                          },
                        },
                        [
                          _vm.seqNo == ""
                            ? _c("option", { attrs: { value: "" } }, [
                                _vm._v("select Place"),
                              ])
                            : _vm._e(),
                          _vm._l(_vm.objPlace["issCtrCd"], function (cvo, idx) {
                            return [
                              _vm.seqNo == "" ||
                              (_vm.seqNo != "" &&
                                _vm.regParams.issPlcCd == cvo.plcCd)
                                ? _c(
                                    "option",
                                    {
                                      key: "issPlcCd_" + idx,
                                      domProps: { value: cvo.plcCd },
                                    },
                                    [_vm._v(_vm._s(cvo.plcEnmOnly))]
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.032"))),
                ]),
                _c("td", [
                  _vm.seqNo == ""
                    ? _c("div", [
                        _c("div", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.frtPncCd,
                                  expression: "regParams.frtPncCd",
                                },
                              ],
                              attrs: { id: "frtPncCd" },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.regParams,
                                      "frtPncCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function ($event) {
                                    return _vm.fnFrtPncChange()
                                  },
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "P" } }, [
                                _vm._v("Prepaid"),
                              ]),
                              _c("option", { attrs: { value: "C" } }, [
                                _vm._v("Collect"),
                              ]),
                            ]
                          ),
                        ]),
                        _vm.regParams.frtPncCd == "C"
                          ? _c("div", { staticClass: "mt5" }, [
                              _c("span", { staticClass: "unit" }, [
                                _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.033"))),
                                _vm._v(" : "),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.soNo,
                                    expression: "regParams.soNo",
                                  },
                                ],
                                staticClass: "wid50p",
                                attrs: {
                                  type: "text",
                                  id: "soNo",
                                  maxlength: "9",
                                },
                                domProps: { value: _vm.regParams.soNo },
                                on: {
                                  blur: function ($event) {
                                    ;(_vm.regParams.soNo =
                                      _vm.regParams.soNo.toUpperCase()),
                                      _vm.findIotCntrYn("S")
                                  },
                                  keyup: function ($event) {
                                    return _vm.checkValidationOne("soNo")
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams,
                                      "soNo",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                      ])
                    : _c("div", [
                        _c("div", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.frtPncCd,
                                  expression: "regParams.frtPncCd",
                                },
                              ],
                              attrs: { id: "frtPncCd" },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.regParams,
                                      "frtPncCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function ($event) {
                                    return _vm.fnFrtPncChange()
                                  },
                                ],
                              },
                            },
                            [
                              _vm.regParams.frtPncCd == "P"
                                ? _c("option", { attrs: { value: "P" } }, [
                                    _vm._v("Prepaid"),
                                  ])
                                : _vm._e(),
                              _vm.regParams.frtPncCd == "C"
                                ? _c("option", { attrs: { value: "C" } }, [
                                    _vm._v("Collect"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm.regParams.frtPncCd == "C"
                          ? _c("div", { staticClass: "mt5" }, [
                              _c("span", { staticClass: "unit" }, [
                                _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.033"))),
                                _vm._v(" : "),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.soNo,
                                    expression: "regParams.soNo",
                                  },
                                ],
                                staticClass: "wid50p",
                                attrs: {
                                  type: "text",
                                  id: "soNo",
                                  readonly: "readonly",
                                },
                                domProps: { value: _vm.regParams.soNo },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams,
                                      "soNo",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "mt10" }, [
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(9),
              _c("tbody", [
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.034"))),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "tbl_form" }, [
                      _c("div", [
                        _c(
                          "span",
                          { staticClass: "pr5" },
                          [
                            _c("e-input-number", {
                              attrs: {
                                "is-comma": true,
                                id: "pkgQty",
                                maxlength: "10",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.checkValidationOne("pkgQty")
                                },
                                blur: function ($event) {
                                  return _vm.fnPackage("CHANGE")
                                },
                              },
                              model: {
                                value: _vm.regParams.pkgQty,
                                callback: function ($$v) {
                                  _vm.$set(_vm.regParams, "pkgQty", $$v)
                                },
                                expression: "regParams.pkgQty",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "col_8" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.pkgCd,
                                  expression: "regParams.pkgCd",
                                },
                              ],
                              attrs: { id: "pkgCd" },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.regParams,
                                      "pkgCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function ($event) {
                                    return _vm.fnPackage("CHANGE")
                                  },
                                ],
                              },
                            },
                            [
                              _vm._l(
                                _vm.commonCodes["01130"],
                                function (cvo, idx) {
                                  return [
                                    cvo.cd != " "
                                      ? _c(
                                          "option",
                                          {
                                            key: "pkgCd_" + idx,
                                            domProps: { value: cvo.cd },
                                          },
                                          [_vm._v(_vm._s(cvo.cdNm))]
                                        )
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.035"))),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "tbl_form" }, [
                      _c(
                        "span",
                        [
                          _c("e-auto-complete-commodity", {
                            attrs: {
                              id: "oprRmk",
                              "ctr-cd": _vm.memberDetail.userCtrCd,
                              "cd-nm": _vm.regParams.oprRmk,
                              disabled: _vm.regParams.emptyFlag === "E",
                              "is-value":
                                _vm.regParams.emptyFlag === "E" ? false : true,
                              "is-char": true,
                              "focus-force": true,
                            },
                            on: {
                              change: (vo) => {
                                _vm.regParams.oprRmk = vo.cdNm
                                _vm.checkValidationAutocomplete("oprRmk")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "tar" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.emptyFlag,
                              expression: "regParams.emptyFlag",
                            },
                          ],
                          attrs: {
                            id: "emptyFlag",
                            type: "checkbox",
                            "true-value": "E",
                            "false-value": "F",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.regParams.emptyFlag)
                              ? _vm._i(_vm.regParams.emptyFlag, null) > -1
                              : _vm._q(_vm.regParams.emptyFlag, "E"),
                          },
                          on: {
                            click: _vm.changeEmptyFlag,
                            change: function ($event) {
                              var $$a = _vm.regParams.emptyFlag,
                                $$el = $event.target,
                                $$c = $$el.checked ? "E" : "F"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.regParams,
                                      "emptyFlag",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.regParams,
                                      "emptyFlag",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.regParams, "emptyFlag", $$c)
                              }
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "emptyFlag" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.036"))),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.037"))),
                  ]),
                  _c(
                    "td",
                    [
                      _c("e-input-number", {
                        staticClass: "wid85p",
                        attrs: {
                          "is-comma": true,
                          point: 3,
                          id: "grsWt",
                          maxlength: "16",
                          "digit-max": 10,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.checkValidationOne("grsWt")
                          },
                        },
                        model: {
                          value: _vm.regParams.grsWt,
                          callback: function ($$v) {
                            _vm.$set(_vm.regParams, "grsWt", $$v)
                          },
                          expression: "regParams.grsWt",
                        },
                      }),
                      _c("span", { staticClass: "unit" }, [_vm._v("Kg")]),
                    ],
                    1
                  ),
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.046"))),
                  ]),
                  _c(
                    "td",
                    [
                      _c("e-input-number", {
                        staticClass: "wid85p",
                        attrs: {
                          "is-comma": true,
                          point: 4,
                          id: "grsCbm",
                          maxlength: "13",
                          "digit-max": 4,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.checkValidationOne("grsCbm")
                          },
                        },
                        model: {
                          value: _vm.regParams.grsCbm,
                          callback: function ($$v) {
                            _vm.$set(_vm.regParams, "grsCbm", $$v)
                          },
                          expression: "regParams.grsCbm",
                        },
                      }),
                      _c("span", { staticClass: "unit" }, [_vm._v("CBM")]),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm.srStat == "B"
          ? _c("div", { staticClass: "content_box mt10" }, [
              _c("h2", { staticClass: "content_title" }, [
                _vm._v("Tally Data Information"),
              ]),
              _c(
                "table",
                { staticClass: "tbl_col" },
                [
                  _vm._m(10),
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Container No")]),
                      _c("th", [_vm._v("Size ")]),
                      _c("th", [_vm._v("Type ")]),
                      _c("th", [_vm._v("PKG CD")]),
                      _c("th", [_vm._v("PKG QTY")]),
                      _c("th", [_vm._v("Weight ")]),
                      _c("th", [_vm._v("SEAL_NO")]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon plus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.addTally()
                              },
                            },
                          },
                          [_vm._v("plus")]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._l(_vm.regParams.tallyList, function (vo, idx) {
                    return _c(
                      "tbody",
                      {
                        key: "tr_tally_" + idx,
                        attrs: { id: "tallyList_" + idx },
                      },
                      [
                        _c("tr", [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.tallCntrNo,
                                  expression: "vo.tallCntrNo",
                                },
                              ],
                              staticClass: "wid85p",
                              attrs: { id: "tallCntrNo", maxlength: "11" },
                              domProps: { value: vo.tallCntrNo },
                              on: {
                                blur: function ($event) {
                                  vo.tallCntrNo = vo.tallCntrNo.toUpperCase()
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    vo,
                                    "tallCntrNo",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: vo.tallCntrSize,
                                    expression: "vo.tallCntrSize",
                                  },
                                ],
                                attrs: { id: "tallCntrSize_" + idx },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      vo,
                                      "tallCntrSize",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.tallycntSzList, function (option) {
                                return _c(
                                  "option",
                                  {
                                    key: option.cd,
                                    domProps: { value: option.cd },
                                  },
                                  [_vm._v(" " + _vm._s(option.cdNm) + " ")]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: vo.tallCntrTypCd,
                                    expression: "vo.tallCntrTypCd",
                                  },
                                ],
                                attrs: { id: "tallCntrTypCd_" + idx },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      vo,
                                      "tallCntrTypCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.cntrTypeList, function (option) {
                                return _c(
                                  "option",
                                  {
                                    key: option.cd,
                                    domProps: { value: option.cd },
                                  },
                                  [_vm._v(" " + _vm._s(option.cdNm) + " ")]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: vo.tallPkgCd,
                                    expression: "vo.tallPkgCd",
                                  },
                                ],
                                attrs: { id: "tallPkgCd_" + idx },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      vo,
                                      "tallPkgCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(
                                _vm.commonCodes["01130"],
                                function (option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option.cd,
                                      domProps: { value: option.cd },
                                    },
                                    [_vm._v(" " + _vm._s(option.cdNm) + " ")]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _c(
                            "td",
                            [
                              _c("e-input-number", {
                                staticClass: "wid85p",
                                attrs: {
                                  "is-comma": true,
                                  point: 3,
                                  id: "tallpkgQty",
                                  maxlength: "10",
                                },
                                model: {
                                  value: vo.tallPkgQty,
                                  callback: function ($$v) {
                                    _vm.$set(vo, "tallPkgQty", $$v)
                                  },
                                  expression: "vo.tallPkgQty",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("e-input-number", {
                                staticClass: "wid85p",
                                attrs: {
                                  "is-comma": true,
                                  point: 3,
                                  id: "tallWeight",
                                  maxlength: "13",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationOne("tallWeight")
                                  },
                                },
                                model: {
                                  value: vo.tallWeight,
                                  callback: function ($$v) {
                                    _vm.$set(vo, "tallWeight", $$v)
                                  },
                                  expression: "vo.tallWeight",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.tallSealNo,
                                  expression: "vo.tallSealNo",
                                },
                              ],
                              staticClass: "wid85p",
                              attrs: { type: "text", maxlength: "20" },
                              domProps: { value: vo.tallSealNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    vo,
                                    "tallSealNo",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c("td", { staticClass: "tac" }, [
                            _c(
                              "button",
                              {
                                staticClass: "tbl_icon minus",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeTally(idx)
                                  },
                                },
                              },
                              [_vm._v(" minus ")]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(" " + _vm._s(_vm.$t("msg.NEWB020G010.038")) + " "),
          ]),
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(11),
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { scope: "col", rowspan: "2" } }),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.039"))),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.040"))),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.041"))),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.042"))),
                ]),
              ]),
              _c("tr", [
                _c("th", { staticClass: "bor-left", attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CMBK100_M1.023_1"))),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CMBA100.00108"))),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CMBA100.00109"))),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.003"))),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("th", { attrs: { scope: "row", rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.043"))),
                ]),
                _c("td", { staticClass: "full" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.actShprCstCtrCd,
                          expression: "regParams.actShprCstCtrCd",
                        },
                      ],
                      attrs: { id: "actShprCstCtrCd" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.regParams,
                              "actShprCstCtrCd",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            _vm.fnChangeActCatId(_vm.regParams.actShprCstCtrCd)
                            _vm.fnAddInfoStateSet(
                              _vm.regParams.actShprCstCtrCd,
                              "ACT",
                              "CHANGE"
                            )
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("select"),
                      ]),
                      _vm._l(_vm.defaultCtrCd, function (cvo, idx) {
                        return _c(
                          "option",
                          {
                            key: "actShprCstCtrCd_" + idx,
                            domProps: { value: cvo.ctrCd },
                          },
                          [_vm._v(_vm._s(cvo.ctrEnm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "actShprCstCityNm", maxlength: "100" },
                      model: {
                        value: _vm.regParams.actShprCstCityNm,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "actShprCstCityNm", $$v)
                        },
                        expression: "regParams.actShprCstCityNm",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tempParams.showActShprStateNm,
                          expression: "tempParams.showActShprStateNm",
                        },
                      ],
                      attrs: { id: "actShprStateNm", maxlength: "35" },
                      model: {
                        value: _vm.regParams.actShprStateNm,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "actShprStateNm", $$v)
                        },
                        expression: "regParams.actShprStateNm",
                      },
                    }),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.tempParams.showActShprStateNm,
                            expression: "!tempParams.showActShprStateNm",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.actShprStateCd,
                            expression: "regParams.actShprStateCd",
                          },
                        ],
                        attrs: { id: "actShprStateCd" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.regParams,
                                "actShprStateCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.fnStateNmSet(
                                _vm.regParams.actShprStateCd,
                                "ACT"
                              )
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("select"),
                        ]),
                        _vm._l(_vm.commonCodes["CS038"], function (cvo, idx) {
                          return _c(
                            "option",
                            {
                              key: "actShprStateCd_" + idx,
                              domProps: { value: cvo.cd },
                            },
                            [_vm._v(_vm._s(cvo.cdNm))]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "actShprPostNo", maxlength: "6" },
                      model: {
                        value: _vm.regParams.actShprPostNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "actShprPostNo", $$v)
                        },
                        expression: "regParams.actShprPostNo",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "actShprCstPicNm", maxlength: "100" },
                      model: {
                        value: _vm.regParams.actShprCstPicNm,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "actShprCstPicNm", $$v)
                        },
                        expression: "regParams.actShprCstPicNm",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "actShprCstTelNo", maxlength: "30" },
                      model: {
                        value: _vm.regParams.actShprCstTelNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "actShprCstTelNo", $$v)
                        },
                        expression: "regParams.actShprCstTelNo",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "actShprCstFaxNo", maxlength: "30" },
                      model: {
                        value: _vm.regParams.actShprCstFaxNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "actShprCstFaxNo", $$v)
                        },
                        expression: "regParams.actShprCstFaxNo",
                      },
                    }),
                  ],
                  1
                ),
                _c("td", [
                  _c("div", { staticClass: "position_relative" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.actShprCstEmlAddr,
                          expression: "regParams.actShprCstEmlAddr",
                        },
                      ],
                      attrs: {
                        type: "text",
                        id: "actShprCstEmlAddr",
                        maxlength: "100",
                      },
                      domProps: { value: _vm.regParams.actShprCstEmlAddr },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.regParams,
                              "actShprCstEmlAddr",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            return _vm.checkValidationActShprCstEmlAddr()
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row", rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.044"))),
                ]),
                _c("td", { staticClass: "full" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.cneCstCtrCd,
                          expression: "regParams.cneCstCtrCd",
                        },
                      ],
                      attrs: { id: "cneCstCtrCd" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.regParams,
                              "cneCstCtrCd",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            _vm.fnChangeCneCatId(_vm.regParams.cneCstCtrCd)
                            _vm.fnAddInfoStateSet(
                              _vm.regParams.cneCstCtrCd,
                              "CNE",
                              "CHANGE"
                            )
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("select"),
                      ]),
                      _vm._l(_vm.defaultCtrCd, function (cvo, idx) {
                        return _c(
                          "option",
                          {
                            key: "cneCstCtrCd_" + idx,
                            domProps: { value: cvo.ctrCd },
                          },
                          [_vm._v(_vm._s(cvo.ctrEnm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "cneCstCityNm", maxlength: "100" },
                      model: {
                        value: _vm.regParams.cneCstCityNm,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "cneCstCityNm", $$v)
                        },
                        expression: "regParams.cneCstCityNm",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tempParams.showCneCstStateNm,
                          expression: "tempParams.showCneCstStateNm",
                        },
                      ],
                      attrs: { id: "cneCstStateNm", maxlength: "35" },
                      model: {
                        value: _vm.regParams.cneCstStateNm,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "cneCstStateNm", $$v)
                        },
                        expression: "regParams.cneCstStateNm",
                      },
                    }),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.tempParams.showCneCstStateNm,
                            expression: "!tempParams.showCneCstStateNm",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.cneCstStateCd,
                            expression: "regParams.cneCstStateCd",
                          },
                        ],
                        attrs: { id: "cneCstStateCd" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.regParams,
                                "cneCstStateCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.fnStateNmSet(
                                _vm.regParams.cneCstStateCd,
                                "CNE"
                              )
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("select"),
                        ]),
                        _vm._l(_vm.commonCodes["CS038"], function (cvo, idx) {
                          return _c(
                            "option",
                            {
                              key: "cneCstStateCd_" + idx,
                              domProps: { value: cvo.cd },
                            },
                            [_vm._v(_vm._s(cvo.cdNm))]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "cneCstPostNo", maxlength: "6" },
                      model: {
                        value: _vm.regParams.cneCstPostNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "cneCstPostNo", $$v)
                        },
                        expression: "regParams.cneCstPostNo",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "cneCstPicNm", maxlength: "100" },
                      model: {
                        value: _vm.regParams.cneCstPicNm,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "cneCstPicNm", $$v)
                        },
                        expression: "regParams.cneCstPicNm",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "cneCstTelNo", maxlength: "30" },
                      model: {
                        value: _vm.regParams.cneCstTelNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "cneCstTelNo", $$v)
                        },
                        expression: "regParams.cneCstTelNo",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "cneCstFaxNo", maxlength: "30" },
                      model: {
                        value: _vm.regParams.cneCstFaxNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "cneCstFaxNo", $$v)
                        },
                        expression: "regParams.cneCstFaxNo",
                      },
                    }),
                  ],
                  1
                ),
                _c("td", [
                  _c("div", { staticClass: "position_relative" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.cneCstEmlAddr,
                          expression: "regParams.cneCstEmlAddr",
                        },
                      ],
                      attrs: {
                        type: "text",
                        id: "cneCstEmlAddr",
                        maxlength: "100",
                      },
                      domProps: { value: _vm.regParams.cneCstEmlAddr },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.regParams,
                              "cneCstEmlAddr",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            return _vm.checkValidationCneCstEmlAddr()
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row", rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.045"))),
                ]),
                _c("td", { staticClass: "full" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.ntifCstCtrCd,
                          expression: "regParams.ntifCstCtrCd",
                        },
                      ],
                      attrs: { id: "ntifCstCtrCd" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.regParams,
                              "ntifCstCtrCd",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            _vm.fnChangeNotiCatId(_vm.regParams.ntifCstCtrCd)
                            _vm.fnAddInfoStateSet(
                              _vm.regParams.ntifCstCtrCd,
                              "NTIF",
                              "CHANGE"
                            )
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("select"),
                      ]),
                      _vm._l(_vm.defaultCtrCd, function (cvo, idx) {
                        return _c(
                          "option",
                          {
                            key: "ntifCstCtrCd_" + idx,
                            domProps: { value: cvo.ctrCd },
                          },
                          [_vm._v(_vm._s(cvo.ctrEnm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "ntifCstCityNm", maxlength: "100" },
                      model: {
                        value: _vm.regParams.ntifCstCityNm,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "ntifCstCityNm", $$v)
                        },
                        expression: "regParams.ntifCstCityNm",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tempParams.showNtifCstStateNm,
                          expression: "tempParams.showNtifCstStateNm",
                        },
                      ],
                      attrs: { id: "ntifCstStateNm", maxlength: "35" },
                      model: {
                        value: _vm.regParams.ntifCstStateNm,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "ntifCstStateNm", $$v)
                        },
                        expression: "regParams.ntifCstStateNm",
                      },
                    }),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.tempParams.showNtifCstStateNm,
                            expression: "!tempParams.showNtifCstStateNm",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.ntifCstStateCd,
                            expression: "regParams.ntifCstStateCd",
                          },
                        ],
                        attrs: { id: "ntifCstStateCd" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.regParams,
                                "ntifCstStateCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.fnStateNmSet(
                                _vm.regParams.ntifCstStateCd,
                                "NTIF"
                              )
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("select"),
                        ]),
                        _vm._l(_vm.commonCodes["CS038"], function (cvo, idx) {
                          return _c(
                            "option",
                            {
                              key: "ntifCstStateCd_" + idx,
                              domProps: { value: cvo.cd },
                            },
                            [_vm._v(_vm._s(cvo.cdNm))]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "ntifCstPostNo", maxlength: "6" },
                      model: {
                        value: _vm.regParams.ntifCstPostNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "ntifCstPostNo", $$v)
                        },
                        expression: "regParams.ntifCstPostNo",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "ntifCstPicNm", maxlength: "100" },
                      model: {
                        value: _vm.regParams.ntifCstPicNm,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "ntifCstPicNm", $$v)
                        },
                        expression: "regParams.ntifCstPicNm",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "ntifCstTelNo", maxlength: "30" },
                      model: {
                        value: _vm.regParams.ntifCstTelNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "ntifCstTelNo", $$v)
                        },
                        expression: "regParams.ntifCstTelNo",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("e-trans-string-check-cnee-input", {
                      attrs: { id: "ntifCstFaxNo", maxlength: "30" },
                      model: {
                        value: _vm.regParams.ntifCstFaxNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.regParams, "ntifCstFaxNo", $$v)
                        },
                        expression: "regParams.ntifCstFaxNo",
                      },
                    }),
                  ],
                  1
                ),
                _c("td", [
                  _c("div", { staticClass: "position_relative" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.ntifCstEmlAddr,
                          expression: "regParams.ntifCstEmlAddr",
                        },
                      ],
                      attrs: {
                        type: "text",
                        id: "ntifCstEmlAddr",
                        maxlength: "100",
                      },
                      domProps: { value: _vm.regParams.ntifCstEmlAddr },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.regParams,
                              "ntifCstEmlAddr",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            return _vm.checkValidationNtifCstEmlAddr()
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "mt10" }, [
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(12),
              _c("tbody", [
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v("Shipper Company ID"),
                  ]),
                  _c("td", { staticClass: "full" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.actShprIdCatCd,
                            expression: "regParams.actShprIdCatCd",
                          },
                        ],
                        attrs: { id: "actShprIdCatCd" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.regParams,
                                "actShprIdCatCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.fnChangeCatId(
                                _vm.regParams.actShprIdCatCd,
                                "ACT"
                              )
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("select"),
                        ]),
                        _vm._l(_vm.srInitData.actCstId, function (cvo, idx) {
                          return _c(
                            "option",
                            {
                              key: "actShprIdCatCd_" + idx,
                              domProps: { value: cvo.cstIdCatCd },
                            },
                            [_vm._v(_vm._s(cvo.cstIdCatNm))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "td",
                    [
                      _c("e-trans-string-check-cnee-input", {
                        attrs: { id: "actShprIdNo", maxlength: "200" },
                        model: {
                          value: _vm.regParams.actShprIdNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.regParams, "actShprIdNo", $$v)
                          },
                          expression: "regParams.actShprIdNo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(" Consignee Company ID "),
                    _vm.tempParams.consRed
                      ? _c("span", { staticClass: "txt" }, [
                          _vm._v(
                            "(" + _vm._s(_vm.$t("msg.CSBL200.075")) + ") "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("td", { staticClass: "full" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.cneIdCatCd,
                            expression: "regParams.cneIdCatCd",
                          },
                        ],
                        attrs: { id: "cneIdCatCd" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.regParams,
                                "cneIdCatCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.fnChangeCatId(
                                _vm.regParams.cneIdCatCd,
                                "CNE"
                              )
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("select"),
                        ]),
                        _vm._l(_vm.srInitData.cneCstId, function (cvo, idx) {
                          return _c(
                            "option",
                            {
                              key: "cneIdCatCd_" + idx,
                              domProps: { value: cvo.cstIdCatCd },
                            },
                            [_vm._v(_vm._s(cvo.cstIdCatNm))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "td",
                    [
                      _c("e-trans-string-check-cnee-input", {
                        attrs: { id: "cneIdNo", maxlength: "200" },
                        model: {
                          value: _vm.regParams.cneIdNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.regParams, "cneIdNo", $$v)
                          },
                          expression: "regParams.cneIdNo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v("Notify Company ID"),
                  ]),
                  _c("td", { staticClass: "full" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.ntifIdCatCd,
                            expression: "regParams.ntifIdCatCd",
                          },
                        ],
                        attrs: { id: "ntifIdCatCd" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.regParams,
                                "ntifIdCatCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.fnChangeCatId(
                                _vm.regParams.ntifIdCatCd,
                                "NTIF"
                              )
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("select"),
                        ]),
                        _vm._l(_vm.srInitData.notiCstId, function (cvo, idx) {
                          return _c(
                            "option",
                            {
                              key: "ntifIdCatCd_" + idx,
                              domProps: { value: cvo.cstIdCatCd },
                            },
                            [_vm._v(_vm._s(cvo.cstIdCatNm))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "td",
                    [
                      _c("e-trans-string-check-cnee-input", {
                        attrs: { id: "ntifIdNo", maxlength: "200" },
                        model: {
                          value: _vm.regParams.ntifIdNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.regParams, "ntifIdNo", $$v)
                          },
                          expression: "regParams.ntifIdNo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm.tempParams.showTaxId == "ID"
            ? _c("div", { staticClass: "mt10" }, [
                _c("table", { staticClass: "tbl_row" }, [
                  _vm._m(13),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(_vm._s(_vm.tempParams.taxNm)),
                      ]),
                      _c(
                        "td",
                        [
                          _c("e-trans-string-check-cnee-input", {
                            attrs: { id: "idTaxNo", maxlength: "30" },
                            model: {
                              value: _vm.regParams.idTaxNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.regParams, "idTaxNo", $$v)
                              },
                              expression: "regParams.idTaxNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.tempParams.showTaxId == "VN"
            ? _c("div", { staticClass: "mt10" }, [
                _c("table", { staticClass: "tbl_row" }, [
                  _vm._m(14),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Importer's Tax ID"),
                      ]),
                      _c(
                        "td",
                        [
                          _c("e-trans-string-check-cnee-input", {
                            attrs: { id: "idTaxNo", maxlength: "30" },
                            model: {
                              value: _vm.regParams.idTaxNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.regParams, "idTaxNo", $$v)
                              },
                              expression: "regParams.idTaxNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c("input", {
                          attrs: {
                            type: "checkbox",
                            name: "wstCgoYn",
                            id: "wstCgoYn",
                            value: "Y",
                          },
                          domProps: { checked: _vm.regParams.wstCgoYn == "Y" },
                          on: { click: _vm.fnWstCgoYn },
                        }),
                        _c("label", { attrs: { for: "wstCgoYn" } }, [
                          _vm._v("WASTE/SCRAP Cargo"),
                        ]),
                      ]),
                    ]),
                    _vm.regParams.wstCgoYn == "Y"
                      ? _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v("License No"),
                          ]),
                          _c(
                            "td",
                            [
                              _c("e-trans-string-check-cnee-input", {
                                attrs: { id: "imLicNo", maxlength: "20" },
                                model: {
                                  value: _vm.regParams.imLicNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.regParams, "imLicNo", $$v)
                                  },
                                  expression: "regParams.imLicNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v("Bank Guarantee No"),
                          ]),
                          _c(
                            "td",
                            [
                              _c("e-trans-string-check-cnee-input", {
                                attrs: { id: "imBnkGurNo", maxlength: "20" },
                                model: {
                                  value: _vm.regParams.imBnkGurNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.regParams, "imBnkGurNo", $$v)
                                  },
                                  expression: "regParams.imBnkGurNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm.tempParams.showIecId
          ? _c("div", { staticClass: "content_box mt10" }, [
              _c("h2", { staticClass: "content_title" }, [
                _vm._v("INDIA Info"),
              ]),
              _c("table", { staticClass: "tbl_row" }, [
                _vm._m(15),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v("Importer's IEC No"),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: { id: "iecNo", maxlength: "10" },
                          model: {
                            value: _vm.regParams.iecNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "iecNo", $$v)
                            },
                            expression: "regParams.iecNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v("FWDR/NVOCC PAN No"),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: { id: "panNo", maxlength: "10" },
                          model: {
                            value: _vm.regParams.panNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "panNo", $$v)
                            },
                            expression: "regParams.panNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.tempParams.showIecIdSub
                    ? _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v("GST No"),
                        ]),
                        _c(
                          "td",
                          [
                            _c("e-trans-string-check-cnee-input", {
                              attrs: { id: "gstNo", maxlength: "20" },
                              model: {
                                value: _vm.regParams.gstNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.regParams, "gstNo", $$v)
                                },
                                expression: "regParams.gstNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v("E-Mail"),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "position_relative" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.iecPicEmlAddr,
                                  expression: "regParams.iecPicEmlAddr",
                                },
                              ],
                              attrs: {
                                type: "text",
                                id: "iecPicEmlAddr",
                                maxlength: "100",
                              },
                              domProps: { value: _vm.regParams.iecPicEmlAddr },
                              on: {
                                keyup: function ($event) {
                                  return _vm.checkValidationIecPicEmlAddr()
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams,
                                    "iecPicEmlAddr",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v("Notify's PAN No"),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: { id: "ntifPanNo", maxlength: "10" },
                          model: {
                            value: _vm.regParams.ntifPanNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "ntifPanNo", $$v)
                            },
                            expression: "regParams.ntifPanNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v("Invoice Value"),
                    ]),
                    _c(
                      "td",
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.invValCurCd,
                                expression: "regParams.invValCurCd",
                              },
                            ],
                            staticStyle: { width: "120px" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.regParams,
                                  "invValCurCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("select"),
                            ]),
                            _vm._l(
                              _vm.commonCodes["00003"],
                              function (cvo, idx) {
                                return _c(
                                  "option",
                                  {
                                    key: "invValCurCd_" + idx,
                                    domProps: { value: cvo.cd },
                                  },
                                  [_vm._v(_vm._s(cvo.cd))]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("e-input-number", {
                          staticClass: "wid200",
                          attrs: {
                            "is-comma": true,
                            point: 3,
                            id: "invValExp",
                            maxlength: "18",
                          },
                          model: {
                            value: _vm.regParams.invValExp,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "invValExp", $$v)
                            },
                            expression: "regParams.invValExp",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.tempParams.consMsg
          ? _c("div", { staticClass: "content_box mt10" }, [
              _vm._v(
                " 根据上海海关2018年第15号公告，Consignee Company ID 为必填项。 (To order不需要) "
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("div", { staticClass: "flex_box mt20" }, [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v("Container Information"),
            ]),
            _c("span", { staticClass: "ml_auto" }, [
              _c(
                "a",
                { staticClass: "button sm", on: { click: _vm.demDetInfoPop } },
                [
                  _c("span", { staticClass: "btn_icon calculator" }),
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.163")) + " "),
                ]
              ),
            ]),
          ]),
          _c(
            "table",
            { staticClass: "tbl_col" },
            [
              _c(
                "colgroup",
                [
                  _c("col", { staticStyle: { width: "8%" } }),
                  _vm.isFlagRF
                    ? [_c("col", { staticStyle: { width: "20%" } })]
                    : [_c("col", { staticStyle: { width: "12%" } })],
                  _c("col", { staticStyle: { width: "10%" } }),
                  _c("col", { staticStyle: { width: "10.5%" } }),
                  _c("col", { staticStyle: { width: "16%" } }),
                  _c("col", { staticStyle: { width: "10%" } }),
                  _c("col", { staticStyle: { width: "auto" } }),
                  _c("col", { staticStyle: { width: "9.5%" } }),
                ],
                2
              ),
              _c("thead", [
                _c("tr", [
                  _c("th", { attrs: { scope: "col", colspan: "4" } }, [
                    _vm._v("CONTAINER"),
                  ]),
                  _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                    _vm._v("Special Cargo Type"),
                  ]),
                  _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                    _vm._v("Pick up Date"),
                  ]),
                  _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                    _vm._v("Pick up Place"),
                  ]),
                  _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "tbl_icon plus",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.addContainer()
                          },
                        },
                      },
                      [_vm._v("plus")]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "col" } }, [_vm._v("Size")]),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v("Type"),
                    _c(
                      "button",
                      {
                        staticClass: "tbl_icon help ml10",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.fnContainerPop()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "offscreen" }, [
                          _vm._v("도움말"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("th", { attrs: { scope: "col" } }, [_vm._v("Q’ty")]),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v("Shipper's Van"),
                  ]),
                ]),
              ]),
              _vm._l(_vm.regParams.containerList, function (vo, idx) {
                return _c(
                  "tbody",
                  { key: "tr_container_" + idx + _vm.forceUpdate },
                  [
                    _c("tr", [
                      _c("td", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.cntrSzCd,
                                expression: "vo.cntrSzCd",
                              },
                            ],
                            attrs: { id: "cntrSzCd_" + idx },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    vo,
                                    "cntrSzCd",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.changeCntrSz(vo)
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.cntSzList, function (option) {
                            return _c(
                              "option",
                              {
                                key: option.cd,
                                domProps: { value: option.cd },
                              },
                              [_vm._v(" " + _vm._s(option.cdNm) + " ")]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("td", { staticClass: "tal" }, [
                        _c(
                          "div",
                          { staticClass: "position_relative" },
                          [
                            vo.cntrTypCd != "RF" && vo.cntrTypCd != "RH"
                              ? [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: vo.cntrTypCd,
                                          expression: "vo.cntrTypCd",
                                        },
                                      ],
                                      attrs: { id: "cntrTypCd_" + idx },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              vo,
                                              "cntrTypCd",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function ($event) {
                                            return _vm.changeCntrTyp(vo, idx)
                                          },
                                        ],
                                      },
                                    },
                                    _vm._l(
                                      vo.subCntrTypList,
                                      function (option) {
                                        return _c(
                                          "option",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !option.isShow,
                                                expression: "!option.isShow",
                                              },
                                            ],
                                            key: option.cd,
                                            domProps: { value: option.cd },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(option.cdNm) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              : [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: vo.cntrTypCd,
                                          expression: "vo.cntrTypCd",
                                        },
                                      ],
                                      class:
                                        vo.cgoTypCd === "05" ? "" : "col_5",
                                      attrs: { id: "cntrTypCd_" + idx },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              vo,
                                              "cntrTypCd",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function ($event) {
                                            _vm.changeCntrTyp(vo, idx)
                                            _vm.checkValidationCntr(
                                              "rfSetupTmpr",
                                              idx
                                            )
                                          },
                                        ],
                                      },
                                    },
                                    _vm._l(
                                      vo.subCntrTypList,
                                      function (option) {
                                        return _c(
                                          "option",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !option.isShow,
                                                expression: "!option.isShow",
                                              },
                                            ],
                                            key: option.cd,
                                            domProps: { value: option.cd },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(option.cdNm) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c("e-input-number", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: vo.cgoTypCd !== "05",
                                        expression: "vo.cgoTypCd !== '05'",
                                      },
                                    ],
                                    class: "col_25p",
                                    attrs: {
                                      id: "rfSetupTmpr_" + idx,
                                      "is-minus": true,
                                      maxlength: "6",
                                      point: 1,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.checkValidationCntr(
                                          "rfSetupTmpr",
                                          idx
                                        )
                                      },
                                    },
                                    model: {
                                      value: vo.rfSetupTmpr,
                                      callback: function ($$v) {
                                        _vm.$set(vo, "rfSetupTmpr", $$v)
                                      },
                                      expression: "vo.rfSetupTmpr",
                                    },
                                  }),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: vo.cgoTypCd !== "05",
                                          expression: "vo.cgoTypCd !== '05'",
                                        },
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: vo.rfTmprUnitCd,
                                          expression: "vo.rfTmprUnitCd",
                                        },
                                      ],
                                      staticClass: "temperature",
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            vo,
                                            "rfTmprUnitCd",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "C" } }, [
                                        _vm._v("℃"),
                                      ]),
                                      _c("option", { attrs: { value: "F" } }, [
                                        _vm._v("°F"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: vo.cgoTypCd !== "05",
                                          expression: "vo.cgoTypCd !== '05'",
                                        },
                                      ],
                                      staticClass: "tbl_form mt5",
                                    },
                                    [
                                      _vm._m(16, true),
                                      _c("span", { staticClass: "tar" }, [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: vo.cntrRfStsCd,
                                                expression: "vo.cntrRfStsCd",
                                              },
                                            ],
                                            staticClass: "col_7",
                                            attrs: { id: "cntrRfStsCd_" + idx },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  vo,
                                                  "cntrRfStsCd",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          _vm._l(
                                            _vm.cntrRfStsList,
                                            function (cvo) {
                                              return _c(
                                                "option",
                                                {
                                                  key: cvo.cd,
                                                  domProps: { value: cvo.cd },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(cvo.cdNm) + " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                            _c("p", { staticClass: "tooltip_essen_box" }, [
                              _c("span", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: false,
                                    expression: "false",
                                  },
                                ],
                                staticClass: "tooltip_essen",
                              }),
                            ]),
                          ],
                          2
                        ),
                      ]),
                      _c(
                        "td",
                        [
                          _c("e-input-number", {
                            attrs: { id: "cntrQty_" + idx, maxlength: "3" },
                            on: {
                              input: function ($event) {
                                return _vm.checkValidationCntr("cntrQty", idx)
                              },
                            },
                            model: {
                              value: vo.cntrQty,
                              callback: function ($$v) {
                                _vm.$set(vo, "cntrQty", $$v)
                              },
                              expression: "vo.cntrQty",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("td", [
                        _c("input", {
                          attrs: {
                            id: "shprVanYn_" + idx,
                            type: "checkbox",
                            disabled: _vm.regParams.polShtmCd === "02",
                          },
                          domProps: {
                            checked:
                              _vm.regParams.polShtmCd !== "02" &&
                              vo.shprVanYn === "Y",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.checkShprVanYn($event, vo, idx)
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "shprVanYn_" + idx } }, [
                          _c("span", { staticClass: "offscreen" }, [
                            _vm._v("화주 컨테이너 체크"),
                          ]),
                        ]),
                      ]),
                      _c("td", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.cgoTypCd,
                                expression: "vo.cgoTypCd",
                              },
                            ],
                            attrs: {
                              id: "cgoTypCd_" + idx,
                              disabled: _vm.seqNo != "",
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    vo,
                                    "cgoTypCd",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.changeCgoTyp(vo, idx)
                                },
                              ],
                            },
                          },
                          [
                            vo.isCgoTypEmpty
                              ? _c("option", { attrs: { value: "" } }, [
                                  _vm._v("---------------------"),
                                ])
                              : _vm._e(),
                            _vm._l(vo.subCgoTypList, function (option) {
                              return _c(
                                "option",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !option.isShow,
                                      expression: "!option.isShow",
                                    },
                                  ],
                                  key: option.cd,
                                  domProps: { value: option.cd },
                                },
                                [_vm._v(" " + _vm._s(option.cdNm) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                      _c("td", { staticClass: "tal" }, [
                        _c(
                          "div",
                          { staticClass: "input_calendar wd100" },
                          [
                            _c("e-date-picker", {
                              attrs: {
                                id: "pcupReqDt_" + idx,
                                disabled: _vm.isPcupReqDtReadOnly(),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.checkValidationCntr(
                                    "pcupReqDt",
                                    idx,
                                    "KEYUP",
                                    ...arguments
                                  )
                                },
                              },
                              model: {
                                value: vo.pcupReqDt,
                                callback: function ($$v) {
                                  _vm.$set(vo, "pcupReqDt", $$v)
                                },
                                expression: "vo.pcupReqDt",
                              },
                            }),
                            _c("div", { staticClass: "mt5" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: vo.pcupReqTm,
                                      expression: "vo.pcupReqTm",
                                    },
                                  ],
                                  attrs: {
                                    id: "pcupReqTm_" + idx,
                                    disabled: _vm.isPcupReqDtReadOnly(),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        vo,
                                        "pcupReqTm",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                _vm._l(_vm.pcupTimeList, function (option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option.cd,
                                      domProps: { value: option.cd },
                                    },
                                    [_vm._v(" " + _vm._s(option.cdNm) + " ")]
                                  )
                                }),
                                0
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.pcupCyNm,
                              expression: "vo.pcupCyNm",
                            },
                          ],
                          attrs: {
                            type: "text",
                            id: "pcupCyNm_" + idx,
                            readonly: "",
                            disabled: _vm.isPcupReqDtReadOnly(),
                          },
                          domProps: { value: vo.pcupCyNm },
                          on: {
                            click: function ($event) {
                              return _vm.getPickupPlace(idx)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(vo, "pcupCyNm", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", { staticClass: "tac" }, [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon minus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.removeContainer(idx)
                              },
                            },
                          },
                          [_vm._v(" minus ")]
                        ),
                      ]),
                    ]),
                    (vo.cntrTypCd === "RF" || vo.cntrTypCd === "RH") &&
                    vo.iotCntrYn === "Y"
                      ? _c("tr", [
                          _c("th", { attrs: { colspan: "2" } }, [
                            _vm._v(" IoT Container  "),
                            _c("button", {
                              staticClass: "tbl_icon help ml5",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.openPopup("container-iot-pop")
                                },
                              },
                            }),
                          ]),
                          _c("td", { attrs: { colspan: "1" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.iotEqpmYn,
                                  expression: "vo.iotEqpmYn",
                                },
                              ],
                              attrs: {
                                id: "iotEqpmYn_" + idx,
                                type: "checkbox",
                                disabled:
                                  vo.iotCntrYn === "Y" || vo.iotEqpmYn === "Y"
                                    ? false
                                    : true,
                                "true-value": "Y",
                                "false-value": "N",
                              },
                              domProps: {
                                checked: vo.iotEqpmYn === "Y" ? true : false,
                                checked: Array.isArray(vo.iotEqpmYn)
                                  ? _vm._i(vo.iotEqpmYn, null) > -1
                                  : _vm._q(vo.iotEqpmYn, "Y"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.checkIotYn($event, vo, idx)
                                },
                                change: function ($event) {
                                  var $$a = vo.iotEqpmYn,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "Y" : "N"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          vo,
                                          "iotEqpmYn",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          vo,
                                          "iotEqpmYn",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(vo, "iotEqpmYn", $$c)
                                  }
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "iotEqpmYn_" + idx } }),
                          ]),
                          _c("td", { attrs: { colspan: "5" } }),
                        ])
                      : _vm._e(),
                    vo.cgoTypCd !== "" &&
                    "01|02|03|06".indexOf(vo.cgoTypCd) > -1
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text-left",
                              attrs: { colspan: "8" },
                            },
                            [
                              vo.cgoTypCd === "01"
                                ? _c("booking-sr-table-dg", {
                                    ref: "dg",
                                    refInFor: true,
                                    attrs: {
                                      index: idx,
                                      "parent-info": vo,
                                      "cas-no-port-yn": _vm.casNoPortYn,
                                      "tel-ctr-no": _vm.usrInfo.telCtrNo,
                                      "disabled-yn": _vm.disabledYn,
                                      "unno-disabled-yn": _vm.unnoDisabledYn,
                                      "save-flag": _vm.saveFlag,
                                      "detail-bkg-no": _vm.detailBkgNo,
                                    },
                                    on: { update: _vm.updateContainerList },
                                  })
                                : _vm._e(),
                              vo.cgoTypCd === "02" ||
                              vo.cgoTypCd === "03" ||
                              (vo.cgoTypCd === "03" &&
                                vo.cntrTypCd === "HC" &&
                                vo.cntrSzCd === "45")
                                ? _c("booking-sr-table-oog", {
                                    ref: "oog",
                                    refInFor: true,
                                    attrs: {
                                      index: idx,
                                      "cgo-typ-cd": vo.cgoTypCd,
                                      "parent-info": vo,
                                      "kmd-vsl-check": _vm.kmdVslCheck,
                                      "empty-flag": _vm.regParams.emptyFlag,
                                      "save-flag": _vm.saveFlag,
                                      "detail-bkg-no": _vm.detailBkgNo,
                                      "grs-wt-ton": _vm.regParams.grsWt,
                                    },
                                    on: { update: _vm.updateContainerList },
                                  })
                                : _vm._e(),
                              vo.cgoTypCd === "06"
                                ? _c("booking-sr-table-flexi", {
                                    ref: "flexi",
                                    refInFor: true,
                                    attrs: {
                                      "parent-info": vo,
                                      index: idx,
                                      "save-flag": _vm.saveFlag,
                                      "loi-down-yn": _vm.loiDownYn,
                                      "detail-bkg-no": _vm.detailBkgNo,
                                    },
                                    on: { update: _vm.updateContainerList },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          ),
          _vm._m(17),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v("Description Information"),
          ]),
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(18),
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Mark & No")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Description")]),
                _c("th", { attrs: { scope: "col" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "tbl_icon plus",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.addDescInfo()
                        },
                      },
                    },
                    [_vm._v("plus")]
                  ),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.regParams.descInfoList, function (vo, idx) {
                return _c("tr", { key: "markInfo_" + idx }, [
                  _c(
                    "td",
                    [
                      _c("ETextarea2", {
                        attrs: {
                          id: "mrk_" + idx,
                          "max-line": 18,
                          "column-length": 25,
                          "is-upper": true,
                          "padding-right": 223,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.checkValidationDescInfo()
                          },
                        },
                        model: {
                          value: vo.mrk,
                          callback: function ($$v) {
                            _vm.$set(vo, "mrk", $$v)
                          },
                          expression: "vo.mrk",
                        },
                      }),
                      _c("div", { staticClass: "add_mark_info" }, [
                        _c("span", [_vm._v("Mark Information")]),
                        _c("button", {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.addMrkPkg(vo)
                            },
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "vat" },
                    [
                      _c("ETextarea2", {
                        attrs: {
                          id: "dscr_" + idx,
                          "max-line": 18,
                          "column-length": 37,
                          "is-upper": true,
                          "padding-right": 320,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.checkValidationDescInfo()
                          },
                        },
                        model: {
                          value: vo.dscr,
                          callback: function ($$v) {
                            _vm.$set(vo, "dscr", $$v)
                          },
                          expression: "vo.dscr",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("td", { staticClass: "tac" }, [
                    _c(
                      "button",
                      {
                        staticClass: "tbl_icon minus",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.removeDescInfo(idx)
                          },
                        },
                      },
                      [_vm._v("minus")]
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v("Mark Information"),
          ]),
          _c(
            "table",
            { staticClass: "tbl_col" },
            [
              _vm._m(19),
              _vm._m(20),
              _vm._l(_vm.regParams.descInfoList, function (vo, idx) {
                return _vm._l(vo.markInfoList, function (svo, sidx) {
                  return _c("tbody", { key: "mrkPkg_" + idx + "_" + sidx }, [
                    _c("tr", [
                      _c("td", { attrs: { rowspan: "2" } }, [
                        _vm._v(" " + _vm._s(idx + 1) + " "),
                      ]),
                      _c("td", { attrs: { rowspan: "2" } }, [
                        _vm._v(" " + _vm._s(sidx + 1) + " "),
                      ]),
                      _c(
                        "td",
                        [
                          _c("e-input-number", {
                            attrs: {
                              "is-comma": true,
                              id: "pkgQty_" + idx + "_" + sidx,
                              maxlength: "13",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.checkValidationMarkInfo("pkgQty")
                              },
                            },
                            model: {
                              value: svo.pkgQty,
                              callback: function ($$v) {
                                _vm.$set(svo, "pkgQty", $$v)
                              },
                              expression: "svo.pkgQty",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("td", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: svo.pkgCd,
                                expression: "svo.pkgCd",
                              },
                            ],
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  svo,
                                  "pkgCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _vm._l(
                              _vm.commonCodes["01130"],
                              function (cvo, cidx) {
                                return [
                                  cvo.cd != " "
                                    ? _c(
                                        "option",
                                        {
                                          key:
                                            "mrkPkg_pkgCd_" +
                                            idx +
                                            "_" +
                                            sidx +
                                            "_" +
                                            cidx,
                                          domProps: { value: cvo.cd },
                                        },
                                        [_vm._v(_vm._s(cvo.cdNm))]
                                      )
                                    : _vm._e(),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                      _c(
                        "td",
                        [
                          _c("e-input-number", {
                            attrs: {
                              "is-phone": true,
                              "digit-max": 10,
                              point: 3,
                              id: "netWt_" + idx + "_" + sidx,
                              maxlength: "13",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.checkValidationMarkInfo("netWt")
                              },
                            },
                            model: {
                              value: svo.netWt,
                              callback: function ($$v) {
                                _vm.$set(svo, "netWt", $$v)
                              },
                              expression: "svo.netWt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("e-input-number", {
                            attrs: {
                              "is-phone": true,
                              "digit-max": 4,
                              point: 4,
                              id: "netCbm_" + idx + "_" + sidx,
                              maxlength: "13",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.checkValidationMarkInfo("netCbm")
                              },
                            },
                            model: {
                              value: svo.netCbm,
                              callback: function ($$v) {
                                _vm.$set(svo, "netCbm", $$v)
                              },
                              expression: "svo.netCbm",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "tac", attrs: { rowspan: "2" } },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeMrkPkg(vo, sidx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "bor-left", attrs: { colspan: "2" } },
                        [
                          _c("ETextarea2", {
                            attrs: {
                              id: "mrkDsc_" + idx + "_" + sidx,
                              "max-line": 3,
                              "column-length": 37,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.checkValidationMarkInfo("mrkDsc")
                              },
                            },
                            model: {
                              value: svo.mrkDsc,
                              callback: function ($$v) {
                                _vm.$set(svo, "mrkDsc", $$v)
                              },
                              expression: "svo.mrkDsc",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _c("ETextarea2", {
                            attrs: {
                              id: "gdsDsc_" + idx + "_" + sidx,
                              "is-upper": true,
                              "max-line": 3,
                              "column-length": 37,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.checkValidationMarkInfo("gdsDsc")
                              },
                            },
                            model: {
                              value: svo.gdsDsc,
                              callback: function ($$v) {
                                _vm.$set(svo, "gdsDsc", $$v)
                              },
                              expression: "svo.gdsDsc",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                })
              }),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("h2", { staticClass: "content_title" }, [_vm._v("Request")]),
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(21),
            _vm._m(22),
            _c("tbody", [
              _c("tr", [
                _c("td", [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.regParams.dorOdrRmk,
                        expression: "regParams.dorOdrRmk",
                      },
                    ],
                    attrs: { id: "dorOdrRmk" },
                    domProps: { value: _vm.regParams.dorOdrRmk },
                    on: {
                      keyup: function ($event) {
                        return _vm.checkValidationDorOdrRmk()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.regParams,
                          "dorOdrRmk",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("td", [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.regParams.docmRmk,
                        expression: "regParams.docmRmk",
                      },
                    ],
                    attrs: { id: "docmRmk" },
                    domProps: { value: _vm.regParams.docmRmk },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.regParams, "docmRmk", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _vm.regParams.podCtrCd === "IR" || _vm.regParams.podCtrCd === "RU"
            ? _c("table", { staticClass: "tbl_row mt20" }, [
                _vm._m(23),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "row", rowspan: "2" } }, [
                      _vm._v("B/L SHIPPER"),
                    ]),
                    _c("th", { attrs: { width: "150" } }, [_vm._v("NAME")]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.iranBookingInfo.actShprCstNm,
                            expression:
                              "regParams.iranBookingInfo.actShprCstNm",
                          },
                        ],
                        attrs: {
                          id: "ir_actShprCstNm",
                          type: "text",
                          maxlength: "100",
                        },
                        domProps: {
                          value: _vm.regParams.iranBookingInfo.actShprCstNm,
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.iranInputCheck("actShprCstNm")
                          },
                          keyup: function ($event) {
                            return _vm.checkIranValidation("actShprCstNm")
                          },
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.regParams.iranBookingInfo,
                                "actShprCstNm",
                                $event.target.value
                              )
                            },
                            _vm.changeActShprCstNm,
                          ],
                        },
                      }),
                      _c(
                        "span",
                        {
                          class:
                            _vm.$ekmtcCommon.isNotEmpty(
                              _vm.regParams.iranBookingInfo.actShprCstNmC
                            ) &&
                            _vm.regParams.iranBookingInfo.actShprCstNmC !== "N"
                              ? ""
                              : "d_none",
                          attrs: { id: "ir_actShprCstNmC" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.115")) + " ")]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticStyle: { "border-left": "1px solid #e1e3eb" },
                        attrs: { scope: "row" },
                      },
                      [_vm._v("ADDRESS")]
                    ),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.iranBookingInfo.actShprAddr,
                            expression: "regParams.iranBookingInfo.actShprAddr",
                          },
                        ],
                        attrs: {
                          id: "ir_actShprAddr",
                          type: "text",
                          maxlength: "100",
                        },
                        domProps: {
                          value: _vm.regParams.iranBookingInfo.actShprAddr,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.regParams.iranBookingInfo,
                              "actShprAddr",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row", rowspan: "2" } }, [
                      _vm._v("B/L CONSIGNEE"),
                    ]),
                    _c("th", { attrs: { scope: "row" } }, [_vm._v("NAME")]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.iranBookingInfo.cneCstNm,
                            expression: "regParams.iranBookingInfo.cneCstNm",
                          },
                        ],
                        attrs: {
                          id: "ir_cneCstNm",
                          type: "text",
                          maxlength: "100",
                        },
                        domProps: {
                          value: _vm.regParams.iranBookingInfo.cneCstNm,
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.iranInputCheck("cneCstNm")
                          },
                          keyup: function ($event) {
                            return _vm.checkIranValidation("cneCstNm")
                          },
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.regParams.iranBookingInfo,
                                "cneCstNm",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              _vm.regParams.iranBookingInfo.cneCstNm =
                                _vm.regParams.iranBookingInfo.cneCstNm.toUpperCase()
                            },
                          ],
                        },
                      }),
                      _c(
                        "span",
                        {
                          class:
                            _vm.$ekmtcCommon.isNotEmpty(
                              _vm.regParams.iranBookingInfo.cneCstNmC
                            ) && _vm.regParams.iranBookingInfo.cneCstNmC !== "N"
                              ? ""
                              : "d_none",
                          attrs: { id: "ir_cneCstNmC" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.115")) + " ")]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticStyle: { "border-left": "1px solid #e1e3eb" },
                        attrs: { scope: "row" },
                      },
                      [_vm._v("ADDRESS")]
                    ),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.iranBookingInfo.cneCstAddr,
                            expression: "regParams.iranBookingInfo.cneCstAddr",
                          },
                        ],
                        attrs: {
                          id: "ir_cneCstAddr",
                          type: "text",
                          maxlength: "100",
                        },
                        domProps: {
                          value: _vm.regParams.iranBookingInfo.cneCstAddr,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.regParams.iranBookingInfo,
                              "cneCstAddr",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row", rowspan: "2" } }, [
                      _vm._v("B/L NOTIFY"),
                    ]),
                    _c("th", { attrs: { scope: "row" } }, [_vm._v("NAME")]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.iranBookingInfo.ntifCstNm,
                            expression: "regParams.iranBookingInfo.ntifCstNm",
                          },
                        ],
                        attrs: {
                          id: "ir_ntifCstNm",
                          type: "text",
                          maxlength: "100",
                        },
                        domProps: {
                          value: _vm.regParams.iranBookingInfo.ntifCstNm,
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.iranInputCheck("ntifCstNm")
                          },
                          keyup: function ($event) {
                            return _vm.checkIranValidation("ntifCstNm")
                          },
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.regParams.iranBookingInfo,
                                "ntifCstNm",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              _vm.regParams.iranBookingInfo.ntifCstNm =
                                _vm.regParams.iranBookingInfo.ntifCstNm.toUpperCase()
                            },
                          ],
                        },
                      }),
                      _c(
                        "span",
                        {
                          class:
                            _vm.$ekmtcCommon.isNotEmpty(
                              _vm.regParams.iranBookingInfo.ntifCstNmC
                            ) &&
                            _vm.regParams.iranBookingInfo.ntifCstNmC !== "N"
                              ? ""
                              : "d_none",
                          attrs: { id: "ir_ntifCstNmC" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.115")) + " ")]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticStyle: { "border-left": "1px solid #e1e3eb" },
                        attrs: { scope: "row" },
                      },
                      [_vm._v("ADDRESS")]
                    ),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.iranBookingInfo.ntifCstAddr,
                            expression: "regParams.iranBookingInfo.ntifCstAddr",
                          },
                        ],
                        attrs: {
                          id: "ir_ntifCstAddr",
                          type: "text",
                          maxlength: "100",
                        },
                        domProps: {
                          value: _vm.regParams.iranBookingInfo.ntifCstAddr,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.regParams.iranBookingInfo,
                              "ntifCstAddr",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                      _vm._v("END USER"),
                    ]),
                    _c("td", [
                      _c("div", {}, [
                        _c(
                          "table",
                          { staticClass: "tbl_col" },
                          [
                            _vm._m(24),
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { rowspan: "2" } }, [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.287"))),
                                ]),
                                _vm._v(" "),
                                _c("th", [_vm._v("NAME")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("ADDRESS")]),
                                _vm._v(" "),
                                _c("th", { attrs: { rowspan: "2" } }),
                              ]),
                              _vm._m(25),
                            ]),
                            _vm._l(_vm.endUserList, function (vo, idx) {
                              return _c("tbody", { key: "trEndUser_" + idx }, [
                                _c("tr", [
                                  _c("td", { attrs: { rowspan: "2" } }, [
                                    _vm._v(_vm._s(idx + 1)),
                                  ]),
                                  _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: vo.endUsrNm,
                                          expression: "vo.endUsrNm",
                                        },
                                      ],
                                      attrs: {
                                        id: "ir_endUsrNm_" + idx,
                                        maxlength: "100",
                                        type: "text",
                                        placeholder: "NAME",
                                      },
                                      domProps: { value: vo.endUsrNm },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.iranInputCheck(
                                            "endUsrNm",
                                            idx
                                          )
                                        },
                                        keyup: function ($event) {
                                          return _vm.checkIranValidation(
                                            "endUsrNm",
                                            idx
                                          )
                                        },
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              vo,
                                              "endUsrNm",
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            _vm.regParams.iranBookingInfo.endUsrNm =
                                              _vm.regParams.iranBookingInfo.endUsrNm.toUpperCase()
                                          },
                                        ],
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        class:
                                          _vm.regParams.iranBookingInfo
                                            .endUsrNmCList &&
                                          _vm.$ekmtcCommon.isNotEmpty(
                                            _vm.regParams.iranBookingInfo
                                              .endUsrNmCList[idx]
                                          ) &&
                                          _vm.regParams.iranBookingInfo
                                            .endUsrNmCList[idx] !== "N"
                                            ? ""
                                            : "d_none",
                                        attrs: { id: "ir_endUsrNmC_" + idx },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("msg.CSBK100.115")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: vo.endUsrAddr,
                                          expression: "vo.endUsrAddr",
                                        },
                                      ],
                                      attrs: {
                                        id: "ir_endUsrAddr_" + idx,
                                        placeholder: "ADDRESS",
                                        maxlength: "100",
                                        type: "text",
                                      },
                                      domProps: { value: vo.endUsrAddr },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            vo,
                                            "endUsrAddr",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("td", { attrs: { rowspan: "2" } }, [
                                    idx === 0
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "tbl_icon plus",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addEndUser()
                                              },
                                            },
                                          },
                                          [_vm._v(" plus ")]
                                        )
                                      : _vm._e(),
                                    idx !== 0
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "tbl_icon minus",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeEndUser(idx)
                                              },
                                            },
                                          },
                                          [_vm._v(" minus ")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "border-left": "1px solid #e1e3eb",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: vo.endUsrTelNo,
                                            expression: "vo.endUsrTelNo",
                                          },
                                        ],
                                        attrs: {
                                          id: "ir_endUsrTelNo_" + idx,
                                          placeholder: "TEL",
                                          maxlength: "25",
                                          type: "text",
                                        },
                                        domProps: { value: vo.endUsrTelNo },
                                        on: {
                                          input: [
                                            function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                vo,
                                                "endUsrTelNo",
                                                $event.target.value
                                              )
                                            },
                                            function ($event) {
                                              return _vm.changeOnlyUpperEng(
                                                $event,
                                                _vm.regParams.iranBookingInfo,
                                                "endUsrTelNo"
                                              )
                                            },
                                          ],
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: vo.endUsrEmlAddr,
                                          expression: "vo.endUsrEmlAddr",
                                        },
                                      ],
                                      attrs: {
                                        id: "ir_endUsrEmlAddr_" + idx,
                                        placeholder: "EMAIL",
                                        maxlength: "100",
                                        type: "text",
                                      },
                                      domProps: { value: vo.endUsrEmlAddr },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              vo,
                                              "endUsrEmlAddr",
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            return _vm.changeOnlyUpperEng(
                                              $event,
                                              _vm.regParams.iranBookingInfo,
                                              "endUsrEmlAddr"
                                            )
                                          },
                                        ],
                                        blur: function ($event) {
                                          return _vm.checkValidationIranEndUsrEmlAddr(
                                            `ir_endUsrEmlAddr_${idx}`
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ])
                            }),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                      _vm._v("NAME OF COMMODITY"),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.iranBookingInfo.cmdtNm,
                            expression: "regParams.iranBookingInfo.cmdtNm",
                          },
                        ],
                        attrs: {
                          id: "ir_cmdtNm",
                          type: "text",
                          maxlength: "100",
                        },
                        domProps: {
                          value: _vm.regParams.iranBookingInfo.cmdtNm,
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.iranInputCheck("cmdtNm")
                          },
                          keyup: function ($event) {
                            return _vm.checkIranValidation("cmdtNm")
                          },
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.regParams.iranBookingInfo,
                                "cmdtNm",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              _vm.regParams.iranBookingInfo.cmdtNm =
                                _vm.regParams.iranBookingInfo.cmdtNm.toUpperCase()
                            },
                          ],
                        },
                      }),
                      _c(
                        "span",
                        {
                          class:
                            _vm.$ekmtcCommon.isNotEmpty(
                              _vm.tempParams.cmdtNmC
                            ) && _vm.tempParams.cmdtNmC !== "N"
                              ? ""
                              : "d_none",
                          attrs: { id: "ir_cmdtNmC" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.116")) + " ")]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                      _vm._v("HS CODE"),
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "tbl_form" }, [
                        _c(
                          "span",
                          { staticClass: "dv col_5" },
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "ir_hsCd",
                                "is-phone": true,
                                maxlength: "10",
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.iranInputCheck("hsCd")
                                },
                                input: function ($event) {
                                  return _vm.checkIranValidation("hsCd")
                                },
                              },
                              model: {
                                value: _vm.regParams.iranBookingInfo.hsCd,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.regParams.iranBookingInfo,
                                    "hsCd",
                                    $$v
                                  )
                                },
                                expression: "regParams.iranBookingInfo.hsCd",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "dv" }, [
                          _vm.saveFlag === "EDIT" &&
                          _vm.$ekmtcCommon.isNotEmpty(_vm.regParams.bkgNo)
                            ? _c(
                                "button",
                                {
                                  staticClass: "tbl_icon plus",
                                  attrs: { type: "button" },
                                  on: { click: _vm.fnIranHsCdMulti },
                                },
                                [_vm._v(" plus ")]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _c(
                        "span",
                        {
                          class:
                            _vm.$ekmtcCommon.isNotEmpty(_vm.tempParams.hsCdC) &&
                            _vm.tempParams.hsCdC !== "N"
                              ? ""
                              : "d_none",
                          attrs: { id: "ir_hsCdC" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.117")) + " ")]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                      _vm._v("PURPOSE OF USE"),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.iranBookingInfo.useDestCont,
                            expression: "regParams.iranBookingInfo.useDestCont",
                          },
                        ],
                        attrs: { id: "ir_useDestCont", type: "text" },
                        domProps: {
                          value: _vm.regParams.iranBookingInfo.useDestCont,
                        },
                        on: {
                          keyup: function ($event) {
                            return _vm.checkIranValidation("useDestCont")
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.regParams.iranBookingInfo,
                              "useDestCont",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  false
                    ? _c("tr", [
                        _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                          _vm._v("LOI"),
                        ]),
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "button sm",
                              on: {
                                click: function ($event) {
                                  return _vm.fnIranLOIDown()
                                },
                              },
                            },
                            [_vm._v("LOI DownLoad")]
                          ),
                          _c(
                            "span",
                            { staticClass: "color_red font_12 ml10" },
                            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.177")))]
                          ),
                          _vm._v(" "),
                          _vm.saveFlag === "EDIT"
                            ? _c(
                                "a",
                                {
                                  staticClass: "button sm",
                                  on: {
                                    click: function ($event) {
                                      return _vm.fnIranUploadPop("01")
                                    },
                                  },
                                },
                                [_vm._v("LOI Upload")]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                      _vm._v("SHIPPER REMARK"),
                    ]),
                    _c("td", [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.iranBookingInfo.reqCont,
                            expression: "regParams.iranBookingInfo.reqCont",
                          },
                        ],
                        attrs: { id: "reqCont" },
                        domProps: {
                          value: _vm.regParams.iranBookingInfo.reqCont,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.regParams.iranBookingInfo,
                              "reqCont",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                      _vm._v("KMTC REMARK"),
                    ]),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.regParams.iranBookingInfo.apvCont) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            staticClass: "btn_right_wrap mt10",
            staticStyle: { overflow: "auto" },
          },
          [
            _c(
              "a",
              {
                staticClass: "button blue lg mr5",
                staticStyle: { float: "left" },
                on: {
                  click: function ($event) {
                    return _vm.fnList()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.MAIN100.213")))]
            ),
            _vm._v(" "),
            !["00", "02", "05"].includes(_vm.regParams.bkgStsCd) &&
            _vm.regParams.cnclYn !== "Y" &&
            _vm.act == "M"
              ? _c(
                  "a",
                  {
                    staticClass: "button blue lg mr5",
                    staticStyle: { float: "left" },
                    on: {
                      click: function ($event) {
                        return _vm.soDownloadPop()
                      },
                    },
                  },
                  [_vm._v("S/O Download")]
                )
              : _vm._e(),
            _vm.act === "R" ||
            (_vm.regParams.cnclYn != "Y" &&
              _vm.bkgUpdYn !== "N" &&
              _vm.srStat !== "B")
              ? _c(
                  "a",
                  {
                    staticClass: "button blue lg mr5",
                    on: {
                      click: function ($event) {
                        return _vm.saveSrInquery()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.CSBK100.178")))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.regParams.cnclYn !== "Y" && _vm.isVgmSrInquery()
              ? _c(
                  "a",
                  {
                    staticClass: "button blue lg mr5",
                    on: {
                      click: function ($event) {
                        return _vm.vgmSrInquery()
                      },
                    },
                  },
                  [_vm._v("Input VGM")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.regParams.cnclYn !== "Y" && _vm.regParams.cancelCd === "99"
              ? _c(
                  "a",
                  {
                    staticClass: "button blue lg mr5",
                    on: {
                      click: function ($event) {
                        return _vm.cancelSrInquery()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX020P070.001")) + " ")]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          { staticClass: "booking_new_sr_popup" },
          [
            _c(
              "win-layer-pop",
              [
                _vm.customComponent
                  ? _c(_vm.customComponent, {
                      tag: "component",
                      attrs: { "parent-info": _vm.popParams },
                      on: { close: _vm.closePoup, selectFunc: _vm.selectFunc },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "doc_req_pop" },
          [
            _c(
              "win-layer-pop",
              [
                _vm.uploadComponent
                  ? _c(_vm.uploadComponent, {
                      tag: "component",
                      attrs: { "parent-info": _vm.uploadParam },
                      on: {
                        close: _vm.closeUploadPopup,
                        "set-dext-file-info": _vm.uploadCallbackFunc,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm.commonBookingComponent
          ? _c(_vm.commonBookingComponent, {
              ref: "commonBooking",
              tag: "component",
              attrs: { "parent-info": _vm.commonBookingParams },
              on: { callback: _vm.commonBookingCallback },
            })
          : _vm._e(),
        _vm.commonBLComponent
          ? _c(_vm.commonBLComponent, {
              ref: "commonBL",
              tag: "component",
              attrs: { "parent-info": _vm.commonBLParams },
              on: { callback: _vm.commonBLCallback },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "100px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "30%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "30%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "30%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mr10", attrs: { for: "chemicalY" } }, [
      _c("span"),
      _vm._v("Y"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mr10", attrs: { for: "chemicalN" } }, [
      _c("span"),
      _vm._v("N"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mr10", attrs: { for: "fixdCgoY" } }, [
      _c("span"),
      _vm._v("Y"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mr10", attrs: { for: "fixdCgoN" } }, [
      _c("span"),
      _vm._v("N"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "21%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "21%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "35%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "35%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "auto" } }),
      _c("col", { attrs: { width: "5%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "21%" } }),
      _c("col", { staticStyle: { width: "21%" } }),
      _c("col", { staticStyle: { width: "21%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "30%" } }),
      _c("col", { staticStyle: { width: "30%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "30%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "35%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "35%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "35%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "35%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("span", [_vm._v("| 风扇")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "select_opt_area mt20" }, [
      _c("div", { staticClass: "notice_message" }, [
        _c("ul", [
          _c("li", [
            _vm._v(
              "Container Type is Reefer : 必修输入 : 冷藏通风量, 设置单位, 设置温度,设置温度."
            ),
          ]),
          _c("li", [
            _vm._v(
              "Container Type is Dangerous Cargo : 必修输入 : Class, UN No., FlashPoint."
            ),
          ]),
          _c("li", [
            _vm._v(
              "Container Type is Out Of Gauge : 必修输入 : 前超 ,后超 , 左超 ,右超,超高."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "45%" } }),
      _c("col", { attrs: { width: "auto" } }),
      _c("col", { attrs: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "7%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
          _vm._v("Mark"),
          _c("br"),
          _vm._v(" Page"),
        ]),
        _c("th", { attrs: { scope: "col", rowspan: "2" } }, [_vm._v("SEQ")]),
        _c("th", { attrs: { scope: "col", colspan: "2" } }, [_vm._v("Marks")]),
        _c("th", { attrs: { scope: "col", colspan: "2" } }, [
          _vm._v("Description of Goods"),
        ]),
        _c("th", { attrs: { scope: "col", rowspan: "2" } }, [_vm._v("Del")]),
      ]),
      _c("tr", [
        _c("th", { staticClass: "bor-left", attrs: { scope: "col" } }, [
          _vm._v("Pkg’s"),
        ]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Pkg Code")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Weight")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("CBM")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "50%" } }),
      _c("col", { attrs: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("CY(Depot) Remark")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Document Remark")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "60%" } }),
      _c("col", { attrs: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticStyle: { "border-left": "1px solid #e1e3eb" } }, [
        _vm._v("TEL"),
      ]),
      _vm._v(" "),
      _c("th", [_vm._v("EMAIL")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }