var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content resize" }, [
    _c("a", { on: { click: _vm.clickEvent } }, [_vm._v("상세이동")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }