<template>
  <div class="position_relative">
    <input type="text"
           v-model="text"
           :id="id"
           :name="name"
           :maxlength="maxlength"
           :readonly="readonly"
           @blur="onInput($event)"
    />
  </div>
</template>

<script>
export default {
  name: 'ETransStringCheckCneeInput',
  props: {
    id: { type: String, required: true },
    isUpperCase: { type: Boolean, default: false, required: false },
    isRequired: { type: Boolean, default: false, required: false },
    messageRequired: { type: String, default: '', required: false },
    maxlength: { type: String, default: '', required: false },
    name: { type: String, default: '', required: false },
    value: { type: String, default: '', required: false },
    errorMessage: { type: String, default: '', required: false },
    readonly: { type: Boolean, default: false, required: false }
  },
  data () {
    return {
      elemId: '',
      text: '',
      rtnText: '',
      rtnNumber: 0,
      interval: undefined
    }
  },
  watch: {
    value () {
      if (this.text !== this.$props.value) {
        this.text = this.$props.value
      }
    },
    errorMessage () {
      const elem = document.querySelector('#' + this.id)
      if (this.$props.errorMessage !== '') {
        this.$ekmtcCommon.showErrorTooltip(elem, this.$props.errorMessage)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
    }
  },
  created () {
    this.text = this.$props.value
  },
  methods: {
    onInput (e) {
      const val = this.isUpperCase ? e.target.value.toUpperCase() : e.target.value
      const arr = []
      let cnt = 0
      let str
      let c

      for (let i = 0; i < val.length; i++) {
        str = val.charAt(i)
        c = val.charCodeAt(i)
        if ('`~!$^_+={}[]'.indexOf(str) < 0 && ((c >= 0 && c <= 91) || (c >= 93 && c <= 127))) {
          arr.push('' + str)
        } else {
          cnt++
        }
      }

      const THIS = this
      const elem = document.querySelector('#' + this.id)

      if (cnt > 0) {
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK215')) // 특수문자 또는 한글을 사용할 수 없습니다.

        e.target.value = arr.join('')
        this.text = arr.join('')
        this.$emit('input', arr.join(''))

        if (this.interval !== undefined) {
          clearInterval(this.interval)
          this.interval = undefined
        }

        this.interval = setInterval(() => {
          THIS.$ekmtcCommon.hideErrorTooltip(elem)
          clearInterval(this.interval)
          this.interval = undefined
        }, 3000)

        return
      }
      e.target.value = val
      this.text = val
      this.$emit('input', val)

      let isOk = true
      let msg = ''

      if (this.isRequired && val === '') {
        isOk = false
        msg = this.messageRequired
      } else if (this.errorMessage !== '') {
        isOk = false
        msg = this.errorMessage
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
    }
  }
}
</script>
