var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content resize" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("menu.MENU01.000"))),
      ]),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kmtcPremiumYn === "Y",
              expression: "kmtcPremiumYn === 'Y'",
            },
          ],
          staticStyle: { position: "absolute", top: "120px", left: "133px" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../assets/images/common/icon_premium_text_only.png"),
            },
          }),
          _vm._v(" KMTC ON PREMIUM 선복보장 서비스로 부킹합니다."),
        ]
      ),
      _vm.page == "SR"
        ? _c("booking-new-sr", {
            attrs: { "seq-no": _vm.seqNo, "schedule-obj": _vm.scheduleObj },
          })
        : _c("booking-new", {
            attrs: {
              "schedule-obj": _vm.scheduleObj,
              "mty-pkup-usag-yn": _vm.mtyPkupUsagYn,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }