var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "650px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close", { saveFlag: _vm.saveFlag })
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("form", { attrs: { id: "frm" } }, [
        _c("div", { staticClass: "popup_cont" }, [
          _c("h2", { staticClass: "page_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX100G020.004"))),
          ]),
          _c("div", { staticClass: "content_box" }, [
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v("Booking No.")]),
                  _c("td", [_vm._v(_vm._s(_vm.params.bkgNo))]),
                ]),
              ]),
            ]),
            _c("h3", { staticClass: "flex_box mt20" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX100G020.005"))),
            ]),
            _c("table", { staticClass: "tbl_row mt10 mb10" }, [
              _vm._m(1),
              _c(
                "tbody",
                _vm._l(_vm.params.containerList, function (container) {
                  return _c("tr", { key: container.cntrSeq }, [
                    _c("th", [_vm._v("기존 " + _vm._s(container.cntrSeq))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(container.pcupCyCdTemp) +
                          " / " +
                          _vm._s(container.pcupReqDtTemp)
                      ),
                    ]),
                    _c("th", [_vm._v("변경 " + _vm._s(container.cntrSeq))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(container.pcupCyCd) +
                          " / " +
                          _vm._s(_vm.pcupReqDt)
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "mt10 text_center" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.editPickupInfo()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("msg.VOSD100.041")) + " ")]
            ),
            _c(
              "a",
              {
                staticClass: "button gray lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("close", { saveFlag: _vm.saveFlag })
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("msg.VOSD100.042")) + " ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "120" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "120" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }