<template>
  <div class="content">
    <e-breadcrumbs />
    <h1 class="page_title">{{$t('menu.MENU02.000')}}</h1>
    <e-tab ref="tab">
      <e-tab-item :title="$t('menu.MENU02.010')" name="leg" path="/schedule" :selected=true>
        <schedule-leg></schedule-leg>
      </e-tab-item>
      <e-tab-item :title="$t('menu.MENU02.020')" name="vessel" path="/schedule">
        <schedule-vessel></schedule-vessel>
      </e-tab-item>
      <e-tab-item :title="$t('menu.MENU02.030')" name="long-term" path="/schedule">
        <schedule-long-term></schedule-long-term>
      </e-tab-item>
    </e-tab>
  </div>
</template>

<script>
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import ETabItem from '@/components/common/ETabItem'
import ETab from '@/components/common/ETab'

import ScheduleLeg from '@/pages/schedule/ScheduleLeg'
import ScheduleVessel from '@/pages/schedule/ScheduleVessel'
import ScheduleLongTerm from '@/pages/schedule/ScheduleLongTerm'

export default {
  name: 'ScheduleMain',
  components: {
    EBreadcrumbs,
    ETabItem,
    ETab,
    ScheduleLeg,
    ScheduleVessel,
    ScheduleLongTerm
  },
  watch: {
    $route () {
      this.$refs.tab.selectTab({ name: this.$route.name })
    }
  },
  mounted () {
    this.$refs.tab.selectTab({ name: this.$route.name })
  },
  methods: {
  }
}

</script>
