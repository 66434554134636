var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select_opt_area", attrs: { id: "dg_area_" + _vm.cntrSeq } },
    [
      _c(
        "div",
        { staticClass: "select_opt_area mt10" },
        [
          _vm._l(_vm.dgInfo.containerDGList, function (vo, idx) {
            return _c(
              "table",
              {
                key: "tbl_dg" + idx + _vm.forceUpdate,
                staticClass: "tbl_col t2",
              },
              [
                _vm._m(0, true),
                _c("thead", [
                  _c("tr", [
                    idx === 0
                      ? _c("th", { attrs: { scope: "col" } }, [
                          _vm.canAddDg()
                            ? _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.saveFlag !== "PICKUP",
                                      expression: "saveFlag !== 'PICKUP'",
                                    },
                                  ],
                                  staticClass: "tbl_icon plus",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addContainerDG()
                                    },
                                  },
                                },
                                [_vm._v(" plus ")]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    idx !== 0
                      ? _c("td", { attrs: { scope: "col" } })
                      : _vm._e(),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CMBA100.00116"))),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.349"))),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.351"))),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.350"))),
                    ]),
                    _c("th", { attrs: { colspan: "3", scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.352"))),
                    ]),
                    _c("th", { attrs: { colspan: "2", scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.353"))),
                    ]),
                  ]),
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticClass: "tac", attrs: { rowspan: "11" } }, [
                      _vm.canAddDg()
                        ? _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.saveFlag !== "PICKUP",
                                  expression: "saveFlag !== 'PICKUP'",
                                },
                              ],
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeContainerDG(idx)
                                },
                              },
                            },
                            [_vm._v(" minus ")]
                          )
                        : _vm._e(),
                    ]),
                    _c("td", { staticClass: "tac" }, [
                      _vm._v(" " + _vm._s(vo.dgSeq) + " "),
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "search_btn" }, [
                        _c("input", {
                          staticClass: "inp_responsive_space3",
                          attrs: {
                            id: "imdunNo_" + _vm.cntrSeq + "_" + idx,
                            type: "text",
                            readonly: "",
                          },
                          domProps: { value: vo.imdunNo },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationDG("imdunNo", idx)
                            },
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.getUnnoInfo(idx)
                            },
                          },
                        }),
                        _c("span", [
                          _c("button", {
                            staticClass: "search_btn01",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.getUnnoInfo(idx)
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c("td", [
                      _c("input", {
                        attrs: {
                          id: "imoClasCd_" + _vm.cntrSeq + "_" + idx,
                          type: "text",
                          readonly: "",
                        },
                        domProps: { value: vo.imoClasCd },
                      }),
                    ]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.dgPkgGrpCd,
                              expression: "vo.dgPkgGrpCd",
                            },
                          ],
                          attrs: {
                            id: "dgPkgGrpCd_" + _vm.cntrSeq + "_" + idx,
                            disabled: "",
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  vo,
                                  "dgPkgGrpCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.checkValidationDG("dgPkgGrpCd", idx)
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: " " } }, [
                            _vm._v("-----"),
                          ]),
                          _c("option", { attrs: { value: "01" } }, [
                            _vm._v("III"),
                          ]),
                          _c("option", { attrs: { value: "02" } }, [
                            _vm._v("II"),
                          ]),
                          _c("option", { attrs: { value: "03" } }, [
                            _vm._v("I"),
                          ]),
                          _c("option", { attrs: { value: "04" } }, [
                            _vm._v("-"),
                          ]),
                        ]
                      ),
                    ]),
                    _c("td", { attrs: { colspan: "3" } }, [
                      _vm._v(" " + _vm._s(vo.dsc) + " "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.imdgGrpCd,
                              expression: "vo.imdgGrpCd",
                            },
                          ],
                          staticClass: "imdgGrpCd",
                          attrs: {
                            id: "imdgGrpCd_" + _vm.cntrSeq + "_" + idx,
                            "data-value": vo.imdgGrpCd,
                            disabled: "",
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  vo,
                                  "imdgGrpCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.checkValidationDG(
                                  "imdgGrpCd",
                                  idx,
                                  "",
                                  $event.target.value
                                )
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.commonCodes["VS044"], function (cvo) {
                          return _c(
                            "option",
                            { key: cvo.cd, domProps: { value: cvo.cd } },
                            [_vm._v(" " + _vm._s(cvo.cdNm) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "bor-left", attrs: { scope: "col" } },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.354"))),
                        _c("br"),
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.355")) + " "),
                      ]
                    ),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.356"))),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.268"))),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.358"))),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.359"))),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.360"))),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.361"))),
                    ]),
                    _c("th", { attrs: { colspan: "2", scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.362"))),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "bor-left" }, [
                      _c(
                        "select",
                        {
                          attrs: { id: "dgRsdCd_" + _vm.cntrSeq + "_" + idx },
                          domProps: { value: vo.dgRsdCd },
                          on: {
                            change: function ($event) {
                              return _vm.checkValidationDG(
                                "dgRsdCd",
                                idx,
                                "",
                                $event.target.value
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("----------"),
                          ]),
                          _c("option", { attrs: { value: "01" } }, [
                            _vm._v("Full"),
                          ]),
                          _c("option", { attrs: { value: "02" } }, [
                            _vm._v("Residue"),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "td",
                      { staticClass: "tal" },
                      [
                        _c("e-input-number", {
                          attrs: {
                            id: "flsTmpr_" + _vm.cntrSeq + "_" + idx,
                            "is-minus": true,
                            point: 2,
                            "input-class": "col_5",
                            value: vo.flsTmpr,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationDG(
                                "flsTmpr",
                                idx,
                                "",
                                ...arguments
                              )
                            },
                          },
                        }),
                        _c(
                          "select",
                          {
                            staticClass: "col_4",
                            attrs: {
                              id: "tmprUnitCd_" + _vm.cntrSeq + "_" + idx,
                            },
                            domProps: { value: vo.tmprUnitCd },
                            on: {
                              change: function ($event) {
                                return _vm.handleSelectChange(
                                  "tmprUnitCd",
                                  idx,
                                  $event.target.value
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "C" } }, [
                              _vm._v("℃"),
                            ]),
                            _c("option", { attrs: { value: "F" } }, [
                              _vm._v("℉"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticClass: "tal" },
                      [
                        _c("e-input-number", {
                          key: _vm.grswtKey,
                          attrs: {
                            id: "grsWt_" + _vm.cntrSeq + "_" + idx,
                            "is-comma": true,
                            point: 3,
                            "input-class": "col_8",
                            value: vo.grsWt,
                            readonly: _vm.cntrTypCd === "TK",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationDG(
                                "grsWt",
                                idx,
                                "",
                                ...arguments
                              )
                            },
                          },
                        }),
                        _vm._v(" kg "),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticClass: "tal" },
                      [
                        _c("e-input-number", {
                          attrs: {
                            id: "netWt_" + _vm.cntrSeq + "_" + idx,
                            "is-comma": true,
                            point: 3,
                            "input-class": "col_8",
                            value: vo.netWt,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationDG(
                                "netWt",
                                idx,
                                "",
                                ...arguments
                              )
                            },
                          },
                        }),
                        _vm._v(" kg "),
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "select",
                        {
                          attrs: {
                            id: "mpltCd_" + _vm.cntrSeq + "_" + idx,
                            disabled: "",
                          },
                          domProps: { value: vo.mpltCd },
                          on: {
                            change: function ($event) {
                              return _vm.checkValidationDG(
                                "mpltCd",
                                idx,
                                "",
                                $event.target.value
                              )
                            },
                            input: function ($event) {
                              return _vm.changeMpltCd($event, idx)
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                          ]),
                          _c("option", { attrs: { value: "Y" } }, [
                            _vm._v("Y"),
                          ]),
                          _c("option", { attrs: { value: "N" } }, [
                            _vm._v("N"),
                          ]),
                        ]
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.emsFeCd,
                              expression: "vo.emsFeCd",
                            },
                          ],
                          attrs: { id: "emsFeCd_" + _vm.cntrSeq + "_" + idx },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                vo,
                                "emsFeCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.emsFeCdList, function (cvo) {
                          return _c(
                            "option",
                            { key: "emsFeCd_" + cvo, domProps: { value: cvo } },
                            [_vm._v(" " + _vm._s(cvo) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.emsSeCd,
                              expression: "vo.emsSeCd",
                            },
                          ],
                          attrs: { id: "emsSeCd_" + _vm.cntrSeq + "_" + idx },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                vo,
                                "emsSeCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.emsSeCdList, function (cvo) {
                          return _c(
                            "option",
                            { key: "emsSeCd_" + cvo, domProps: { value: cvo } },
                            [_vm._v(" " + _vm._s(cvo) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            "is-comma": true,
                            id: "dgPkgQty_" + _vm.cntrSeq + "_" + idx,
                            "input-class": "dgpkg",
                            value: vo.dgPkgQty,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationDG(
                                "dgPkgQty",
                                idx,
                                "",
                                ...arguments
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "select",
                        {
                          attrs: { id: "dgMatlCd_" + _vm.cntrSeq + "_" + idx },
                          domProps: { value: vo.dgMatlCd },
                          on: {
                            change: function ($event) {
                              return _vm.changeDgOuter(
                                vo,
                                idx,
                                $event.target.value
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(
                              " --------------------------------------------------- "
                            ),
                          ]),
                          _vm._l(vo.outerPkgList, function (cvo) {
                            return _c(
                              "option",
                              { key: cvo.cd, domProps: { value: cvo.cd } },
                              [_vm._v(" " + _vm._s(cvo.cdNm) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticClass: "bor-left",
                        attrs: { colspan: "4", scope: "col" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.363")) + " ")]
                    ),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.364"))),
                    ]),
                    _c("th", { attrs: { colspan: "2", scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.365"))),
                    ]),
                    _c("th", { attrs: { colspan: "2", scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.366"))),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "bor-left tac", attrs: { colspan: "4" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.tchlNm,
                              expression: "vo.tchlNm",
                            },
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false",
                            },
                          ],
                          attrs: {
                            id: "tchlNm_" + _vm.cntrSeq + "_" + idx,
                            type: "text",
                            disabled: "",
                          },
                          domProps: { value: vo.tchlNm },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "tchlNm", $event.target.value)
                              },
                              function ($event) {
                                return _vm.checkValidationDG("tchlNm", idx)
                              },
                            ],
                          },
                        }),
                        _c(
                          "span",
                          {
                            attrs: {
                              id: "tchlNmSpan_" + _vm.cntrSeq + "_" + idx,
                            },
                          },
                          [_vm._v(" - ")]
                        ),
                      ]
                    ),
                    _c("td", { staticClass: "tac" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: vo.lqYn,
                            expression: "vo.lqYn",
                          },
                        ],
                        attrs: {
                          id: "lqYn_" + _vm.cntrSeq + "_" + idx,
                          type: "checkbox",
                          "true-value": "Y",
                          "false-value": "N",
                        },
                        domProps: {
                          value: vo.lqYn,
                          checked: Array.isArray(vo.lqYn)
                            ? _vm._i(vo.lqYn, vo.lqYn) > -1
                            : _vm._q(vo.lqYn, "Y"),
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = vo.lqYn,
                                $$el = $event.target,
                                $$c = $$el.checked ? "Y" : "N"
                              if (Array.isArray($$a)) {
                                var $$v = vo.lqYn,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(vo, "lqYn", $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      vo,
                                      "lqYn",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(vo, "lqYn", $$c)
                              }
                            },
                            function ($event) {
                              return _vm.handeCheckboxChange(
                                "lqYn",
                                idx,
                                $event.target.checked
                              )
                            },
                          ],
                        },
                      }),
                      _c(
                        "label",
                        { attrs: { for: "lqYn_" + _vm.cntrSeq + "_" + idx } },
                        [
                          _c("span", { staticClass: "offscreen" }, [
                            _vm._v("L/Q YN"),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            id: "saptTmpr_" + _vm.cntrSeq + "_" + idx,
                            value: vo.saptTmpr,
                            "is-comma": true,
                            "input-class": "saptTmpr",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.handleDgChange(
                                idx,
                                "saptTmpr",
                                ...arguments
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "select",
                        {
                          attrs: {
                            id: "saptTmprUnitCd_" + _vm.cntrSeq + "_" + idx,
                            disabled: "",
                          },
                          domProps: { value: vo.saptTmprUnitCd },
                          on: {
                            change: function ($event) {
                              return _vm.handleDgChange(
                                idx,
                                "saptTmprUnitCd",
                                $event.target.value
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "C" } }, [
                            _vm._v("℃"),
                          ]),
                          _c("option", { attrs: { value: "F" } }, [
                            _vm._v("℉"),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            "is-comma": true,
                            id: "isdDgPkgQty_" + _vm.cntrSeq + "_" + idx,
                            "input-class": "isdDgpkg",
                            value: vo.isdDgPkgQty,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationDG(
                                "isdDgPkgQty",
                                idx,
                                "",
                                ...arguments
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "select",
                        {
                          attrs: { id: "dgCatCd_" + _vm.cntrSeq + "_" + idx },
                          domProps: { value: vo.dgCatCd },
                          on: {
                            change: function ($event) {
                              return _vm.checkValidationDG(
                                "dgCatCd",
                                idx,
                                "",
                                $event.target.value
                              )
                            },
                          },
                        },
                        _vm._l(vo.innerPkgList, function (cvo) {
                          return _c(
                            "option",
                            { key: cvo.cd, domProps: { value: cvo.cd } },
                            [_vm._v(" " + _vm._s(cvo.cdNm) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticClass: "bor-left",
                        attrs: { scope: "col", colspan: "2" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.367")) + " ")]
                    ),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.368"))),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.369"))),
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "tal",
                        attrs: { scope: "col", colspan: "5" },
                      },
                      [
                        vo.addInfoMsg !== undefined && vo.addInfoMsg !== ""
                          ? _c("span", [_vm._v("Q. " + _vm._s(vo.addInfoMsg))])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "bor-left", attrs: { colspan: "2" } },
                      [
                        _c("input", {
                          attrs: {
                            id: "emrPicNm_" + _vm.cntrSeq + "_" + idx,
                            type: "text",
                          },
                          domProps: { value: vo.emrPicNm },
                          on: {
                            keyup: function ($event) {
                              return _vm.checkValidationDG(
                                "emrPicNm",
                                idx,
                                "",
                                $event.target.value
                              )
                            },
                            input: function ($event) {
                              return _vm.changeEmrPic($event, idx)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            value: vo.emrTelCtrNo,
                            id: "emrTelCtrNo_" + _vm.cntrSeq + "_" + idx,
                            "is-phone": true,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationDG(
                                "emrTelCtrNo",
                                idx,
                                "",
                                ...arguments
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            value: vo.emrTelNo,
                            id: "emrTelNo_" + _vm.cntrSeq + "_" + idx,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationDG(
                                "emrTelNo",
                                idx,
                                "",
                                ...arguments
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "bor-left bor-r0",
                        attrs: { colspan: "5" },
                      },
                      [
                        vo.addInfoMsgList !== undefined &&
                        vo.addInfoMsgList !== null &&
                        vo.addInfoMsgList.length > 0
                          ? _c(
                              "select",
                              {
                                attrs: {
                                  id: "repsCont_" + _vm.cntrSeq + "_" + idx,
                                },
                                domProps: { value: vo.repsCont },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkValidationDG(
                                      "repsCont",
                                      idx,
                                      "",
                                      $event.target.value
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.371"))),
                                ]),
                                _vm._l(vo.addInfoMsgList, function (cvo, i) {
                                  return _c(
                                    "option",
                                    {
                                      key: i,
                                      domProps: { value: cvo.rspsCont },
                                    },
                                    [_vm._v(" " + _vm._s(cvo.rspsCont) + " ")]
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticClass: "bor-left",
                        attrs: { scope: "col", colspan: "4" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.372")) + " ")]
                    ),
                    _c("th", { attrs: { scope: "col", colspan: "3" } }, [
                      _vm._v("RATIO(%)"),
                    ]),
                    _c("th", { attrs: { scope: "col", colspan: "2" } }),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass: "bor-left bor-r0",
                        attrs: { colspan: "4" },
                      },
                      [
                        _c(
                          "div",
                          _vm._l(vo.casNoList, function (cvo, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "tbl_form cas_area" },
                              [
                                _c("span", { staticClass: "dv wid20" }, [
                                  i === 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "tbl_icon plus",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addCasNo(idx)
                                            },
                                          },
                                        },
                                        [_vm._v(" plus ")]
                                      )
                                    : _vm._e(),
                                  i !== 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "tbl_icon minus",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeCasNo(idx, i)
                                            },
                                          },
                                        },
                                        [_vm._v(" minus ")]
                                      )
                                    : _vm._e(),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "dv position_relative" },
                                  [
                                    _c("e-input-number", {
                                      attrs: {
                                        value: cvo.casNo,
                                        id: "casNo_" + idx + "_" + i,
                                        maxlength: "10",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.checkValidationCasNo(
                                            cvo,
                                            idx,
                                            i,
                                            ...arguments
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "dv" }),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "bor-left bor-r0",
                        attrs: { colspan: "3" },
                      },
                      [
                        _vm._l(vo.casNoList, function (cvo, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "tbl_form cas_area" },
                            [
                              _c("e-input-number", {
                                attrs: {
                                  value: cvo.rto,
                                  id: "rto_" + idx + "_" + i,
                                  point: 2,
                                  "digit-max": 3,
                                  maxlength: "6",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationRto(
                                      cvo,
                                      idx,
                                      i,
                                      ...arguments
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                    _c("td", { attrs: { colspan: "2" } }),
                  ]),
                ]),
              ]
            )
          }),
          _c("table", { staticClass: "tbl_col t2 mt10" }, [
            _vm._m(1),
            _c("tbody", [
              _vm.lang === "ENG" && _vm.vanningDtYn === "Y"
                ? _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.373"))),
                    ]),
                    _c("td", { attrs: { colspan: "3" } }, [
                      _c(
                        "div",
                        { staticClass: "input_calendar" },
                        [
                          _c("e-date-picker", {
                            attrs: {
                              value: _vm.dgInfo.vaniDt,
                              id: "vaniDt_" + _vm.cntrSeq,
                              type: "text",
                              placeholder: "YYYY-MM-DD",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.handleEDatePickerChange($event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm.saveFlag !== "PICKUP"
              ? _c("tbody", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v("File Upload"),
                    ]),
                    _c("td", { attrs: { colspan: "3" } }, [
                      _c("div", { staticClass: "filebox" }, [
                        _c(
                          "a",
                          {
                            class: _vm.$ekmtcCommon.isEmpty(_vm.dgInfo.reqRno)
                              ? "button sm gray file"
                              : "button sm blue",
                            on: {
                              click: function ($event) {
                                return _vm.uploadPopup()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.CSBK100.205")) + " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.374"))),
                    ]),
                    _c("td", { attrs: { colspan: "3" } }, [
                      _c("textarea", {
                        domProps: { value: _vm.dgInfo.reqConts },
                        on: {
                          input: function ($event) {
                            return _vm.setContainer(
                              "reqConts",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.375"))),
                    ]),
                    _c("td", [
                      _c("span", { staticClass: "span_scg" }, [
                        _vm._v(_vm._s(_vm.dgInfo.apvStsNm)),
                      ]),
                    ]),
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.376"))),
                    ]),
                    _c("td", [
                      _c("span", { staticClass: "span_scg" }, [
                        _vm._v(_vm._s(_vm.dgInfo.scgAppNo)),
                      ]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.377"))),
                    ]),
                    _c("td", { attrs: { colspan: "3" } }, [
                      _c("textarea", {
                        attrs: { readonly: "" },
                        domProps: { value: _vm.dgInfo.apvCont },
                      }),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popParams },
                on: { close: _vm.closePoup, selectFunc: _vm.selectFunc },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "5%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "11%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "35%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }