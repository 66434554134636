var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content resize" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(" KMTC ON NOW로 지금 즉시 운임을 협의해보세요! "),
        _c(
          "a",
          {
            staticClass: "button lg",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.pdfDownload("KMTC_ON_NOW_FAQ_240821.pdf")
              },
            },
          },
          [_vm._v(" [KMTC ON NOW 이용 가이드 다운로드 (CLICK)]")]
        ),
      ]),
      _c(
        "form",
        { attrs: { id: "frm2" } },
        [
          _c(
            "win-layer-pop",
            { staticClass: "my_surcharge_pop" },
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: { "parent-info": _vm.surChargeParams },
                    on: { close: _vm.closePopup },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "content_box beforetab" }, [
            _c("table", { staticClass: "tbl_search" }, [
              _c("colgroup", [
                _c("col", {
                  attrs: {
                    width: [_vm.serviceCtrCd === "KR" ? "60px" : "80px"],
                  },
                }),
                _c("col", { attrs: { width: "150px" } }),
                _c("col", { attrs: { width: "324px" } }),
                _c("col", { attrs: { width: "50px" } }),
                _c("col", { attrs: { width: "150px" } }),
                _c("col", { attrs: { width: "150px" } }),
                _c("col", { attrs: { width: "80px" } }),
                _c("col", { attrs: { width: "120px" } }),
              ]),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.001")))]),
                  _c(
                    "td",
                    { attrs: { colspan: "2" } },
                    [
                      _c("e-auto-complete-place", {
                        attrs: {
                          id: "polPol",
                          "ctr-cd": _vm.params.porCtrCd,
                          "plc-cd": _vm.params.porPlcCd,
                          "is-char": true,
                          "is-char-alert": true,
                        },
                        on: { change: _vm.changePol },
                      }),
                    ],
                    1
                  ),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.002")))]),
                  _c(
                    "td",
                    { attrs: { colspan: "4" } },
                    [
                      _c("e-auto-complete-place", {
                        attrs: {
                          id: "podPod",
                          "ctr-cd": _vm.params.dlyCtrCd,
                          "plc-cd": _vm.params.dlyPlcCd,
                          "is-char": true,
                          "is-char-alert": true,
                        },
                        on: { change: _vm.changePod },
                      }),
                    ],
                    1
                  ),
                  _c("td", { staticClass: "text_right" }),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.003")))]),
                  _c("td", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.kind,
                            expression: "params.kind",
                          },
                        ],
                        staticClass: "wid150",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.params,
                              "kind",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "R" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.FARE020T010.005"))),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "td",
                    { attrs: { colspan: "5" } },
                    [
                      _c("e-date-range-picker", {
                        attrs: {
                          id: "date_picker",
                          sdate: _vm.params.fromDt,
                          edate: _vm.params.toDt,
                          "is-debug": true,
                        },
                        on: { change: _vm.changeDateRange },
                      }),
                    ],
                    1
                  ),
                  _c("td", { staticClass: "text_right" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button blue sh",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.searchMyOceanFreight(true)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.013")))]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "content_box mt10" }, [
            _c("div", { staticClass: "flex_box" }, [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "44%",
                    float: "left",
                    color: "#075bb9",
                    "font-weight": "500",
                    "font-size": "17px",
                    "margin-left": "10px",
                  },
                },
                [
                  _vm._v("R/A (S/C) No : " + _vm._s(_vm.raNo) + " "),
                  _c(
                    "span",
                    { staticStyle: { "font-size": "15px", color: "red" } },
                    [_vm._v(" " + _vm._s(_vm.negoAfterText) + " ")]
                  ),
                ]
              ),
              _vm._m(0),
            ]),
            _c(
              "div",
              {
                staticClass: "content_box beforetab",
                staticStyle: {
                  display: "inline-block",
                  width: "44%",
                  "margin-right": "10px",
                },
              },
              [
                _c("table", { staticClass: "tbl_search" }, [
                  _vm._m(1),
                  _c("tbody", [
                    _vm._m(2),
                    _c("tr", [
                      _c("th", [_vm._v("POR (국가)")]),
                      _c("td", { attrs: { colspan: "4" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.porPlcNm,
                              expression: "porPlcNm",
                            },
                          ],
                          attrs: { type: "text", id: "porPlcNm", readonly: "" },
                          domProps: { value: _vm.porPlcNm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.porPlcNm = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c("td", { attrs: { colspan: "4" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.porCtrNm,
                              expression: "porCtrNm",
                            },
                          ],
                          attrs: { type: "text", id: "porCtrNm", readonly: "" },
                          domProps: { value: _vm.porCtrNm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.porCtrNm = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("DLY (국가)")]),
                      _c("td", { attrs: { colspan: "4" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.dlyPlcNm,
                              expression: "dlyPlcNm",
                            },
                          ],
                          attrs: { type: "text", id: "dlyPlcNm", readonly: "" },
                          domProps: { value: _vm.dlyPlcNm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.dlyPlcNm = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c("td", { attrs: { colspan: "4" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.dlyCtrNm,
                              expression: "dlyCtrNm",
                            },
                          ],
                          attrs: { type: "text", id: "dlyCtrNm", readonly: "" },
                          domProps: { value: _vm.dlyCtrNm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.dlyCtrNm = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("운임유효기간")]),
                      _c("td", { attrs: { colspan: "4" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.freExpDate,
                              expression: "freExpDate",
                            },
                          ],
                          attrs: {
                            type: "text",
                            id: "freExpDate",
                            readonly: "",
                          },
                          domProps: { value: _vm.freExpDate },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.freExpDate = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("br"),
                    _vm._m(3),
                    _c("br"),
                    _c("tr", [
                      _c("th", [_vm._v("실화주")]),
                      _c("td", { attrs: { colspan: "5" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.actShpNm,
                              expression: "actShpNm",
                            },
                          ],
                          attrs: { type: "text", id: "actShpNm" },
                          domProps: { value: _vm.actShpNm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.actShpNm = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("IDEAL RATE")]),
                      _c("th", { attrs: { colspan: "2" } }, [
                        _vm._v("20FT (USD)"),
                      ]),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.req20Ft,
                              expression: "req20Ft",
                            },
                          ],
                          attrs: { type: "text", id: "req20Ft" },
                          domProps: { value: _vm.req20Ft },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.req20Ft = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th"),
                      _c("th", { attrs: { colspan: "2" } }, [
                        _vm._v("40FT (USD)"),
                      ]),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.req40Ft,
                              expression: "req40Ft",
                            },
                          ],
                          attrs: { type: "text", id: "req40Ft" },
                          domProps: { value: _vm.req40Ft },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.req40Ft = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._m(4),
                    _c("tr", [
                      _c("th"),
                      _c("td", { attrs: { colspan: "6" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.lssYn,
                              expression: "lssYn",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "lssYn",
                            name: "lssYn",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.lssYn)
                              ? _vm._i(_vm.lssYn, null) > -1
                              : _vm._q(_vm.lssYn, "Y"),
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.lssYn,
                                $$el = $event.target,
                                $$c = $$el.checked ? "Y" : "N"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.lssYn = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.lssYn = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.lssYn = $$c
                              }
                            },
                          },
                        }),
                        _vm._m(5),
                        _c("br"),
                      ]),
                    ]),
                    _vm._m(6),
                    _vm._m(7),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "text_right", attrs: { colspan: "7" } },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "button blue sh",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.kmtcOnNegoRequest()
                                },
                              },
                            },
                            [_vm._v("KMTC ON NOW 운임 협의하기")]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c("div", {
              style: `display: inline-block; width: 55%; height: ${_vm.gridHeight}px;`,
              attrs: { id: "realgrid" },
            }),
          ]),
          _c("br"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticStyle: { width: "55%", float: "right", "font-size": "15px" } },
      [
        _vm._v(
          "하기 운임 LIST의 운임은 OCEAN FREIGHT와 선적지 LSS 포함 여부만 표시됩니다."
        ),
        _c("br"),
        _vm._v(
          " 기타 LOCAL CHARGE, SURCHARGE를 확인하시려면 S/C No.를 클릭 부탁드립니다. "
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "100px" } }),
      _c("col", { attrs: { width: "50px" } }),
      _c("col", { attrs: { width: "50px" } }),
      _c("col", { attrs: { width: "50px" } }),
      _c("col", { attrs: { width: "50px" } }),
      _c("col", { attrs: { width: "100px" } }),
      _c("col", { attrs: { width: "100px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "10" } }, [
        _c("div", { staticStyle: { "font-size": "16px" } }, [
          _vm._v(" KMTC ON NOW를 이용해서 운임을 협의해보세요."),
          _c("br"),
          _vm._v(
            " 하기 정보를 입력해주시면 즉시 협의된 운임을 확인하실 수 있습니다."
          ),
          _c("br"),
          _c("br"),
          _vm._v(" (요청해주신 IDEAL RATE 수준에 따라 "),
          _c("br"),
          _vm._v(
            "  원하시는 협의 운임이 도출되지 않을 수 있는 점 양해 부탁드립니다.) "
          ),
          _c("br"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mr10", attrs: { for: "lssYn" } }, [
      _c("span"),
      _vm._v("선적지 LSS 포함 기준으로 IDEAL RATE를 제출하겠습니다.(선택)"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th"), _c("td")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th"), _c("td")])
  },
]
render._withStripped = true

export { render, staticRenderFns }