var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position_relative" },
    [
      _vm.isChar
        ? [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.text,
                  expression: "text",
                },
              ],
              ref: "textareaTransAddr",
              attrs: { id: _vm.id, name: _vm.name, maxlength: _vm.maxLength },
              domProps: { value: _vm.text },
              on: {
                blur: function ($event) {
                  return _vm.onInput($event)
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.text = $event.target.value
                  },
                  function ($event) {
                    return _vm.onInput1($event)
                  },
                ],
              },
            }),
          ]
        : [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.text,
                  expression: "text",
                },
              ],
              ref: "textareaTransAddr",
              attrs: { id: _vm.id, name: _vm.name, maxlength: _vm.maxLength },
              domProps: { value: _vm.text },
              on: {
                blur: function ($event) {
                  return _vm.onInput($event)
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.text = $event.target.value
                  },
                  function ($event) {
                    return _vm.onInput1($event)
                  },
                ],
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }