var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "500px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("form", { attrs: { id: "mailFrm" } }, [
        _c("div", { staticClass: "popup_cont" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("Schedule Mail")]),
          _c("div", { staticClass: "content_box" }, [
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v("To (e-mail)")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.recptEmlAddr,
                          expression: "params.recptEmlAddr",
                        },
                      ],
                      attrs: { type: "text", id: "toEmlAddr", name: "" },
                      domProps: { value: _vm.params.recptEmlAddr },
                      on: {
                        keyup: function ($event) {
                          return _vm.checkValidationEmail("toEmlAddr")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.params,
                            "recptEmlAddr",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("From (e-mail)")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.trsrEmlAddr,
                          expression: "params.trsrEmlAddr",
                        },
                      ],
                      attrs: { type: "text", id: "fromEmlAddr", name: "" },
                      domProps: { value: _vm.params.trsrEmlAddr },
                      on: {
                        keyup: function ($event) {
                          return _vm.checkValidationEmail("fromEmlAddr")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.params,
                            "trsrEmlAddr",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("Subject")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.emlTite,
                          expression: "params.emlTite",
                        },
                      ],
                      attrs: {
                        type: "text",
                        id: "subject",
                        name: "",
                        maxlength: "20",
                      },
                      domProps: { value: _vm.params.emlTite },
                      on: {
                        keyup: function ($event) {
                          return _vm.checkValidationContents("subject")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "emlTite", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _vm._m(1),
                  _c("td", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.emlCont,
                          expression: "params.emlCont",
                        },
                      ],
                      staticStyle: { height: "180px" },
                      attrs: { id: "message", maxlength: "100" },
                      domProps: { value: _vm.params.emlCont },
                      on: {
                        keyup: function ($event) {
                          return _vm.checkValidationContents("message")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "emlCont", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "mt10 text_center" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.sendEmail.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.072")))]
            ),
            _c(
              "a",
              {
                staticClass: "button gray lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.CSBK100.024")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "120" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { staticClass: "valign_top" }, [
      _vm._v("Additional "),
      _c("br"),
      _vm._v("Message"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }