<template>
  <div class="content resize">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('menu.MENU01.000') }}</h1>
    <!--      2024.02.07 KMTC PREMIUM 개발로 KMTC ON 마크는 주석처리 후 KMTC PREMIUM으로 대체함 -->
    <span v-show="kmtcPremiumYn === 'Y'" style="position: absolute; top: 120px; left:133px;">
      <img src="../../assets/images/common/icon_premium_text_only.png"> KMTC ON PREMIUM 선복보장 서비스로 부킹합니다.</span>
    <!--    </h1><div v-if="kmtcSpotYn === 'Y'" class="button sm green" style="position: absolute; top: 107px; left:133px;">{{ $t('msg.MAIN010G030.038') }}</div>-->
    <booking-new-sr v-if="page == 'SR'" :seq-no="seqNo" :schedule-obj="scheduleObj"></booking-new-sr>
    <booking-new
      v-else
      :schedule-obj="scheduleObj"
      :mty-pkup-usag-yn="mtyPkupUsagYn"
    ></booking-new>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'

import BookingNew from '@/pages/trans/BookingNew'
import BookingNewSr from '@/pages/trans/BookingNewSr'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'

export default {
  name: 'BookingMain',
  components: {
    BookingNew,
    BookingNewSr,
    EBreadcrumbs
  },
  props: {
    scheduleObj: {
      type: Object,
      default: null
    },
    seqNo: {
      type: String,
      default: ''
    },
    mtyPkupUsagYn: {
      type: String,
      default: 'N'
    }
  },
  data () {
    return {
      page: 'BOOKING',
      kmtcPremiumYn: 'N'
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    if (this.memberDetail.bkgPlcCd === 'SHA' || this.memberDetail.bkgPlcCd === 'SZP' || this.auth.serviceLang === 'CHN') {
      this.page = 'SR'
    } else {
      this.page = 'BOOKING'
    }

    if (this.scheduleObj !== null) {
      if (this.scheduleObj.schedule.kmtcPremiumLineYn === 'Y') {
        this.kmtcPremiumYn = 'Y'
      }
    }
  },
  mounted () {
    // if (this.memberDetail.bkgPlcCd === 'SHA' || this.memberDetail.bkgPlcCd === 'SZP' || this.auth.serviceLang === 'CHN') {
    //   this.page = 'SR'
    // } else {
    //   this.page = 'BOOKING'
    // }
  },
  methods: {
    kmtcPremiumChk (chkYn) {
      if (chkYn === 'Y') {
        this.kmtcPremiumYn = 'Y'
      }
    }
  }
}

</script>
