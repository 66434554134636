<template>
  <div class="position_relative">
    <input type="text"
           ref="inputTransAddr"
           v-model="text"
           :id="id"
           :name="name"
           :maxlength="maxLength"
           @blur="onInput($event)"
    />
  </div>
</template>

<script>
export default {
  name: 'ETransAddressInput',
  props: {
    id: { type: String, required: true },
    formId: { type: String, required: false },
    isRequired: { type: Boolean, default: false, required: false },
    messageRequired: { type: String, default: '', required: false },
    maxLength: { type: Number, default: 1000, required: false },
    name: { type: String, default: '', required: false },
    value: { type: String, default: '', required: false },
    isChar: { type: Boolean, default: false, required: false }
  },
  data () {
    return {
      elemId: '',
      text: '',
      rtnText: '',
      rtnNumber: 0,
      interval: undefined,
      isOk: true
    }
  },
  watch: {
    value () {
      if (this.text !== this.$props.value) {
        this.text = this.$props.value
        this.check(this.text)
      }
    }
  },
  created () {
    this.text = this.$props.value
  },
  methods: {
    onInput (e) {
      const val = e.target.value.toUpperCase()
      this.check(val, e)
    },
    check (val, e) {
      const arr = []
      let cnt = 0
      let str
      let c

      if (this.$props.isChar) {
        for (let i = 0; i < val.length; i++) {
          str = val.charAt(i)
          c = val.charCodeAt(i)
          if ('?~`$_|{}[]!^\\"\\='.indexOf(str) < 0 && ((c >= 0 && c <= 91) || (c >= 93 && c <= 127))) {
            arr.push('' + str)
          } else {
            cnt++
          }
        }
      } else {
        for (let i = 0; i < val.length; i++) {
          c = val.charCodeAt(i)
          if (c >= 0 && c <= 127) {
            arr.push('' + val.charAt(i))
          } else {
            cnt++
          }
        }
      }

      const THIS = this
      let elem = document.querySelector('#' + this.id)

      if (this.$ekmtcCommon.isNotEmpty(this.formId)) {
        const frm = document.querySelector('#' + this.formId)
        elem = frm.querySelector('#' + this.id)
      }

      if (cnt > 0) {
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK215')) // 특수문자 또는 한글을 사용할 수 없습니다.
        if (e) {
          e.target.value = arr.join('')
        }
        this.text = arr.join('')
        this.$emit('input', arr.join(''))

        if (this.interval !== undefined) {
          clearInterval(this.interval)
          this.interval = undefined
        }

        this.interval = setInterval(() => {
          THIS.$ekmtcCommon.hideErrorTooltip(elem)
          clearInterval(this.interval)
          this.interval = undefined
        }, 3000)

        return
      }

      if (e !== undefined) {
        e.target.value = val
      }
      this.text = val
      this.$emit('input', val)

      let isOk = true
      let msg = ''

      if (this.isRequired && val === '') {
        isOk = false
        msg = this.messageRequired
      } else if (!this.$ekmtcCommon.checkTextByte(val, 100)) {
        isOk = false
        msg = this.$t('msg.CSBK100.182')
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      this.isOk = isOk
    },
    isValid () {
      if (this.isOk && this.isRequired && this.text === '') {
        this.check(this.text)
        return false
      }
      return this.isOk
    }
  }
}
</script>
