import Send from '../../schedule.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  // searchLegScheduleForm - 스케줄 > 구간화면 - GET /schedule/leg/search-scheduleform
  getLegScheduleForm () {
    return Send({
      url: '/schedule/leg/search-scheduleform',
      method: 'get'
    })
  },
  // searchLegSchedule - 구간별 스케줄 조회 - GET /schedule/term/search-longterm-schedule
  getLongTermSchedule (params) {
    return Send({
      url: '/schedule/term/search-longterm-schedule',
      method: 'get',
      params: params
    })
  },
  // searchLegScheduleDetail2 - 선택 스케줄 상세정보 - GET /schedule/leg/search-schedule-detail2
  getLegScheduleDetail (params) {
    return Send({
      url: '/schedule/leg/search-schedule-detail2',
      method: 'get',
      params: params
    })
  },
  // getSecondVessel - 2nd Vessel 조회 - GET /schedule/vessel/search-2nd-vessel
  get2ndVessel (params) {
    return Send({
      url: '/schedule/vessel/search-2nd-vessel',
      method: 'get',
      params: params
    })
  },
  // isHolidayYn - 휴일 유무 체크 - GET /schedule/leg/is-holiday
  getHolidayYn (day) {
    return Send({
      url: '/schedule/leg/is-holiday',
      method: 'get',
      params: {
        day: day
      }
    })
  },
  // insertWB242H - WEB 스케줄 조회 로그 저장 - POST /schedule/leg/insert-WB242H
  insertWB242H (param) {
    return Send({
      url: '/schedule/leg/insert-WB242H',
      method: 'post',
      params: param
    })
  },
  // scheduleRestriction - 부킹 제약사항체크 - GET /schedule/leg/schedule-restriction
  checkBkgRestriction (params) {
    return Send({
      url: '/schedule/leg/schedule-restriction',
      method: 'get',
      params: params
    })
  },
  // excelDownloadLeg - 구간별 스케줄 Excel 출력 - GET /schedule/leg/excel-downloadLeg
  excelDownloadLeg (params) {
    return Send({
      url: '/schedule/leg/excel-downloadLeg',
      method: 'get',
      params: params
    })
  },
  // excelDownload - 기간별 스케줄 Excel 출력 - GET /schedule/term/excel-download
  excelDownload (params) {
    return Send({
      url: '/schedule/term/excel-download',
      method: 'get',
      params: params,
      responseType: 'blob'
    })
  },
  // findSA271Yn - 부킹 넘어 가기전에 실적 체크(최근 7일간 운임견적 확인 유무) - GET /schedule/leg/find-SA271Yn
  findSA271Yn (params) {
    return Send({
      url: '/schedule/leg/find-SA271Yn',
      method: 'get',
      params: params
    })
  },
  // popFreAppNo - 스케줄 R/A No 조회 - GET /schedule/leg/pop-fre-app-no
  popFreAppNo (params) {
    return Send({
      url: '/schedule/leg/pop-fre-app-no',
      method: 'get',
      params: params
    })
  },
  // popFreSurcharge - 견적 운임 조회 - GET /schedule/leg/pop-fre-surcharge
  popFreSurcharge (params) {
    return Send({
      url: '/schedule/leg/pop-fre-surcharge',
      method: 'get',
      params: params
    })
  },
  // popSearchScheduleResult_bkg - 구간별 스케줄 검색 결과(Booking 전용) - GET /schedule/leg/pop-search-schedule-sesult-bkg
  popSearchScheduleBkg (params) {
    return Send({
      url: '/schedule/leg/pop-search-schedule-sesult-bkg',
      method: 'get',
      params: params
    })
  },
  getLegDetail (bkgNo) {
    return Send({
      url: '/schedule/leg/' + bkgNo + '/leg-detail',
      method: 'get'
    })
  },
  // insertCS243I - KMTC SPOT 이용시 문의사항 접수
  insertCS243I (param) {
    return Send({
      url: '/schedule/term/insert-CS243I',
      method: 'post',
      params: param
    })
  },
  // KMTC ON FAQ 다운로드
  pdfDownload (fileName) {
    return Send({
      method: 'get',
      url: '/schedule/term/static-download/' + fileName,
      responseType: 'blob'
    }).catch((err) => {
      console.log(err)
    })
  }
}
