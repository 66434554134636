var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _vm._m(0),
      _c("h1", { staticClass: "page_title" }, [_vm._v("Upload Flat File")]),
      _c("div", { staticClass: "content_box pb15" }, [
        _c("table", { staticClass: "tbl_search" }, [
          _vm._m(1),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v("M.B/L No.")]),
              _c("td", [
                _c("input", {
                  staticClass: "wid45 mrm4",
                  attrs: {
                    type: "text",
                    id: "",
                    name: "",
                    value: "KMTC",
                    disabled: "disabled",
                  },
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.masterBlNo,
                      expression: "masterBlNo",
                    },
                  ],
                  staticClass: "wid120",
                  attrs: { type: "text", id: "", name: "" },
                  domProps: { value: _vm.masterBlNo },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.masterBlNo = $event.target.value
                    },
                  },
                }),
                _c("span", { staticClass: "ml2" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button blue sh",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.fnSearch.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("검색")]
                  ),
                ]),
              ]),
              _c("td", { staticClass: "text_right" }),
            ]),
          ]),
        ]),
        _c("p", { staticClass: "txt_desc none ml10" }, [
          _vm._v("Please input the master B/L No. for your flatfile."),
        ]),
      ]),
      _c("div", { staticClass: "content_box pb15 mt10" }, [
        _c("table", { staticClass: "tbl_search" }, [
          _vm._m(2),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v("Flat File")]),
              _c("td", { staticClass: "text_left" }, [
                _c("input", {
                  staticClass: "upload_name",
                  attrs: { type: "text", id: "", name: "" },
                  domProps: {
                    value:
                      _vm.popupParams && _vm.popupParams.length > 0
                        ? _vm.popupParams[0].originalName
                        : "",
                  },
                }),
                _c(
                  "a",
                  {
                    staticClass: "button sm gray file",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.uploadPopup("flat-file-upload-pop")
                      },
                    },
                  },
                  [_vm._v("파일첨부")]
                ),
                _c("span", [
                  _c(
                    "a",
                    {
                      staticClass: "button sm",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.fnUploadFile("B")
                        },
                      },
                    },
                    [_vm._v("Excel Upload")]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("table", { staticClass: "tbl_col" }, [
          _vm._m(3),
          _vm._m(4),
          _c(
            "tbody",
            [
              _vm.mainArr.length > 0
                ? _vm._l(_vm.mainArr, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(item.mblNo))]),
                      _c("td", [
                        _c("p", {
                          domProps: { innerHTML: _vm._s(item.hblNo) },
                        }),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.reqDt))]),
                      _c("td", [_vm._v(_vm._s(item.apvStsCdNm))]),
                      _c("td", [_vm._v(_vm._s(item.rmk))]),
                    ])
                  })
                : [_vm._m(5)],
            ],
            2
          ),
        ]),
        _c("table", { staticClass: "tbl_col mt10" }, [
          _vm._m(6),
          _vm._m(7),
          _c(
            "tbody",
            _vm._l(_vm.vesselTimeArr, function (item, index) {
              return _c("tr", { key: index }, [
                _c("td", [_vm._v(_vm._s(item.wday))]),
                _c("td", [
                  _vm._v(
                    _vm._s(item.closWdayCd) +
                      " @" +
                      _vm._s(item.formattedClosDt)
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(item.sinClosWdayCd) +
                      " @" +
                      _vm._s(item.formattedClosDt)
                  ),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
      _c(
        "win-layer-pop",
        { staticClass: "win-layer-pop" },
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popupParams },
                on: {
                  close: _vm.layerClose,
                  "set-dext-file-info": _vm.arrangeUploadParam,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "location" }, [
      _c("ul", [
        _c("li", [
          _c("a", { staticClass: "home", attrs: { href: "#" } }, [
            _vm._v("home"),
          ]),
        ]),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [_vm._v("진행중인 업무")]),
        ]),
        _c("li", [_vm._v("진행중인 업무")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "100px" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "110px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "100px" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "30%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Master B/L")]),
        _c("th", [_vm._v("House B/L")]),
        _c("th", [_vm._v("Reg. Date")]),
        _c("th", [_vm._v("Status")]),
        _c("th", [_vm._v("Remark")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "5" } }, [_vm._v("No Data.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "33%" } }),
      _c("col", { attrs: { width: "33%" } }),
      _c("col", { attrs: { width: "34%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Vessel ETA")]),
        _c("th", { attrs: { colspan: "2" } }, [
          _vm._v("Free time flat file submission"),
        ]),
      ]),
      _c("tr", [
        _c("th", [_vm._v("(Based on revised schedule)")]),
        _c("th", [_vm._v("All Port(exluding SIN)")]),
        _c("th", [_vm._v("Inbound shipment from SIN")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }