<template>
  <div class="container_ekmtc" style="background-color: rgba(165,223,236,0.25)">
    <!--  <div class="container_ekmtc" :style="{ backgroundImage: 'url(/img/kmtc2023_001.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}">-->
    <div class="content resize">
      <h1 class="page_title">새로운 디지털 세일즈, {{ $t('msg.MAIN010G030.038') }}으로 GO!</h1>
      <!-- content : 화면 리사이징 되는 화면 resize  -->
      <div class="content_box mt10">
        <h1 class="page_title">&lt;KMTC ON 이란?&gt;</h1>
        <br>
        ☞ 고려해운이 야심차게 준비한 신규 서비스, 디지털 플랫폼으로 선복보장 서비스 구간 운임을 한눈에 조회하고, 이용하실 수 있습니다.<br>
        <br>
        <a class="button lg" href="#" @click.prevent="pdfDownload('KMTC_ON_FAQ_231019.pdf')">KMTC ON FAQ 다운로드 (CLICK)</a><!-- CLP form Download //-->
        <h1 class="page_title"><img src="../../assets/images/main/small_ship.png"> KMTC ON, 이렇게 이용해보세요 <img src="../../assets/images/main/small_ship.png"></h1>
        <br>
        <div style="font-weight: bold">STEP 1. 원하시는 선복보장 스케줄을 검색해보세요.</div>
        KMTC ON 서비스 구간은 현재 일부 구간만 가능합니다. 꾸준히 지역을 확장하여 제공하겠습니다.<br>
        <br>
        <div style="font-weight: bold">STEP 2. 조회한 구간의 운임을 한눈에 확인해 보세요.</div>
        MARKET 및 운임 데이터 분석을 통해 KMTC ON 만을 위한 합리적인 운임을 제공합니다.<br>
        <br>
        <div style="font-weight: bold">STEP 3. 선복보장 서비스.</div>
        KMTC ON을 위한 전용 SPACE를 배정하여 SPACE 부족으로 인한 변경 없이 스케줄을 보장합니다.<br>
        <br>
        <div style="font-weight: bold">STEP 4. 부킹 이후 업무는 동일하게.</div>
        부킹 이후 업무는 기존과 동일하게 e-KMTC 사이트에서 이용 가능하십니다.<br>
      </div>
      <br>
      <div class="content_box">
        <!-- content_box : 탭있을 경우 beforetab  추가 -->
        <div class="popup_dim" id="schKS_popup">
          <component
            v-if="customComponentKS"
            :is="customComponentKS"
            @close="closePopup"
            :parent-info="parentInfo"
          />
        </div>
        <form id="frm2">
          <div class="border_bottom_gray_box">
            <!-- border_bottom_gray_box -->
            <table class="tbl_search">
              <colgroup>
                <col width="80px"><col width="130px"><col width="130px"><col width="250px"><col width="80px"><col width="250px"><col width="300px"><col>
              </colgroup>
              <tbody>
                <!-- 1st 구간 -->
                <tr>
                  <th>{{ $t('msg.VOSD100.002') }}</th><!-- 출발 -->
                  <td colspan="2" class="position_relative">
                    <select v-model="polCtrPlcEnm" id="polCtrPlcEnm" @change="chgPolCtrPlcEnm" class="col_10">
                      <option value="">{{ this.$t("msg.CSBK100.050") }}</option><!-- 직접입력 -->
                      <option v-for="vo in polCtrPlcInfoList" :key="vo.seq" :value="vo.seq">{{ vo.polCtrPlcEnm }}</option>
                    </select>
                  </td>
                  <td>
                    <e-auto-complete-place id="polKS" @change="changePol" :ctr-cd="pol.ctrCd" :plc-cd="pol.plcCd" :is-char="true" :is-char-alert="true" :disabled="chkChgPol === 'Y'" />
                  </td>
                  <th><span class="ml20">{{ $t('msg.VOSD100.003') }}</span></th><!-- 도착 -->
                  <td class="position_relative">
                    <select v-model="podCtrPlcEnm" id="podCtrPlcEnm" @change="chgPodCtrPlcEnm" class="col_10">
                      <option value="">{{ this.$t("msg.CSBK100.050") }}</option><!-- 직접입력 -->
                      <option v-for="vo in podCtrPlcInfoList" :key="vo.seq" :value="vo.seq">{{ vo.podCtrPlcEnm }}</option>
                    </select>
                  </td>
                  <td>
                    <e-auto-complete-place id="podKS" @change="changePod" :ctr-cd="pod.ctrCd" :plc-cd="pod.plcCd" :is-char="true" :is-char-alert="true" :disabled="chkChgPod === 'Y'" />
                  </td>
                </tr>
                <!-- 2nd 구간 -->
                <tr v-show="section2nd ==='Y'">
                  <th>{{ $t('msg.VOSD100.002') }}</th><!-- 출발 -->
                  <td>
                    <e-auto-complete-place id="polKS2" @change="changePol2" :ctr-cd="pol2.ctrCd" :plc-cd="pol2.plcCd" />
                  </td>
                  <th><span class="ml20">{{ $t('msg.VOSD100.003') }}</span></th><!-- 도착 -->
                  <td>
                    <e-auto-complete-place id="podKS2" @change="changePod2" :ctr-cd="pod2.ctrCd" :plc-cd="pod2.plcCd" />
                  </td>
                  <td class="pr0 text-right">
                    <span><button class="tbl_icon minus" @click.prevent="close2ndSection">minus</button></span>
                  </td>
                </tr>
                <!-- 3rd 구간 -->
                <tr v-show="section3rd ==='Y'">
                  <th>{{ $t('msg.VOSD100.002') }}</th><!-- 출발 -->
                  <td>
                    <e-auto-complete-place id="polKS3" @change="changePol3" :ctr-cd="pol3.ctrCd" :plc-cd="pol3.plcCd" />
                  </td>
                  <th><span class="ml20">{{ $t('msg.VOSD100.003') }}</span></th><!-- 도착 -->
                  <td>
                    <e-auto-complete-place id="podKS3" @change="changePod3" :ctr-cd="pod3.ctrCd" :plc-cd="pod3.plcCd" />
                  </td>
                  <td class="pr0 text-right">
                    <span><button class="tbl_icon minus" @click.prevent="close3rdSestion">minus</button></span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('msg.VOSD100.008') }}</th><!-- 조회년월 > 조회기간 변경 -->
                  <td>
                    <span>
                      <div class="input_calendar mid">
                        <e-date-picker @input="chanageSearchDt" v-model="yyyymmdd" />
                      </div>
                    </span>
                  </td>
                  <!--                      <div class="input_calendar">-->
                  <!--                        <e-month-picker2 v-model="yyyymm" />-->
                  <!--                      </div>-->
                  <td colspan="5">
                    <span>
                      <span class="wid20 d_inline_block text_center">~</span>
                      <select name="" id="" class="wid90" v-model="weeks" @change="changeWeeks">
                        <option value="1">1 Week</option>
                        <option value="2">2 Week</option>
                        <option value="4">1 Month</option>
                        <option value="8">2 Month</option>
                        <option value="12">3 Month</option>
                      </select>
                    </span>
                    <span class="ml40">
                      <input type="radio" id="trad21" name="trad2" v-model="paramBound" value="O" checked="checked" />
                      <label for="trad21" class="mr10"><span></span>{{ $t('msg.VOSD100_M3.008') }}</label><!-- 수출 & 출항일 -->
                      <input type="radio" id="trad22" name="trad2" v-model="paramBound" value="I" />
                      <label for="trad22" class="mr10"><span></span>{{ $t('msg.VOSD100_M3.007') }}</label><!-- 수입 & 입항일 -->
                    </span>
                    <span class="pr0" style="float:right">
                      <input type="checkbox" id="bk3" name="bk" v-model="checkBkg" true-value="Y" false-value="N" />
                      <label for="bk3"><span></span>{{ $t('msg.VOSD100_M5.013') }} (마감 선박 제외하기)<!-- 부킹가능한 선박 --></label>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div><!-- border_bottom_gray_box // -->
          <div class="border_bottom_gray_box border_bottom_none">
            <!-- border_bottom_gray_box -->
            <p class="tit_label ml10" v-if="$ekmtcCommon.isNotEmpty(userCtrCd)">
              <!--            <input type="checkbox" id="myschtit2" name="myschtit2" v-model="clickMySchedule" true-value="Y" false-value="N" />-->
              <!--            <label for="myschtit2"><span></span>{{ $t('msg.VOSD100_M5.014') }}&lt;!&ndash; 나의 스케줄 &ndash;&gt;</label>-->
            </p>
            <div class="ml10" style="display:block;" v-show="clickMySchedule === 'Y'">
              <ul class="my_sche">
                <li v-for="(list,index) in myScheduleList" :key="index">
                  <input
                    type="radio"
                    :id="list.schId + 'LT'"
                    :value="list.schId"
                    :checked="list.schId === schId"
                    name="mysch2"
                    @click="selectMySchedule(list)"
                  />
                  <label :for="list.schId + 'LT'"><span></span>{{ list.polNm2 }} - {{ list.podNm2 }}</label>
                  <span class="d_inline_block valign_top ml10 mt2"><button class="tbl_icon delete" @click="fnDeleteMySchedule(list.schId)">delete</button></span>
                </li>
              </ul>
            </div>
          </div><!-- border_bottom_gray_box // -->
          <div class="position_relative">
            <span class="position_absolute" style="right:0; bottom:0;"><a class="button blue sh" href="#" @click.prevent="getLongTermSchdule">{{ $t('msg.VOSD100_M5.107') }}</a></span><!-- 검색 -->
          </div>
        </form>
      </div><!-- content_box // -->
      <div class="content_box mt10" v-show="listSchedule4List.length !== 0">
        <!-- content_box  -->
        <div class="wrap_schedule">
          <!-- wrap_schedule -->
          <div class="schedule_top">
            <!-- schedule_top -->
            <div class="sail_area">
              <p class="port" v-for="(list,i) in startPlcNameSel" :key="i + 'PT'">
                <span><a href="#" @click.prevent="selectPort(i)" :class="'port ' + (idxPort !== i ? 'color_lightgray' : '')">{{ startPlcNameSel[i] }}</a></span>
                <span><a href="#" @click.prevent="selectPort(i)" :class="'port ' + (idxPort !== i ? 'color_lightgray' : '')">{{ destPlcNameSel[i] }}</a></span>
                <!--                <span class="icon" v-if="$ekmtcCommon.isNotEmpty(userCtrCd)">-->
                <!--                  <div v-if="$ekmtcCommon.isEmpty(checkMySchList[i].myScheduleOrNot) || checkMySchList[i].myScheduleOrNot === 'N'">-->
                <!--                    <button class="tbl_icon myschedule" @click="fnAddMySchedule(i)">&lt;!&ndash; 나의스케줄등록 &ndash;&gt;</button>-->
                <!--                  </div>-->
                <!--                  <div v-else>-->
                <!--                    <button class="tbl_icon myschedule on" @click="deleteMyScheduleStar(checkMySchList[i].schId)">&lt;!&ndash; 나의스케줄등록 &ndash;&gt;</button>-->
                <!--                  </div>-->
                <!--                </span>-->
              </p>
              <!--              <ul v-for="(list,index) in voyCntVoList" :key="index">-->
              <!--                <div v-show="idxPort === index">-->
              <!--                  <li><span>{{ $t('msg.VOSD100_M5.016') }}</span> <span class="num">{{ list.monCnt }}</span></li>-->
              <!--                  <li><span>{{ $t('msg.VOSD100_M5.017') }}</span> <span class="num">{{ list.tueCnt }}</span></li>-->
              <!--                  <li><span>{{ $t('msg.VOSD100_M5.018') }}</span> <span class="num">{{ list.wedCnt }}</span></li>-->
              <!--                  <li><span>{{ $t('msg.VOSD100_M5.019') }}</span> <span class="num">{{ list.thuCnt }}</span></li>-->
              <!--                  <li><span>{{ $t('msg.VOSD100_M5.020') }}</span> <span class="num">{{ list.friCnt }}</span></li>-->
              <!--                  <li><span>{{ $t('msg.VOSD100_M5.021') }}</span> <span class="num">{{ list.satCnt }}</span></li>-->
              <!--                  <li><span>{{ $t('msg.VOSD100_M5.022') }}</span> <span class="num">{{ list.sunCnt }}</span></li>-->
              <!--                  <li class="week"><span class="week">{{ $t('msg.VOSD100_M5.031') }}</span> <span class="num">{{ list.voyCntSum }}</span> <span>{{ $t('msg.VOSD100_M5.023') }}</span></li>-->
              <!--                </div>-->
              <!--              </ul>-->
              <!--            <div class="btn_area">-->
              <!--              <span><a class="button sm" href="#" @click.prevent="openPopup('EmailSchedulePop', '')">{{ $t('msg.VOSD100.040') }}</a></span>&lt;!&ndash; 스케줄 구독 &ndash;&gt;-->
              <!--              <span><a class="button sm" href="#" @click.prevent="openPopup('EmailPop', '')">email</a></span>-->
              <!--              <span><a class="button sm" href="#" @click.prevent="excelDownload()">Download</a></span>-->
              <!--            </div>-->
            </div>
            <div class="date_area">
              <!-- date_area -->
              <div class="remark">
                <span class="finish">{{ $t('msg.CSBL100_M1.017') }}</span><!-- 마감 -->
                <span class="possible">{{ $t('msg.CSBL100_M1.013') }}</span><!-- 가능 -->
                <span class="after">{{ $t('msg.VOSD100_M5.024') }}</span><!-- 60일 이후 -->
              </div>
              <div class="date">
                <span><a href="#" @click.prevent="fnChangeCalendar(prevMonth)" class="prev">prev</a></span>
                <span class="year">{{ currentYear }}</span>
                <span class="month">{{ currentMonth }}</span>
                <span><a href="#" @click.prevent="fnChangeCalendar(nextMonth)" class="next">next</a></span>
              </div>
              <!-- <div class="memu">
                <a href="#" class="month on"></a>
                <a href="#" class="list off"></a>
              </div> -->
            </div><!-- date_area // -->
          </div><!-- schedule_top // -->
          <div class="sc_list_type">
            <!-- 리스트 타입 -->
            <table class="tbl_col">
              <colgroup>
                <col style="width:7%">
                <col style="width:15%">
                <col style="width:auto">
                <col style="width:24%" v-show="bound === 'O'">
                <col style="width:34%" v-show="bound === 'I'">
                <col style="width:10%">
                <col style="width:12%" v-show="bound === 'O'">
                <col style="width:8%" v-show="bound === 'O'">
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">{{ $t('msg.CMBA100.00154') }}</th><!-- 구분 -->
                  <th scope="col">{{ $t('msg.VOSD100_M4.005') }}</th><!-- 선명/항차 -->
                  <th scope="col">{{ $t('msg.VOSD100.002') }}</th><!-- 출발 -->
                  <th scope="col">{{ $t('msg.VOSD100.003') }}</th><!-- 도착 -->
                  <th scope="col">{{ $t('msg.CSBL100_M1.015') }}</th><!-- T/T -->
                  <th scope="col" v-show="bound === 'O'">{{ $t('msg.VOSD100_M4.009') }}/{{ $t('msg.VOSD100_M4.010') }}</th><!-- 서류마감/반입마감 -->
                  <th scope="col" v-show="bound === 'O'">Booking</th>
                </tr>
              </thead>
              <!-- <tbody v-for="(schedule,schIdx) in pagingList" :key="schIdx"> -->
              <tbody v-for="(schedule,schIdx) in listSchedule4List" :key="schIdx">
                <!-- T/S Start -->
                <tr v-if="schedule.ts === 'Y' && schIdx >= startIdx && schIdx < endIdx">
                  <td rowspan="10">T/S</td>
                  <td class="text_left" rowspan="2">
                    {{ schedule.vslNm1 }} {{ schedule.voyNo }}<br>(Route : {{ schedule.rteCd }})
                  </td>
                  <td>
                    {{ schedule.polNm }}
                  </td>
                  <td>
                    {{ schedule.pol2Nm }}
                  </td>
                  <td rowspan="2">
                    <ul>
                      <li>{{ schedule.transitTime1 }}</li>
                    </ul>
                  </td>
                  <td :rowspan="schedule.tsDegree * 2" v-show="bound === 'O'">
                    <ul>
                      <li>{{ changeDateTime2Display(schedule.bkgDocCls, lang) }}</li>
                      <li>{{ changeDateTime2Display(schedule.bkgCgoCls, lang) }}</li>
                    </ul>
                  </td>
                  <td :rowspan="schedule.tsDegree * 2" class="color_red" v-show="bound === 'O'">
                    <div v-if="schedule.bkgClose === 'Y'" class="button sm4 red1">
                      {{ $t('msg.CSBL100_M1.032') }}<!-- SOLD OUT -->
                    </div>
                    <div v-else-if="schedule.bkgClose !== 'M'">
                      <ul>
                        <li><a class="button sm3 green" @click="openPopup('FreSurchargeKmtcSpotPop', schedule)">{{ $t('msg.MAIN010G030.038') }}<br>${{ schedule.kmtcSpotSurcharge[0].rate20 }} / ${{ schedule.kmtcSpotSurcharge[0].rate40 }}</a></li>  <!--운임확인-->
                        <!--<li class="mt5"><a class="button sm" href="#none">{{ $t('msg.VOSD100_M5.026') }} 운임문의 </a></li>-->
                        <li class="mt5"><a class="button sm blue" @click="bookingBooking4List(schedule)">Booking</a></li>
                      </ul>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                </tr>
                <tr v-if="schedule.ts === 'Y' && schIdx >= startIdx && schIdx < endIdx">
                  <td class="bor-left">
                    <ul>
                      <li>{{ $t('msg.VOSD100_M2.003') }}<!-- 입항 --> : {{ changeDate2Display(schedule.polEtb, schedule.polEtbTm, lang) }}</li>
                      <li>{{ $t('msg.VOSD100_M2.004') }}<!-- 출항 --> : {{ changeDate2Display(schedule.etd,schedule.etdTm, lang) }}</li>
                      <li>{{ fnTrmlText(schedule.otrmlCd, schedule.otrmlNm) }}</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>{{ $t('msg.VOSD100_M2.003') }}<!-- 입항 --> : {{ changeDate2Display(schedule.podEtd1, schedule.etaTm1, lang) }}</li>
                      <!--<li>출항 : 2021.06.24 13:30</li>-->
                      <li>{{ fnTrmlText(schedule.podTrml1, schedule.podTrml1Nm) }}</li>
                    </ul>
                  </td>
                </tr>
                <!-- 2nd line -->
                <tr v-if="schedule.ts === 'Y' && schIdx >= startIdx && schIdx < endIdx">
                  <td class="text_left bor-left" rowspan="2">
                    {{ schedule.vslNm2 }} {{ schedule.voyNo2 }}<br>(Route : {{ schedule.rteCd2 }})
                    <div class="mt5" v-show="schedule.tsDegree === '2' && schedule.vessel2ndButton === 'Y'">
                      <a class="button sm" @click="change2ndVessel4List(schedule, schIdx)">{{ $t('msg.VOSD100_M5.027') }} <span class="btn_icon icoup"></span></a>
                    </div>
                  </td>
                  <td>
                    {{ schedule.pod1Nm }}
                  </td>
                  <td>
                    {{ schedule.pod2Nm }}
                  </td>
                  <td rowspan="2">
                    <ul>
                      <li>{{ schedule.transitTime2 }}</li>
                    </ul>
                  </td>
                </tr>
                <tr v-if="schedule.ts === 'Y' && schIdx >= startIdx && schIdx < endIdx">
                  <td class="bor-left">
                    <ul>
                      <!--<li>입항 : 2021.06.24 04:30</li>-->
                      <li>{{ $t('msg.VOSD100_M2.004') }}<!-- 출항 --> : {{ changeDate2Display(schedule.polEtd2, schedule.etdTm2, lang) }}</li>
                      <li>{{ fnTrmlText(schedule.polTrml2, schedule.polTrml2Nm) }}</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>{{ $t('msg.VOSD100_M2.003') }}<!-- 입항 --> : {{ changeDate2Display(schedule.podEtd2, schedule.etaTm2, lang) }}</li>
                      <!--<li>출항 : 2021.06.24 13:30</li>-->
                      <li>{{ fnTrmlText(schedule.podTrml2, schedule.podTrml2Nm) }}</li>
                    </ul>
                  </td>
                </tr>
                <!-- 3rd line -->
                <tr v-if="schedule.ts === 'Y' && schedule.tsDegree >= 3 && schIdx >= startIdx && schIdx < endIdx">
                  <td class="text_left bor-left" rowspan="2">
                    {{ schedule.vslNm3 }} {{ schedule.voyNo3 }}<br>(Route : {{ schedule.rteCd3 }})
                  </td>
                  <td>
                    {{ schedule.pod2Nm }}
                  </td>
                  <td>
                    {{ schedule.pod3Nm }}
                  </td>
                  <td rowspan="2">
                    <ul>
                      <li>{{ schedule.transitTime3 }}</li>
                    </ul>
                  </td>
                </tr>
                <tr v-if="schedule.ts === 'Y' && schedule.tsDegree >= 3 && schIdx >= startIdx && schIdx < endIdx">
                  <td class="bor-left">
                    <ul>
                      <!--<li>입항 : 2021.06.24 04:30</li>-->
                      <li>{{ $t('msg.VOSD100_M2.004') }}<!-- 출항 --> : {{ changeDate2Display(schedule.polEtd3, schedule.etdTm3, lang) }}</li>
                      <li>{{ fnTrmlText(schedule.polTrml3, schedule.polTrml3Nm) }}</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>{{ $t('msg.VOSD100_M2.003') }}<!-- 입항 --> : {{ changeDate2Display(schedule.podEtd3, schedule.etaTm3, lang) }}</li>
                      <!--<li>출항 : 2021.06.24 13:30</li>-->
                      <li>{{ fnTrmlText(schedule.podTrml3, schedule.podTrml3Nm) }}</li>
                    </ul>
                  </td>
                </tr>
                <!-- 4th line -->
                <tr v-if="schedule.ts === 'Y' && schedule.tsDegree >= 4 && schIdx >= startIdx && schIdx < endIdx">
                  <td class="text_left bor-left" rowspan="2">
                    {{ schedule.vslNm4 }} {{ schedule.voyNo4 }}<br>(Route : {{ schedule.rteCd4 }})
                  </td>
                  <td>
                    {{ schedule.pod3Nm }}
                  </td>
                  <td>
                    {{ schedule.pod4Nm }}
                  </td>
                  <td rowspan="2">
                    <ul>
                      <li>{{ schedule.transitTime4 }}</li>
                    </ul>
                  </td>
                </tr>
                <tr v-if="schedule.ts === 'Y' && schedule.tsDegree >= 4 && schIdx >= startIdx && schIdx < endIdx">
                  <td class="bor-left">
                    <ul>
                      <!--<li>입항 : 2021.06.24 04:30</li>-->
                      <li>{{ $t('msg.VOSD100_M2.004') }}<!-- 출항 --> : {{ changeDate2Display(schedule.polEtd4, schedule.etdTm4, lang) }}</li>
                      <li>{{ fnTrmlText(schedule.polTrml4, schedule.polTrml4Nm) }}</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>{{ $t('msg.VOSD100_M2.003') }}<!-- 입항 --> : {{ changeDate2Display(schedule.podEtd4, schedule.etaTm4, lang) }}</li>
                      <!--<li>출항 : 2021.06.24 13:30</li>-->
                      <li>{{ fnTrmlText(schedule.podTrml4, schedule.podTrml4Nm) }}</li>
                    </ul>
                  </td>
                </tr>
                <!-- T/S Finish -->
                <!-- 2nd Vessel Start -->
                <tr :id="'vessel2nd' + schIdx" v-show="show2ndVessel === 'Y' && schIdx === listIndexNo">
                  <td class="bor-left pd0" colspan="6">
                    <div class="inner_table">
                      <div class="port_info">
                        <p class="port t2">
                          <span>{{ schedule.polNm }}</span>
                          <span>{{ schedule.podNm }}</span>
                        </p>
                        <p class="txt_desc none">{{ $t('msg.VOSD100_M5.028') }}<!-- 변경을 원하는 선박을 선택해주세요. --></p>
                        <div class="btn_port_cancel">
                          <a class="button sm gray" @click="cancel2ndVessel4List(schIdx)">{{ $t('msg.VOSD100_M5.029') }}<!-- 선박 변경 취소 --></a>
                        </div>
                      </div>
                      <table class="tbl_col t2 tac mt10">
                        <colgroup>
                          <col width="24%">
                          <col width="24%">
                          <col width="24%">
                          <col width="18%">
                          <col width="10%">
                        </colgroup>
                        <thead>
                          <tr>
                            <th>{{ $t('menu.MENU09.060') }}</th>
                            <th>ETD</th>
                            <th>ETA</th>
                            <th>{{ $t('msg.CSBL100_M1.015') }}</th>
                            <th>{{ $t('msg.CSBL200.075') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(list,idx) in vessel2ndList" :key="idx">
                            <td>{{ list.vslNm }} {{ list.voyNo }}</td>
                            <td>{{ list.etdStrDT }}</td>
                            <td>{{ list.etaStrDT }}</td>
                            <td>{{ list.transitTime }}</td>
                            <td><a class="button gray sm" @click="select2ndVessel4List(list, schIdx)">{{ $t('msg.CSBL200.075') }}</a></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
                <!-- 2nd Vessel Finish -->
                <!-- Direct Start -->
                <tr v-if="schedule.ts !== 'Y' && schIdx >= startIdx && schIdx < endIdx">
                  <td rowspan="2">Direct</td>
                  <td rowspan="2" class="text_left">{{ schedule.vslNm }} {{ schedule.voyNo }}<br>(Route : {{ schedule.rteCd }})</td>
                  <td>
                    {{ schedule.polNm }}
                  </td>
                  <td>
                    {{ schedule.podNm }}
                  </td>
                  <td rowspan="2">
                    <ul>
                      <li>{{ schedule.transitTime }}</li>
                    </ul>
                  </td>
                  <td rowspan="2" v-show="bound === 'O'">
                    <ul>
                      <li>{{ changeDateTime2Display(schedule.bkgDocCls, lang) }}</li>
                      <li>{{ changeDateTime2Display(schedule.bkgCgoCls, lang) }}</li>
                    </ul>
                  </td>
                  <td rowspan="2" class="color_red" v-show="bound === 'O'">
                    <div v-if="schedule.bkgClose === 'Y'" class="button sm4 red1">
                      {{ $t('msg.CSBL100_M1.032') }}<!-- SOLD OUT -->
                    </div>
                    <div v-else-if="schedule.bkgClose !== 'M'">
                      <ul>
                        <li><a class="button sm3 green" @click="openPopup('FreSurchargeKmtcSpotPop', schedule)">{{ $t('msg.MAIN010G030.038') }}<br>${{ schedule.kmtcSpotSurcharge[0].rate20 }} / ${{ schedule.kmtcSpotSurcharge[0].rate40 }}</a></li>  <!--운임확인-->
                        <!--<li class="mt5"><a class="button sm" href="#none">{{ $t('msg.VOSD100_M5.026') }} 운임문의 </a></li>-->
                        <li class="mt5"><a class="button sm blue" @click="bookingBooking4List(schedule)">Booking</a></li>
                      </ul>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                </tr>
                <tr v-if="schedule.ts !== 'Y' && schIdx >= startIdx && schIdx < endIdx">
                  <td class="bor-left">
                    <ul>
                      <li>{{ $t('msg.VOSD100_M2.003') }}<!-- 입항 --> : {{ changeDate2Display(schedule.polEtb, schedule.polEtbTm, lang) }}</li>
                      <li>{{ $t('msg.VOSD100_M2.004') }}<!-- 출항 --> : {{ changeDate2Display(schedule.etd, schedule.etdTm, lang) }}</li>
                      <li>{{ schedule.otrmlCd }} ({{ schedule.otrmlNm }})</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>{{ $t('msg.VOSD100_M2.003') }}<!-- 입항 --> : {{ changeDate2Display(schedule.eta, schedule.etaTm, lang) }}</li>
                      <li>{{ schedule.itrmlCd }} ({{ schedule.itrmlNm }})</li>
                    </ul>
                  </td>
                </tr>
              <!-- Direct Finish -->
              </tbody>
            </table>
            <!-- paging -->
            <div
              v-if="pagingList.length > 0"
              class="paging"
            >
              <a
                class="btn prev"
                @click.prevent="getList4Schedule(curPage-1)"
              >
                Prev
              </a>
              <a
                v-for="idx in pagingList"
                :key="idx"
                :class="[idx === curPage ? 'on' : '']"
                href="#"
                @click.prevent="getList4Schedule(idx)"
              >
                {{ idx }}
              </a>
              <a
                class="btn next"
                @click.prevent="getList4Schedule(curPage+1)"
              >
                Next
              </a>
            </div>
            <!-- paging // -->
          </div><!-- 리스트 타입 // -->
        </div><!-- wrap_schedule // -->
      </div><!-- content_box // -->
      <div class="content_box mt10">
        <h1 class="page_title">{{ $t('msg.MAIN010G030.038') }}, 무엇이든 물어보세요.</h1>
        <p class="tit_label ml10">
          <input type="checkbox" id="chkRateApp" name="chkRateApp" v-model="clickRateApp" true-value="Y" false-value="N" />
          <label for="chkRateApp">운임문의</label>
        </p>
        <span style="font-weight: bold">&lt;KMTC ON 운임 문의는 한국발 수출 일반화물만 가능합니다&gt;</span>
        <table class="tbl_search">
          <colgroup>
            <col width="50px">
            <col width="200px">
            <col width="50px">
            <col width="200px">
            <col width="100px">
            <col width="200px">
            <col width="50px">
            <col width="200px">
          </colgroup>
          <tbody>
            <tr>
              <th>POL : </th>
              <td>
                <e-auto-complete-place
                  id="polChk"
                  @change="changePolChk"
                  :ctr-cd="polChk.ctrCd"
                  :plc-cd="polChk.plcCd"
                  :is-char="true"
                  :is-char-alert="true"
                  :disabled="clickRateApp === 'N'"
                />
              </td>
              <th>POD : </th>
              <td>
                <e-auto-complete-place
                  id="podChk"
                  @change="changePodChk"
                  :ctr-cd="podChk.ctrCd"
                  :plc-cd="podChk.plcCd"
                  :is-char="true"
                  :is-char-alert="true"
                  :disabled="clickRateApp === 'N'"
                />
              </td>
              <th>희망운임  20ft : </th>
              <td>
                <span class="input_box">
                  <input type="text" id="ft20" v-model="ft20" class="wid200" :disabled="clickRateApp === 'N'">
                </span>
              </td>
              <th>40ft : </th>
              <td>
                <span class="input_box">
                  <input type="text" id="ft40" v-model="ft40" class="wid200" :disabled="clickRateApp === 'N'">
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <textarea v-model="reqVocCont" id="reqVocCont" style="height:200px;" maxlength="4000"></textarea>
        <div class="flex_box mt10">
          <a class="button blue lg ml_auto mr5" @click.prevent="insertCS243I">문의사항 접수</a>
        </div>
      </div>
    </div><!-- content // -->
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import schedules from '@/api/rest/schedule/schedule'
import commons from '@/api/services/commons'
import mySchedules from '@/api/rest/schedule/mySchedule'
import scheduleLT from '@/api/rest/schedule/scheduleLongTerm'
import cp from '@/api/rest/cp/cp'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import EmailSchedulePop from '@/pages/schedule/popup/EmailSchedulePop'/* 스케줄 구독 팝업 */
import EmailPop from '@/pages/schedule/popup/EmailPop'/* 스케줄 메일 팝업 */
import FreSurchargeKmtcSpotPop from '@/pages/schedule/popup/FreSurchargeKmtcSpotPop' /* KMTC SPOT 운임확인 팝업 */
import EBreadcrumbs from '@/components/common/EBreadcrumbs.vue'
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import intergis from '@/api/rest/common/intergis'
import EDatePicker from '@/components/common/EDatePickerCustom.vue'

export default {
  name: 'ScheduleKmtcSpot',
  computed: {
    ...rootComputed
  },
  components: {
    EDatePicker,
    EBreadcrumbs,
    EAutoCompletePlace,
    EmailSchedulePop,
    EmailPop,
    FreSurchargeKmtcSpotPop,
    'e-month-picker2': () => import('@/components/common/EMonthPicker')
  },
  data: function () {
    return {
      lang: 'KOR',
      reqVocCont: this.$t('msg.MAIN010G030.039'),
      pol: {
        ctrCd: '',
        plcCd: ''
      },
      pod: {
        ctrCd: '',
        plcCd: ''
      },
      pol2: {
        ctrCd: '',
        plcCd: ''
      },
      pod2: {
        ctrCd: '',
        plcCd: ''
      },
      pol3: {
        ctrCd: '',
        plcCd: ''
      },
      pod3: {
        ctrCd: '',
        plcCd: ''
      },
      polChk: {
        ctrCd: '',
        plcCd: ''
      },
      podChk: {
        ctrCd: '',
        plcCd: ''
      },
      yyyymm: '',
      yyyymmdd: '',
      searchYear: '',
      searchMonth: '',
      searchDay: '',
      currentYear: 0,
      currentMonth: 0,
      currentDay: 0,
      currentDt: 0,
      prevMonth: '',
      nextMonth: '',
      weeks: 4,
      scheduleForm: {
        startCtrCd: '',
        startPlcCd: '',
        startPlcName: '',
        destCtrCd: '',
        destPlcCd: '',
        destPlcName: '',
        startPolName: '',
        destPodName: '',
        weeks: 4
      },
      clickMySchedule: 'N',
      myScheduleList: [],
      schId: 0,
      bound: 'O',
      paramBound: 'O',
      voyCntVoList: [],
      startPlcNameSel: [],
      destPlcNameSel: [],
      scheduleData: [],
      listSchedule: [],
      listScheduleBkg: [],
      listSchedule4List: [],
      pagingList: [],
      startIdx: 0,
      endIdx: 10,
      curPage: 1,
      finalPage: 0,
      pageSize: 10,
      checkBkg: 'N',
      show2ndVessel: 'N',
      cpYn: 'N',
      cpS101: {},
      cpS201: {},
      cpS301: {},
      vessel2ndList: [],
      idxPort: 0,
      section2nd: 'N',
      section3rd: 'N',
      customComponentKS: null,
      parentInfo: {},
      popupParams: {
        EmailSchedulePop: {
          polCtrCd: '',
          polPlcCd: '',
          polPlcNm: '',
          podCtrCd: '',
          podPlcCd: '',
          podPlcNm: '',
          eiCatCd: ''
        },
        EmailPop: {
          porCtrCd: '',
          porPlcCd: ''
        },
        FreSurchargeKmtcSpotPop: {
          porCtrCd: '',
          porPlcCd: '',
          dlyCtrCd: '',
          dlyPlcCd: '',
          polNm: '',
          podNm: '',
          startDt: '',
          endDt: '',
          etd: '',
          frtAppNo: '',
          vslCd: '',
          voyNo: '',
          vslNm: '',
          rteCd: '',
          eiCatCd: '',
          frtResult: '',
          reqRno: '',
          bkgClose: '',
          raTsParam: '',
          promotionChk: 'N',
          scenarioCd: '',
          promoNo: '',
          hotDealYn: 'N',
          refFrtAppNo: '',
          rmPopOpenFlag: 'Y',
          kmtcSpotYn: 'Y'
        }
      },
      tempData: '',
      searchYN: 'N',
      stCtrcd: '',
      stPlcCd: '',
      deCtrCd: '',
      dePlcCd: '',
      checkMySchList: [],
      etaBookingMsg: '',
      detailResp2: {
        tsDegree: '',
        vslNm1: '',
        voyNo: '',
        rteCd: '',
        polNm: '',
        podNm: '',
        pod1Nm: '',
        transitTime: '',
        transitTime1: '',
        polEtbDT: '',
        etdDT: '',
        polTml: '',
        polTmlCd: '',
        etaDT: '',
        podTml: '',
        vslNm2: '', // 2nd
        voyNo2: '',
        rteCd2: '',
        pod2Nm: '',
        transitTime2: '',
        polEtbDT2: '',
        polTml2: '',
        etaDT2: '',
        podTml2: '',
        vslNm3: '', // 3rd
        voyNo3: '',
        rteCd3: '',
        pod3Nm: '',
        transitTime3: '',
        polEtbDT3: '',
        polTml3: '',
        etaDT3: '',
        podTml3: '',
        vslNm4: '', // 4th
        voyNo4: '',
        rteCd4: '',
        pod4Nm: '',
        transitTime4: '',
        polEtbDT4: '',
        polTml4: '',
        etaDT4: '',
        podTml4: '',
        bkgDocCls: '',
        bkgCgoCls: '',
        bkgMfCls: '',
        cfsCls: '',
        mrnNo: '',
        callSign: '',
        jrstCstm: '',
        ts: '',
        vslCd: '',
        pol: '',
        pod: '',
        bkgClose: '',
        dtBkgYn: ''
      },
      tempDetailResp2: {
        vslNm2BK: '',
        voyNo2BK: '',
        rteCd2BK: '',
        transitTime2BK: '',
        polEtbDT2BK: '',
        etaDT2BK: ''
      },
      // Pop-up
      bkgParams: {
        vslCd: '', // [0]선명코드
        voyNo: '', // [1]항차
        vslNm: '', // [2]선명
        polName: '', // [3]출발지 포트명
        podName: '', // [4]도착지 포트명
        etd: '', // [5]입항일
        etdTm: '', // [6]입항시간
        eta: '', // [7]출항일
        etaTm: '', // [8]출항시간
        polPortCd: '', // [9]출발지 포트코드
        podPortCd: '', // [10]도착지 포트코드
        polCtrCd: '', // [11]출발지 국가코드
        podCtrCd: '', // [12]도착지 국가코드
        cct: '', // [13] ?
        docCls: '', // [14]서류마감
        docPic: '', // [15]서류마감 담당자
        polTrmlCd: '', // [16]출발지 터미널 코드
        podTrmlCd: '', // [18]도착지 터미널 코드
        mrnNo: '', // [18]MRN Number
        callSign: '', // [19]Call sign
        hidCS008I: '', // [20]info (@_@로 붙어있는 정보)
        originInfo: '', // [20]info Backup (@_@로 붙어있는 정보)
        mfCls: '', // [21]EDI 신고마감
        cgoCls: '', // [22]반입마감
        cgoPic: '', // [23]반입마감 담당자
        finalEta: '', // [24]최종ETA
        cfsCls: '', // [25]cfs 반입마감
        cfsPic: '', // [26]cfs 반입마감 담당자
        bkgCloseYn: '', // [27] booking close 여부
        prrmSgEtd: '', // [28] ?
        polTrmlNm: '', // [29] 출발지 터미널명
        railCheck: '', // [30] rail booking 여부 ? >> schedule 처리 부분 없음(주석처리)
        frtAppNo: '', // [31] 운임 APPLICATION 번호 >> 견적운임조회
        logDtm: '', // [32] >> 견적운임조회
        srRest: '', // [33] SR 생성여부 >> 부킹 제약사항체크
        localDtm: '', // [34] 현재 시간 >> 부킹 제약사항체크
        vslRest: '', // [35] 선박 close 여부 >> 부킹 제약사항체크
        cgoRest: '', // [36] 반입 마감시한 >> 부킹 제약사항체크
        kmtcSpotYn: 'Y'
      },
      key: '',
      clickRateApp: 'N',
      ft20: '',
      ft40: '',
      polCtrPlcEnm: '',
      podCtrPlcEnm: '',
      polCtrPlcInfoList: [],
      podCtrPlcInfoList: [],
      chkChgPol: 'N',
      chkChgPod: 'N'
    }
  },
  watch: {
    yyyymm () {
      this.searchYear = this.yyyymm.substring(0, 4)
      this.searchMonth = this.yyyymm.replaceAll('.', '').substring(4, 6)
    }
  },
  created () {
    this.init()
    const date = new Date()
    this.year = date.getFullYear()
    this.month = date.getMonth() + 1
    this.day = date.getDate()

    if (this.month < 10) {
      this.month = '0' + this.month
    }

    if (this.day < 10) {
      this.day = '0' + this.day
    }

    this.currentYear = this.year
    this.currentMonth = this.month
    this.currentDay = this.day

    this.yyyymm = this.year + '.' + this.month
    this.yyyymmdd = this.year + '.' + this.month + '.' + this.day
    this.currentDt = this.year + this.month + this.day

    this.lang = this.auth.serviceLang

    // 비로그인 kmtc on 진입 금지
    if (!this.auth.userId || Object.keys(this.$route.query).length <= 0) {
      this.$router.push('/main')
    } else { // 로그인 후 미설정 화주 kmtc on 진입금지
      // 가로배너 클릭, kmtc on 아이콘 클릭 구분하기 위해 로그 전달
      schedules.getKmtcSpotTarget(this.$route.query.menuCd).then((response) => {
        if (response.data[0].kmtcSpotUserYn !== 'Y') {
          this.$router.push('/main')
        }
      })
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  methods: {
    handleKeydown (e) {
      if (e.key === 'Enter') {
        let focusEle = document.activeElement
        if (document.getElementById('reqVocCont') !== focusEle) {
          this.getLongTermSchdule()
        }
      }
    },
    async init () {
      this.lang = this.auth.serviceLang
      this.userCtrCd = (this.memberDetail === undefined) ? 'KR' : this.memberDetail.userCtrCd
      // 나의 스케줄
      const mySchlist = await mySchedules.getMySchedule()
      this.tempData = mySchlist.data.schKey

      await schedules.getKmtcOnCtrPlcInfo('POL').then((res) => {
        this.polCtrPlcInfoList = res.data[0]
      })

      await schedules.getKmtcOnCtrPlcInfo('POD').then((res) => {
        this.podCtrPlcInfoList = res.data[0]
      })
    },
    chanageSearchDt (data) {
      const frm = document.querySelector('#frm2')
      const elem = frm.querySelector('.datepicker')
      let isOk = true
      let msg = ''

      const etd = this.$ekmtcCommon.getStrToDate(data)
      const today = new Date()

      if (this.$ekmtcCommon.getDateToStrDelim(etd) < this.$ekmtcCommon.getDateToStrDelim(today)) {
        msg = this.$t('msg.FARE010T010.016') // 예상 출항일이 오늘 날짜보다 작을 수 없습니다.
        isOk = false
      }

      // 조회기간에 에러메세지 노출시 사용
      // if (!isOk) {
      //   this.$ekmtcCommon.showErrorTooltip(elem, msg)
      // } else {
      //   this.$ekmtcCommon.hideErrorTooltip(elem)
      //   this.yyyymm = data.substring(0, 6)
      //   this.yyyymmdd = data
      // }

      this.yyyymm = data.substring(0, 6)
      this.yyyymmdd = data
    },
    changePol (vo) {
      this.pol = vo
      this.scheduleForm.startPolName = vo.plcEnm
      this.scheduleForm.startCtrCd = vo.ctrCd
      this.scheduleForm.startPlcCd = vo.plcCd
      this.scheduleForm.startPlcName = vo.plcEnm
      const frm2 = document.querySelector('#frm2')
      const elem = frm2.querySelector('#polKS')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod (vo) {
      this.pod = vo
      this.scheduleForm.destPodName = vo.plcEnm
      this.scheduleForm.destCtrCd = vo.ctrCd
      this.scheduleForm.destPlcCd = vo.plcCd
      this.scheduleForm.destPlcName = vo.plcEnm
      const frm2 = document.querySelector('#frm2')
      const elem = frm2.querySelector('#podKS')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePol2 (vo) {
      this.pol2 = vo
      this.scheduleForm.startPolName = vo.plcEnm
      this.scheduleForm.startCtrCd = vo.ctrCd
      this.scheduleForm.startPlcCd = vo.plcCd
      this.scheduleForm.startPlcName = vo.plcEnm
      const frm2 = document.querySelector('#frm2')
      const elem = frm2.querySelector('#polKS2')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod2 (vo) {
      this.pod2 = vo
      this.scheduleForm.destPodName = vo.plcEnm
      this.scheduleForm.destCtrCd = vo.ctrCd
      this.scheduleForm.destPlcCd = vo.plcCd
      this.scheduleForm.destPlcName = vo.plcEnm
      const frm2 = document.querySelector('#frm2')
      const elem = frm2.querySelector('#podKS2')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePol3 (vo) {
      this.pol3 = vo
      this.scheduleForm.startPolName = vo.plcEnm
      this.scheduleForm.startCtrCd = vo.ctrCd
      this.scheduleForm.startPlcCd = vo.plcCd
      this.scheduleForm.startPlcName = vo.plcEnm
      const frm2 = document.querySelector('#frm2')
      const elem = frm2.querySelector('#polKS3')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod3 (vo) {
      this.pod3 = vo
      this.scheduleForm.destPodName = vo.plcEnm
      this.scheduleForm.destCtrCd = vo.ctrCd
      this.scheduleForm.destPlcCd = vo.plcCd
      this.scheduleForm.destPlcName = vo.plcEnm
      const frm2 = document.querySelector('#frm2')
      const elem = frm2.querySelector('#podKS3')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePolChk (vo) {
      this.polChk = vo
    },
    changePodChk (vo) {
      this.podChk = vo
    },
    fnDeleteMySchedule (schId) {
      mySchedules.deleteMySchedule(schId).then((resultCode) => {
        if (resultCode.data.result === 1) {
          mySchedules.getMySchedule().then((rtnData) => {
            this.myScheduleList = rtnData.data.mySchedule
          })
        }
      })
    },
    deleteMyScheduleStar (schId) {
      mySchedules.deleteMySchedule(schId).then((resultCode) => {
        if (resultCode.data.result === 1) {
          mySchedules.getMySchedule().then((rtnData) => {
            this.myScheduleList = rtnData.data.mySchedule
          })
          this.checkMySchedule()
        }
      })
    },
    fnAddMySchedule (idx) {
      const params = {
        startPolCn: this.scheduleData.arrayStartCtrCdSel[idx],
        startPolCd: this.scheduleData.arrayStartPlcCdSel[idx],
        destPodCn: this.scheduleData.arrayDestCtrCdSel[idx],
        destPodCd: this.scheduleData.arrayDestPlcCdSel[idx],
        startCtrNm: this.scheduleData.startCtrNameSel[idx],
        startPlcNm: this.scheduleData.startPlcNameSel[idx],
        destCtrNm: this.scheduleData.destCtrNameSel[idx],
        destPlcNm: this.scheduleData.destPlcNameSel[idx]
      }
      mySchedules.addMySchedule(params).then((resultCode) => {
        if (resultCode.data.result === 1) {
          mySchedules.getMySchedule().then((rtnData) => {
            this.myScheduleList = rtnData.data.mySchedule
          })
          this.checkMySchedule()
        }
      })
    },
    checkMySchedule () {
      // 나의 스케줄
      const paramsMySch = {
        startCtrCdStrs: this.stCtrcd,
        startPlcCdStrs: this.stPlcCd,
        destCtrCdStrs: this.deCtrCd,
        destPlcCdStrs: this.dePlcCd
      }
      mySchedules.checkMySchedule4LT(paramsMySch).then((rtnMySchData) => {
        this.checkMySchList = rtnMySchData.data.checkMySchList
      })
    },
    selectMySchedule (list) {
      this.scheduleForm.startPolName = list.polNm
      this.scheduleForm.destPodName = list.podNm
      this.scheduleForm.startCtrCd = list.polCn
      this.scheduleForm.startPlcCd = list.polCd
      this.scheduleForm.startPlcName = list.polNm2
      this.scheduleForm.destCtrCd = list.podCn
      this.scheduleForm.destPlcCd = list.podCd
      this.scheduleForm.destPlcName = list.podNm2
      this.pol.ctrCd = list.polCn
      this.pol.plcCd = list.polCd
      this.pod.ctrCd = list.podCn
      this.pod.plcCd = list.podCd
      const frm2 = document.querySelector('#frm2')
      const elemPol = frm2.querySelector('#polKS')
      const elemPod = frm2.querySelector('#podKS')
      this.$ekmtcCommon.hideErrorTooltip(elemPol)
      this.$ekmtcCommon.hideErrorTooltip(elemPod)
    },
    changeWeeks (e) {
      const val = e.target.value
      this.scheduleForm.weeks = val
    },
    async getLongTermSchdule () {
      this.show2ndVessel = 'N'
      let isAllOk = true
      isAllOk = this.validationCheck()
      if (!isAllOk) {
        return
      }
      // please wait
      if (this.searchYN === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('tem.CMAT033'))
        return
      }
      // this.getCpS101()
      const cpS101 = {
        actionCd: 'S101',
        porPlc: this.pol.plcCd,
        dlyPlc: this.pod.plcCd
      }
      await cp.getCallActionHandler(cpS101).then((res) => {
        console.log('>>>>> CP / S101 <<<<<', res)
        this.cpYn = res.data.result
        this.cpS101 = res.data
        this.cpYn = 'Y'
      })
      this.getCpS201()
      this.getCpS301()
      const wbParams = {
        logCatCd: '05'
      }
      await schedules.insertWB242H(wbParams).then((resultCode) => {
        this.key = resultCode.data
        console.log('this.key', this.key)
      })
      this.searchYN = 'Y'
      this.stCtrcd = this.pol.ctrCd
      this.stPlcCd = this.pol.plcCd
      this.deCtrCd = this.pod.ctrCd
      this.dePlcCd = this.pod.plcCd
      if (this.section2nd === 'Y') {
        this.stCtrcd += '@' + this.pol2.ctrCd
        this.stPlcCd += '@' + this.pol2.plcCd
        this.deCtrCd += '@' + this.pod2.ctrCd
        this.dePlcCd += '@' + this.pod2.plcCd
      }
      if (this.section3rd === 'Y') {
        this.stCtrcd += '@' + this.pol3.ctrCd
        this.stPlcCd += '@' + this.pol3.plcCd
        this.deCtrCd += '@' + this.pod3.ctrCd
        this.dePlcCd += '@' + this.pod3.plcCd
      }
      const params = {
        searchYear: this.searchYear,
        searchMonth: this.searchMonth,
        startCtrCd: this.scheduleForm.startCtrCd,
        startPlcCd: this.scheduleForm.startPlcCd,
        destCtrCd: this.scheduleForm.destCtrCd,
        destPlcCd: this.scheduleForm.destPlcCd,
        legIdx: this.idxPort,
        bound: this.paramBound,
        startDt: this.yyyymmdd.replaceAll('.', ''),
        weeks: this.scheduleForm.weeks,
        startCtrCdStrs: this.stCtrcd,
        startPlcCdStrs: this.stPlcCd,
        destCtrCdStrs: this.deCtrCd,
        destPlcCdStrs: this.dePlcCd,
        cpYn: this.cpYn,
        key: this.key,
        schData: this.tempData,
        kmtcSpotYn: 'Y'
      }
      // 검색
      await scheduleLT.getLongTermSchedule(params).then((rtnData) => {
        // console.log('$$$$$$$$ [LongTerm] getLongTermSchdule $$$$$$$$')
        // console.log(rtnData)
        if (rtnData !== null) {
          this.searchYN = 'N'
        }
        this.voyCntVoList = rtnData.data.voyCntVoList
        this.startPlcNameSel = rtnData.data.startPlcNameSel
        this.destPlcNameSel = rtnData.data.destPlcNameSel
        this.etaBookingMsg = rtnData.data.etaBookingMsg
        // TODO : schedule list
        this.scheduleData = rtnData.data
        this.listSchedule = rtnData.data.schedule[rtnData.data.legIdx] // 스케줄 목록 데이터
        // getCPS401
        if (this.listSchedule !== undefined || this.listSchedule.length !== 0) {
          for (const item of this.listSchedule) {
            this.getCpS401(item)
          }
        }
        this.prevMonth = rtnData.data.prevMonth
        this.nextMonth = rtnData.data.nextMonth
        this.currentYear = this.searchYear
        this.currentMonth = this.searchMonth
        this.bound = rtnData.data.bound
        // List > 부킹가능한 선박 검색
        var listSize = 0
        this.listSchedule4List = []
        this.listScheduleBkg = []
        // 부킹가능한 선박. (kmtc on은 ts 제외)
        if (this.checkBkg === 'Y') {
          for (const item of this.listSchedule) {
            if (item.bkgClose !== 'Y' && item.ts === 'N') { // 부킹 가능하면서 ts 아닌건
              this.listScheduleBkg.push(item)
            }
          }
          this.listSchedule4List = this.listScheduleBkg
          listSize = this.listScheduleBkg.length
        } else {
          for (const item of this.listSchedule) {
            if (item.ts === 'N') { // ts 아닌건
              this.listSchedule4List.push(item)
            }
          }
//          this.listSchedule4List = this.listSchedule
          listSize = this.listSchedule4List.length
        }
        // Paging
        this.pagingList = []
        this.startIdx = 0
        this.endIdx = 10
        this.curPage = 1
        if (listSize > this.pageSize) {
          listSize = Math.ceil(listSize / this.pageSize)
          this.finalPage = listSize
          for (let i = 0; i < listSize; i++) {
            this.pagingList.push(i + 1)
          }
        }
        // CP
        if (this.cpS101.result === 'Y') {
          this.setCpS101()
        }
        if (this.cpS201.result === 'Y') {
          // this.listSchedule = this.setCpS201(this.listSchedule)
          this.setCpS201()
        }
        if (this.cpS301.result === 'Y') {
          this.setCpS301()
        }
        if (this.listSchedule === undefined || this.listSchedule.length === 0 || this.listSchedule4List.length === 0) {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
        }
      })
      // 나의 스케줄
//      this.checkMySchedule()
    },
    getCpS101 () {
      // CP S101 : Priotiry표시
      // - 결과 = Y 이면
      // 1. 스케쥴 호출 시 파라미터에 cp=Y 추가
      // 2. 스케쥴 정보 수신 후 적용기준/시작일자/종료일자 이외의 스케쥴은 기존 스케쥴(Priority 높은것)을 유지함. (해당 기간에만 Priority 상관없이 모든 스케쥴을 받을 수 있음)
      // 3. 스케쥴 정보 수신 후 맨마지막 배열 (127번째)이 Priority 값으로, Y인 경우 2번의 기간이 아니면 스케쥴을 삭제함.
      const cpS101 = {
        actionCd: 'S101',
        porPlc: this.scheduleForm.startPlcCd,
        dlyPlc: this.scheduleForm.destPlcCd
      }
      cp.getCallActionHandler(cpS101).then((res) => {
        console.log('>>>>> CP / S101 <<<<<', res)
        this.cpYn = res.data.result
        this.cpS101 = res.data
      })
    },
    getCpS201 () {
      // CP S201 : 선명항차달력표시
      // - 결과 = Y 이면
      // 1. 스케쥴 정보 수신 후 적용기준/시작일자/종료일자/TS1/TS2/TS3/항로/선명/항차 조건에 걸리는 스케쥴은 삭제함
      // 2. Output은 필수값은 없기에 넘어온 값만큼 비교하여 대상을 삭제함.
      const cpS201 = {
        actionCd: 'S201',
        porPlc: this.pol.plcCd,
        dlyPlc: this.pod.plcCd
      }
      cp.getCallActionHandler(cpS201).then((res) => {
        console.log('>>>>> CP / S201 <<<<<')
        console.log(res)
        this.cpS201 = res.data
        // return this.cpS201
        // return res.data
      })
    },
    getCpS301 () {
      // CP S301 : 선명항차색깔표시 > Web Closing 선명/항차(빨간색)가 부킹 가능하게 파란색으로 보임
      // - 결과 = Y 이면
      // 1. 스케쥴 정보 수신 후 적용기준/시작일자/종료일자/TS1/TS2/TS3/항로/선명/항차 조건에 걸리는 스케쥴중 서류마감은 Closing 유지.
      //    서류마감이 되지 않았고, Web Closing건에 대해서는 부킹 가능하게 처리.
      // 2. Output은 필수값은 없기에 넘어온 값만큼 비교하여 처리.
      const cpS301 = {
        actionCd: 'S301',
        porPlc: this.pol.plcCd,
        dlyPlc: this.pod.plcCd
      }
      cp.getCallActionHandler(cpS301).then((res) => {
        console.log('>>>>> CP / S301 <<<<<')
        console.log(res)
        this.cpS301 = res.data
      })
    },
    async getCpS401 (vo) {
      const cpS401 = {
        actionCd: 'S401',
        etdDate: vo.etd,
        porPlc: vo.pol,
        ts1: vo.pol2,
        ts2: vo.pol3,
        ts3: vo.pol4,
        dlyPlc: vo.pod
      }
      await cp.getCallActionHandler(cpS401).then((res) => {
        vo.vessel2ndButton = res.data.result
        console.log('>>>>> CP / S401 <<<<<', res)
        // console.log(vo)
      })
    },
    setCpS101 () {
      // CP S101 : Priotiry표시
      // - 결과 = Y 이면
      // 1. 스케쥴 호출 시 파라미터에 cp=Y 추가
      // 2. 스케쥴 정보 수신 후 적용기준/시작일자/종료일자 이외의 스케쥴은 기존 스케쥴(Priority 높은것)을 유지함. (해당 기간에만 Priority 상관없이 모든 스케쥴을 받을 수 있음)
      // 3. 스케쥴 정보 수신 후 맨마지막 배열 (127번째)이 Priority 값으로 Y인 경우, 2번의 기간이 아니면 스케쥴을 삭제함.
      if (this.cpS101.result === 'Y') {
        // 적용기준
        if (!this.$ekmtcCommon.isEmpty(this.cpS101.periodDiv)) {
          if (this.cpS101.periodDiv === '입항일자') {
            // 입항일자 > polEtb
            if (!this.$ekmtcCommon.isEmpty(this.cpS101.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS101.periodTo)) {
              // 시작일자, 종료일자 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                // if (this.cpS101.periodFr <= this.listSchedule[i].polEtb && this.listSchedule[i].polEtb <= this.cpS101.periodTo) {
                if (this.listSchedule[i].polEtb <= this.cpS101.periodFr || this.cpS101.periodTo <= this.listSchedule[i].polEtb) {
                  if (this.listSchedule[i].priority === 'Y') {
                    console.log('#### [S101] 입항일자 > 시작일자, 종료일자 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                }
              }
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS101.periodFr) && this.$ekmtcCommon.isEmpty(this.cpS101.periodTo)) {
              // 시작일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                // if (this.cpS101.periodFr <= this.listSchedule[i].polEtb) {
                if (this.listSchedule[i].polEtb <= this.cpS101.periodFr) {
                  if (this.listSchedule[i].priority === 'Y') {
                    console.log('#### [S101] 입항일자 > 시작일자만 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                }
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS101.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS101.periodTo)) {
              // 종료일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                // if (this.listSchedule[i].polEtb <= this.cpS101.periodTo) {
                if (this.cpS101.periodTo <= this.listSchedule[i].polEtb) {
                  if (this.listSchedule[i].priority === 'Y') {
                    console.log('#### [S101] 입항일자 > 종료일자만 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                }
              }
            } else {
              // 둘 다 없는 경우는 없겠지?!
              console.log('#### [S101] 입항일자 > 둘 다 없는 경우')
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.listSchedule[i].priority === 'Y') {
                  this.listSchedule.splice(i, 1)
                  i--
                }
              }
            }
          } else {
            // 출항일자 > etd
            if (!this.$ekmtcCommon.isEmpty(this.cpS101.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS101.periodTo)) {
              // 시작일자, 종료일자 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                // console.log('>>>> list[' + i + '].etd = ' + this.listSchedule[i].etd + ' / ' + this.listSchedule[i].vslNm + ' / ' + this.listSchedule[i].voyNo + ' / ' + this.listSchedule[i].priority)
                // if (this.cpS101.periodFr <= this.listSchedule[i].etd && this.listSchedule[i].etd <= this.cpS101.periodTo) {
                if (this.listSchedule[i].etd <= this.cpS101.periodFr || this.cpS101.periodTo <= this.listSchedule[i].etd) {
                  if (this.listSchedule[i].priority === 'Y') {
                    console.log('#### [S101] 출항일자 > 시작일자, 종료일자 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                }
              }
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS101.periodFr) && this.$ekmtcCommon.isEmpty(this.cpS101.periodTo)) {
              // 시작일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                // if (this.cpS101.periodFr <= this.listSchedule[i].etd) {
                if (this.listSchedule[i].etd <= this.cpS101.periodFr) {
                  if (this.listSchedule[i].priority === 'Y') {
                    console.log('#### [S101] 출항일자 > 시작일자만 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                }
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS101.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS101.periodTo)) {
              // 종료일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                // if (this.listSchedule[i].etd <= this.cpS101.periodTo) {
                if (this.cpS101.periodTo <= this.listSchedule[i].etd) {
                  if (this.listSchedule[i].priority === 'Y') {
                    console.log('#### [S101] 출항일자 > 종료일자만 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                }
              }
            } else {
              // 둘 다 없는 경우는 없겠지?!
              console.log('#### [S101] 출항일자 > 둘 다 없는 경우')
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.listSchedule[i].priority === 'Y') {
                  this.listSchedule.splice(i, 1)
                  i--
                }
              }
            }
          }
        } else {
          // No 적용기준 > 시작, 종료일도 없음
          console.log('No 적용기준')
          for (let i = 0; i < this.listSchedule.length; i++) {
            if (this.listSchedule[i].priority === 'Y') {
              this.listSchedule.splice(i, 1)
              i--
            }
          }
        }
      }
      console.log(this.listSchedule)
    },
    setCpS201 () {
      // cp S201
      // - 결과 = Y 이면
      // 1. 스케쥴 정보 수신 후 적용기준/시작일자/종료일자/TS1/TS2/TS3/항로/선명/항차 조건에 걸리는 스케쥴은 삭제함
      // 2. Output은 필수값은 없기에 넘어온 값만큼 비교하여 대상을 삭제함.
      let onlyDate = false
      if (this.$ekmtcCommon.isEmpty(this.cpS201.route) &&
        this.$ekmtcCommon.isEmpty(this.cpS201.vessel) &&
        this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo) &&
        this.$ekmtcCommon.isEmpty(this.cpS201.ts1) &&
        this.$ekmtcCommon.isEmpty(this.cpS201.ts2) &&
        this.$ekmtcCommon.isEmpty(this.cpS201.ts3)) {
        onlyDate = true
      }
      if (this.cpS201.result === 'Y') {
        // 적용기준
        if (!this.$ekmtcCommon.isEmpty(this.cpS201.periodDiv)) {
          if (this.cpS201.periodDiv === '입항일자') {
            // 입항일자 > polEtb
            if (!this.$ekmtcCommon.isEmpty(this.cpS201.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS201.periodTo)) {
              // 시작일자, 종료일자 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.cpS201.periodFr <= this.listSchedule[i].polEtb && this.listSchedule[i].polEtb <= this.cpS201.periodTo) {
                  console.log('#### [S201] 입항일자 > 시작일자, 종료일자 있는 경우')
                  if (onlyDate) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    }
                    // if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                    //     this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                    //   this.listSchedule.splice(i, 1)
                    //   i--
                    // }
                  }
                }
              }
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.periodFr) && this.$ekmtcCommon.isEmpty(this.cpS201.periodTo)) {
              // 시작일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.cpS201.periodFr <= this.listSchedule[i].polEtb) {
                  console.log('#### [S201] 입항일자 > 시작일자만 있는 경우')
                  if (onlyDate) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    }
                    // if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                    //     this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                    //   this.listSchedule.splice(i, 1)
                    //   i--
                    // }
                  }
                }
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS201.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS201.periodTo)) {
              // 종료일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.listSchedule[i].polEtb <= this.cpS201.periodTo) {
                  console.log('#### [S201] 입항일자 > 종료일자만 있는 경우')
                  if (onlyDate) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    }
                    // if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                    //     this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                    //   this.listSchedule.splice(i, 1)
                    //   i--
                    // }
                  }
                }
              }
            } else {
              // 둘 다 없는 경우는 없겠지?!
              console.log('#### [S201] 입항일자 > 둘 다 없는 경우')
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                  this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                  this.listSchedule.splice(i, 1)
                  i--
                }
              }
            }
          } else {
            // 출항일자 > etd
            if (!this.$ekmtcCommon.isEmpty(this.cpS201.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS201.periodTo)) {
              // 시작일자, 종료일자 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                // console.log('Only Date >> list[' + i + '].etd = ' + this.listSchedule[i].etd + ' / ' + this.listSchedule[i].vslNm + ' / ' + this.listSchedule[i].voyNo)
                if (this.cpS201.periodFr <= this.listSchedule[i].etd && this.listSchedule[i].etd <= this.cpS201.periodTo) {
                  console.log('#### [S201] 출항일자 > 시작일자, 종료일자 있는 경우')
                  if (onlyDate) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    }
                    // if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                    //     this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                    //       this.listSchedule.splice(i, 1)
                    //       i--
                    // }
                  }
                }
              }
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.periodFr) && this.$ekmtcCommon.isEmpty(this.cpS201.periodTo)) {
              // 시작일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.cpS201.periodFr <= this.listSchedule[i].etd) {
                  console.log('#### [S201] 출항일자 > 시작일자만 있는 경우')
                  if (onlyDate) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    }
                    // if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                    //     this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                    //   this.listSchedule.splice(i, 1)
                    //   i--
                    // }
                  }
                }
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS201.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS201.periodTo)) {
              // 종료일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.listSchedule[i].etd <= this.cpS201.periodTo) {
                  console.log('#### [S201] 출항일자 > 종료일자만 있는 경우')
                  if (onlyDate) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                      // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    }
                    // if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                    //     this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                    //   this.listSchedule.splice(i, 1)
                    //   i--
                    // }
                  }
                }
              }
            } else {
              // 둘 다 없는 경우는 없겠지?!
              console.log('#### [S201] 출항일자 > 둘 다 없는 경우')
              for (let i = 0; i < this.listSchedule.length; i++) {
                // 3개 있을 경우
                if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                    console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                    (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                  // 2개 있을 경우
                } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel) {
                    console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + ') 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel &&
                    (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                    console.log('#### [S201] Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  } else if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                    (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                    console.log('#### [S201] Route(' + this.cpS201.route + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                    (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                  // 1개 있을 경우
                } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].rteCd === this.cpS201.route) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else if (this.listSchedule[i].rteCd === this.cpS201.route &&
                    (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].vslCd === this.cpS201.vessel) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else if (this.listSchedule[i].vslCd === this.cpS201.vessel &&
                    (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else if (this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                    (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                  // 없을 경우
                } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                }
                // if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                //     this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                //   this.listSchedule.splice(i, 1)
                //   i--
                // }
              }
            }
          }
        } else {
          // No 적용기준 > 시작, 종료일도 없음
          console.log('No 적용기준')
          for (let i = 0; i < this.listSchedule.length; i++) {
            if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
              this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
              this.listSchedule.splice(i, 1)
              i--
            }
          }
        }
      }
      console.log(this.listSchedule)
    },
    async setCpS301 () {
      // CP S301 : 선명항차색깔표시 > Web Closing 선명/항차(빨간색)가 부킹 가능하게 파란색으로 보임
      // - 결과 = Y 이면
      // 1. 스케쥴 정보 수신 후 적용기준/시작일자/종료일자/TS1/TS2/TS3/항로/선명/항차 조건에 걸리는 스케쥴중 서류마감은 Closing 유지.
      //    서류마감이 되지 않았고, Web Closing건에 대해서는 부킹 가능하게 처리.
      // 2. Output은 필수값은 없기에 넘어온 값만큼 비교하여 처리.
      let onlyDate = false
      if (this.$ekmtcCommon.isEmpty(this.cpS301.route) &&
        this.$ekmtcCommon.isEmpty(this.cpS301.vessel) &&
        this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo) &&
        this.$ekmtcCommon.isEmpty(this.cpS301.ts1) &&
        this.$ekmtcCommon.isEmpty(this.cpS301.ts2) &&
        this.$ekmtcCommon.isEmpty(this.cpS301.ts3)) {
        onlyDate = true
      }

      let serverDt = ''
      await commons.getServerTime().then(res => {
        if (res.data !== undefined) {
          serverDt = res.data.length > 12 ? res.data.substring(0, 12) : res.data
        }
      })

      let idx = 0
      if (this.cpS301.result === 'Y') {
        // 적용기준
        if (!this.$ekmtcCommon.isEmpty(this.cpS301.periodDiv)) {
          if (this.cpS301.periodDiv === '입항일자') {
            // 입항일자 > polEtb
            if (!this.$ekmtcCommon.isEmpty(this.cpS301.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS301.periodTo)) {
              // 시작일자, 종료일자 있는 경우
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (this.cpS301.periodFr <= item.polEtb && item.polEtb <= this.cpS301.periodTo) {
                  //console.log('#### [S301] 입항일자 > 시작일자, 종료일자 있는 경우')
                  if (onlyDate) {
                    item.bkgClose = 'A'
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route) {
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.vslCd === this.cpS301.vessel) {
                        item.bkgClose = 'A'
                      } else if (item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.voyNo === this.cpS301.voyageNo) {
                        item.bkgClose = 'A'
                      } else if (item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                        item.bkgClose = 'A'
                      }
                    }
                    // if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                    //     item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                    //   item.bkgClose = 'A'
                    // }
                  }
                }
                idx++
              }
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.periodFr) && this.$ekmtcCommon.isEmpty(this.cpS301.periodTo)) {
              // 시작일자만 있는 경우
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (this.cpS301.periodFr <= item.polEtb) {
                  //console.log('#### [S301] 입항일자 > 시작일자만 있는 경우')
                  if (onlyDate) {
                    item.bkgClose = 'A'
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route) {
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.vslCd === this.cpS301.vessel) {
                        item.bkgClose = 'A'
                      } else if (item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.voyNo === this.cpS301.voyageNo) {
                        item.bkgClose = 'A'
                      } else if (item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                        item.bkgClose = 'A'
                      }
                    }
                    // if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                    //     item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                    //   item.bkgClose = 'A'
                    // }
                  }
                }
                idx++
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS301.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS301.periodTo)) {
              // 종료일자만 있는 경우
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (item.polEtb <= this.cpS301.periodTo) {
                  //console.log('#### [S301] 입항일자 > 종료일자만 있는 경우')
                  if (onlyDate) {
                    item.bkgClose = 'A'
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route) {
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.vslCd === this.cpS301.vessel) {
                        item.bkgClose = 'A'
                      } else if (item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.voyNo === this.cpS301.voyageNo) {
                        item.bkgClose = 'A'
                      } else if (item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                        item.bkgClose = 'A'
                      }
                    }
                    // if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                    //     item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                    //   item.bkgClose = 'A'
                    // }
                  }
                }
                idx++
              }
            } else {
              // 둘 다 없는 경우는 없겠지?!
              //console.log('#### [S301] 입항일자 > 둘 다 없는 경우')
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                  item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                  item.bkgClose = 'A'
                }
                idx++
              }
            }
          } else {
            // 출항일자 > etd
            if (!this.$ekmtcCommon.isEmpty(this.cpS301.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS301.periodTo)) {
              // 시작일자, 종료일자 있는 경우
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (this.cpS301.periodFr <= item.etd && item.etd <= this.cpS301.periodTo) {
                  //console.log('#### [S301] 출항일자 > 시작일자, 종료일자 있는 경우')
                  if (onlyDate) {
                    item.bkgClose = 'A'
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route) {
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.vslCd === this.cpS301.vessel) {
                        item.bkgClose = 'A'
                      } else if (item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.voyNo === this.cpS301.voyageNo) {
                        item.bkgClose = 'A'
                      } else if (item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                        item.bkgClose = 'A'
                      }
                    }
                    // if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                    //     item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                    //   item.bkgClose = 'A'
                    // }
                  }
                }
                idx++
              }
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.periodFr) && this.$ekmtcCommon.isEmpty(this.cpS301.periodTo)) {
              // 시작일자만 있는 경우
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (this.cpS301.periodFr <= item.etd) {
                  //console.log('#### [S301] 출항일자 > 시작일자만 있는 경우')
                  if (onlyDate) {
                    item.bkgClose = 'A'
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route) {
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.vslCd === this.cpS301.vessel) {
                        item.bkgClose = 'A'
                      } else if (item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.voyNo === this.cpS301.voyageNo) {
                        item.bkgClose = 'A'
                      } else if (item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                        item.bkgClose = 'A'
                      }
                    }
                    // if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                    //     item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                    //   item.bkgClose = 'A'
                    // }
                  }
                }
                idx++
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS301.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS301.periodTo)) {
              // 종료일자만 있는 경우
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (item.etd <= this.cpS301.periodTo) {
                  //console.log('#### [S301] 출항일자 > 종료일자만 있는 경우')
                  if (onlyDate) {
                    item.bkgClose = 'A'
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route) {
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.vslCd === this.cpS301.vessel) {
                        item.bkgClose = 'A'
                      } else if (item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.voyNo === this.cpS301.voyageNo) {
                        item.bkgClose = 'A'
                      } else if (item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                      // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                        item.bkgClose = 'A'
                      }
                    }
                    // if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                    //     item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                    //   item.bkgClose = 'A'
                    // }
                  }
                }
                idx++
              }
            } else {
              // 둘 다 없는 경우는 없겠지?!
              //console.log('#### [S301] 출항일자 > 둘 다 없는 경우')
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                  item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                  item.bkgClose = 'A'
                }
                idx++
              }
            }
          }
        } else {
          // No 적용기준 > 시작, 종료일도 없음
          //console.log('No 적용기준')
          for (const item of this.listSchedule) {
            if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
              continue
            }
            // 3개 있을 경우
            if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                item.bkgClose = 'A'
              } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                item.bkgClose = 'A'
              }
              // 2개 있을 경우
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel) {
                console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + ') 있는 경우')
                item.bkgClose = 'A'
              } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel &&
                (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                item.bkgClose = 'A'
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                console.log('#### [S301] Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                item.bkgClose = 'A'
              } else if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                item.bkgClose = 'A'
              }
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo) {
                console.log('#### [S301] Route(' + this.cpS301.route + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                item.bkgClose = 'A'
              } else if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo &&
                (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                item.bkgClose = 'A'
              }
              // 1개 있을 경우
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.rteCd === this.cpS301.route) {
                item.bkgClose = 'A'
              } else if (item.rteCd === this.cpS301.route &&
                (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                item.bkgClose = 'A'
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.vslCd === this.cpS301.vessel) {
                item.bkgClose = 'A'
              } else if (item.vslCd === this.cpS301.vessel &&
                (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                item.bkgClose = 'A'
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.voyNo === this.cpS301.voyageNo) {
                item.bkgClose = 'A'
              } else if (item.voyNo === this.cpS301.voyageNo &&
                (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                item.bkgClose = 'A'
              }
              // 없을 경우
            } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                item.bkgClose = 'A'
              }
            }
            // if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
            //     item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
            //   item.bkgClose = 'A'
            // }
            idx++
          }
        }
      }
    },
    fnChangeCalendar (yearMonth) {
      if (this.month > yearMonth.substring(4, 6)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.FARE010T010.016'))
        return
      }

      this.yyyymm = yearMonth
      this.yyyymmdd = yearMonth + '01'
      this.searchYear = yearMonth.substring(0, 4)
      this.searchMonth = yearMonth.substring(4, 6)
      this.getLongTermSchdule()
    },
    validationCheck () {
      const frm2 = document.querySelector('#frm2')
      let isAllOk = true
      if (this.pol.plcCd === '') {
        const elem = frm2.querySelector('#polKS')
        let msg = this.$t('art.CMATK068') + this.$t('tem.CMAT003') // '출발지역을 선택하세요.'
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
        this.curPage = 1
        isAllOk = false
      }
      if (this.pod.plcCd === '') {
        const elem = frm2.querySelector('#podKS')
        let msg = this.$t('art.CMATK010') + this.$t('tem.CMAT003') // '도착지역을 선택하세요.'
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
        this.curPage = 1
        isAllOk = false
      }
      if (this.section2nd === 'Y') {
        if (this.pol2.plcCd === '') {
          const elem = frm2.querySelector('#polKS2')
          let msg = this.$t('art.CMATK068') + this.$t('tem.CMAT003') // '출발지역을 선택하세요.'
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
          this.curPage = 1
          isAllOk = false
        }
        if (this.pod2.plcCd === '') {
          const elem = frm2.querySelector('#podKS2')
          let msg = this.$t('art.CMATK010') + this.$t('tem.CMAT003') // '도착지역을 선택하세요.'
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
          this.curPage = 1
          isAllOk = false
        }
      }
      if (this.section3rd === 'Y') {
        if (this.pol3.plcCd === '') {
          const elem = frm2.querySelector('#polKS3')
          let msg = this.$t('art.CMATK068') + this.$t('tem.CMAT003') // '출발지역을 선택하세요.'
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
          this.curPage = 1
          isAllOk = false
        }
        if (this.pod3.plcCd === '') {
          const elem = frm2.querySelector('#podKS3')
          let msg = this.$t('art.CMATK010') + this.$t('tem.CMAT003') // '도착지역을 선택하세요.'
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
          this.curPage = 1
          isAllOk = false
        }
      }
      return isAllOk
    },
    // checkValidationPol (frm2) {
    //   const elem = frm2.querySelector('#polKS')
    //   let msg = this.$t('art.CMATK068') + this.$t('tem.CMAT003') // '출발지역을 선택하세요.'
    //   this.$ekmtcCommon.showErrorTooltip(elem, msg)
    // },
    // checkValidationPod (frm2) {
    //   const elem = frm2.querySelector('#podKS')
    //   let msg = this.$t('art.CMATK010') + this.$t('tem.CMAT003') // '도착지역을 선택하세요.'
    //   this.$ekmtcCommon.showErrorTooltip(elem, msg)
    // },
    change2ndVessel (data) {
      const params = {
        asPolPort: data.pol2nd,
        asPodPort: data.pod2nd,
        asFrom: data.etd2nd
      }
      schedules.get2ndVessel(params).then((rtnData) => {
        this.vessel2ndList = rtnData.data.vessel2ndList
        this.show2ndVessel = 'Y'
      })
    },
    change2ndVessel4List (vo, idx) {
      if (this.listIndexNo === idx && this.show2ndVessel === 'Y') {
        this.show2ndVessel = 'N'
        this.listIndexNo = null
        return
      }
      this.listIndexNo = idx
      const params = {
        asPolPort: vo.pol,
        asPodPort: vo.pod,
        asFrom: vo.etd
      }
      schedules.get2ndVessel(params).then((rtnData) => {
        this.vessel2ndList = rtnData.data.vessel2ndList
        this.show2ndVessel = 'Y'
      })
    },
    select2ndVessel (data) {
      // set parameter
      var hidCS008I2nd = data.vslNm + ':' +
        data.oportCd + ':' +
        data.orvsdEndpDt +
        data.orvsdEndpTm + ':' +
        data.iportCd + ':' +
        data.irvsdEndpDt +
        data.irvsdEndpTm + ':' +
        data.voyNo + ':' +
        data.schSeq + ':' +
        data.schSeq1 + ':' +
        data.vslCatCd + ':' +
        data.voyNo + ':' +
        data.ivoyNo + ':' +
        data.otrmlCd + ':' +
        data.itrmlCd + ':' +
        data.schSeq + ':' +
        data.schSeq1 + ':' +
        data.cnntDds + ':' +
        data.trstDds + ':' +
        data.vslCd
      var hidCS008I = this.bkgParams.hidCS008I.split('@_@')
      var nd2hidCS008I = ''
      var length2 = hidCS008I.length - 1
      for (let i = 0; i < length2; i++) {
        if (i === 2) {
          nd2hidCS008I += hidCS008I2nd + '@_@'
        } else {
          nd2hidCS008I += hidCS008I[i] + '@_@'
        }
      }
      this.bkgParams.hidCS008I = nd2hidCS008I
    },
    select2ndVessel4List (data, idx) {
      var listData = this.listSchedule4List[idx]
      listData.polEtd2 = data.orvsdEndpDt
      listData.etdTm2 = data.orvsdEndpTm
      listData.eta = data.irvsdEndpDt
      listData.etaTm = data.irvsdEndpTm
      listData.vslNm2 = data.vslNm
      listData.voyNo2 = data.voyNo
      listData.rteCd2 = data.rteCd
      listData.transitTime2 = data.transitTime
      listData.polEtbDT2 = data.etdStrDT
      listData.etaDT2 = data.etaStrDT
      // set parameter
      this.vessel2ndParam = data.vslNm + ':' +
        data.oportCd + ':' +
        data.orvsdEndpDt +
        data.orvsdEndpTm + ':' +
        data.iportCd + ':' +
        data.irvsdEndpDt +
        data.irvsdEndpTm + ':' +
        data.voyNo + ':' +
        data.schSeq + ':' +
        data.schSeq1 + ':' +
        data.vslCatCd + ':' +
        data.voyNo + ':' +
        data.ivoyNo + ':' +
        data.otrmlCd + ':' +
        data.itrmlCd + ':' +
        data.schSeq + ':' +
        data.schSeq1 + ':' +
        data.cnntDds + ':' +
        data.trstDds + ':' +
        data.vslCd
      this.show2ndVessel = 'N'
    },
    cancel2ndVessel4List (idx) {
      this.show2ndVessel = 'N'
    },
    getList4Schedule (idx) {
      this.show2ndVessel = 'N'
      this.listIndexNo = null
      if (idx < 1) {
        // alert(this.$t('js.common.002')) // 처음페이지입니다.
        this.$ekmtcCommon.alertDefault(this.$t('js.common.002'))
        this.curPage = 1
        return
      }
      if (idx > this.finalPage) {
        // alert(this.$t('js.common.001')) // 마지막페이지입니다.
        this.$ekmtcCommon.alertDefault(this.$t('js.common.001'))
        this.curPage = this.finalPage
        return
      }
      this.curPage = idx
      this.startIdx = (idx * this.pageSize) - this.pageSize
      this.endIdx = idx * this.pageSize
    },
    changeDate2Display (strDate, strTime, lang) {
      var rtnStrDate = ''
      if (strDate === null || strDate === '') {
        rtnStrDate = ''
      } else {
        if (lang === 'ENG') {
          if (strDate.substring(4, 6) === '01') {
            rtnStrDate = strDate.substring(0, 4) + '.Jan.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '02') {
            rtnStrDate = strDate.substring(0, 4) + '.Feb.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '03') {
            rtnStrDate = strDate.substring(0, 4) + '.Mar.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '04') {
            rtnStrDate = strDate.substring(0, 4) + '.Apr.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '05') {
            rtnStrDate = strDate.substring(0, 4) + '.May.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '06') {
            rtnStrDate = strDate.substring(0, 4) + '.Jun.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '07') {
            rtnStrDate = strDate.substring(0, 4) + '.Jul.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '08') {
            rtnStrDate = strDate.substring(0, 4) + '.Aug.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '09') {
            rtnStrDate = strDate.substring(0, 4) + '.Sep.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '10') {
            rtnStrDate = strDate.substring(0, 4) + '.Oct.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '11') {
            rtnStrDate = strDate.substring(0, 4) + '.Nov.' + strDate.substring(6, 8)
          } else {
            rtnStrDate = strDate.substring(0, 4) + '.Dec.' + strDate.substring(6, 8)
          }
        } else {
          rtnStrDate = strDate.substring(0, 4) + '.' + strDate.substring(4, 6) + '.' + strDate.substring(6, 8)
        }
      }
      if (strTime === null || strTime === '') {
        rtnStrDate = ''
      } else {
        rtnStrDate = rtnStrDate + ' ' + strTime.substring(0, 2) + ':' + strTime.substring(2, 4)
      }
      return rtnStrDate
    },
    changeDateTime2Display (strDate, lang) {
      var rtnStrDate = ''
      if (strDate === null || strDate === '') {
        rtnStrDate = ''
      } else {
        if (lang === 'ENG') {
          if (strDate.substring(4, 6) === '01') {
            rtnStrDate = strDate.substring(0, 4) + '.Jan.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '02') {
            rtnStrDate = strDate.substring(0, 4) + '.Feb.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '03') {
            rtnStrDate = strDate.substring(0, 4) + '.Mar.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '04') {
            rtnStrDate = strDate.substring(0, 4) + '.Apr.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '05') {
            rtnStrDate = strDate.substring(0, 4) + '.May.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '06') {
            rtnStrDate = strDate.substring(0, 4) + '.Jun.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '07') {
            rtnStrDate = strDate.substring(0, 4) + '.Jul.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '08') {
            rtnStrDate = strDate.substring(0, 4) + '.Aug.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '09') {
            rtnStrDate = strDate.substring(0, 4) + '.Sep.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '10') {
            rtnStrDate = strDate.substring(0, 4) + '.Oct.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '11') {
            rtnStrDate = strDate.substring(0, 4) + '.Nov.' + strDate.substring(6, 8)
          } else {
            rtnStrDate = strDate.substring(0, 4) + '.Dec.' + strDate.substring(6, 8)
          }
        } else {
          rtnStrDate = strDate.substring(0, 4) + '.' + strDate.substring(4, 6) + '.' + strDate.substring(6, 8)
        }
        rtnStrDate = rtnStrDate + ' ' + strDate.substring(8, 10) + ':' + strDate.substring(10, 12)
      }
      return rtnStrDate
    },
    fnTrmlText (trml, trml_nm) {
      var str = ''
      if (trml !== '') {
        str += trml
      } else {
        str = ''
      }
      if (trml_nm !== '' && trml_nm !== undefined) {
        str += ' (' + trml_nm + ')'
      } else if (trml_nm === undefined) {
        str = ''
      } else {
        str = ''
      }
      return str
    },
    selectPort (idx) {
      this.idxPort = idx
      this.scheduleForm.startCtrCd = this.scheduleData.arrayStartCtrCdSel[idx]
      this.scheduleForm.startPlcCd = this.scheduleData.arrayStartPlcCdSel[idx]
      this.scheduleForm.startPlcName = this.scheduleData.startPlcNameSel[idx]
      this.scheduleForm.destCtrCd = this.scheduleData.arrayDestCtrCdSel[idx]
      this.scheduleForm.destPlcCd = this.scheduleData.arrayDestPlcCdSel[idx]
      this.scheduleForm.destPlcName = this.scheduleData.destPlcNameSel[idx]
      this.scheduleForm.startPolName = this.scheduleData.startCtrNameSel[idx] + ',' + this.scheduleData.startPlcNameSel[idx]
      this.scheduleForm.destPodName = this.scheduleData.destCtrNameSel[idx] + ',' + this.scheduleData.destPlcNameSel[idx]
      this.getLongTermSchdule()
    },
    openSection () {
      if (this.section2nd === 'Y' && this.section3rd === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK066')) // 최대 3건까지 동시에 조회하실 수 있습니다.
        return
      }
      if (this.section2nd === 'N' && this.section3rd === 'N') {
        this.section2nd = 'Y'
      } else if (this.section2nd === 'Y' && this.section3rd === 'N') {
        this.section3rd = 'Y'
      } else if (this.section2nd === 'N' && this.section3rd === 'Y') {
        this.section2nd = 'Y'
      }
      // if (this.section3rd === 'N' && this.section2nd === 'Y') {
      //   this.section3rd = 'Y'
      // }
    },
    close2ndSection () {
      this.section2nd = 'N'
      this.pol2.ctrCd = ''
      this.pol2.plcCd = ''
      this.pod2.ctrCd = ''
      this.pod2.plcCd = ''
    },
    close3rdSestion () {
      this.section3rd = 'N'
      this.pol3.ctrCd = ''
      this.pol3.plcCd = ''
      this.pod3.ctrCd = ''
      this.pod3.plcCd = ''
    },
    openPopup (compNm, vo) {
      const elemSchPopup = document.querySelector('#schKS_popup')
      if (this.customComponentKS !== null) {
        this.customComponentKS = null
        this.$ekmtcCommon.layerClose()
        elemSchPopup.style.display = 'none'
      }
      if (compNm === 'EmailSchedulePop') {
        if (!this.auth.userId) {
          // 로그인이 필요한 메뉴입니다.
          // if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.MAIN010G030.034') })) {
          // 로그인을 확인해주세요.
          if (this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') })) {
            if (!this.$store.getters['auth/requireLogin']) {
              this.$store.commit('auth/requireLogin', !this.$store.getters['auth/requireLogin'])
            }
          }
          return
        }

        this.popupParams.EmailSchedulePop.polCtrCd = this.scheduleForm.startCtrCd
        this.popupParams.EmailSchedulePop.polPlcCd = this.scheduleForm.startPlcCd
        this.popupParams.EmailSchedulePop.polPlcNm = this.scheduleForm.startPlcName
        this.popupParams.EmailSchedulePop.podCtrCd = this.scheduleForm.destCtrCd
        this.popupParams.EmailSchedulePop.podPlcCd = this.scheduleForm.destPlcCd
        this.popupParams.EmailSchedulePop.podPlcNm = this.scheduleForm.destPlcName
        this.popupParams.EmailSchedulePop.eiCatCd = this.bound
      } else if (compNm === 'EmailPop') {
        this.popupParams.EmailPop.porCtrCd = this.scheduleForm.startCtrCd
        this.popupParams.EmailPop.porPlcCd = this.scheduleForm.startPlcCd
      } else if (compNm === 'FreSurchargeKmtcSpotPop') {
        this.popupParams.FreSurchargeKmtcSpotPop.porCtrCd = vo.polCtrCd // 출발지 국가코드
        this.popupParams.FreSurchargeKmtcSpotPop.porPlcCd = vo.pol // 출발지 포트코드
        this.popupParams.FreSurchargeKmtcSpotPop.dlyCtrCd = vo.podCtrCd // 도착지 국가코드
        this.popupParams.FreSurchargeKmtcSpotPop.dlyPlcCd = vo.pod // 도착지 포트코드
        this.popupParams.FreSurchargeKmtcSpotPop.polNm = vo.polNm // 출발지 포트명
        this.popupParams.FreSurchargeKmtcSpotPop.podNm = vo.podNm // 도착지 포트명
        this.popupParams.FreSurchargeKmtcSpotPop.startDt = ''
        this.popupParams.FreSurchargeKmtcSpotPop.endDt = ''
        this.popupParams.FreSurchargeKmtcSpotPop.etd = vo.etd // 출발지 출항일자
        this.popupParams.FreSurchargeKmtcSpotPop.frtAppNo = ''
        this.popupParams.FreSurchargeKmtcSpotPop.vslCd = vo.vslCd // 선명코드
        this.popupParams.FreSurchargeKmtcSpotPop.voyNo = vo.voyNo // 항차
        this.popupParams.FreSurchargeKmtcSpotPop.vslNm = vo.vslNm // 선명
        this.popupParams.FreSurchargeKmtcSpotPop.rteCd = vo.rteCd
        this.popupParams.FreSurchargeKmtcSpotPop.eiCatCd = this.bound
        this.popupParams.FreSurchargeKmtcSpotPop.frtResult = ''
        this.popupParams.FreSurchargeKmtcSpotPop.reqRno = ''
        this.popupParams.FreSurchargeKmtcSpotPop.bkgClose = vo.bkgClose // booking close 여부
        this.popupParams.FreSurchargeKmtcSpotPop.raTsParam = ''
        this.popupParams.FreSurchargeKmtcSpotPop.refFrtAppNo = ''
        this.popupParams.FreSurchargeKmtcSpotPop.rmPopOpenFlag = 'Y'
        this.popupParams.FreSurchargeKmtcSpotPop.kmtcSpotYn = 'Y'
      }
      this.parentInfo = this.popupParams[compNm]
      console.log('=====================================')
      console.log(compNm)
      console.log('-------------------------------------')
      console.log(this.parentInfo)
      console.log('-------------------------------------')
      this.customComponentKS = compNm
      if (compNm === 'EmailSchedulePop' || compNm === 'EmailPop' || compNm === 'FreSurchargeKmtcSpotPop') {
        // this.$ekmtcCommon.layerOpen()
        this.$ekmtcCommon.layerOpen('#schKS_popup')
      }
    },
    closePopup (obj) {
      this.customComponentKS = null
      this.$ekmtcCommon.layerClose('#schKS_popup')
    },
    excelDownload () {
      const params = {
        searchYear: this.searchYear,
        searchMonth: this.searchMonth,
        bound: this.bound,
        startCtrCd: this.scheduleForm.startCtrCd,
        startPlcCd: this.scheduleForm.startPlcCd,
        destCtrCd: this.scheduleForm.destCtrCd,
        destPlcCd: this.scheduleForm.destPlcCd,
        legIdx: '0',
        pointChangeYN: '',
        filterPolCd: '',
        pointLength: '',
        startPlcName: this.scheduleForm.startPlcName,
        // filterYn: this.filterYn,
        searchYN: 'Y',
        filterPodCd: '',
        hiddestPlcCd: '',
        startDt: this.yyyymm.replaceAll('.', ''),
        weeks: this.scheduleForm.weeks,
        filterTranMax: '0',
        filterTranMin: '0',
        hidstartPlcCd: '',
        destPlcName: this.scheduleForm.destPlcName,
        main: 'N'
      }
      // schedules.excelDownloadLeg(params).then((response) => {
      scheduleLT.excelDownload(params).then((response) => {
        const fileName = params.startPlcCd + '_' + params.destPlcCd + '_LongTermSchedule.xls'
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(new Blob([response.data]), fileName)
        } else {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', fileName)
          document.body.appendChild(fileLink)

          fileLink.click()
          fileLink.remove()
          window.URL.revokeObjectURL(fileURL)
        }
      })
    },
    async bookingBooking4List (vo) {
      if (!this.$ekmtcCommon.isEmpty(this.etaBookingMsg)) {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.etaBookingMsg }) // 해당 국가 ( 한국 ) 수출화물에 대해서만 부킹이 가능합니다.
        return
      }

      if (!await this.kmtcSpotacceptQuote(vo)) {
        this.$ekmtcCommon.alertDefault(this.$t('운임확정 오류발생'))
        return
      }

      // console.log('@@@@@ bookingBooking4List @@@@@')
      // console.log(vo)
      this.makeBookingParam(vo)
      if (this.vessel2ndParam !== '') {
        var hidCS008I = this.bkgParams.hidCS008I.split('@_@')
        var nd2hidCS008I = ''
        var length2 = hidCS008I.length - 1
        for (let i = 0; i < length2; i++) {
          if (i === 2) {
            nd2hidCS008I += this.vessel2ndParam + '@_@'
          } else {
            nd2hidCS008I += hidCS008I[i] + '@_@'
          }
        }
        this.bkgParams.hidCS008I = nd2hidCS008I
      }
      // console.log(this.bkgParams)
      this.bkgScheduleData = vo
      this.detailInfo4Booking(vo)
    },
    makeBookingParam (vo) {
      var finalEta = ''
      if (vo.ts === 'Y') {
        finalEta = vo.podEtd2 + vo.etaTm2
      } else {
        finalEta = vo.eta + vo.etaTm
      }
      this.bkgParams.vslCd = vo.vslCd // [0]선명코드
      this.bkgParams.voyNo = vo.voyNo // [1]항차
      this.bkgParams.vslNm = vo.vslNm // [2]선명
      this.bkgParams.polName = vo.polNm // [3]출발지 포트명
      this.bkgParams.podName = vo.podNm // [4]도착지 포트명
      this.bkgParams.etd = vo.etd // [5]입항일
      this.bkgParams.etdTm = vo.etdTm // [6]입항시간
      this.bkgParams.eta = vo.polEtb // [7]출항일
      this.bkgParams.etaTm = vo.polEtbTm // [8]출항시간
      this.bkgParams.polPortCd = vo.pol // [9]출발지 포트코드
      this.bkgParams.podPortCd = vo.pod // [10]도착지 포트코드
      this.bkgParams.polCtrCd = vo.polCtrCd // [11]출발지 국가코드
      this.bkgParams.podCtrCd = vo.podCtrCd // [12]도착지 국가코드
      this.bkgParams.cct = vo.cct // [13] ?
      this.bkgParams.docCls = vo.bkgDocCls // [14]서류마감
      this.bkgParams.docPic = vo.bkgDocPic // [15]서류마감 담당자
      this.bkgParams.polTrmlCd = vo.otrmlCd // [16]출발지 터미널 코드
      this.bkgParams.podTrmlCd = vo.itrmlCd // [18]도착지 터미널 코드
      this.bkgParams.mrnNo = vo.mrnNo // [18]MRN Number
      this.bkgParams.callSign = vo.callSign // [19]Call sign
      this.bkgParams.hidCS008I = vo.info // [20]info (@_@로 붙어있는 정보)
      this.bkgParams.originInfo = vo.info // [20]info Backup (@_@로 붙어있는 정보)
      this.bkgParams.mfCls = vo.bkgMfCls // [21]EDI 신고마감
      this.bkgParams.cgoCls = vo.bkgCgoCls // [22]반입마감
      this.bkgParams.cgoPic = vo.bkgCgoPic // [23]반입마감 담당자
      this.bkgParams.finalEta = finalEta // [24]최종ETA
      this.bkgParams.cfsCls = vo.cfsCls // [25]cfs 반입마감
      this.bkgParams.cfsPic = vo.bkgCfsPic // [26]cfs 반입마감 담당자
      this.bkgParams.bkgCloseYn = vo.bkgClose // [27] booking close 여부
      this.bkgParams.prrmSgEtd = vo.prrmSgEtd // [28] EDI 마감시간
      this.bkgParams.polTrmlNm = vo.otrmlNm // [29] 출발지 터미널명
      this.bkgParams.railCheck = '' // [30] rail booking 여부 ? >> schedule 처리 부분 없음(주석처리)
      this.bkgParams.frtAppNo = vo.frtAppNo // [31] 운임 APPLICATION 번호 >> 견적운임조회
      this.bkgParams.logDtm = '' // [32] >> 견적운임조회 >> _notyet
      // this.bkgParams.srRest = '_notyet' // [33] SR 생성여부 >> 부킹 제약사항체크
      // this.bkgParams.localDtm = '20210906151954_notyet' // [34] 현재 시간 >> 부킹 제약사항체크
      // this.bkgParams.vslRest = '0_notyet' // [35] 선박 close 여부 >> 부킹 제약사항체크
      // this.bkgParams.cgoRest = '202109071600_notyet' // [36] 반입 마감시한 >> 부킹 제약사항체크
      this.bkgParams.rfYn = 'N'
      // console.log(this.bkgParams)
    },
    detailInfo4Booking (vo) {
      let longParam = vo.ts + ';' +
        vo.vslNm + ';' +
        vo.vslCd + ';' +
        vo.voyNo + ';' +
        vo.transitTime + ';' +
        vo.polEtb + ';' +
        vo.polEtbTm + ';' +
        vo.etd + ';' +
        vo.etdTm + ';' +
        vo.eta + ';' +
        vo.etaTm + ';' +
        vo.polTml + ';' +
        vo.podTml + ';' +
        vo.polPicTel + ';' +
        vo.itrmlNm + ';' +
        vo.podPicTel + ';' +
        vo.closeTime + ';' +
        vo.cct + ';' +
        vo.mrnNo + ';' +
        vo.callSign + ';' +
        vo.bkgClose + ';' +
        vo.mfCls + ';' +
        vo.webCls + ';' +
        vo.cgoCls + ';' +
        vo.jrstCstm + ';' +
        vo.info + ';' +
        vo.pol + ';' +
        vo.pod + ';' +
        vo.bkgDocCls + ';' +
        vo.bkgCgoCls + ';' +
        vo.bkgMfCls + ';' +
        vo.cfsCls + ';' +
        vo.pod1 + ';' +
        vo.podEtd1 + ';' +
        vo.podTrml1 + ';' +
        vo.podPicTel1 + ';' +
        vo.etaTm1 + ';' +
        vo.transitTime1 + ';' +
        vo.pol1 + ';' +
        vo.vslNm2 + ';' +
        vo.vslCd2 + ';' +
        vo.voyNo2 + ';' +
        vo.polEtd2 + ';' +
        vo.podEtd2 + ';' +
        vo.polTrml2 + ';' +
        vo.podTrml2 + ';' +
        vo.polPicNm2 + ';' +
        vo.polPicTel2 + ';' +
        vo.podPicTel2 + ';' +
        vo.etdTm2 + ';' +
        vo.etaTm2 + ';' +
        vo.transitTime2 + ';' +
        vo.tsDegree + ';' +
        vo.vslNm3 + ';' +
        vo.vslCd3 + ';' +
        vo.voyNo3 + ';' +
        vo.polEtd3 + ';' +
        vo.etdTm3 + ';' +
        vo.polTrml3 + ';' +
        vo.polPicTel3 + ';' +
        vo.podEtd3 + ';' +
        vo.etaTm3 + ';' +
        vo.podTrml3 + ';' +
        vo.podPicTel3 + ';' +
        vo.transitTime3 + ';' +
        vo.vslNm4 + ';' +
        vo.vslCd4 + ';' +
        vo.voyNo4 + ';' +
        vo.polEtd4 + ';' +
        vo.etdTm4 + ';' +
        vo.polTrml4 + ';' +
        vo.polPicTel4 + ';' +
        vo.podEtd4 + ';' +
        vo.etaTm4 + ';' +
        vo.podTrml4 + ';' +
        vo.podPicTel4 + ';' +
        vo.transitTime4 + ';' +
        vo.polTrml1 + ';' +
        vo.pol2 + ';' +
        vo.pod3 + ';' +
        vo.pod4 + ';' +
        vo.pod2 + ';' +
        vo.bkgDocPic + ';' +
        vo.bkgCgoPic + ';' +
        vo.vslNm1 + ';' +
        vo.pol3 + ';' +
        vo.pol4 + ';' +
        vo.bkgCfsPic + ';' +
        vo.bkgVslCd + ';' +
        vo.bkgVoyNo + ';' +
        vo.polCtrCd + ';' +
        vo.rteCd
      const params = {
        param: longParam,
        bound: 'O'
      }
      schedules.getLegScheduleDetail(params).then((rtnData) => {
        this.fnDetailLayerResponse2(rtnData, vo)
        this.bookingBooking(vo)
      })
    },
    fnDetailLayerResponse2 (rtnData, vo) {
      var tsDegree = vo.tsDegree
      this.detailResp2.tsDegree = vo.tsDegree
      this.raTsParam = ''
      this.detailResp2.ts = vo.ts
      this.detailResp2.vslCd = vo.vslCd
      this.detailResp2.pol = vo.pol
      this.detailResp2.pod = vo.pod
      this.detailResp2.etd = vo.etd
      this.detailResp2.vslNm = vo.vslNm
      this.detailResp2.bkgClose = vo.bkgClose
      if (rtnData.data.bkgMdlCloseYn === 'Y') {
        this.detailResp2.bkgClose = 'Y'
      }
      this.detailResp2.dtBkgYn = rtnData.data.dtBkgYn
      // 1st Vessel
      this.detailResp2.vslNm1 = vo.vslNm1
      this.detailResp2.voyNo = vo.voyNo
      this.detailResp2.rteCd = rtnData.data.rteCd
      this.detailResp2.polNm = rtnData.data.polNm
      this.detailResp2.podNm = rtnData.data.podNm
      this.detailResp2.pod1Nm = rtnData.data.pod1Nm
      this.detailResp2.transitTime = vo.transitTime
      this.detailResp2.transitTime1 = vo.transitTime1
      this.detailResp2.polEtbDT = this.changeDate2Display(vo.polEtb, vo.polEtbTm, this.lang)
      this.detailResp2.etdDT = this.changeDate2Display(vo.etd, vo.etdTm, this.lang)
      this.detailResp2.polTml = this.fnTrmlText(vo.polTml, rtnData.data.polTmlNm)
      this.detailResp2.etaDT = this.changeDate2Display(vo.podEtd1, vo.etaTm1, this.lang)
      this.detailResp2.podTml = this.fnTrmlText(vo.podTrml1, rtnData.data.podTrml1Nm)
      this.detailResp2.polTmlCd = vo.polTml
      this.detailResp2.podTmlCd = vo.podTml
      if (vo.ts === 'Y') {
        // 2nd Vessel
        this.detailResp2.vslNm2 = vo.vslNm2
        this.detailResp2.vslCd2 = vo.vslCd2
        this.detailResp2.voyNo2 = vo.voyNo2
        this.detailResp2.rteCd2 = rtnData.data.rteCd2
        this.detailResp2.pod1Nm = rtnData.data.pod1Nm
        this.detailResp2.pod2Nm = rtnData.data.pod2Nm
        this.detailResp2.transitTime2 = vo.transitTime2
        this.detailResp2.transitTSTime2 = vo.transitTSTime2
        this.detailResp2.polEtbDT2 = this.changeDate2Display(vo.polEtd2, vo.etdTm2, this.lang)
        this.detailResp2.polTml2 = this.fnTrmlText(vo.polTrml2, rtnData.data.polTrml2Nm)
        this.detailResp2.polTmlCd2 = vo.polTrml2
        this.detailResp2.etaDT2 = this.changeDate2Display(vo.podEtd2, vo.etaTm2, this.lang)
        this.detailResp2.podTml2 = this.fnTrmlText(vo.podTrml2, rtnData.data.podTrml2Nm)
        this.detailResp2.podTmlCd2 = vo.podTrml2
        this.detailResp2.pol1 = vo.pol1
        this.detailResp2.pod1 = vo.pod1
        this.detailResp2.pol2 = vo.pol2
        this.detailResp2.pod2 = vo.pod2
        // 2nd Vessel
        // this.scheduleDetailData.pol2nd = vo.pol
        // this.scheduleDetailData.pod2nd = vo.pod
        // this.scheduleDetailData.etd2nd = vo.etd
        // raTsParam
        this.raTsParam = '1;'
        this.raTsParam += vo.pol1 + ';'
        this.raTsParam += vo.pod1 + ';'
        this.raTsParam += vo.vslCd + ';'
        this.raTsParam += vo.voyNo + ';'
        this.raTsParam += vo.rteCd + ';'
        this.raTsParam += vo.etd + ';'
        this.raTsParam += '@'
        this.raTsParam += '2;'
        this.raTsParam += vo.pol2 + ';'
        this.raTsParam += vo.pod2 + ';'
        this.raTsParam += vo.vslCd2 + ';'
        this.raTsParam += vo.voyNo2 + ';'
        this.raTsParam += vo.rteCd2 + ';'
        this.raTsParam += vo.polEtd2 + ';'
        if (tsDegree >= 3) {
          this.detailResp2.vslNm3 = vo.vslNm3
          this.detailResp2.vslCd3 = vo.vslCd3
          this.detailResp2.voyNo3 = vo.voyNo3
          this.detailResp2.rteCd3 = rtnData.data.rteCd3
          this.detailResp2.pod2Nm = rtnData.data.pod2Nm
          this.detailResp2.pod3Nm = rtnData.data.pod3Nm
          this.detailResp2.transitTime3 = vo.transitTime3
          this.detailResp2.transitTSTime3 = vo.transitTSTime3
          this.detailResp2.polEtbDT3 = this.changeDate2Display(vo.polEtd3, vo.etdTm3, this.lang)
          this.detailResp2.polTml3 = this.fnTrmlText(vo.polTrml3, rtnData.data.polTrml3Nm)
          this.detailResp2.polTmlCd3 = vo.polTrml3
          this.detailResp2.etaDT3 = this.changeDate2Display(vo.podEtd3, vo.etaTm3, this.lang)
          this.detailResp2.podTml3 = this.fnTrmlText(vo.podTrml3, rtnData.data.podTrml3Nm)
          this.detailResp2.podTmlCd3 = vo.podTrml3
          this.raTsParam += '@'
          this.raTsParam += '3;'
          this.raTsParam += vo.pol3 + ';'
          this.raTsParam += vo.pod3 + ';'
          this.raTsParam += vo.vslCd3 + ';'
          this.raTsParam += vo.voyNo3 + ';'
          this.raTsParam += vo.rteCd3 + ';'
          this.raTsParam += vo.polEtd3 + ';'
        }
        if (tsDegree >= 4) {
          this.detailResp2.vslNm4 = vo.vslNm4
          this.detailResp2.vslCd4 = vo.vslCd4
          this.detailResp2.voyNo4 = vo.voyNo4
          this.detailResp2.rteCd4 = rtnData.data.rteCd4
          this.detailResp2.pod3Nm = rtnData.data.pod3Nm
          this.detailResp2.pod4Nm = rtnData.data.pod4Nm
          this.detailResp2.transitTime4 = vo.transitTime4
          this.detailResp2.transitTSTime4 = vo.transitTSTime4
          this.detailResp2.polEtbDT4 = this.changeDate2Display(vo.polEtd4, vo.etdTm4, this.lang)
          this.detailResp2.polTml4 = this.fnTrmlText(vo.polTrml4, rtnData.data.polTrml4Nm)
          this.detailResp2.polTmlCd4 = vo.polTrml4
          this.detailResp2.etaDT4 = this.changeDate2Display(vo.podEtd4, vo.etaTm4, this.lang)
          this.detailResp2.podTml4 = this.fnTrmlText(vo.podTrml4, rtnData.data.podTrml4Nm)
          this.detailResp2.podTmlCd4 = vo.podTrml4
          this.raTsParam += '@'
          this.raTsParam += '4;'
          this.raTsParam += vo.pol4 + ';'
          this.raTsParam += vo.pod4 + ';'
          this.raTsParam += vo.vslCd4 + ';'
          this.raTsParam += vo.voyNo4 + ';'
          this.raTsParam += vo.rteCd4 + ';'
          this.raTsParam += vo.polEtd4 + ';'
        }
      }
      // 서류마감
      this.detailResp2.bkgDocCls = this.changeDateTime2Display(vo.bkgDocCls, this.lang)
      // CY Open
      // VGM 마감 >> bkgDocCls 동일?
      // Container 반입마감
      this.detailResp2.bkgCgoCls = this.changeDateTime2Display(vo.bkgCgoCls, this.lang)
      // EDI 신고마감
      this.detailResp2.bkgMfCls = this.changeDateTime2Display(vo.bkgMfCls, this.lang)
      // CFS 반입마감
      this.detailResp2.cfsCls = this.changeDateTime2Display(vo.cfsCls, this.lang)
      // MRN No.
      this.detailResp2.mrnNo = vo.mrnNo
      // CALL SIGN
      this.detailResp2.callSign = vo.callSign
      // 관할세관
      this.detailResp2.jrstCstm = rtnData.data.jrstCstm
      // 출항 Terminal >> polTml 동일
      // 선박 변경 취소용
      this.tempDetailResp2.vslNm2BK = this.detailResp2.vslNm2
      this.tempDetailResp2.voyNo2BK = this.detailResp2.voyNo2
      this.tempDetailResp2.rteCd2BK = this.detailResp2.rteCd2
      this.tempDetailResp2.transitTime2BK = this.detailResp2.transitTime2
      this.tempDetailResp2.polEtbDT2BK = this.detailResp2.polEtbDT2
      this.tempDetailResp2.etaDT2BK = this.detailResp2.etaDT2
      // R/A check
      // if (this.auth.userId !== '') {
      //   this.popFreAppNoCheck()
      // }
      // 상세정보 표시
      this.detailLayer2 = 'Y'
      if (vo.bkgClose === 'N') {
        this.afterClickDetail = 'Y'
      } else {
        this.afterClickDetail = 'N'
      }
      this.makeBookingParam(vo)
    },
    bookingBooking (vo) {
      const params = {
        asVslCd: vo.vslCd,
        asVoyNo: vo.voyNo,
        asPolPortCd: vo.pol,
        asPodPortCd: vo.pod
      }
      schedules.checkBkgRestriction(params).then((data) => {
        this.bkgParams.srRest = data.data.srRest // [33] SR 생성여부 >> 부킹 제약사항체크
        this.bkgParams.localDtm = data.data.localDtm // [34] 현재 시간 >> 부킹 제약사항체크
        this.bkgParams.vslRest = data.data.vslRest // [35] 선박 close 여부 >> 부킹 제약사항체크
        this.bkgParams.cgoRest = data.data.cgoRest // [36] 반입 마감시한 >> 부킹 제약사항체크
      })
      // console.log('$$$$$ bookingBooking / this.bkgScheduleData $$$$$')
      // console.log(this.bkgScheduleData)
      // this.insertWB242H('03')
      this.findSA271Yn()
      this.popFreAppNo()
    },
    // 부킹 넘어 가기전에 실적 체크 / fnRateChkYn(As-is)
    findSA271Yn (bkgClose) {
      var eiCatCd = (this.bkgParams.podCtrCd === 'KR') ? 'I' : 'O'
      if (this.oEngHcsYn === 'Y' && this.userCtrCd !== 'KR') {
        eiCatCd = 'O'
      }
      if ('TH|CN|HK|ID|IN'.indexOf(this.userCtrCd) > -1) {
        eiCatCd = 'O'
      }
      var rteCd = this.detailResp2.rteCd
      if (rteCd === undefined) {
        if (this.detailResp2.tsDegree >= 2) {
          rteCd = this.detailResp2.rteCd2
        }
      }
      if (rteCd === undefined) {
        if (this.detailResp2.tsDegree >= 3) {
          rteCd = this.detailResp2.rteCd3
        }
      }
      if (rteCd === undefined) {
        if (this.detailResp2.tsDegree >= 4) {
          rteCd = this.detailResp2.rteCd4
        }
      }
      const params = {
        porCtrCd: this.bkgParams.polCtrCd,
        porPlcCd: this.bkgParams.polPortCd,
        dlyCtrCd: this.bkgParams.podCtrCd,
        dlyPlcCd: this.bkgParams.podPortCd,
        etd: this.bkgParams.etd,
        vslCd: this.bkgParams.vslCd,
        voyNo: this.bkgParams.voyNo,
        rteCd: rteCd,
        eiCatCd: eiCatCd,
        raTsParam: this.raTsParam
      }
      schedules.findSA271Yn(params).then((data) => {
        if ('Y|X'.indexOf(data.data.rtn) > -1) {
          this.popFreAppNo(bkgClose, 'Y')
        } else {
          this.popFreAppNo(bkgClose)
        }
      })
    },
    // 견적운임문의 클릭 / fnPopReqestApp(As-is)
    // 1.실적이 존재하면 해당 구간의 정보를 팝업
    // 2.실적이 없고 Rate Applicationd에 존재시 해당 구간 정보 팝업.
    // 3.실적이 없고 Rate Applicationd에 없는 경우, Rate Management 정보를 팝업.
    // 4.위 모두 미해당 시 구간 정보 Rate Management 등록
    popFreAppNo (bkgClose, rateYn) {
      var eiCatCd = (this.bkgParams.podCtrCd === 'KR') ? 'I' : 'O'
      if (this.oEngHcsYn === 'Y' && this.userCtrCd !== 'KR') {
        eiCatCd = 'O'
      }
      if ('TH|CN|HK|ID|IN'.indexOf(this.userCtrCd) > -1) {
        eiCatCd = 'O'
      }
      const params = {
        porCtrCd: this.bkgParams.polCtrCd,
        porPlcCd: this.bkgParams.polPortCd,
        dlyCtrCd: this.bkgParams.podCtrCd,
        dlyPlcCd: this.bkgParams.podPortCd,
        etd: this.bkgParams.etd,
        vslCd: this.bkgParams.vslCd,
        voyNo: this.bkgParams.voyNo,
        rteCd: this.detailResp2.rteCd,
        eiCatCd: eiCatCd,
        hotDealYn: 'N',
        hotDealReqRno: '',
        raTsParam: this.raTsParam
      }
      // 부킹 클로즈 유무 추가
      if (!bkgClose || this.detailResp2.dtBkgYn === 'Y') {
        bkgClose = 'N'
      }
      schedules.popFreAppNo(params).then((data) => {
        // this.bkgParams.frtAppNo = data.data.frtAppNo
        if (data.data.frtResult === 'Y') {
          // 1. 실적이 존재하면 해당 구간의 정보를 팝업
          // 2. 실적이 없고 Rate Applicationd에 존재시 해당 구간 정보 팝업
          if (rateYn && rateYn === 'Y') {
            // this.setScheduleInfo() // Booking gogosing
          } else {
            this.popFreSurcharge(data.data.frtAppNo, data.data.frtResult, data.data.reqRno, bkgClose)
          }
        } else if (data.data.frtResult === 'A') {
          // 3. 실적이 없고 Rate Applicationd에 없는 경우, Rate Management 정보를 팝업
          if (this.lang !== 'KOR') {
            // this.fnPopFindPrice(data.data.frtAppNo, data.data.frtResult, data.data.reqRno, bkgClose)
            // 운임문의 팝업(SCHD010P080)?!
          } else {
            this.popFreSurcharge(data.data.frtAppNo, data.data.frtResult, data.data.reqRno, bkgClose)
          }
        } else {
          // 4. 운임요청
          // fnpopRatePicNm(bkgClose); >> 운임문의 팝업(SCHD010P080)?!
        }
        this.throwBookingParams()
      })
    },
    popFreSurcharge (frtAppNo, frtResult, reqRno, bkgClose) {
      const params = {
        porCtrCd: this.bkgParams.polCtrCd,
        porPlcCd: this.bkgParams.polPortCd,
        dlyCtrCd: this.bkgParams.podCtrCd,
        dlyPlcCd: this.bkgParams.podPortCd,
        etd: this.bkgParams.etd,
        frtAppNo: frtAppNo,
        bkgVslCd: this.bkgParams.vslCd,
        bkgVoyNo: this.bkgParams.voyNo,
        vslCd: this.bkgParams.vslCd,
        voyNo: this.bkgParams.voyNo,
        rteCd: this.detailResp2.rteCd,
        eiCatCd: (this.bkgParams.podCtrCd === 'KR') ? 'I' : 'O',
        frtResult: frtResult,
        reqRno: reqRno,
        hotDealYn: 'N',
        hotReqRno: '',
        bkgClose: bkgClose,
        raTsParam: this.raTsParam
      }
      schedules.popFreSurcharge(params).then((data) => {
        console.log('@@@@@ popFreSurcharge Return Data @@@@@')
        console.log(data)
      })
    },
    popFreAppNoCheck () {
      this.eiCatCd = (this.bkgParams.podCtrCd === 'KR') ? 'I' : 'O'
      if (this.oEngHcsYn === 'Y' && this.userCtrCd !== 'KR') {
        this.eiCatCd = 'O'
      }
      if ('TH|CN|HK|ID|IN'.indexOf(this.userCtrCd) > -1) {
        this.eiCatCd = 'O'
      }
      const params = {
        porCtrCd: this.scheduleForm.startCtrCd,
        porPlcCd: this.scheduleForm.startPlcCd,
        dlyCtrCd: this.scheduleForm.destCtrCd,
        dlyPlcCd: this.scheduleForm.destPlcCd,
        eiCatCd: this.eiCatCd,
        etd: this.detailResp2.etd //this.yyyymmdd.replaceAll('.', '')
      }
      schedules.popFreAppNo(params).then((data) => {
        // console.log('@@@@@ popFreAppNoCheck Return Data @@@@@')
        // console.log(data)
        this.frtResult = data.data.frtResult
        this.freAppData = data.data

        this.detailResp2.frtResult = this.freAppData.frtResult
        this.frtResult = this.freAppData.frtResult
        this.detailResp2.strDt = this.freAppData.strDt
        this.detailResp2.endDt = this.freAppData.endDt
        this.detailResp2.frtAppNo = this.freAppData.frtAppNo
        this.detailResp2.frtEstnYn = this.freAppData.frtEstnYn
        this.detailResp2.reqRno = this.freAppData.reqRno
      })
    },
    throwBookingParams () {
      const obj = {
        params: this.bkgParams,
        schedule: this.bkgScheduleData,
        detail: this.detailResp2//this.bkgDetailData
      }
      if (this.bkgOrNot) {
        this.$emit('ScheduleLegPop', obj)
        this.$emit('close')
      } else {
        this.$router.push({ name: 'booking-new', params: { scheduleObj: obj } }).catch(() => {})
        //this.$router.push('/booking-new?obj=' + obj)
      }
    },
    insertWB242H () {
      let params = {}
      params = {
        logCatCd: '05'
      }
      schedules.insertWB242H(params).then((resultCode) => {
        this.key = resultCode.data
      })
    },
    insertCS243I () {
      if (this.reqVocCont === '' || this.reqVocCont === undefined) {
        this.$ekmtcCommon.alertDefault(this.$t('문의할 내용이 없습니다.'))
        return
      }

      let params = {}

      if (this.clickRateApp === 'N') {
        params = {
          rmk: this.reqVocCont
        }
      } else {
        let strTemp = 'POL : ' + this.polChk.plcCd + ', ' + 'POD : ' + this.podChk.plcCd + '\n' + 'IDEAL RATE : ' + this.ft20 + '/' + this.ft40 + '\n'
        params = {
          rmk: strTemp + this.reqVocCont
        }
      }

      scheduleLT.insertCS243I(params).then((resultCode) => {
        if (resultCode.data === 1) {
          this.clickRateApp = 'N'
          this.$ekmtcCommon.alertDefault(this.$t('요청하신 문의사항이 접수되었습니다.'))
          this.reqVocCont = this.$t('msg.MAIN010G030.039')
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('문의사항 접수시 에러가 발생하였습니다.'))
        }
      })
    },
    async kmtcSpotacceptQuote (vo) {
      const params = {
        porCtrCd: vo.polCtrCd, // [11]출발지 국가코드
        porPlcCd: vo.pol, // [9]출발지 포트코드
        dlyCtrCd: vo.podCtrCd, // [12]도착지 국가코드
        dlyPlcCd: vo.pod, // [10]도착지 포트코드
        etd: vo.etd, // [5]입항일
        rteCd: vo.rteCd,
        vslCd: vo.vslCd, // [0]선명코드
        voyNo: vo.voyNo, // [1]항차
        eiCatCd: this.bound,
        of20Rate: vo.kmtcSpotSurcharge[0].rate20,
        of40Rate: vo.kmtcSpotSurcharge[0].rate40,
        strDt: vo.kmtcSpotSurcharge[0].strDt,
        endDt: vo.kmtcSpotSurcharge[0].endDt
      }
      await vslScheduleInfo.kmtcSpotAcceptQuote(params).then((rtnData) => {
        vo.frtAppNo = rtnData.data.frtAppNo
      })

      if (vo.frtAppNo !== '' && vo.frtAppNo !== undefined) {
        return true
      } else {
        return false
      }
    },
    // KMTC ON FAQ 다운로드
    async pdfDownload (fileName) {
      const result = await intergis.excelDownload(fileName)
      console.log('excelDownload')
      console.log(result)
      //blob 생성
      const blob = new Blob([result.data], { type: result.headers['content-type'] })

      if (window.navigator.msSaveOrOpenBlob) { //browser Check - IE
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else { //browser Check - 그 외
        //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.target = '_self'
        link.download = fileName
        link.click()

        //url 사용 후 메모리에서 제거
        window.URL.revokeObjectURL(url)
      }
    },
    chgPolCtrPlcEnm (e) {
      if (e.target.value === '') { // 직접입력인 경우
        this.chkChgPol = 'N'
        return
      } else { // 선택한 경우
        this.chkChgPol = 'Y'
        const frm2 = document.querySelector('#frm2')
        const elemPol = frm2.querySelector('#polKS')
        this.$ekmtcCommon.hideErrorTooltip(elemPol)
      }

      this.pol.plcCd = this.polCtrPlcInfoList[e.target.value].polPlcCd
      this.pol.ctrCd = this.polCtrPlcInfoList[e.target.value].polCtrCd
    },
    chgPodCtrPlcEnm (e) {
      if (e.target.value === '') { // 직접입력인 경우
        this.chkChgPod = 'N'
        return
      } else { // 선택한 경우
        this.chkChgPod = 'Y'
        const frm2 = document.querySelector('#frm2')
        const elemPod = frm2.querySelector('#podKS')
        this.$ekmtcCommon.hideErrorTooltip(elemPod)
      }

      this.pod.plcCd = this.podCtrPlcInfoList[e.target.value].podPlcCd
      this.pod.ctrCd = this.podCtrPlcInfoList[e.target.value].podCtrCd
    }
  }
}

</script>
