var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container_ekmtc",
      staticStyle: { "background-color": "rgba(165,223,236,0.25)" },
    },
    [
      _c("div", { staticClass: "content resize" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(
            "새로운 디지털 세일즈, " +
              _vm._s(_vm.$t("msg.MAIN010G030.038")) +
              "으로 GO!"
          ),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("<KMTC ON 이란?>")]),
          _c("br"),
          _vm._v(
            " ☞ 고려해운이 야심차게 준비한 신규 서비스, 디지털 플랫폼으로 선복보장 서비스 구간 운임을 한눈에 조회하고, 이용하실 수 있습니다."
          ),
          _c("br"),
          _c("br"),
          _c(
            "a",
            {
              staticClass: "button lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.pdfDownload("KMTC_ON_FAQ_231019.pdf")
                },
              },
            },
            [_vm._v("KMTC ON FAQ 다운로드 (CLICK)")]
          ),
          _vm._m(0),
          _c("br"),
          _c("div", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v("STEP 1. 원하시는 선복보장 스케줄을 검색해보세요."),
          ]),
          _vm._v(
            " KMTC ON 서비스 구간은 현재 일부 구간만 가능합니다. 꾸준히 지역을 확장하여 제공하겠습니다."
          ),
          _c("br"),
          _c("br"),
          _c("div", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v("STEP 2. 조회한 구간의 운임을 한눈에 확인해 보세요."),
          ]),
          _vm._v(
            " MARKET 및 운임 데이터 분석을 통해 KMTC ON 만을 위한 합리적인 운임을 제공합니다."
          ),
          _c("br"),
          _c("br"),
          _c("div", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v("STEP 3. 선복보장 서비스."),
          ]),
          _vm._v(
            " KMTC ON을 위한 전용 SPACE를 배정하여 SPACE 부족으로 인한 변경 없이 스케줄을 보장합니다."
          ),
          _c("br"),
          _c("br"),
          _c("div", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v("STEP 4. 부킹 이후 업무는 동일하게."),
          ]),
          _vm._v(
            " 부킹 이후 업무는 기존과 동일하게 e-KMTC 사이트에서 이용 가능하십니다."
          ),
          _c("br"),
        ]),
        _c("br"),
        _c("div", { staticClass: "content_box" }, [
          _c(
            "div",
            { staticClass: "popup_dim", attrs: { id: "schKS_popup" } },
            [
              _vm.customComponentKS
                ? _c(_vm.customComponentKS, {
                    tag: "component",
                    attrs: { "parent-info": _vm.parentInfo },
                    on: { close: _vm.closePopup },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("form", { attrs: { id: "frm2" } }, [
            _c("div", { staticClass: "border_bottom_gray_box" }, [
              _c("table", { staticClass: "tbl_search" }, [
                _vm._m(1),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100.002")))]),
                    _c(
                      "td",
                      {
                        staticClass: "position_relative",
                        attrs: { colspan: "2" },
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.polCtrPlcEnm,
                                expression: "polCtrPlcEnm",
                              },
                            ],
                            staticClass: "col_10",
                            attrs: { id: "polCtrPlcEnm" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.polCtrPlcEnm = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.chgPolCtrPlcEnm,
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(this.$t("msg.CSBK100.050"))),
                            ]),
                            _vm._l(_vm.polCtrPlcInfoList, function (vo) {
                              return _c(
                                "option",
                                { key: vo.seq, domProps: { value: vo.seq } },
                                [_vm._v(_vm._s(vo.polCtrPlcEnm))]
                              )
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                    _c(
                      "td",
                      [
                        _c("e-auto-complete-place", {
                          attrs: {
                            id: "polKS",
                            "ctr-cd": _vm.pol.ctrCd,
                            "plc-cd": _vm.pol.plcCd,
                            "is-char": true,
                            "is-char-alert": true,
                            disabled: _vm.chkChgPol === "Y",
                          },
                          on: { change: _vm.changePol },
                        }),
                      ],
                      1
                    ),
                    _c("th", [
                      _c("span", { staticClass: "ml20" }, [
                        _vm._v(_vm._s(_vm.$t("msg.VOSD100.003"))),
                      ]),
                    ]),
                    _c("td", { staticClass: "position_relative" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.podCtrPlcEnm,
                              expression: "podCtrPlcEnm",
                            },
                          ],
                          staticClass: "col_10",
                          attrs: { id: "podCtrPlcEnm" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.podCtrPlcEnm = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              _vm.chgPodCtrPlcEnm,
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(this.$t("msg.CSBK100.050"))),
                          ]),
                          _vm._l(_vm.podCtrPlcInfoList, function (vo) {
                            return _c(
                              "option",
                              { key: vo.seq, domProps: { value: vo.seq } },
                              [_vm._v(_vm._s(vo.podCtrPlcEnm))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-auto-complete-place", {
                          attrs: {
                            id: "podKS",
                            "ctr-cd": _vm.pod.ctrCd,
                            "plc-cd": _vm.pod.plcCd,
                            "is-char": true,
                            "is-char-alert": true,
                            disabled: _vm.chkChgPod === "Y",
                          },
                          on: { change: _vm.changePod },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "tr",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.section2nd === "Y",
                          expression: "section2nd ==='Y'",
                        },
                      ],
                    },
                    [
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100.002")))]),
                      _c(
                        "td",
                        [
                          _c("e-auto-complete-place", {
                            attrs: {
                              id: "polKS2",
                              "ctr-cd": _vm.pol2.ctrCd,
                              "plc-cd": _vm.pol2.plcCd,
                            },
                            on: { change: _vm.changePol2 },
                          }),
                        ],
                        1
                      ),
                      _c("th", [
                        _c("span", { staticClass: "ml20" }, [
                          _vm._v(_vm._s(_vm.$t("msg.VOSD100.003"))),
                        ]),
                      ]),
                      _c(
                        "td",
                        [
                          _c("e-auto-complete-place", {
                            attrs: {
                              id: "podKS2",
                              "ctr-cd": _vm.pod2.ctrCd,
                              "plc-cd": _vm.pod2.plcCd,
                            },
                            on: { change: _vm.changePod2 },
                          }),
                        ],
                        1
                      ),
                      _c("td", { staticClass: "pr0 text-right" }, [
                        _c("span", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.close2ndSection.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.section3rd === "Y",
                          expression: "section3rd ==='Y'",
                        },
                      ],
                    },
                    [
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100.002")))]),
                      _c(
                        "td",
                        [
                          _c("e-auto-complete-place", {
                            attrs: {
                              id: "polKS3",
                              "ctr-cd": _vm.pol3.ctrCd,
                              "plc-cd": _vm.pol3.plcCd,
                            },
                            on: { change: _vm.changePol3 },
                          }),
                        ],
                        1
                      ),
                      _c("th", [
                        _c("span", { staticClass: "ml20" }, [
                          _vm._v(_vm._s(_vm.$t("msg.VOSD100.003"))),
                        ]),
                      ]),
                      _c(
                        "td",
                        [
                          _c("e-auto-complete-place", {
                            attrs: {
                              id: "podKS3",
                              "ctr-cd": _vm.pod3.ctrCd,
                              "plc-cd": _vm.pod3.plcCd,
                            },
                            on: { change: _vm.changePod3 },
                          }),
                        ],
                        1
                      ),
                      _c("td", { staticClass: "pr0 text-right" }, [
                        _c("span", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.close3rdSestion.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100.008")))]),
                    _c("td", [
                      _c("span", [
                        _c(
                          "div",
                          { staticClass: "input_calendar mid" },
                          [
                            _c("e-date-picker", {
                              on: { input: _vm.chanageSearchDt },
                              model: {
                                value: _vm.yyyymmdd,
                                callback: function ($$v) {
                                  _vm.yyyymmdd = $$v
                                },
                                expression: "yyyymmdd",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("td", { attrs: { colspan: "5" } }, [
                      _c("span", [
                        _c(
                          "span",
                          { staticClass: "wid20 d_inline_block text_center" },
                          [_vm._v("~")]
                        ),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.weeks,
                                expression: "weeks",
                              },
                            ],
                            staticClass: "wid90",
                            attrs: { name: "", id: "" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.weeks = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.changeWeeks,
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "1" } }, [
                              _vm._v("1 Week"),
                            ]),
                            _c("option", { attrs: { value: "2" } }, [
                              _vm._v("2 Week"),
                            ]),
                            _c("option", { attrs: { value: "4" } }, [
                              _vm._v("1 Month"),
                            ]),
                            _c("option", { attrs: { value: "8" } }, [
                              _vm._v("2 Month"),
                            ]),
                            _c("option", { attrs: { value: "12" } }, [
                              _vm._v("3 Month"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("span", { staticClass: "ml40" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.paramBound,
                              expression: "paramBound",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "trad21",
                            name: "trad2",
                            value: "O",
                            checked: "checked",
                          },
                          domProps: { checked: _vm._q(_vm.paramBound, "O") },
                          on: {
                            change: function ($event) {
                              _vm.paramBound = "O"
                            },
                          },
                        }),
                        _c(
                          "label",
                          { staticClass: "mr10", attrs: { for: "trad21" } },
                          [
                            _c("span"),
                            _vm._v(_vm._s(_vm.$t("msg.VOSD100_M3.008"))),
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.paramBound,
                              expression: "paramBound",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "trad22",
                            name: "trad2",
                            value: "I",
                          },
                          domProps: { checked: _vm._q(_vm.paramBound, "I") },
                          on: {
                            change: function ($event) {
                              _vm.paramBound = "I"
                            },
                          },
                        }),
                        _c(
                          "label",
                          { staticClass: "mr10", attrs: { for: "trad22" } },
                          [
                            _c("span"),
                            _vm._v(_vm._s(_vm.$t("msg.VOSD100_M3.007"))),
                          ]
                        ),
                      ]),
                      _c(
                        "span",
                        { staticClass: "pr0", staticStyle: { float: "right" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.checkBkg,
                                expression: "checkBkg",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "bk3",
                              name: "bk",
                              "true-value": "Y",
                              "false-value": "N",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.checkBkg)
                                ? _vm._i(_vm.checkBkg, null) > -1
                                : _vm._q(_vm.checkBkg, "Y"),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.checkBkg,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "Y" : "N"
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.checkBkg = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.checkBkg = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.checkBkg = $$c
                                }
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "bk3" } }, [
                            _c("span"),
                            _vm._v(
                              _vm._s(_vm.$t("msg.VOSD100_M5.013")) +
                                " (마감 선박 제외하기)"
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "border_bottom_gray_box border_bottom_none" },
              [
                _vm.$ekmtcCommon.isNotEmpty(_vm.userCtrCd)
                  ? _c("p", { staticClass: "tit_label ml10" })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.clickMySchedule === "Y",
                        expression: "clickMySchedule === 'Y'",
                      },
                    ],
                    staticClass: "ml10",
                    staticStyle: { display: "block" },
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "my_sche" },
                      _vm._l(_vm.myScheduleList, function (list, index) {
                        return _c("li", { key: index }, [
                          _c("input", {
                            attrs: {
                              type: "radio",
                              id: list.schId + "LT",
                              name: "mysch2",
                            },
                            domProps: {
                              value: list.schId,
                              checked: list.schId === _vm.schId,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.selectMySchedule(list)
                              },
                            },
                          }),
                          _c("label", { attrs: { for: list.schId + "LT" } }, [
                            _c("span"),
                            _vm._v(
                              _vm._s(list.polNm2) + " - " + _vm._s(list.podNm2)
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "d_inline_block valign_top ml10 mt2",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "tbl_icon delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.fnDeleteMySchedule(list.schId)
                                    },
                                  },
                                },
                                [_vm._v("delete")]
                              ),
                            ]
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "position_relative" }, [
              _c(
                "span",
                {
                  staticClass: "position_absolute",
                  staticStyle: { right: "0", bottom: "0" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "button blue sh",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.getLongTermSchdule.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.107")))]
                  ),
                ]
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.listSchedule4List.length !== 0,
                expression: "listSchedule4List.length !== 0",
              },
            ],
            staticClass: "content_box mt10",
          },
          [
            _c("div", { staticClass: "wrap_schedule" }, [
              _c("div", { staticClass: "schedule_top" }, [
                _c(
                  "div",
                  { staticClass: "sail_area" },
                  _vm._l(_vm.startPlcNameSel, function (list, i) {
                    return _c("p", { key: i + "PT", staticClass: "port" }, [
                      _c("span", [
                        _c(
                          "a",
                          {
                            class:
                              "port " +
                              (_vm.idxPort !== i ? "color_lightgray" : ""),
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.selectPort(i)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.startPlcNameSel[i]))]
                        ),
                      ]),
                      _c("span", [
                        _c(
                          "a",
                          {
                            class:
                              "port " +
                              (_vm.idxPort !== i ? "color_lightgray" : ""),
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.selectPort(i)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.destPlcNameSel[i]))]
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
                _c("div", { staticClass: "date_area" }, [
                  _c("div", { staticClass: "remark" }, [
                    _c("span", { staticClass: "finish" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL100_M1.017"))),
                    ]),
                    _c("span", { staticClass: "possible" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL100_M1.013"))),
                    ]),
                    _c("span", { staticClass: "after" }, [
                      _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.024"))),
                    ]),
                  ]),
                  _c("div", { staticClass: "date" }, [
                    _c("span", [
                      _c(
                        "a",
                        {
                          staticClass: "prev",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnChangeCalendar(_vm.prevMonth)
                            },
                          },
                        },
                        [_vm._v("prev")]
                      ),
                    ]),
                    _c("span", { staticClass: "year" }, [
                      _vm._v(_vm._s(_vm.currentYear)),
                    ]),
                    _c("span", { staticClass: "month" }, [
                      _vm._v(_vm._s(_vm.currentMonth)),
                    ]),
                    _c("span", [
                      _c(
                        "a",
                        {
                          staticClass: "next",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnChangeCalendar(_vm.nextMonth)
                            },
                          },
                        },
                        [_vm._v("next")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "sc_list_type" }, [
                _c(
                  "table",
                  { staticClass: "tbl_col" },
                  [
                    _c("colgroup", [
                      _c("col", { staticStyle: { width: "7%" } }),
                      _c("col", { staticStyle: { width: "15%" } }),
                      _c("col", { staticStyle: { width: "auto" } }),
                      _c("col", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.bound === "O",
                            expression: "bound === 'O'",
                          },
                        ],
                        staticStyle: { width: "24%" },
                      }),
                      _c("col", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.bound === "I",
                            expression: "bound === 'I'",
                          },
                        ],
                        staticStyle: { width: "34%" },
                      }),
                      _c("col", { staticStyle: { width: "10%" } }),
                      _c("col", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.bound === "O",
                            expression: "bound === 'O'",
                          },
                        ],
                        staticStyle: { width: "12%" },
                      }),
                      _c("col", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.bound === "O",
                            expression: "bound === 'O'",
                          },
                        ],
                        staticStyle: { width: "8%" },
                      }),
                    ]),
                    _c("thead", [
                      _c("tr", [
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CMBA100.00154"))),
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.VOSD100_M4.005"))),
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.VOSD100.002"))),
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.VOSD100.003"))),
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBL100_M1.015"))),
                        ]),
                        _c(
                          "th",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.bound === "O",
                                expression: "bound === 'O'",
                              },
                            ],
                            attrs: { scope: "col" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("msg.VOSD100_M4.009")) +
                                "/" +
                                _vm._s(_vm.$t("msg.VOSD100_M4.010"))
                            ),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.bound === "O",
                                expression: "bound === 'O'",
                              },
                            ],
                            attrs: { scope: "col" },
                          },
                          [_vm._v("Booking")]
                        ),
                      ]),
                    ]),
                    _vm._l(_vm.listSchedule4List, function (schedule, schIdx) {
                      return _c("tbody", { key: schIdx }, [
                        schedule.ts === "Y" &&
                        schIdx >= _vm.startIdx &&
                        schIdx < _vm.endIdx
                          ? _c("tr", [
                              _c("td", { attrs: { rowspan: "10" } }, [
                                _vm._v("T/S"),
                              ]),
                              _c(
                                "td",
                                {
                                  staticClass: "text_left",
                                  attrs: { rowspan: "2" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(schedule.vslNm1) +
                                      " " +
                                      _vm._s(schedule.voyNo)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "(Route : " + _vm._s(schedule.rteCd) + ") "
                                  ),
                                ]
                              ),
                              _c("td", [
                                _vm._v(" " + _vm._s(schedule.polNm) + " "),
                              ]),
                              _c("td", [
                                _vm._v(" " + _vm._s(schedule.pol2Nm) + " "),
                              ]),
                              _c("td", { attrs: { rowspan: "2" } }, [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(_vm._s(schedule.transitTime1)),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "td",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.bound === "O",
                                      expression: "bound === 'O'",
                                    },
                                  ],
                                  attrs: { rowspan: schedule.tsDegree * 2 },
                                },
                                [
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.changeDateTime2Display(
                                            schedule.bkgDocCls,
                                            _vm.lang
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.changeDateTime2Display(
                                            schedule.bkgCgoCls,
                                            _vm.lang
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.bound === "O",
                                      expression: "bound === 'O'",
                                    },
                                  ],
                                  staticClass: "color_red",
                                  attrs: { rowspan: schedule.tsDegree * 2 },
                                },
                                [
                                  schedule.bkgClose === "Y"
                                    ? _c(
                                        "div",
                                        { staticClass: "button sm4 red1" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("msg.CSBL100_M1.032")
                                              )
                                          ),
                                        ]
                                      )
                                    : schedule.bkgClose !== "M"
                                    ? _c("div", [
                                        _c("ul", [
                                          _c("li", [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "button sm3 green",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openPopup(
                                                      "FreSurchargeKmtcSpotPop",
                                                      schedule
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "msg.MAIN010G030.038"
                                                    )
                                                  )
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "$" +
                                                    _vm._s(
                                                      schedule
                                                        .kmtcSpotSurcharge[0]
                                                        .rate20
                                                    ) +
                                                    " / $" +
                                                    _vm._s(
                                                      schedule
                                                        .kmtcSpotSurcharge[0]
                                                        .rate40
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("li", { staticClass: "mt5" }, [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "button sm blue",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.bookingBooking4List(
                                                      schedule
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Booking")]
                                            ),
                                          ]),
                                        ]),
                                      ])
                                    : _c("div", [_vm._v(" - ")]),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        schedule.ts === "Y" &&
                        schIdx >= _vm.startIdx &&
                        schIdx < _vm.endIdx
                          ? _c("tr", [
                              _c("td", { staticClass: "bor-left" }, [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.VOSD100_M2.003"))
                                    ),
                                    _vm._v(
                                      " : " +
                                        _vm._s(
                                          _vm.changeDate2Display(
                                            schedule.polEtb,
                                            schedule.polEtbTm,
                                            _vm.lang
                                          )
                                        )
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.VOSD100_M2.004"))
                                    ),
                                    _vm._v(
                                      " : " +
                                        _vm._s(
                                          _vm.changeDate2Display(
                                            schedule.etd,
                                            schedule.etdTm,
                                            _vm.lang
                                          )
                                        )
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.otrmlCd,
                                          schedule.otrmlNm
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("td", [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.VOSD100_M2.003"))
                                    ),
                                    _vm._v(
                                      " : " +
                                        _vm._s(
                                          _vm.changeDate2Display(
                                            schedule.podEtd1,
                                            schedule.etaTm1,
                                            _vm.lang
                                          )
                                        )
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.podTrml1,
                                          schedule.podTrml1Nm
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        schedule.ts === "Y" &&
                        schIdx >= _vm.startIdx &&
                        schIdx < _vm.endIdx
                          ? _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "text_left bor-left",
                                  attrs: { rowspan: "2" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(schedule.vslNm2) +
                                      " " +
                                      _vm._s(schedule.voyNo2)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "(Route : " + _vm._s(schedule.rteCd2) + ") "
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            schedule.tsDegree === "2" &&
                                            schedule.vessel2ndButton === "Y",
                                          expression:
                                            "schedule.tsDegree === '2' && schedule.vessel2ndButton === 'Y'",
                                        },
                                      ],
                                      staticClass: "mt5",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "button sm",
                                          on: {
                                            click: function ($event) {
                                              return _vm.change2ndVessel4List(
                                                schedule,
                                                schIdx
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("msg.VOSD100_M5.027")
                                            ) + " "
                                          ),
                                          _c("span", {
                                            staticClass: "btn_icon icoup",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("td", [
                                _vm._v(" " + _vm._s(schedule.pod1Nm) + " "),
                              ]),
                              _c("td", [
                                _vm._v(" " + _vm._s(schedule.pod2Nm) + " "),
                              ]),
                              _c("td", { attrs: { rowspan: "2" } }, [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(_vm._s(schedule.transitTime2)),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        schedule.ts === "Y" &&
                        schIdx >= _vm.startIdx &&
                        schIdx < _vm.endIdx
                          ? _c("tr", [
                              _c("td", { staticClass: "bor-left" }, [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.VOSD100_M2.004"))
                                    ),
                                    _vm._v(
                                      " : " +
                                        _vm._s(
                                          _vm.changeDate2Display(
                                            schedule.polEtd2,
                                            schedule.etdTm2,
                                            _vm.lang
                                          )
                                        )
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.polTrml2,
                                          schedule.polTrml2Nm
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("td", [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.VOSD100_M2.003"))
                                    ),
                                    _vm._v(
                                      " : " +
                                        _vm._s(
                                          _vm.changeDate2Display(
                                            schedule.podEtd2,
                                            schedule.etaTm2,
                                            _vm.lang
                                          )
                                        )
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.podTrml2,
                                          schedule.podTrml2Nm
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        schedule.ts === "Y" &&
                        schedule.tsDegree >= 3 &&
                        schIdx >= _vm.startIdx &&
                        schIdx < _vm.endIdx
                          ? _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "text_left bor-left",
                                  attrs: { rowspan: "2" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(schedule.vslNm3) +
                                      " " +
                                      _vm._s(schedule.voyNo3)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "(Route : " + _vm._s(schedule.rteCd3) + ") "
                                  ),
                                ]
                              ),
                              _c("td", [
                                _vm._v(" " + _vm._s(schedule.pod2Nm) + " "),
                              ]),
                              _c("td", [
                                _vm._v(" " + _vm._s(schedule.pod3Nm) + " "),
                              ]),
                              _c("td", { attrs: { rowspan: "2" } }, [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(_vm._s(schedule.transitTime3)),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        schedule.ts === "Y" &&
                        schedule.tsDegree >= 3 &&
                        schIdx >= _vm.startIdx &&
                        schIdx < _vm.endIdx
                          ? _c("tr", [
                              _c("td", { staticClass: "bor-left" }, [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.VOSD100_M2.004"))
                                    ),
                                    _vm._v(
                                      " : " +
                                        _vm._s(
                                          _vm.changeDate2Display(
                                            schedule.polEtd3,
                                            schedule.etdTm3,
                                            _vm.lang
                                          )
                                        )
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.polTrml3,
                                          schedule.polTrml3Nm
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("td", [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.VOSD100_M2.003"))
                                    ),
                                    _vm._v(
                                      " : " +
                                        _vm._s(
                                          _vm.changeDate2Display(
                                            schedule.podEtd3,
                                            schedule.etaTm3,
                                            _vm.lang
                                          )
                                        )
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.podTrml3,
                                          schedule.podTrml3Nm
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        schedule.ts === "Y" &&
                        schedule.tsDegree >= 4 &&
                        schIdx >= _vm.startIdx &&
                        schIdx < _vm.endIdx
                          ? _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "text_left bor-left",
                                  attrs: { rowspan: "2" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(schedule.vslNm4) +
                                      " " +
                                      _vm._s(schedule.voyNo4)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "(Route : " + _vm._s(schedule.rteCd4) + ") "
                                  ),
                                ]
                              ),
                              _c("td", [
                                _vm._v(" " + _vm._s(schedule.pod3Nm) + " "),
                              ]),
                              _c("td", [
                                _vm._v(" " + _vm._s(schedule.pod4Nm) + " "),
                              ]),
                              _c("td", { attrs: { rowspan: "2" } }, [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(_vm._s(schedule.transitTime4)),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        schedule.ts === "Y" &&
                        schedule.tsDegree >= 4 &&
                        schIdx >= _vm.startIdx &&
                        schIdx < _vm.endIdx
                          ? _c("tr", [
                              _c("td", { staticClass: "bor-left" }, [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.VOSD100_M2.004"))
                                    ),
                                    _vm._v(
                                      " : " +
                                        _vm._s(
                                          _vm.changeDate2Display(
                                            schedule.polEtd4,
                                            schedule.etdTm4,
                                            _vm.lang
                                          )
                                        )
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.polTrml4,
                                          schedule.polTrml4Nm
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("td", [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.VOSD100_M2.003"))
                                    ),
                                    _vm._v(
                                      " : " +
                                        _vm._s(
                                          _vm.changeDate2Display(
                                            schedule.podEtd4,
                                            schedule.etaTm4,
                                            _vm.lang
                                          )
                                        )
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fnTrmlText(
                                          schedule.podTrml4,
                                          schedule.podTrml4Nm
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "tr",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.show2ndVessel === "Y" &&
                                  schIdx === _vm.listIndexNo,
                                expression:
                                  "show2ndVessel === 'Y' && schIdx === listIndexNo",
                              },
                            ],
                            attrs: { id: "vessel2nd" + schIdx },
                          },
                          [
                            _c(
                              "td",
                              {
                                staticClass: "bor-left pd0",
                                attrs: { colspan: "6" },
                              },
                              [
                                _c("div", { staticClass: "inner_table" }, [
                                  _c("div", { staticClass: "port_info" }, [
                                    _c("p", { staticClass: "port t2" }, [
                                      _c("span", [
                                        _vm._v(_vm._s(schedule.polNm)),
                                      ]),
                                      _c("span", [
                                        _vm._v(_vm._s(schedule.podNm)),
                                      ]),
                                    ]),
                                    _c("p", { staticClass: "txt_desc none" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.VOSD100_M5.028"))
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "btn_port_cancel" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "button sm gray",
                                            on: {
                                              click: function ($event) {
                                                return _vm.cancel2ndVessel4List(
                                                  schIdx
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("msg.VOSD100_M5.029")
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "table",
                                    { staticClass: "tbl_col t2 tac mt10" },
                                    [
                                      _vm._m(2, true),
                                      _c("thead", [
                                        _c("tr", [
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(_vm.$t("menu.MENU09.060"))
                                            ),
                                          ]),
                                          _c("th", [_vm._v("ETD")]),
                                          _c("th", [_vm._v("ETA")]),
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("msg.CSBL100_M1.015")
                                              )
                                            ),
                                          ]),
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(_vm.$t("msg.CSBL200.075"))
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.vessel2ndList,
                                          function (list, idx) {
                                            return _c("tr", { key: idx }, [
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(list.vslNm) +
                                                    " " +
                                                    _vm._s(list.voyNo)
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(list.etdStrDT)),
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(list.etaStrDT)),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(list.transitTime)
                                                ),
                                              ]),
                                              _c("td", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "button gray sm",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.select2ndVessel4List(
                                                          list,
                                                          schIdx
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "msg.CSBL200.075"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        schedule.ts !== "Y" &&
                        schIdx >= _vm.startIdx &&
                        schIdx < _vm.endIdx
                          ? _c("tr", [
                              _c("td", { attrs: { rowspan: "2" } }, [
                                _vm._v("Direct"),
                              ]),
                              _c(
                                "td",
                                {
                                  staticClass: "text_left",
                                  attrs: { rowspan: "2" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(schedule.vslNm) +
                                      " " +
                                      _vm._s(schedule.voyNo)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "(Route : " + _vm._s(schedule.rteCd) + ")"
                                  ),
                                ]
                              ),
                              _c("td", [
                                _vm._v(" " + _vm._s(schedule.polNm) + " "),
                              ]),
                              _c("td", [
                                _vm._v(" " + _vm._s(schedule.podNm) + " "),
                              ]),
                              _c("td", { attrs: { rowspan: "2" } }, [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(_vm._s(schedule.transitTime)),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "td",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.bound === "O",
                                      expression: "bound === 'O'",
                                    },
                                  ],
                                  attrs: { rowspan: "2" },
                                },
                                [
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.changeDateTime2Display(
                                            schedule.bkgDocCls,
                                            _vm.lang
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.changeDateTime2Display(
                                            schedule.bkgCgoCls,
                                            _vm.lang
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.bound === "O",
                                      expression: "bound === 'O'",
                                    },
                                  ],
                                  staticClass: "color_red",
                                  attrs: { rowspan: "2" },
                                },
                                [
                                  schedule.bkgClose === "Y"
                                    ? _c(
                                        "div",
                                        { staticClass: "button sm4 red1" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("msg.CSBL100_M1.032")
                                              )
                                          ),
                                        ]
                                      )
                                    : schedule.bkgClose !== "M"
                                    ? _c("div", [
                                        _c("ul", [
                                          _c("li", [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "button sm3 green",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openPopup(
                                                      "FreSurchargeKmtcSpotPop",
                                                      schedule
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "msg.MAIN010G030.038"
                                                    )
                                                  )
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "$" +
                                                    _vm._s(
                                                      schedule
                                                        .kmtcSpotSurcharge[0]
                                                        .rate20
                                                    ) +
                                                    " / $" +
                                                    _vm._s(
                                                      schedule
                                                        .kmtcSpotSurcharge[0]
                                                        .rate40
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("li", { staticClass: "mt5" }, [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "button sm blue",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.bookingBooking4List(
                                                      schedule
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Booking")]
                                            ),
                                          ]),
                                        ]),
                                      ])
                                    : _c("div", [_vm._v(" - ")]),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        schedule.ts !== "Y" &&
                        schIdx >= _vm.startIdx &&
                        schIdx < _vm.endIdx
                          ? _c("tr", [
                              _c("td", { staticClass: "bor-left" }, [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.VOSD100_M2.003"))
                                    ),
                                    _vm._v(
                                      " : " +
                                        _vm._s(
                                          _vm.changeDate2Display(
                                            schedule.polEtb,
                                            schedule.polEtbTm,
                                            _vm.lang
                                          )
                                        )
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.VOSD100_M2.004"))
                                    ),
                                    _vm._v(
                                      " : " +
                                        _vm._s(
                                          _vm.changeDate2Display(
                                            schedule.etd,
                                            schedule.etdTm,
                                            _vm.lang
                                          )
                                        )
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(schedule.otrmlCd) +
                                        " (" +
                                        _vm._s(schedule.otrmlNm) +
                                        ")"
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("td", [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.VOSD100_M2.003"))
                                    ),
                                    _vm._v(
                                      " : " +
                                        _vm._s(
                                          _vm.changeDate2Display(
                                            schedule.eta,
                                            schedule.etaTm,
                                            _vm.lang
                                          )
                                        )
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(schedule.itrmlCd) +
                                        " (" +
                                        _vm._s(schedule.itrmlNm) +
                                        ")"
                                    ),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ])
                    }),
                  ],
                  2
                ),
                _vm.pagingList.length > 0
                  ? _c(
                      "div",
                      { staticClass: "paging" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "btn prev",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.getList4Schedule(_vm.curPage - 1)
                              },
                            },
                          },
                          [_vm._v(" Prev ")]
                        ),
                        _vm._l(_vm.pagingList, function (idx) {
                          return _c(
                            "a",
                            {
                              key: idx,
                              class: [idx === _vm.curPage ? "on" : ""],
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.getList4Schedule(idx)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(idx) + " ")]
                          )
                        }),
                        _c(
                          "a",
                          {
                            staticClass: "btn next",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.getList4Schedule(_vm.curPage + 1)
                              },
                            },
                          },
                          [_vm._v(" Next ")]
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v(
              _vm._s(_vm.$t("msg.MAIN010G030.038")) + ", 무엇이든 물어보세요."
            ),
          ]),
          _c("p", { staticClass: "tit_label ml10" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.clickRateApp,
                  expression: "clickRateApp",
                },
              ],
              attrs: {
                type: "checkbox",
                id: "chkRateApp",
                name: "chkRateApp",
                "true-value": "Y",
                "false-value": "N",
              },
              domProps: {
                checked: Array.isArray(_vm.clickRateApp)
                  ? _vm._i(_vm.clickRateApp, null) > -1
                  : _vm._q(_vm.clickRateApp, "Y"),
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.clickRateApp,
                    $$el = $event.target,
                    $$c = $$el.checked ? "Y" : "N"
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.clickRateApp = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.clickRateApp = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.clickRateApp = $$c
                  }
                },
              },
            }),
            _c("label", { attrs: { for: "chkRateApp" } }, [_vm._v("운임문의")]),
          ]),
          _c("span", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v("<KMTC ON 운임 문의는 한국발 수출 일반화물만 가능합니다>"),
          ]),
          _c("table", { staticClass: "tbl_search" }, [
            _vm._m(3),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v("POL : ")]),
                _c(
                  "td",
                  [
                    _c("e-auto-complete-place", {
                      attrs: {
                        id: "polChk",
                        "ctr-cd": _vm.polChk.ctrCd,
                        "plc-cd": _vm.polChk.plcCd,
                        "is-char": true,
                        "is-char-alert": true,
                        disabled: _vm.clickRateApp === "N",
                      },
                      on: { change: _vm.changePolChk },
                    }),
                  ],
                  1
                ),
                _c("th", [_vm._v("POD : ")]),
                _c(
                  "td",
                  [
                    _c("e-auto-complete-place", {
                      attrs: {
                        id: "podChk",
                        "ctr-cd": _vm.podChk.ctrCd,
                        "plc-cd": _vm.podChk.plcCd,
                        "is-char": true,
                        "is-char-alert": true,
                        disabled: _vm.clickRateApp === "N",
                      },
                      on: { change: _vm.changePodChk },
                    }),
                  ],
                  1
                ),
                _c("th", [_vm._v("희망운임 20ft : ")]),
                _c("td", [
                  _c("span", { staticClass: "input_box" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ft20,
                          expression: "ft20",
                        },
                      ],
                      staticClass: "wid200",
                      attrs: {
                        type: "text",
                        id: "ft20",
                        disabled: _vm.clickRateApp === "N",
                      },
                      domProps: { value: _vm.ft20 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.ft20 = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _c("th", [_vm._v("40ft : ")]),
                _c("td", [
                  _c("span", { staticClass: "input_box" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ft40,
                          expression: "ft40",
                        },
                      ],
                      staticClass: "wid200",
                      attrs: {
                        type: "text",
                        id: "ft40",
                        disabled: _vm.clickRateApp === "N",
                      },
                      domProps: { value: _vm.ft40 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.ft40 = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.reqVocCont,
                expression: "reqVocCont",
              },
            ],
            staticStyle: { height: "200px" },
            attrs: { id: "reqVocCont", maxlength: "4000" },
            domProps: { value: _vm.reqVocCont },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.reqVocCont = $event.target.value
              },
            },
          }),
          _c("div", { staticClass: "flex_box mt10" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg ml_auto mr5",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.insertCS243I.apply(null, arguments)
                  },
                },
              },
              [_vm._v("문의사항 접수")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "page_title" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/main/small_ship.png") },
      }),
      _vm._v(" KMTC ON, 이렇게 이용해보세요 "),
      _c("img", {
        attrs: { src: require("../../assets/images/main/small_ship.png") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "80px" } }),
      _c("col", { attrs: { width: "130px" } }),
      _c("col", { attrs: { width: "130px" } }),
      _c("col", { attrs: { width: "250px" } }),
      _c("col", { attrs: { width: "80px" } }),
      _c("col", { attrs: { width: "250px" } }),
      _c("col", { attrs: { width: "300px" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "24%" } }),
      _c("col", { attrs: { width: "24%" } }),
      _c("col", { attrs: { width: "24%" } }),
      _c("col", { attrs: { width: "18%" } }),
      _c("col", { attrs: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "50px" } }),
      _c("col", { attrs: { width: "200px" } }),
      _c("col", { attrs: { width: "50px" } }),
      _c("col", { attrs: { width: "200px" } }),
      _c("col", { attrs: { width: "100px" } }),
      _c("col", { attrs: { width: "200px" } }),
      _c("col", { attrs: { width: "50px" } }),
      _c("col", { attrs: { width: "200px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }