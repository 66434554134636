<template>
  <div class="content resize">
    <e-breadcrumbs />
    <div class="flex_box container_return">
      <h1 class="page_title">{{ $t('msg.ONIM070G010.001') }}</h1> <!--반납-->
      <span class="ml_auto mt20">
        <a class="button md print" href="#" @click.prevent="screenPrint"><span class="btn_icon print"></span>인쇄</a>
      </span>
    </div>

    <div class="content_box">
      <!-- content_box -->
      <table class="tbl_search">
        <colgroup>
          <col width="70px">
          <col width="152px">
          <col width="*">
          <col width="110px">
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.ONIM070G010.002') }}</th> <!-- 조회정보 -->
            <td>
              <select name="dtKnd" id="dtKnd" class="wid150" v-model="form.dtKnd" @change="fnDtKndChange()">
                <option value="BL">{{ $t('msg.ONIM070G010.003') }}</option> <!-- B/L No. -->
                <option value="CT">{{ $t('msg.ONIM070G010.004') }}</option>
                <!-- Container No. -->
              </select>
            </td>
            <td>
              <input v-if="form.dtKnd == 'BL'" type="text" class="wid45 mrm4" value="KMTC" disabled="disabled">
              <input v-if="form.dtKnd == 'BL'" type="text" class="wid120" maxlength="30" value="" v-model="form.blNo" id="blKndNo">
              <input v-else type="text" class="wid120" maxlength="30" value="" v-model="form.cntrNo" id="ctKndNo">
            </td>
            <td class="text_right">
              <a class="button blue sh" href="#" @click.prevent="searchReturnContainerList()">{{ $t('msg.ONIM070G010.005') }}</a> <!-- 검색 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->

    <div class="content_box mt10 capture_area" id="capture_area">
      <!-- content_box -->
      <h2 class="content_title">{{ $t('msg.ONIM070G010.006') }}</h2> <!-- 출발/도착 정보 -->
      <table class="tbl_row">
        <colgroup>
          <col width="130"><col><col width="130"><col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.ONIM070G010.007') }}</th> <!-- 출발 -->
            <td>{{ detail.polPortNm }}</td>
            <th>{{ $t('msg.ONIM070G010.009') }}</th> <!-- 선명/항차 -->
            <td>{{ detail.vslNm }} {{ detail.voyNo }}</td>
          </tr>
          <tr>
            <th>{{ $t('msg.ONIM070G010.010') }}</th> <!-- 도착 -->
            <td>{{ detail.podPortNm }}</td>
            <th>{{ $t('msg.ONIM070G010.011') }}</th> <!-- 입항일시 -->
            <td>{{ detail.eta }}</td>
          </tr>
        </tbody>
      </table>

      <h2 class="content_title">{{ $t('msg.ONIM070G010.012') }}</h2> <!-- Container 반납 현황 -->
      <table class="tbl_list gridtype">
        <colgroup>
          <col width="4%">
          <col width="10%">
          <col width="8%">
          <col width="8%">
          <col width="30%">
          <col width="6%">
          <col width="6%">
          <col width="10%">
          <col width="8%">
          <col width="10%">
        </colgroup>
        <thead>
          <tr>
            <th class="pl5"><input type="checkbox" id="chkAll" @click="checkAll($event)"><label for="chkAll"></label></th>
            <th>{{ $t('msg.ONIM070G010.013') }}</th> <!-- Container No. -->
            <th>{{ $t('msg.ONIM070G010.014') }}</th> <!-- Size/Type -->
            <th>{{ $t('msg.ONIM070G010.015') }}</th> <!-- 반납마감시한 -->
            <th>{{ $t('msg.ONIM070G010.016') }}</th> <!-- 반납 장소 -->
            <th v-if="detail.ofcYn == 'Y'">{{ $t('msg.ONIM070G010.017') }}</th> <!-- OFC -->
            <th v-else-if="detail.ofcYn == 'N' || !detail.ofcYn">{{ $t('msg.ONIM070G010.018') }}</th> <!-- DET -->
            <th>{{ $t('msg.ONIM070G010.019') }}</th> <!-- 리스벤반납 -->
            <th>{{ $t('msg.ONIM070G010.020') }}</th> <!-- Invoice No. -->
            <th>{{ $t('msg.ONIM070G010.021') }}</th> <!-- 처리상태 -->
            <th>{{ $t('msg.ONIM070G010.022') }}</th> <!-- 입금/계산서 -->
          </tr>
        </thead>
        <tbody v-if="contentsList.length > 0 && doChk">
          <tr v-for="(content,index) in contentsList" :key="index">
            <td class="pr0">
              <input type="checkbox" name="checkedIndex" class="checkedIndex" :id="'check' + index" @click="containerValiCheck(index)" v-model="checkedIndex" :value="index"><label :for="'check' + index"><span class="offscreen">No</span></label>
            </td>
            <!-- Container No. -->
            <td>{{ content.cntrNo }}</td>
            <!-- Size/Type -->
            <td>{{ content.cntrSzCd }} / {{ content.cntrTypCd == 'GP' ? 'Dry' : content.cntrTypCd }}</td>
            <!-- 반납마감시한 -->
            <td v-if="content.returnFlag == '02'">{{ $t('msg.ONIM070G010.023') }}</td> <!-- 반납완료 -->
            <td v-else-if="content.detDt == '00000000'">{{ $t('msg.ONIM070G010.024') }}</td> <!-- 미반출 -->
            <td v-else-if="content.leaseYn && content.rtnValdDt">{{ $ekmtcCommon.changeDatePattern(content.rtnValdDt,'.') }}</td>
            <td v-else>{{ $ekmtcCommon.changeDatePattern(content.detDt,'.') }}</td>
            <!-- 반납 장소 -->
            <td v-if="!content.leaseYn" class="text_left">
              {{ content.leasnm }} {{ content.cyCd }} ( {{ content.cyEnm }} ) <br>
              ({{ $t('msg.ONIM070G010.025') }}: {{ content.picEnm }} / {{ $t('msg.ONIM070G010.026') }}: {{ content.telNo }} {{ content.rtnApvNo ? ' / ' + $t('msg.ONIM070G010.027') + ':' + content.rtnApvNo : '' }}) <!--(담당자: xxx / 연락처: 010-xxxx-xxxx / 반납 Order No.: xxxx ) -->
            </td>
            <td v-else class="text_left">
              {{ content.leasnm }} {{ content.cyCd }} ( {{ content.cyEnm }} ) / {{ content.plcNm }} / <br> {{ $t('msg.ONIM070G010.025') }}/{{ $t('msg.ONIM070G010.026') }} : {{ content.ctfcCont }} <br> {{ $t('msg.ONIM070G010.027') }} : {{ content.rtnApvNo }}
            </td>
            <!-- DET -->
            <td>{{ $ekmtcCommon.changeNumberFormat(Math.floor(content.detAmt * 1.1), { isComma: true }) }}</td>

            <!-- <td v-if="content.returnFlag === '01'">변경가능</td>
            <td v-else-if="content.returnFlag === '02'">완료</td>
            <td v-else>불가</td> -->

            <!-- 리스벤반납 -->
            <td v-if="!content.leaseYn">N</td>
            <td v-else-if="content.leaseYn && content.returnFlag == '12'">Y</td>
            <td v-else>Y</td>
            <!-- Invoince No. -->
            <td>
              <template v-if="content.apvStsCd != 'Cancelled'">
                <template v-if="(auth.userId && auth.userId === content.invUser) || (auth.orgUserId && auth.orgUserId === content.invUser) || (auth.cstCd && auth.cstCd === content.cstCd)||(memberDetail ? memberDetail.staffFlag : '') === 'Y'">
                  <!-- <a href="#" @click.prevent="fnReqDownload(content.reqRno, 1, content.cm136Yn)">
                    {{ content.invIssNo }}
                  </a> -->
                  <a href="#" @click.prevent="openPdfPopup(content)">
                    {{ content.invIssNo }}
                  </a>
                </template>
                <template v-else>{{ content.invIssNo }}</template>
              </template>
            </td>
            <!-- 처리상태 -->
            <td :id="'invStatus_' + index"
                @mouseover="fnToolTip(content.apvStsCd, content.reqCy, index, content.reqRsps)"
            >
              <template v-if="content.returnFlag === '04'
                || content.returnFlag === '05'
                || content.returnFlag === '06'
                || (content.returnFlag === '07' && content.leaseCyCnt ==='0' && $ekmtcCommon.isEmpty(content.invIssNo) && $ekmtcCommon.isNotEmpty(content.apvStsCd))
                || content.returnFlag === '08'
                || content.returnFlag === '09'
                || content.returnFlag === '10'
                || content.partUno"
              >
                {{ $t('msg.ONIM070G010.028') }} <!-- 요청불가 -->
              </template>
              <template v-else-if="content.apvStsCd != 'Cancelled' && content.invIssNo">
                <template v-if="content.reqRsps != null && content.reqRsps != ''">
                  <span class="position_relative span_tooltip">
                    <a class="txt_underline" @click="content.apvStsCd != 'Accepted' ? $ekmtcCommon.showTooltip($event) : ''">
                      {{ content.apvStsCd }}
                    </a>
                    <div class="tooltip_wrap short position_absolute">
                      <div class="cont">
                        <button
                          type="button"
                          class="close"
                          @click="$ekmtcCommon.hideTooltip($event)"
                        >close</button>
                        {{ content.reqRsps }}
                      </div>
                    </div>
                  </span>
                </template>
                <template v-else>
                  {{ content.apvStsCd }}
                </template>
              </template>
            </td>
            <!-- 입금/계산서 -->
            <td>
              <template v-if="(content.apvStsCd == 'Requested' || content.apvStsCd == 'Pending') && content.invIssNo">
                <a href="#" @click.prevent="fnInsBank(index, content.invIssNo, content.invUser, content.cstCd)" :class="(content.bankYn === 'N' ? 'txt-red' : '')">{{ $t('msg.ONIM070G010.029') }}</a> <!-- 정보입력 -->
              </template>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td v-if="!doChk" colspan="10">D/O가 발행되지 않아 반납지 변경이 불가합니다.</td>
            <td v-else colspan="10">No Data.</td>
          </tr>
        </tbody>
      </table>
      <p class="txt_desc">{{ $t('msg.ONIM070G010.030') }}</p> <!-- 문의처 : 부산지점 (051-461-2800), 인천사무소 (032-883-2800), 울산사무소 (052-261-0234) 및 수입 영업사원 -->
      <p class="txt_desc">{{ $t('msg.ONIM070G010.031') }}</p> <!-- Drop off charge 부과: 인천 및 부곡 지역 수입 반납 Empty 컨테이너 (인천 입항분 제외, 20'/40' = KRW 50,000/70,000) -->
      <p class="txt_desc">{{ $t('msg.ONIM070G010.070') }}</p> <!-- 타지역 반출 Empty 컨테이너 경인지역 반납 시 반납지 변경한 당일 반납이 원칙 (당일 반납 건만 반납지 변경 진행 / 기변경한 반납지가 당일 반납지와 불일치 시 반납 절대 불가)-->
    </div><!-- content_box // -->
    <div class="flex_box mt10">
      <!-- <a class="button lg mr5" href="#" @click.prevent="fnFreetimeTariffPopup">{{ $t('msg.ONIM070G010.032') }}</a>Freetime Tariff -->
      <a class="button lg mr5" href="#" @click.prevent="fnDoKLNetSend">{{ $t('msg.ONIM070G010.033') }}</a> <!-- 반납지 KL-NET 재전송 -->
      <a class="button lg" href="#" @click.prevent="fngoEtrans">{{ $t('msg.ONIM070G010.034') }}</a> <!-- e-trans -->

      <a class="button blue lg ml_auto mr5" href="#" @click.prevent="returnChangePopup">{{ $t('msg.ONIM070G010.035') }}</a> <!-- 반납지 변경요청 -->
      <a class="button blue lg" href="#" @click.prevent="fnCencel">{{ $t('msg.ONIM070G010.036') }}</a> <!-- 요청취소 -->
    </div>

    <!-- 변경요청 팝업 -->
    <div id="container_return_pop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :prop-container-list="selectedContentsList"
          @reload="searchReturnContainerList"
          :freight-bill-info="parentInfo"
          @fnChangeAfterCancel="fnChangeAfterCancel"
        />
      </win-layer-pop>
    </div>
    <div id="pdf_area"></div>

    <div id="container_return_pdf_pop">
      <win-layer-pop class="pdf_download_pop">
        <component
          v-if="pdfCustomComponent"
          :is="pdfCustomComponent"
          @close="closePdfPopup"
          :parent-info="pdfPopupParams"
        />
      </win-layer-pop>
    </div>
  </div>
</template>

<script>
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import containerReturn from '@/api/rest/trans/containerReturn'
import { rootComputed } from '@/store/helpers'
import doapi from '@/api/rest/trans/do'
import FreeTimeApi from '@/api/rest/settle/freeTime'

export default {
  name: 'ContainerReturn',
  components: {
    EBreadcrumbs,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'return-change-pop': () => import('@/pages/trans/popup/ReturnChangePop'),
    'etc-return-change-pop': () => import('@/pages/trans/popup/EtcReturnChangePop'),
    'dem-det-tariff-pop': () => import('@/pages/settle/popup/DemDetTariffPop'),
    'input-tax-invoice-info': () => import('@/pages/settle/popup/InputTaxInvoiceInfo'),
    'container-return-pop': () => import('@/pages/pdf/popup/ContainerReturnPop')
  },
  props: {
    detailBlNo: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      customComponent: '',
      detail: {},
      contentsList: [],
      checkedIndex: [],
      selectedContentsList: [],
      form: {
        dtKnd: 'BL',
        blNo: '',
        cntrNo: ''
      },
      parentInfo: {},
      doChk: true,
      choiceCntrNo: '', // 조회후 체크할 컨테이너번호

      // PDF 다운로드 팝업 컴포넌트
      pdfCustomComponent: null,
      pdfPopupParams: {},
      relYn: true
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    // [s] 2021.12.21 - router.query 작업
    if (Object.keys(this.$route.query).length > 0) {
      const vo = this.$route.query

      if (vo != null) {
        if (vo.searchType === 'BL') {
          this.form.dtKnd = 'BL'
          this.form.blNo = vo.keyword
        }
        if (vo.searchType === 'CONTAINER') {
          this.form.dtKnd = 'CT'
          this.form.cntrNo = vo.keyword
        }
      }

      this.choiceCntrNo = this.$route.query.choiceCntrNo // 조회후 체크할 컨테이너번호

      this.searchReturnContainerList()
    }
    // [e] 2021.12.21 - router.query 작업

    if (this.detailBlNo) {
      this.form.blNo = this.detailBlNo
      this.searchReturnContainerList()
    }
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })
  },
  methods: {
    handleKeydown (e) {
      // JamSin
      if (this.relYn) {
        if (e.key === 'Enter') {
          this.searchReturnContainerList()
        }
      }
    },
    //컨테이너 검색 조회
    async searchReturnContainerList () {
      // [s] 2021.12.21 - router.query 작업
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.replace({ query: {} })
      }
      // [e] 2021.12.21 - router.query 작업

      if (this.form.dtKnd === 'BL' && !this.form.blNo) {
        this.$ekmtcCommon.alertDefault('B/L NO.를 확인하세요.') //B/L NO.를 확인하세요.
        return
      } else if (this.form.dtKnd !== 'BL' && !this.form.cntrNo) {
        this.$ekmtcCommon.alertDefault('Container No.를 확인하세요.') //Container No.를 확인하세요.
        return
      }

      this.checkedIndex = []
      let resultData = await containerReturn.getContainerReturn(this.form)

      this.doChk = true

      //(BL마스터 데이터가 있을 때)DO발행 안 된 경우
      if (resultData.data.detail && Number(resultData.data.doChk) === 0) {
        this.doChk = false
      }

      this.detail = { ...resultData.data.detail }
      this.detail.eta = this.changeDateTime2Display(this.detail.eta)
      this.contentsList = [...resultData.data.contentsList]

      console.log('contentsList >>>>>> ', this.contentsList)
      console.log('detail >>>>>> ', this.detail)

      document.getElementById('chkAll').checked = false

      console.log('>>>>> choiceCntrNo : ' + this.choiceCntrNo)
      // 조회 후 체크할 컨테이너번호가 있는경우 체크 한다.
      if (this.doChk && this.$ekmtcCommon.isNotEmpty(this.choiceCntrNo)) {
        this.$nextTick(() => {
          for (let i = 0; i < this.contentsList.length; i++) {
            if (this.choiceCntrNo === this.contentsList[i].cntrNo) {
              //this.$set(this.checkedIndex)
              this.checkedIndex.push(i) // 선 -> 체크 처리
              this.containerValiCheck(i) // 후 -> 체크 가능 체크 (불가시 체크해제)
              break
            }
          }
          this.choiceCntrNo = '' // 조회후 체크할 컨테이너번호 Clear
        })
      }
    },
    //변경요청 팝업
    async returnChangePopup () {
      if (this.checkedIndex.length === 0) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.037')) //컨테이너를 선택하세요.
        return
      }
      let checkedList = []
      checkedList = this.checkedIndex

      await this.searchReturnContainerList()

      this.checkedIndex = checkedList
      //선택된 컨테이너 데이터 배열처리
      this.selectedContentsList = []
      this.checkedIndex.map((index) => {
        let targetContainer = this.contentsList[index]
        this.selectedContentsList.push(targetContainer)
      })

      let lessor = ''
      let loginUser = this.auth.orgUserId
      let leaseCyCnt = 0
      let lseBkgNo = ''
      let lseChk = ''
      let returnFlagArray = []
      for (let i = 0; i < this.selectedContentsList.length; i++) {
        let selectedContainer = this.selectedContentsList[i]
        let invUser = selectedContainer.invUser
        let invNo = selectedContainer.invIssNo
        let apvStsCd = selectedContainer.apvStsCd
        lessor = selectedContainer.rtnLseCstNo
        let rtnY = selectedContainer.rtnY //TO-BE
        let returnFlag = selectedContainer.returnFlag
        returnFlagArray.push(returnFlag)
        if (returnFlag === '07') {
          if (i > 0 && lseBkgNo !== selectedContainer.lseBkgNo) {
            lseChk = 'Y'
          }
          lseBkgNo = selectedContainer.lseBkgNo
          leaseCyCnt = selectedContainer.leaseCyCnt
        }
        if (apvStsCd === 'Requested' && invNo) {
          if (loginUser !== invUser) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.038')) //권한이 없습니다.
            return
          }
        }
        if (apvStsCd === 'Accepted' && invNo) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.068')) //이미 승인 처리 된 요청 건에 대해서는 변경이 불가합니다.
          return
        }
        if (apvStsCd === 'Pending' && invNo) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.069')) //변경 요청 확인 중입니다. 담당자에게 문의하세요.
          return
        }
        if (rtnY > 0) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.039')) //반납 완료 건은 반납지 변경이 불가합니다.
          return
        }
      }

      //BEC, SKY, CLI
      if (lessor === 'BEC' || lessor === 'SKY' || lessor === 'CLI') {
        if (this.checkedIndex.length > 1) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.040')) //하나만 선택하세요.
          return
        }
        this.customComponent = 'etc-return-change-pop'
        this.$ekmtcCommon.layerOpen('#container_return_pop > .popup_dim')
        return
      }
      if (leaseCyCnt > 0) {
        if (lseChk === 'Y') {
          this.$ekmtcCommon.alertDefault('컨테이너 넘버를 확인하세요.') //컨테이너 넘버를 확인하세요.
          return
        }
        if (returnFlagArray.indexOf('01') > -1 && returnFlagArray.indexOf('07') > -1) {
          this.$ekmtcCommon.alertDefault('컨테이너 넘버를 확인하세요.') //컨테이너 넘버를 확인하세요.
          return
        }
        if (this.checkedIndex.length > 1) {
          //this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.040')) //하나만 선택하세요.
          //return
        }
      }
      this.customComponent = 'return-change-pop'
      this.$ekmtcCommon.layerOpen('#container_return_pop > .popup_dim')
    },
    //변경요청 팝업 닫기
    closePopup () {
      this.customComponent = null
      this.selectedContentsList = null
      // 전체선택버튼 초기화

      this.$ekmtcCommon.layerClose('#container_return_pop > .popup_dim')
    },
    //컨테이너 체크박스 클릭시 벨리데이션 체크
    containerValiCheck (index) {
      let targetContainer = this.contentsList[index]
      let partNo = targetContainer.partUno
      let returnFlag = targetContainer.returnFlag
      let checkedIndex = document.getElementsByClassName('checkedIndex')
      let uncheckFlag = false
      let leaseCyCnt = targetContainer.leaseCyCnt
      if (partNo) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.041') + '\n' + this.$t('msg.ONIM070G010.042') + '\n(BL No : ' + partNo.split('@@')[0] + ' / Container No : ' + partNo.split('@@')[1] + ')') //동일한 컨테이너 번호에 대한 신청 이력이 있습니다. 확인 부탁드립니다.
        uncheckFlag = true
      }

      if (returnFlag === '04') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.043')) //D/O가 발행되지 않아 반납지 변경이 불가 합니다.
        uncheckFlag = true
      } else if (returnFlag === '05') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.044')) //고려해운의 컨테이너가 아니므로 변경 불가합니다.
        uncheckFlag = true
      } else if (returnFlag === '06') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.045')) //SOC 화물은 변경 불가합니다.
        uncheckFlag = true
      } else if (returnFlag === '08' || returnFlag === '09' || (returnFlag === '07' && leaseCyCnt === '0')) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.046') + '\n' + this.$t('msg.ONIM070G010.047')) //리스컨테이너 반납지 지정 건으로 반납지 변경 불가 /n 문의:기기관리팀 / 윤지수씨 / 02-311-6127
        uncheckFlag = true
      } else if (returnFlag === '10') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.048') + ' \n' + this.$t('msg.ONIM070G010.047')) //매각 대상 컨테이너 반납지 지정 건으로 반납지 변경 불가 /n 문의:기기관리팀 / 윤지수씨 / 02-311-6127
        uncheckFlag = true
      }

      if (uncheckFlag) {
        checkedIndex[index].checked = false
        this.checkedIndex = this.checkedIndex.filter(item => item !== index)
      }
    },
    //e-trans 접속
    fngoEtrans () {
      window.open('http://etrans.klnet.co.kr/index.jsp', 'win')
    },
    //요청취소
    async fnCencel () {
      if (this.checkedIndex.length === 0) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.049')) //컨테이너를 선택하세요.
        return
      }

      let loginUser = this.auth.orgUserId
      let invNo = ''
      let apvStsCd = ''
      let invUser = ''
      let reqRno = ''
      this.checkedIndex.map((index) => {
        let targetContainer = this.contentsList[index]

        invNo = targetContainer.invIssNo
        apvStsCd = targetContainer.apvStsCd
        invUser = targetContainer.invUser
        reqRno = targetContainer.reqRno
      })

      if (!invNo) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.049')) //컨테이너를 확인 하세요.
        return
      }

      if (invUser !== loginUser) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.038')) //권한이 없습니다
        return
      }
      if (apvStsCd === 'Accepted') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.050')) //이미 승인 처리 된 요청 건에 대해서는 취소가 불가합니다.
        return
      }
      if (apvStsCd === 'Rejected') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.051')) //이미 Rejected 처리 된 요청 건에 대해서는 취소가 불가합니다.
        return
      }
      if (apvStsCd === 'Cancelled') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.052')) //이미 Cancelled 처리 된 요청 건에 대해서는 취소가 불가합니다.
        return
      }

      if (confirm(this.$t('msg.ONIM070G010.053') + '\n' + '(Invoice No.' + invNo + ')')) { //요청하신 반납지 변경 요청을 취소하시겠습니까? \n '(Invoice No. xxx..)'
        let formData = {
          invNo: invNo,
          reqRno: reqRno
        }
        let resultData = await containerReturn.returnChangeCancel(formData)
        let respCode = resultData.headers.respcode

        if (respCode === 'C0000') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.054')) //Cancel successfully!
          this.searchReturnContainerList()
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.055')) //Cancel fail!
        }
      }
    },
    //KL-NET 재전송
    async fnDoKLNetSend () {
      if (this.checkedIndex.length === 0) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.056')) //Container No.를 선택하세요.
        return
      }

      let now = new Date()
      let year = now.getFullYear()
      let mon = (now.getMonth() + 1) > 9 ? '' + (now.getMonth() + 1) : '0' + (now.getMonth() + 1)
      let day = now.getDate() > 9 ? '' + now.getDate() : '0' + now.getDate()
      let toDay = year + mon + day
      let detBreak = ''

      this.checkedIndex.forEach((index) => {
        let targetContainer = this.contentsList[index]

        if (targetContainer.detDt === '00000000') {
          detBreak = '01'
          return false
        }
        if (targetContainer.detDt !== '00000000' && targetContainer < toDay) {
          detBreak = '02'
          return false
        }
      })

      if (detBreak === '01') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.057')) //화물이 반출되지 않았습니다.
        return false
      } else if (detBreak === '02') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.058')) //반납기한이 지났습니다.
        return false
      }

      let result = await doapi.sendKLNetDo({ blNo: this.contentsList[0].blNo })
      let sendResult = result.data

      if (sendResult === '01') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.059')) //반납지 정보가 KL-NET에 전송되었습니다.
      } else if (sendResult === '02') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.060')) //D/O가 발행되지 않았습니다.
      } else if (sendResult === '03') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.061')) //Demurrage가 초과되었습니다.
      } else if (sendResult === '04') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.062')) //Detention이 초과되었습니다.
      } else if (sendResult === '05') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.063')) //KL_NET DO SEND FAIL !!
      }
    },
    //처리상태 툴팁
    fnToolTip (apvStsCd, reqCy, index, reqRsps) {
      //(1) Requested : XXXX으로 반납지 변경 요청중입니다.
      //(2) Rejected: XXXX으로 반납지 변경이 거절되었습니다. (담당자 입력 내용 표기)
      //(3) Accepted: XXXX으로 반납지 변경이 승인되었습니다.
      let title = ''

      if (!reqRsps) {
        reqRsps = ''
      }

      if (apvStsCd === 'Requested') {
        title = reqCy + this.$t('msg.ONIM070G010.064') //xx->xx으로 반납지 변경 요청중입니다.
      } else if (apvStsCd === 'Rejected') {
        title = reqCy + this.$t('msg.ONIM070G010.065') + ' \n' + this.$t('msg.ONIM070G010.066') + reqRsps //xx->xx으로 반납지 변경이 거절되었습니다. \n 거절 사유: xxx..
      } else if (apvStsCd === 'Accepted') {
        title = reqCy + this.$t('msg.ONIM070G010.067') + '\n' + reqRsps //xx->xx으로 반납지 변경이 승인되었습니다.
      } else {
        if (reqRsps) {
          title = reqRsps
        }
      }
      document.getElementById('invStatus_' + index).title = title
    },
    //Freetime Tariff 팝업
    fnFreetimeTariffPopup () {
      this.customComponent = 'dem-det-tariff-pop'
      this.$ekmtcCommon.layerOpen('#container_return_pop > .popup_dim')
    },
    //반납 인보이스 다운로드 (파일명 blob화 될 때 못 받와서 따로 API 호출(개선필요))
    async fnReqDownload (reqRno, seq, cm136Yn) {
      if (cm136Yn !== 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('js.common.011')) //담당자에게 문의 바랍니다.
        return
      }

      //API
      const formData = {
        reqRno: reqRno,
        atchFileSeq: seq
      }
      const resultName = await containerReturn.reqfileName(formData)
      const result = await containerReturn.reqDownload(formData)

      //blob 생성
      const blob = new Blob([result.data], { type: result.headers['content-type'] })
      if (window.navigator.msSaveOrOpenBlob) { //browser Check - IE
        window.navigator.msSaveOrOpenBlob(blob, resultName.data)
      } else { //browser Check - 그 외
        //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.target = '_self'
        link.download = resultName.data
        link.click()

        //url 사용 후 메모리에서 제거
        window.URL.revokeObjectURL(url)
      }
    },
    //세금계산서 발행 팝업
    fnInsBank (index, issInvNo, invUser, cstCd) {
      //권한별 발행 팝업 노출 벨리데이션
      let userId = ''
      let staffFlag = ''
      let authCstCd = ''
      let bzNoAuth = ''

      if (!this.auth.login) {
        this.$ekmtcCommon.alertDefault('로그인을 해주세요.') // 로그인 안한 상태에서 정보입력 실행시 컨테이너 auth 파라미터 없어 로그에 오류 발생함
        return
      }

      if (this.auth) {
        userId = sessionStorage.getItem('loginId')
        staffFlag = this.auth.staffFlag
        authCstCd = this.auth.cstCd
      }

      /* 2022.07.18 동일 화주코드에서 동일 사업자번호로 권한체크 변경함. (dsyang 349번)
      if ((!userId || userId !== invUser) && (staffFlag !== 'Y' || !staffFlag) && (cstCd !== authCstCd || !authCstCd)) {
        this.$ekmtcCommon.alertDefault('권한이 없습니다.') //권한이 없습니다.
        return
      }
      */
      bzNoAuth = this.contentsList[index].bzNoAuth
      if (((!userId) && (staffFlag !== 'Y' || !staffFlag) && (bzNoAuth !== 'A')) || invUser === 'API') {
        if (invUser === 'API') {
          this.$ekmtcCommon.alertDefault('E-TRANS 신청 건입니다.\nE-TRANS에서 확인 바랍니다.') //권한이 없습니다.
          return
        } else {
          this.$ekmtcCommon.alertDefault('권한이 없습니다.') //권한이 없습니다.
          return
        }
      }

      //파라미터 셋팅
      this.getContainerInfo(index, issInvNo)
    },
    screenPrint () {
      const ele = document.querySelector('.capture_area')
      this.$ekmtcCommon.elemAddClass(ele, 'screen_shot')

      const options = {
        cssSelector: '.capture_area'
      }

      this.$ekmtcCommon.screenToPrint(options).then(res => {
        if (res === undefined || res === null) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.244'))
        }
        this.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
      }).catch(() => {
        this.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
      })
    },
    changeDateTime2Display (strDate) {
      let rtnStrDate = ''
      if (!strDate) {
        rtnStrDate = ''
      } else {
        if (strDate) {
          rtnStrDate = strDate.substring(0, 4) + '.' + strDate.substring(4, 6) + '.' + strDate.substring(6, 8)
        }
        if (rtnStrDate) {
          rtnStrDate = rtnStrDate + ' ' + strDate.substring(8, 10) + ':' + strDate.substring(10, 12)
        }
      }
      return rtnStrDate
    },
    //정산팝업 활성화 전 파라미터 셋팅
    getInputData (index, containerInfo, invIssNo) {
      FreeTimeApi.getWebRequestInfo({ blNo: this.form.blNo, invIssNo: invIssNo, reqBizCatCd: '05', demDetCatCd: '29' }).then(response => {
        this.parentInfo = this.parseInputTaxInvoiceInfo(index, containerInfo, response.data, invIssNo)
        this.customComponent = 'input-tax-invoice-info'
        this.$ekmtcCommon.layerOpen('#container_return_pop > .popup_dim')
      }).catch(e => {
        console.log(e)
      })
    },
    parseInputTaxInvoiceInfo (index, containerInfo, data, invIssNo = '') {
      let krw = 0
      let cntrList = []
      let inputDefaultData = {}
      if (!this.isEmpty(data)) {
        inputDefaultData = data
      }

      let r = JSON.parse(JSON.stringify(containerInfo))
      cntrList.push(r)

      let targetContainer = this.contentsList[index]
      krw += targetContainer.dropKrw * 1.1

      let info = {
        dpId: 'SETT060G010',
        dpType: 'R',
        btnName: '',
        btnType: 'RETURN',
        inputDefaultData: inputDefaultData,
        totalAmt: data.totalAmt || '',
        isShow: {
          info: false,
          method: true,
          tax: true
        },
        blList: [{
          blNo: this.form.blNo,
          krw: krw,
          usd: 0,
          exRate: 1136,
          currency: 'KRW',
          obrdDt: '',
          // demDetCatCd: '02',
          demDetCatCd: '29',
          eiCatCd: 'I',
          cntrList: cntrList,
          invIssNo: invIssNo
        }]
      }
      return info
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    },
    //컨테이너 정보 가져오기(Freetime전용)
    async getContainerInfo (index, invIssNo) {
      let params = '?'
      params += 'blNo=' + this.form.blNo
      params += '&cntrNo=' + this.contentsList[index].cntrNo
      params += '&dtKnd=' + 'BL'
      params += '&eiCatCd=' + 'I'
      await FreeTimeApi.getFreeTime(params).then(response => {
        if (response.data.detdetail4list.length > 0) {
          this.getInputData(index, response.data.detdetail4list[0], invIssNo)
        }
      }).catch(e => {
        console.log(e)
      })
    },
    checkAll (e) {
      const checkList = document.getElementsByClassName('checkedIndex')
      let checked = false
      if (e.target.checked) {
        checked = true
      } else {
        checked = false
      }

      this.checkedIndex = []

      if (checked) {
        for (let i = 0; i < checkList.length; i++) {
          checkList[i].checked = true
          this.checkedIndex.push(i)
        }

        this.checkAllValidation()
      } else {
        for (let i = 0; i < checkList.length; i++) {
          checkList[i].checked = false
        }

        this.checkedIndex = []
      }
    },
    checkAllValidation () {
      let containerList = this.contentsList
      let checkedIndex = document.getElementsByClassName('checkedIndex')
      let uncheckMessage = []
      let uncheckFlag = false

      containerList.forEach((c, idx) => {
        let partNo = c.partUno
        let returnFlag = c.returnFlag
        let leaseCyCnt = c.leaseCyCnt

        if (partNo) {
          const message = '[' + c.cntrNo + '] ' + this.$t('msg.ONIM070G010.041') + '\n' + this.$t('msg.ONIM070G010.042') + '\n(BL No : ' + partNo.split('@@')[0] + ' / Container No : ' + partNo.split('@@')[1] + ')'
          uncheckMessage.push(message)
          uncheckFlag = true
        }

        if (returnFlag === '04') {
          const message = '[' + c.cntrNo + '] ' + this.$t('msg.ONIM070G010.043')
          uncheckMessage.push(message)
          uncheckFlag = true
        } else if (returnFlag === '05') {
          const message = '[' + c.cntrNo + '] ' + this.$t('msg.ONIM070G010.044')
          uncheckMessage.push(message)
          uncheckFlag = true
        } else if (returnFlag === '06') {
          const message = '[' + c.cntrNo + '] ' + this.$t('msg.ONIM070G010.045')
          uncheckMessage.push(message)
          uncheckFlag = true
        } else if ((returnFlag === '07' && leaseCyCnt === '0') || returnFlag === '08' || returnFlag === '09') {
          const message = '[' + c.cntrNo + '] ' + this.$t('msg.ONIM070G010.046') + ' ' + this.$t('msg.ONIM070G010.047') //리스컨테이너 반납지 지정 건으로 반납지 변경 불가 /n 문의:기기관리팀 / 윤지수씨 / 02-311-6127
          uncheckMessage.push(message)
          uncheckFlag = true
        } else if (returnFlag === '10') {
          const message = '[' + c.cntrNo + '] ' + this.$t('msg.ONIM070G010.048') + ' ' + this.$t('msg.ONIM070G010.047') //매각 대상 컨테이너 반납지 지정 건으로 반납지 변경 불가 /n 문의:기기관리팀 / 윤지수씨 / 02-311-6127
          uncheckMessage.push(message)
          uncheckFlag = true
        }

        if (uncheckFlag) {
          checkedIndex[idx].checked = false
          this.checkedIndex = this.checkedIndex.filter(item => item !== idx)
        }

        uncheckFlag = false
      })

      if (uncheckMessage.length > 0) {
        this.$ekmtcCommon.alertDefault(uncheckMessage.join('\n'))
      }

      if (this.checkedIndex.length === 0) {
        document.getElementById('chkAll').checked = false
      }
    },
    // PDF 다운로드 팝업 열기
    openPdfPopup (content) {
      if (this.pdfCustomComponent !== null) {
        this.pdfCustomComponent = null
        this.$ekmtcCommon.layerClose()
      }

      content.title = '반납'

      this.pdfCustomComponent = 'container-return-pop'
      this.pdfPopupParams = content

      this.$ekmtcCommon.layerOpen('#container_return_pdf_pop > .popup_dim')
    },
    // PDF 다운로드 팝업 닫기
    closePdfPopup () {
      this.pdfCustomComponent = null
      this.$ekmtcCommon.layerClose('#container_return_pdf_pop > .popup_dim')
    },
    //요청취소
    async fnChangeAfterCancel () {
      if (this.checkedIndex.length === 0) {
        return
      }

      let loginUser = this.auth.orgUserId
      let invNo = ''
      let apvStsCd = ''
      let invUser = ''
      let reqRno = ''
      this.checkedIndex.map((index) => {
        let targetContainer = this.contentsList[index]

        invNo = targetContainer.invIssNo
        apvStsCd = targetContainer.apvStsCd
        invUser = targetContainer.invUser
        reqRno = targetContainer.reqRno
      })

      if (apvStsCd === 'Requested') {
        let formData = {
          invNo: invNo,
          reqRno: reqRno
        }
        let resultData = await containerReturn.returnChangeCancel(formData)
      }
    },
    fnDtKndChange () {
      if (this.form.dtKnd === 'BL') {
        this.form.cntrNo = ''
      } else {
        this.form.blNo = ''
      }
    }
  }
}
</script>
<style scoped>
.txt-red {
  color:red;
  font-weight:bold;
}
.tooltip_wrap {width:250px; right:0; top:24px; display:none;}
.tooltip_wrap .cont {padding: 20px;}
.pl5 {padding-left: 5px;}
</style>
