<template>
  <div
    class="popup_wrap"
    style="width:650px;"
  >
    <button
      class="layer_close"
      @click="$emit('close', {saveFlag: saveFlag})"
    >
      close
    </button>
    <form id="frm">
      <div class="popup_cont">
        <h2 class="page_title">{{ $t('msg.ONEX100G020.004') }}</h2><!-- Pick up 정보를 변경 확정 하시겠습니까? -->
        <div class="content_box">
          <table class="tbl_row">
            <colgroup>
              <col width="120"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>Booking No.</th><!-- Booking No. -->
                <td>{{ params.bkgNo }}</td>
              </tr>
            </tbody>
          </table>
          <h3 class="flex_box mt20">{{ $t('msg.ONEX100G020.005') }}</h3>
          <table class="tbl_row mt10 mb10">
            <colgroup>
              <col width="120"><col>
            </colgroup>
            <tbody>
              <tr v-for="container in params.containerList" :key="container.cntrSeq">
                <th>기존 {{ container.cntrSeq }}</th><!-- 기존 -->
                <td>{{ container.pcupCyCdTemp }} / {{ container.pcupReqDtTemp }}</td>
                <th>변경 {{ container.cntrSeq }}</th><!-- 변경 -->
                <td>{{ container.pcupCyCd }} / {{ pcupReqDt }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt10 text_center">
          <!-- 저장 -->
          <a
            class="button blue lg"
            href="#"
            @click.prevent="editPickupInfo()"
          >
            {{ $t('msg.VOSD100.041') }}
          </a>
          <!-- 닫기 -->
          <a
            class="button gray lg"
            href="#"
            @click.prevent="$emit('close', {saveFlag: saveFlag})"
          >
            {{ $t('msg.VOSD100.042') }}
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import trans from '@/api/rest/trans/trans'

export default {
  name: 'PickUpConfirmPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          bkgNo: '',
          facNm: '',
          facNmTemp: '',
          zip1: '',
          zip1Temp: '',
          tfcoCstNm: '',
          tfcoCstNmTemp: '',
          facPicNm: '',
          facPicNmTemp: '',
          facCtfcNo: '',
          facCtfcNoTemp: '',
          owtrYn: '',
          owtrYnTemp: '',
          containerList: []
        }
      }
    }
  },
  data () {
    return {
      saveFlag: false,
      isProcess: false,
      pcupReqDt: '',
      params: {
        bkgNo: '',
        facNm: '',
        facNmTemp: '',
        zip1Temp: '',
        zip1: '',
        tfcoCstNmTemp: '',
        tfcoCstNm: '',
        facPicNmTemp: '',
        facPicNm: '',
        facCtfcNoTemp: '',
        facCtfcNo: '',
        owtrYn: '',
        owtrYnTemp: '',
        containerList: []
      }
    }
  },
  created () {
    this.initParam()
  },
  methods: {
    // init parameter
    initParam () {
      for (let i = 0; i < this.parentInfo.containerList.length; i++) {
        this.parentInfo.containerList[i].pcupReqDtTemp = this.$ekmtcCommon.changeDatePattern(this.parentInfo.containerList[i].pcupReqDtOld, '-')
        this.pcupReqDt = this.$ekmtcCommon.changeDatePattern(this.parentInfo.containerList[i].pcupReqDt, '-')
      }

      this.params = { ...this.params, ...this.parentInfo }
    },
    // Pick UP 정보 변경
    editPickupInfo () {
      console.log(this.params)

      trans.editPickupInfo(this.params)
        .then(response => {
          const alertOpt = {
            alertType: 'normal',
            customCloseBtnText: this.$t('msg.ONEX100G020.001'), // 닫기
            type: 'info'
          }

          if (response.headers.respcode === 'C0000') {
            const TH = this
            this.saveFlag = true
            this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX100G020.002'), function () {
              TH.$emit('close', { saveFlag: TH.saveFlag })
            })
          } else {
            this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX100G020.003')) // PickUp정보 수정에 실패하였습니다.
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isProcess = false
        })
    }
  }
}
</script>
