var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content resize pickup_area" },
    [
      _c("e-breadcrumbs"),
      _c("div", { staticClass: "flex_box mt20" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX100G010.002"))),
        ]),
        _c("span", { staticClass: "ml_auto mt20" }, [
          _c("p", { staticClass: "txt_desc asterisk" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX100G010.003"))),
          ]),
        ]),
      ]),
      _c(
        "form",
        {
          attrs: { id: "frm" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _vm.saveFlag === "PICKUP"
            ? _c("div", { staticClass: "content_box beforetab" }, [
                _c("table", { staticClass: "tbl_search" }, [
                  _vm._m(0),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Booking No"),
                      ]),
                      _c("td", { attrs: { id: "area_input_bkgNo" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.bkgNo,
                              expression: "regParams.bkgNo",
                            },
                          ],
                          attrs: { type: "text", id: "bkgNo" },
                          domProps: { value: _vm.regParams.bkgNo },
                          on: {
                            keyup: function ($event) {
                              return _vm.fnValidationBkgNo()
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.regParams,
                                "bkgNo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c(
                          "a",
                          {
                            staticClass: "button blue sh",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.initEdit()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.005")))]
                        ),
                      ]),
                      _c("td", { staticClass: "position_relative" }, [
                        _vm.$ekmtcCommon.isNotEmpty(_vm.statusMsg)
                          ? _c(
                              "span",
                              { class: "box_info " + _vm.statusClass },
                              [
                                _c("span", { staticClass: "note" }, [
                                  _vm._v(_vm._s(_vm.statusMsg)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "content_box mt10" },
            [
              _c("table", { staticClass: "tbl_row" }, [
                _vm._m(1),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX100G010.004")))]),
                    _c("td", [_vm._v(_vm._s(_vm.regParams.bkgShprCstEnm))]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX100G010.005")))]),
                    _c("td", [_vm._v(_vm._s(_vm.regParams.tfcoCstNm))]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "flex_box mt20" }, [
                _vm.regParams.mtyPkupUsagYn !== "Y"
                  ? _c("h2", { staticClass: "content_title" }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.151")) + " "),
                    ])
                  : _vm._e(),
              ]),
              _vm.regParams.mtyPkupUsagYn !== "Y"
                ? _c("booking-schedule-info", {
                    attrs: {
                      "bkg-no": _vm.regParams.bkgNo,
                      "save-flag": _vm.saveFlag,
                      "except-watch-bkg-no": true,
                      "search-in-pick-up-info": _vm.searchInPickUpInfo,
                      "is-pickup": true,
                    },
                  })
                : _vm._e(),
              _c("div", { staticClass: "flex_box mt20" }, [
                _c("h2", { staticClass: "content_title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.040")) + " "),
                ]),
              ]),
              _c(
                "table",
                { staticClass: "tbl_col" },
                [
                  _vm._m(2),
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { colspan: "4" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX100G010.006"))),
                      ]),
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.068"))),
                      ]),
                      _vm._v(" "),
                      _vm.regParams.polShtmCd === "01"
                        ? _c("th", { attrs: { rowspan: "2" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.069"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.regParams.polShtmCd === "01"
                        ? _c("th", { attrs: { rowspan: "2" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.070"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.regParams.polShtmCd === "02"
                        ? _c("th", { attrs: { rowspan: "2" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.073"))),
                          ])
                        : _vm._e(),
                      _vm.regParams.polShtmCd === "02" && _vm.lang === "KOR"
                        ? _c("th", { attrs: { rowspan: "2" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.164"))),
                            _c("br"),
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.164_1"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.regParams.polShtmCd === "02" && _vm.lang !== "KOR"
                        ? _c("th", { attrs: { rowspan: "2" } })
                        : _vm._e(),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Size")]),
                      _c("th", [
                        _vm._v(" Type "),
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon help ml5",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.openPopup("container-pop")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "offscreen" }, [
                              _vm._v(_vm._s(_vm.$t("msg.ONEX100G010.007"))),
                            ]),
                          ]
                        ),
                      ]),
                      _c("th", [_vm._v("Q'ty")]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.071")))]),
                    ]),
                  ]),
                  _vm._l(_vm.regParams.containerList, function (vo, idx) {
                    return _c("tbody", { key: "tr_container_" + idx }, [
                      _c("tr", [
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.cntrSzCd,
                                  expression: "vo.cntrSzCd",
                                },
                              ],
                              attrs: { id: "cntrSzCd_" + idx, readonly: "" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    vo,
                                    "cntrSzCd",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(_vm.cntSzList, function (option) {
                              return _c(
                                "option",
                                {
                                  key: option.cd,
                                  domProps: { value: option.cd },
                                },
                                [_vm._v(" " + _vm._s(option.cdNm) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.cntrTypCd,
                                  expression: "vo.cntrTypCd",
                                },
                              ],
                              attrs: { id: "cntrTypCd_" + idx, readonly: "" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    vo,
                                    "cntrTypCd",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("---------------------"),
                              ]),
                              _vm._l(vo.subCntrTypList, function (option) {
                                return _c(
                                  "option",
                                  {
                                    key: option.cd,
                                    domProps: { value: option.cd },
                                  },
                                  [_vm._v(" " + _vm._s(option.cdNm) + " ")]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: { id: "cntrQty_" + idx, readonly: "" },
                              model: {
                                value: vo.cntrQty,
                                callback: function ($$v) {
                                  _vm.$set(vo, "cntrQty", $$v)
                                },
                                expression: "vo.cntrQty",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.shprVanYn,
                                expression: "vo.shprVanYn",
                              },
                            ],
                            attrs: {
                              id: "shprVanYn_" + idx,
                              type: "checkbox",
                              "true-value": "Y",
                              "false-value": "N",
                              disabled: "",
                            },
                            domProps: {
                              checked: Array.isArray(vo.shprVanYn)
                                ? _vm._i(vo.shprVanYn, null) > -1
                                : _vm._q(vo.shprVanYn, "Y"),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = vo.shprVanYn,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "Y" : "N"
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        vo,
                                        "shprVanYn",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        vo,
                                        "shprVanYn",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(vo, "shprVanYn", $$c)
                                }
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "shprVanYn_" + idx } }, [
                            _c("span", { staticClass: "offscreen" }, [
                              _vm._v(_vm._s(_vm.$t("msg.ONEX100G010.008"))),
                            ]),
                          ]),
                        ]),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.cgoTypCd,
                                  expression: "vo.cgoTypCd",
                                },
                              ],
                              attrs: { id: "cgoTypCd_" + idx, readonly: "" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    vo,
                                    "cgoTypCd",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              vo.cntrTypCd !== "OT" &&
                              vo.cntrTypCd !== "FR" &&
                              vo.cntrTypCd !== "SR"
                                ? _c("option", { attrs: { value: "" } }, [
                                    _vm._v(
                                      " --------------------------------------- "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._l(vo.subCgoTypList, function (option) {
                                return _c(
                                  "option",
                                  {
                                    key: option.cd,
                                    domProps: { value: option.cd },
                                  },
                                  [_vm._v(" " + _vm._s(option.cdNm) + " ")]
                                )
                              }),
                            ],
                            2
                          ),
                          _vm.$ekmtcCommon.isNotEmpty(vo.apvNo)
                            ? _c("div", { staticClass: "danger_num" }, [
                                _c("div", [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.165"))),
                                ]),
                                _c("div", { staticClass: "color_red" }, [
                                  _vm._v(" " + _vm._s(vo.apvNo) + " "),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "input_calendar" },
                            [
                              _c(
                                "e-date-picker",
                                {
                                  attrs: {
                                    id: "pcupReqDt_" + idx,
                                    disabled: vo.shprVanYn === "Y",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.checkValidationCntr(
                                        "pcupReqDt",
                                        idx,
                                        "KEYUP"
                                      )
                                    },
                                  },
                                  model: {
                                    value: vo.pcupReqDt,
                                    callback: function ($$v) {
                                      _vm.$set(vo, "pcupReqDt", $$v)
                                    },
                                    expression: "vo.pcupReqDt",
                                  },
                                },
                                [
                                  _vm.lang !== "KOR"
                                    ? _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: vo.pcupReqTm,
                                              expression: "vo.pcupReqTm",
                                            },
                                          ],
                                          staticClass: "mt5 col_8",
                                          attrs: { id: "pcupReqTm_" + idx },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                vo,
                                                "pcupReqTm",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "09" } },
                                            [_vm._v("09:00")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "10" } },
                                            [_vm._v("10:00")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "11" } },
                                            [_vm._v("11:00")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "12" } },
                                            [_vm._v("12:00")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "13" } },
                                            [_vm._v("13:00")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "14" } },
                                            [_vm._v("14:00")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "15" } },
                                            [_vm._v("15:00")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "16" } },
                                            [_vm._v("16:00")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "17" } },
                                            [_vm._v("17:00")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("td", [
                          _vm.cstCatCd !== "Y"
                            ? _c("div", [
                                _vm.lang !== "KOR" &&
                                _vm.regParams.polShtmCd === "01"
                                  ? _c("div", [
                                      _c("p", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: vo.pickUpPlace,
                                              expression: "vo.pickUpPlace",
                                            },
                                          ],
                                          attrs: {
                                            id: "pcupCyCd_" + idx,
                                            type: "text",
                                            placeholder: "Company Name",
                                            readonly: "",
                                            disabled: vo.shprVanYn === "Y",
                                          },
                                          domProps: { value: vo.pickUpPlace },
                                          on: {
                                            click: function ($event) {
                                              return _vm.getPickupPlace(idx)
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                vo,
                                                "pickUpPlace",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.lang === "KOR" &&
                                _vm.regParams.polShtmCd === "01"
                                  ? _c(
                                      "div",
                                      { staticClass: "position_relative" },
                                      [
                                        _c("p", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: vo.pickUpPlace,
                                                expression: "vo.pickUpPlace",
                                              },
                                            ],
                                            attrs: {
                                              id: "pcupCyCd_" + idx,
                                              type: "text",
                                              placeholder: "Company Name",
                                              readonly: "",
                                              disabled: vo.shprVanYn === "Y",
                                            },
                                            domProps: { value: vo.pickUpPlace },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getPickupPlace(idx)
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  vo,
                                                  "pickUpPlace",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                        _c(
                                          "p",
                                          { staticStyle: { margin: "5px 0" } },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: vo.pickUpMan,
                                                  expression: "vo.pickUpMan",
                                                },
                                              ],
                                              attrs: {
                                                id: "pickUpMan_" + idx,
                                                type: "text",
                                                readonly: "",
                                                disabled: vo.shprVanYn === "Y",
                                                placeholder:
                                                  _vm.$t("msg.CSBK100.129"),
                                              },
                                              domProps: { value: vo.pickUpMan },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    vo,
                                                    "pickUpMan",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("p", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: vo.pickUpTel,
                                                expression: "vo.pickUpTel",
                                              },
                                            ],
                                            attrs: {
                                              id: "pickUpTel_" + idx,
                                              type: "text",
                                              readonly: "",
                                              disabled: vo.shprVanYn === "Y",
                                              placeholder:
                                                _vm.$t("msg.CSBK100.161"),
                                            },
                                            domProps: { value: vo.pickUpTel },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  vo,
                                                  "pickUpTel",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.lang === "KOR" &&
                                _vm.regParams.polShtmCd !== "01" &&
                                idx === 0
                                  ? _c("div", [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.regParams.cfsFixdTypCd,
                                              expression:
                                                "regParams.cfsFixdTypCd",
                                            },
                                          ],
                                          staticClass: "col_4",
                                          attrs: { readonly: "" },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.regParams,
                                                "cfsFixdTypCd",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "01" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.CSBK100.047")
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "02" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.CSBK100.148")
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "03" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.CSBK100.166")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.regParams.cfsFixdEntrNm,
                                            expression:
                                              "regParams.cfsFixdEntrNm",
                                          },
                                        ],
                                        staticClass: "col_5",
                                        attrs: {
                                          id: "cfsFixdEntrNm_" + idx,
                                          type: "text",
                                          readonly: "",
                                        },
                                        domProps: {
                                          value: _vm.regParams.cfsFixdEntrNm,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.regParams,
                                              "cfsFixdEntrNm",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm.cstCatCd === "Y"
                            ? _c("div", [
                                _c("p", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: vo.pickUpPlace,
                                        expression: "vo.pickUpPlace",
                                      },
                                    ],
                                    attrs: {
                                      type: "text",
                                      placeholder: "Company Name",
                                      readonly: "",
                                      disabled: vo.shprVanYn === "Y",
                                    },
                                    domProps: { value: vo.pickUpPlace },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getPickUpPlace()
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          vo,
                                          "pickUpPlace",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      vo.cntrTypCd === "RF" || vo.cntrTypCd === "RH"
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text_left btn_responsive",
                                attrs: { colspan: "2" },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: vo.chkNOR,
                                      expression: "vo.chkNOR",
                                    },
                                  ],
                                  attrs: {
                                    id: "chk12",
                                    type: "checkbox",
                                    "true-value": "Y",
                                    "false-value": "N",
                                    readonly: "",
                                  },
                                  domProps: {
                                    checked: Array.isArray(vo.chkNOR)
                                      ? _vm._i(vo.chkNOR, null) > -1
                                      : _vm._q(vo.chkNOR, "Y"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = vo.chkNOR,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? "Y" : "N"
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              vo,
                                              "chkNOR",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              vo,
                                              "chkNOR",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(vo, "chkNOR", $$c)
                                      }
                                    },
                                  },
                                }),
                                _c("label", { attrs: { for: "chk12" } }, [
                                  _vm._v("NOR(Non Operating Reefer)"),
                                ]),
                                _vm._v("  "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "position_relative span_tooltip",
                                  },
                                  [
                                    _c("button", {
                                      staticClass: "tbl_icon help",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$ekmtcCommon.showTooltip(
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tooltip_wrap short position_absolute",
                                      },
                                      [
                                        _c("div", { staticClass: "cont" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "close",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$ekmtcCommon.hideTooltip(
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("close")]
                                          ),
                                          _c(
                                            "ul",
                                            { staticClass: "bul_list_sm t2" },
                                            [
                                              _c("li", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("msg.CSBK100.143")
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.CMBK100_M1.025"))),
                            ]),
                            _c(
                              "td",
                              [
                                _c("e-input-number", {
                                  class: "col_4",
                                  attrs: {
                                    id: "rfSetupTmpr_" + idx,
                                    "is-minus": true,
                                  },
                                  model: {
                                    value: vo.rfSetupTmpr,
                                    callback: function ($$v) {
                                      _vm.$set(vo, "rfSetupTmpr", $$v)
                                    },
                                    expression: "vo.rfSetupTmpr",
                                  },
                                }),
                                _vm.usrCtrCd !== "KR" && vo.chkNOR !== "Y"
                                  ? _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: vo.rfTmprUnitCd,
                                            expression: "vo.rfTmprUnitCd",
                                          },
                                        ],
                                        staticClass: "col_5",
                                        attrs: { readonly: "" },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              vo,
                                              "rfTmprUnitCd",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          { attrs: { value: "C" } },
                                          [_vm._v("℃")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "F" } },
                                          [_vm._v("℉")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.usrCtrCd === "KR" || vo.chkNOR === "Y"
                                  ? _c("span", { staticClass: "d_inline" }, [
                                      _vm._v("℃"),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v("VENT"),
                            ]),
                            _c("td", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: vo.cntrRfStsCd,
                                      expression: "vo.cntrRfStsCd",
                                    },
                                  ],
                                  attrs: {
                                    id: "cntrRfStsCd_" + idx,
                                    readonly: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        vo,
                                        "cntrRfStsCd",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                _vm._l(_vm.cntrRfStsList, function (cvo) {
                                  return _c(
                                    "option",
                                    {
                                      key: cvo.cd,
                                      domProps: { value: cvo.cd },
                                    },
                                    [_vm._v(" " + _vm._s(cvo.cdNm) + " ")]
                                  )
                                }),
                                0
                              ),
                            ]),
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v(" IoT Container  "),
                              _c(
                                "span",
                                {
                                  staticClass: "position_relative span_tooltip",
                                },
                                [
                                  _c("button", {
                                    staticClass: "tbl_icon help",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$ekmtcCommon.showTooltip(
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tooltip_wrap short position_absolute",
                                    },
                                    [
                                      _c("div", { staticClass: "cont" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "close",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$ekmtcCommon.hideTooltip(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" close ")]
                                        ),
                                        _vm._m(3, true),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: vo.iotEqpmYn,
                                    expression: "vo.iotEqpmYn",
                                  },
                                ],
                                attrs: {
                                  id: "iotEqpmYn_" + idx,
                                  type: "checkbox",
                                  disabled: "",
                                  "true-value": "Y",
                                  "false-value": "N",
                                },
                                domProps: {
                                  checked: vo.iotEqpmYn === "Y" ? true : false,
                                  checked: Array.isArray(vo.iotEqpmYn)
                                    ? _vm._i(vo.iotEqpmYn, null) > -1
                                    : _vm._q(vo.iotEqpmYn, "Y"),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = vo.iotEqpmYn,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? "Y" : "N"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            vo,
                                            "iotEqpmYn",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            vo,
                                            "iotEqpmYn",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(vo, "iotEqpmYn", $$c)
                                    }
                                  },
                                },
                              }),
                              _c("label", {
                                attrs: { for: "iotEqpmYn_" + idx },
                              }),
                            ]),
                          ])
                        : _vm._e(),
                      vo.cntrTypCd === "RF" || vo.cntrTypCd === "RH"
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "6" } }, [
                              _c("div", { staticClass: "select_opt_area" }, [
                                _c("div", { staticClass: "desc_list" }, [
                                  _c("ul", [
                                    _c(
                                      "li",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.usrCtrCd !== "JP",
                                            expression: "usrCtrCd !== 'JP'",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.CSBK100.168_1"))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.usrCtrCd !== "JP",
                                            expression: "usrCtrCd !== 'JP'",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.CSBK100.168_2"))
                                        ),
                                      ]
                                    ),
                                    _c("li", [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.CSBK100.168_3"))
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.CSBK100.168_4")) +
                                          " "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text_decoration",
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            href: "/#/common/pds?messageIdSeq=CKR362&groupId=362",
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("msg.CSBK100.168_5"))
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _c("td", { attrs: { colspan: "2" } }, [
                              _vm.lang === "KOR"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "button sm gray",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openRfHpmgSite(
                                            vo.rfHmpgUrl
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("msg.CSBK100.167")))]
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                      vo.cgoTypCd !== "" &&
                      "01|02|03|06".indexOf(vo.cgoTypCd) > -1
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: { "text-align": "left" },
                                attrs: { colspan: "8" },
                              },
                              [
                                vo.cgoTypCd === "01"
                                  ? _c("booking-table-dg", {
                                      ref: "dg",
                                      refInFor: true,
                                      attrs: {
                                        "parent-info": vo,
                                        "cas-no-port-yn": _vm.casNoPortYn,
                                        "tel-ctr-no": _vm.usrInfo.telCtrNo,
                                        "disabled-yn": _vm.disabledYn,
                                        "unno-disabled-yn": _vm.unnoDisabledYn,
                                        lang: _vm.lang,
                                        "save-flag": _vm.saveFlag,
                                      },
                                      on: { vanningChk: _vm.vanningChk },
                                    })
                                  : _vm._e(),
                                vo.cgoTypCd === "02" ||
                                (vo.cgoTypCd === "03" &&
                                  vo.cntrTypCd !== "HC" &&
                                  vo.cntrTypCd !== "45")
                                  ? _c("booking-table-oog", {
                                      ref: "oog",
                                      refInFor: true,
                                      attrs: {
                                        "cgo-typ-cd": vo.cgoTypCd,
                                        "parent-info": vo,
                                        "kmd-vsl-check": _vm.kmdVslCheck,
                                        "empty-flag": _vm.regParams.emptyFlag,
                                        lang: _vm.lang,
                                        "save-flag": _vm.saveFlag,
                                      },
                                    })
                                  : _vm._e(),
                                vo.cgoTypCd === "06"
                                  ? _c("booking-table-flexi", {
                                      ref: "flexi",
                                      refInFor: true,
                                      attrs: {
                                        "parent-info": vo,
                                        lang: _vm.lang,
                                        "save-flag": _vm.saveFlag,
                                      },
                                      on: { fbCatInfo: _vm.callFbCatInfo },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ])
                  }),
                ],
                2
              ),
              _vm.usrCtrCd === "TH"
                ? _c("div", { staticClass: "mt20" }, [
                    _c("table", { staticClass: "tbl_col" }, [
                      _vm._m(4),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("Return Date")]),
                          _c(
                            "td",
                            { staticClass: "text_left" },
                            [
                              _c("e-date-picker", {
                                model: {
                                  value: _vm.regParams.returnDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.regParams, "returnDate", $$v)
                                  },
                                  expression: "regParams.returnDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.vanningYn === "Y"
                ? _c("div", { staticClass: "mt20" }, [
                    _c("h2", { staticClass: "content_title" }, [
                      _vm._v("Charcoal Info."),
                    ]),
                    _c("table", { staticClass: "tbl_row mt10" }, [
                      _vm._m(5),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("B/L Shipper"),
                          ]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.chclActShprCstNo,
                                    expression: "regParams.chclActShprCstNo",
                                  },
                                ],
                                attrs: { id: "chclActShprCstNo" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.regParams,
                                      "chclActShprCstNo",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("Select"),
                                ]),
                                _vm._l(_vm.chclActList, function (cvo) {
                                  return _c(
                                    "option",
                                    {
                                      key: cvo.actShprCstNo,
                                      domProps: { value: cvo.actShprCstNo },
                                    },
                                    [_vm._v(_vm._s(cvo.actShprCstNm))]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("Vanning Surveyor."),
                          ]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.vaniSurvCstNo,
                                    expression: "regParams.vaniSurvCstNo",
                                  },
                                ],
                                attrs: {
                                  id: "vaniSurvCstNo",
                                  disabled: _vm.vanningSurvYn === "N",
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.regParams,
                                      "vaniSurvCstNo",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("Select"),
                                ]),
                                _vm._l(_vm.chclActList, function (cvo) {
                                  return _c(
                                    "option",
                                    {
                                      key: cvo.vaniSurvCstNo,
                                      domProps: { value: cvo.vaniSurvCstNo },
                                    },
                                    [_vm._v(_vm._s(cvo.vaniSurvCstNm))]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.msdsCheck === "Y" && _vm.regParams.podPortCd === "SHA"
                ? _c("div", { staticClass: "desc_list mt20" }, [_vm._m(6)])
                : _vm._e(),
              _vm.msdsCheck === "Y" && _vm.regParams.podPortCd !== "SHA"
                ? _c("div", { staticClass: "desc_list mt20" }, [_vm._m(7)])
                : _vm._e(),
              _vm.consigneeYn === "Y"
                ? _c("div", { staticClass: "mt20" }, [
                    _c("h2", { staticClass: "content_title text_left" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.169"))),
                    ]),
                    _vm._v(" "),
                    _c("table", { staticClass: "tbl_col t2" }, [
                      _vm._m(8),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.129"))),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.148"))),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.170"))),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v(
                                "(" + _vm._s(_vm.$t("msg.CSBK100.162")) + ")"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("email"),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v(
                                "(" + _vm._s(_vm.$t("msg.CSBK100.162")) + ")"
                              ),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.cnePicNm,
                                  expression: "regParams.cnePicNm",
                                },
                              ],
                              attrs: {
                                type: "text",
                                id: "cnePicNm",
                                maxlength: "100",
                              },
                              domProps: { value: _vm.regParams.cnePicNm },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams,
                                      "cnePicNm",
                                      $event.target.value
                                    )
                                  },
                                  _vm.inputConsignee,
                                ],
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.cnePicTelNo,
                                  expression: "regParams.cnePicTelNo",
                                },
                              ],
                              attrs: {
                                type: "text",
                                id: "cnePicTelNo",
                                maxlength: "30",
                              },
                              domProps: { value: _vm.regParams.cnePicTelNo },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams,
                                      "cnePicTelNo",
                                      $event.target.value
                                    )
                                  },
                                  _vm.inputConsignee,
                                ],
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.cnePicFaxNo,
                                  expression: "regParams.cnePicFaxNo",
                                },
                              ],
                              attrs: { type: "text", maxlength: "30" },
                              domProps: { value: _vm.regParams.cnePicFaxNo },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams,
                                      "cnePicFaxNo",
                                      $event.target.value
                                    )
                                  },
                                  _vm.inputConsignee,
                                ],
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.cnePicEmlAddr,
                                  expression: "regParams.cnePicEmlAddr",
                                },
                              ],
                              attrs: { type: "text", maxlength: "100" },
                              domProps: { value: _vm.regParams.cnePicEmlAddr },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams,
                                    "cnePicEmlAddr",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.mtyPkupUsagYn !== "Y" && _vm.usrCtrCd !== "VN"
                ? _c("table", { staticClass: "tbl_row mt20" }, [
                    _vm._m(9),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.022"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.txtDocCls))]),
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBK100.075"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.txtDocPic))]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.028"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.txtCgoCls))]),
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.031"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.txtPolTrmlInfo)),
                          _c("br"),
                          _vm._v(_vm._s(_vm.whCodeInfo)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.026"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.txtMfCls))]),
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v("MRN NO."),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.mrnNo))]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.lang === "KOR"
                ? _c("div", { staticClass: "desc_list" }, [
                    _c("ul", [
                      _c("li", { staticClass: "color_blue" }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.175"))),
                        _c("br"),
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.176"))),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.podCtrCd === "IR"
                ? _c("table", { staticClass: "tbl_row mt20" }, [
                    _vm._m(10),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v("B/L SHIPPER(ENGLISH ONLY)"),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.regParams.iranBookingInfo.actShprCstNm,
                                expression:
                                  "regParams.iranBookingInfo.actShprCstNm",
                              },
                            ],
                            attrs: {
                              id: "ir_actShprCstNm",
                              type: "text",
                              maxlength: "100",
                            },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.actShprCstNm,
                            },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams.iranBookingInfo,
                                    "actShprCstNm",
                                    $event.target.value
                                  )
                                },
                                _vm.changeActShprCstNm,
                              ],
                            },
                          }),
                          _c(
                            "span",
                            {
                              class:
                                _vm.$ekmtcCommon.isNotEmpty(
                                  _vm.regParams.iranBookingInfo.actShprCstNmC
                                ) &&
                                _vm.regParams.iranBookingInfo.actShprCstNmC !==
                                  "N"
                                  ? ""
                                  : "d_none",
                              attrs: { id: "ir_actShprCstNmC" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("msg.CSBK100.115")) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v("NAME OF CONSIGNEE"),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.iranBookingInfo.cneCstNm,
                                expression:
                                  "regParams.iranBookingInfo.cneCstNm",
                              },
                            ],
                            attrs: {
                              id: "ir_cneCstNm",
                              type: "text",
                              maxlength: "100",
                            },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.cneCstNm,
                            },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams.iranBookingInfo,
                                    "cneCstNm",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  _vm.regParams.iranBookingInfo.cneCstNm =
                                    _vm.regParams.iranBookingInfo.cneCstNm.toUpperCase()
                                },
                              ],
                            },
                          }),
                          _c(
                            "span",
                            {
                              class:
                                _vm.$ekmtcCommon.isNotEmpty(
                                  _vm.regParams.iranBookingInfo.cneCstNmC
                                ) &&
                                _vm.regParams.iranBookingInfo.cneCstNmC !== "N"
                                  ? ""
                                  : "d_none",
                              attrs: { id: "ir_cneCstNmC" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("msg.CSBK100.115")) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v("END USER"),
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "tbl_form position_relative" },
                            [
                              _c("span", { staticClass: "dv col_5" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.regParams.iranBookingInfo.endUsrNm,
                                      expression:
                                        "regParams.iranBookingInfo.endUsrNm",
                                    },
                                  ],
                                  attrs: {
                                    id: "ir_endUsrNm",
                                    type: "text",
                                    maxlength: "100",
                                  },
                                  domProps: {
                                    value:
                                      _vm.regParams.iranBookingInfo.endUsrNm,
                                  },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.regParams.iranBookingInfo,
                                          "endUsrNm",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        _vm.regParams.iranBookingInfo.endUsrNm =
                                          _vm.regParams.iranBookingInfo.endUsrNm.toUpperCase()
                                      },
                                    ],
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    class:
                                      _vm.$ekmtcCommon.isNotEmpty(
                                        _vm.regParams.iranBookingInfo.endUsrNmC
                                      ) &&
                                      _vm.regParams.iranBookingInfo
                                        .endUsrNmC !== "N"
                                        ? ""
                                        : "d_none",
                                    attrs: { id: "ir_endUsrNmC" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("msg.CSBK100.115")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("span", { staticClass: "dv" }, [
                                _c("input", {
                                  staticClass: "ml20",
                                  attrs: { type: "checkbox", id: "sameas" },
                                  on: { click: _vm.sameConsigneeName },
                                }),
                                _c("label", { attrs: { for: "sameas" } }, [
                                  _vm._v("SAME AS CONSIGNEE"),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v("END USER INFORMATION"),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _c("span", { staticClass: "dv col_2" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.regParams.iranBookingInfo.endUsrTelNo,
                                    expression:
                                      "regParams.iranBookingInfo.endUsrTelNo",
                                  },
                                ],
                                attrs: {
                                  id: "ir_endUsrTelNo",
                                  type: "text",
                                  placeholder: "phone no",
                                  maxlength: "25",
                                },
                                domProps: {
                                  value:
                                    _vm.regParams.iranBookingInfo.endUsrTelNo,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams.iranBookingInfo,
                                      "endUsrTelNo",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("span", { staticClass: "dv col_3" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.regParams.iranBookingInfo
                                        .endUsrEmlAddr,
                                    expression:
                                      "regParams.iranBookingInfo.endUsrEmlAddr",
                                  },
                                ],
                                attrs: {
                                  id: "ir_endUsrEmlAddr",
                                  type: "text",
                                  placeholder: "email",
                                  maxlength: "100",
                                },
                                domProps: {
                                  value:
                                    _vm.regParams.iranBookingInfo.endUsrEmlAddr,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams.iranBookingInfo,
                                      "endUsrEmlAddr",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("span", { staticClass: "dv" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.regParams.iranBookingInfo.endUsrAddr,
                                    expression:
                                      "regParams.iranBookingInfo.endUsrAddr",
                                  },
                                ],
                                attrs: {
                                  id: "ir_endUsrAddr",
                                  type: "text",
                                  placeholder: "address",
                                  maxlength: "100",
                                },
                                domProps: {
                                  value:
                                    _vm.regParams.iranBookingInfo.endUsrAddr,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams.iranBookingInfo,
                                      "endUsrAddr",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v("NAME OF COMMODITY"),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.iranBookingInfo.cmdtNm,
                                expression: "regParams.iranBookingInfo.cmdtNm",
                              },
                            ],
                            attrs: {
                              id: "ir_cmdtNm",
                              type: "text",
                              maxlength: "100",
                            },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.cmdtNm,
                            },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams.iranBookingInfo,
                                    "cmdtNm",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  _vm.regParams.iranBookingInfo.cmdtNm =
                                    _vm.regParams.iranBookingInfo.cmdtNm.toUpperCase()
                                },
                              ],
                            },
                          }),
                          _c(
                            "span",
                            {
                              class:
                                _vm.$ekmtcCommon.isNotEmpty(_vm.cmdtNmC) &&
                                _vm.cmdtNmC !== "N"
                                  ? ""
                                  : "d_none",
                              attrs: { id: "ir_cmdtNmC" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("msg.CSBK100.116")) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v("HS CODE"),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.iranBookingInfo.hsCd,
                                expression: "regParams.iranBookingInfo.hsCd",
                              },
                            ],
                            attrs: {
                              id: "ir_hsCd",
                              type: "text",
                              maxlength: "10",
                            },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.hsCd,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.regParams.iranBookingInfo,
                                  "hsCd",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c(
                            "span",
                            {
                              class:
                                _vm.$ekmtcCommon.isNotEmpty(_vm.hsCdC) &&
                                _vm.hsCdC !== "N"
                                  ? ""
                                  : "d_none",
                              attrs: { id: "ir_hsCdC" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("msg.CSBK100.117")) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v("PURPOSE OF USE"),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.regParams.iranBookingInfo.useDestCont,
                                expression:
                                  "regParams.iranBookingInfo.useDestCont",
                              },
                            ],
                            attrs: { id: "ir_useDestCont", type: "text" },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.useDestCont,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.regParams.iranBookingInfo,
                                  "useDestCont",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [_vm._v("LOI")]),
                        _c("td", [
                          _c(
                            "a",
                            { staticClass: "button sm", attrs: { href: "#" } },
                            [_vm._v("LOI DownLoad")]
                          ),
                          _c(
                            "span",
                            { staticClass: "color_red font_12 ml10" },
                            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.177")))]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v("SHIPPER REMARK"),
                        ]),
                        _c("td", [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.iranBookingInfo.reqCont,
                                expression: "regParams.iranBookingInfo.reqCont",
                              },
                            ],
                            attrs: { id: "reqCont" },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.reqCont,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.regParams.iranBookingInfo,
                                  "reqCont",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v("KMTC REMARK"),
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.regParams.iranBookingInfo.apvCont) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.lang !== "KOR"
                ? _c("div", { staticClass: "mt20 div_req_area" }, [
                    _c("h2", { staticClass: "content_title" }, [
                      _vm._v("Request"),
                    ]),
                    _c("table", { staticClass: "tbl_row" }, [
                      _vm._m(11),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("CY(Depot) Remark")]),
                          _c("td", [
                            _c("div", { staticClass: "position_relative" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.dorOdrRmk,
                                    expression: "regParams.dorOdrRmk",
                                  },
                                ],
                                attrs: { id: "dorOdrRmk" },
                                domProps: { value: _vm.regParams.dorOdrRmk },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams,
                                      "dorOdrRmk",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._m(12),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Document Remark")]),
                          _c("td", [
                            _vm.usrCtrCd !== "TH"
                              ? _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.regParams.returnDate,
                                      expression: "regParams.returnDate",
                                    },
                                  ],
                                  domProps: { value: _vm.regParams.returnDate },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.regParams,
                                        "returnDate",
                                        $event.target.value
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.usrCtrCd === "TH"
                              ? _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.returnDateTH,
                                      expression: "returnDateTH",
                                    },
                                  ],
                                  attrs: { readonly: "" },
                                  domProps: { value: _vm.returnDateTH },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.returnDateTH = $event.target.value
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Operation Remark")]),
                          _c("td", [
                            _c("div", { staticClass: "position_relative" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.oprRmk,
                                    expression: "regParams.oprRmk",
                                  },
                                ],
                                attrs: { id: "oprRmk" },
                                domProps: { value: _vm.regParams.oprRmk },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams,
                                      "oprRmk",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._m(13),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.saveFlag === "PICKUP"
            ? _c("div", [
                _c("div", { staticClass: "mt10 text_right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button blue lg mr5",
                      on: {
                        click: function ($event) {
                          return _vm.confirmPickup()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ONEX100G010.001")))]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popParams },
                on: { close: _vm.closePoup, selectFunc: _vm.selectFunc },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "130px" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "150" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10.5%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "bul_list_sm t2" }, [
      _c("li", [_vm._v(" Iot 컨테이너 ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "80%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v("To Shanghai, a A MSDS is required. Please upload the file."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "To S.China or Ningbo, a A MSDS in CHINESE is required. Please upload the file."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "85%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "div_req_byte" }, [
      _c(
        "span",
        {
          staticClass: "color_black font_medium",
          attrs: { id: "byte_dorOdrRmk" },
        },
        [_vm._v("0")]
      ),
      _c("span", { staticClass: "color_lightgray" }, [_vm._v("/")]),
      _c("span", { staticClass: "color_lightgray" }, [_vm._v("2,000")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "div_req_byte" }, [
      _c(
        "span",
        {
          staticClass: "color_black font_medium",
          attrs: { id: "byte_oprRmk" },
        },
        [_vm._v("0")]
      ),
      _c("span", { staticClass: "color_lightgray" }, [_vm._v("/")]),
      _c("span", { staticClass: "color_lightgray" }, [_vm._v("2,000")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }