<template>
  <div
    class="popup_wrap"
    style="width:800px;height:600px;"
  >
    <button
      class="layer_close"
      @click.prevent="closeNmove()"
    >
      close
    </button>
    <div class="popup_cont capture_area">
      <h1 style="display: inline;" class="page_title">{{ $t('msg.VOSD100_M5.005') }}</h1>&nbsp;&nbsp;<!-- 운임확인 --><div class="button sm green" style="position: absolute; top: 2px;">{{ $t('msg.MAIN010G030.038') }}</div><!-- KMTC SPOT -->
      <div class="content_box capture_sub_area">
        <!-- content_box -->
        <p class="txt_desc none">
          {{ $t('msg.VOSD100.002') }} : {{ polNm }} ~ {{ $t('msg.VOSD100.003') }} : {{ podNm }}<br />
          {{ $t('msg.VOSD100_M5.006') }} : {{ parentInfo.rteCd }} / {{ parentInfo.vslNm }} / {{ parentInfo.voyNo }}<br />
        </p>
        <table class="tbl_search mt10">
          <colgroup>
            <col width="80px"><col>
          </colgroup>
          <tbody>
            <tr>
              <th class="pl0">
                {{ $t('msg.VOSD100_M5.007') }}<!--CNTR Type -->
              </th>
              <td>
                <!-- <select name="" id="" class="wid150" :disabled="containerType.length === 1" @change="changeCntnType">
                <option v-for="vo in containerType" :key="'sel_' + vo.cd" :value="vo.cd">{{ vo.cdNm }}</option>
              </select> -->
                <select name="" id="" class="wid150" disabled="true" @change="changeCntnTypeV2">
                  <!-- disabled-->
                  <option v-for="vo in cntrTypList" :key="'sel_' + vo.reqRno" :value="vo.reqRno">{{ vo.asCntrTypCd }}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="tbl_col mt10">
          <colgroup>
            <col width="28%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
          </colgroup>
          <thead>
            <tr>
              <th>Freight Charges</th>
              <th>Currency</th>
              <th>Type</th>
              <th>Cargo</th>
              <th>20'</th>
              <th>40'</th>
              <th>HC</th>
            </tr>
          </thead>
          <tr v-for="(row,idx) in surChargeList2Show" :key="idx">
            <td class="text_left" v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">{{ row.frtCdNm }}</td>
            <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">{{ row.curCd }}</td>
            <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
            <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">{{ row.cgoTypNm }}</td>
            <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">
              <div v-if="row.rate20 !== '' && row.rate20 !== '0' && frtAppNo === '' && of20Rate !== '' && of20Rate !== undefined">
                {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate20) + parseInt(of20Rate), { isComma: true }) }}
              </div>
              <div v-else-if="row.rate20 !== '' && row.rate20 !== '0' && of20Rate !== '' && of20Rate !== undefined">
                {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate20) + parseInt(of20Rate), { isComma: true }) }}
              </div>
              <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</div>
            </td>
            <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">
              <div v-if="row.rate40 !== '' && row.rate40 !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate40) + parseInt(of40Rate), { isComma: true }) }}
              </div>
              <div v-else-if="row.rate40 !== '' && row.rate40 !== '0' && of40Rate !== '' && of40Rate !== undefined">
                {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate40) + parseInt(of40Rate), { isComma: true }) }}
              </div>
              <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</div>
            </td>
            <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">
              <div v-if="row.rateHc !== '' && row.rateHc !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rateHc) + parseInt(of40Rate), { isComma: true }) }}
              </div>
              <div v-else-if="row.rateHc !== '' && row.rateHc !== '0' && of40Rate !== '' && of40Rate !== undefined">
                {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rateHc) + parseInt(of40Rate), { isComma: true }) }}
              </div>
              <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</div>
            </td>
          </tr>
        </table>
        <table class="tbl_col mt10">
          <colgroup>
            <col width="28%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
          </colgroup>
          <thead>
            <tr>
              <th>Export Charges</th>
              <th>Currency</th>
              <th>Type</th>
              <th>Cargo</th>
              <th>20'</th>
              <th>40'</th>
              <th>HC</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row,idx) in surChargeList2Show" :key="idx">
              <td class="text_left" v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">{{ row.frtCdNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">{{ row.curCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">
                <div v-if="row.waive20 === 'Y'" style="color: red"><del>{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</del></div>
                <div v-else-if="row.waive20 === 'N'" style="color: red">{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</div>
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">
                <div v-if="row.waive40 === 'Y'" style="color: red"><del>{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</del></div>
                <div v-else-if="row.waive40 === 'N'" style="color: red">{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</div>
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">
                <div v-if="row.waiveHc === 'Y'" style="color: red"><del>{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</del></div>
                <div v-else-if="row.waiveHc === 'N'" style="color: red">{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Import Charge -->
        <table class="tbl_col mt10">
          <colgroup>
            <col width="28%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
          </colgroup>
          <thead>
            <tr>
              <th>Import Charges</th>
              <th>Currency</th>
              <th>Type</th>
              <th>Cargo</th>
              <th>20'</th>
              <th>40'</th>
              <th>HC</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row,idx) in surChargeList2Show" :key="idx">
              <td class="text_left" v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">{{ row.frtCdNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">{{ row.curCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">
                <div v-if="row.waive20 === 'Y'" style="color: red"><del>{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</del></div>
                <div v-else-if="row.waive20 === 'N'" style="color: red">{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</div>
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">
                <div v-if="row.waive40 === 'Y'" style="color: red"><del>{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</del></div>
                <div v-else-if="row.waive40 === 'N'" style="color: red">{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</div>
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'">
                <div v-if="row.waiveHc === 'Y'" style="color: red"><del>{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</del></div>
                <div v-else-if="row.waiveHc === 'N'" style="color: red">{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Import Charge -->
        <!-- Notes -->
        <div v-show="surChargeNoteList.length !== 0">
          <p class="txt_desc asterisk">Notes<br /></p>
          <div v-for="(row,idx) in surChargeNoteList" :key="idx">
            <div v-show="row.frtCd === 'DCF' || row.frtCd === 'SRD' || row.frtCd === 'AFS'">
              <p class="txt_desc2">{{ idx+1 }}. Subject to {{ row.frtCdNm }} : {{ row.curCd }} {{ $ekmtcCommon.changeNumberFormat(row.blRate, { isComma: true }) }} per Bill of Lading.<br /></p>
            </div>
            <div v-if="idx === (surChargeNoteList.length-1)">
              <p class="txt_desc2">{{ idx+2 }}. Subject to SPECIAL DG HANDLING SURCHARGE : USD 600/20', USD 700/40' per container (※For only DG CLASS 2 by PUSAN T/S).<br /></p>
            </div>
          </div>
        </div>
        <!-- Notes -->
        <!-- Destination Freetime -->
        <table class="tbl_col mt10">
          <colgroup>
            <col width="20%">
            <col width="20%">
            <col width="20%">
            <col width="20%">
            <col width="20%">
          </colgroup>
          <thead>
            <tr>
              <th rowspan="2">KIND</th>
              <th colspan="2">Loading</th>
              <th colspan="2">Discharging</th>
            </tr>
            <tr>
              <th style="border-left:1px solid #e1e3eb;">GP</th>
              <th>HC</th>
              <th>GP</th>
              <th>HC</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row,idx) in rateFreeDdsList" :key="idx">
              <td class="text_left">{{ row.demDetCatNm }}</td>
              <td>{{ row.ldGpFreeDds }}</td>
              <td>{{ row.ldHcFreeDds }}</td>
              <td>{{ row.disGpFreeDds }}</td>
              <td>{{ row.disHcFreeDds }}</td>
            </tr>
          </tbody>
        </table>
        <!-- Destination Freetime -->
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <span><a class="button gray lg" href="#" @click.prevent="closeNmove()">{{ $t('msg.VOSD100.042') }}<!-- 닫기 --></a></span>
      </div>
    </div>
  </div>
</template>

<script>
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import schedules from '@/api/rest/schedule/schedule'
import myScheduleApi from '@/api/rest/schedule/mySchedule'

export default {
  name: 'FreSurchargePop2',
  components: {},
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          porCtrCd: '',
          porPlcCd: '',
          dlyCtrCd: '',
          dlyPlcCd: '',
          polNm: '',
          podNm: '',
          etd: '',
          frtAppNo: '',
          vslCd: '',
          vslNm: '',
          voyNo: '',
          rteCd: '',
          eiCatCd: '',
          frtResult: '',
          reqRno: '',
          bkgClose: '',
          raTsParam: '',
          negoYN: '',
          etaBookingMsg: '',
          promotionChk: 'N',
          scenarioCd: '',
          promoNo: '',
          applyFr: '',
          applyTo: '',
          ofCntrSize01: '',
          ofCntrType01: '',
          ofDcRate01: 0,
          ofDcType01: '',
          ofCntrSize02: '',
          ofCntrType02: '',
          ofDcRate02: 0,
          ofDcType02: '',
          dtCntrSize01: '',
          dtCntrType01: '',
          dtDcRate01: 0,
          dtDcType01: '',
          dtCntrSize02: '',
          dtCntrType02: '',
          dtDcRate02: 0,
          dtDcType02: ''

        }
      }
    }
  },
  data: function () {
    return {
      startDate: '',
      endDate: '',
      strDt: '',
      endDt: '',
      polNm: '',
      podNm: '',
      surChargeList: [],
      surChargeNoteList: [],
      surChargeGPList: [],
      surChargeRFList: [],
      frtAppNo: '',
      of20Rate: '',
      of40Rate: '',
      spread20: 0,
      spread40: 0,
      schgReqRno: '',
      cntrTypCd2: '',
      checkCharge: 'N',
      containerType: [],
      surChargeListDry: [],
      surChargeListRee: [],
      surChargeListEtc: [],
      surChargeList2Show: [],
      rateFreeDdsList: [],
      cntrTypList: [],
      vslReqRno: '',
      vslSeq: '',
      acceptYn: false,
      legMenuChk: 'N',
      urlOrNot: false // JamSin DSM
    }
  },
  async created () {
    //console.log('>>>>>> [freSurcharge2] this.parentInfo <<<<<<')
    //console.log(this.parentInfo)
    this.urlOrNot = this.$ekmtcCommon.isNotEmpty(this.parentInfo.urlOrNot) ? this.parentInfo.urlOrNot : false // JamSin DSM 트래킹을 위해 스케줄에서 urlOrNot 넘김

    const params = {
      scenarioCd: this.parentInfo.scenarioCd,
      promoNo: this.parentInfo.promoNo
    }

    if (this.parentInfo.promotionChk === 'Y') {
      await myScheduleApi.getPromotionDcAmt(params).then(res => {
        this.parentInfo.applyFr = res.data[0].applyFr
        this.parentInfo.applyTo = res.data[0].applyTo
        this.parentInfo.ofCntrSize01 = res.data[0].ofCntrSize01
        this.parentInfo.ofCntrType01 = res.data[0].ofCntrType01
        this.parentInfo.ofDcRate01 = res.data[0].ofDcRate01
        this.parentInfo.ofDcType01 = res.data[0].ofDcType01
        this.parentInfo.ofCntrSize02 = res.data[0].ofCntrSize02
        this.parentInfo.ofCntrType02 = res.data[0].ofCntrType02
        this.parentInfo.ofDcRate02 = res.data[0].ofDcRate02
        this.parentInfo.ofDcType02 = res.data[0].ofDcType02
        this.parentInfo.dtCntrSize01 = res.data[0].dtCntrSize01
        this.parentInfo.dtCntrType01 = res.data[0].dtCntrType01
        this.parentInfo.dtDcRate01 = res.data[0].dtDcRate01
        this.parentInfo.dtDcType01 = res.data[0].dtDcType01
        this.parentInfo.dtCntrSize02 = res.data[0].dtCntrSize02
        this.parentInfo.dtCntrType02 = res.data[0].dtCntrType02
        this.parentInfo.dtDcRate02 = res.data[0].dtDcRate02
        this.parentInfo.dtDcType02 = res.data[0].dtDcType02
      }).catch(e => {
        console.log(e)
      })
    }

    // 메뉴 경로로 현재 스케줄 메뉴 여부 확인
    if (this.$route.path === '/schedule/leg') {
      this.legMenuChk = 'Y'
    }

    this.getCntrList()
    // this.getFreSurcharge()
  },
  methods: {
    getCntrList () {
      vslScheduleInfo.popFreRateNoCntr(this.parentInfo).then((rtnData) => {
        // console.log('>>>>>> [freSurcharge] popFreRateNoCntr <<<<<<')
        // console.log(rtnData)
        this.cntrTypList = rtnData.data.cntrTypList
        if (this.cntrTypList === undefined || this.cntrTypList.length === 0) {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
        }
        this.parentInfo.reqRno = this.cntrTypList[0].reqRno
        this.cntrTypCd2 = this.cntrTypList[0].cntrTypCd
        this.getFreSurcharge()
      })
    },
    async getCntrTypeMessage () {
      let message = this.$t('msg.CSCT060G040.010') + '<br><br>'

      message += '<div class="d_flex">'
      message += '  <div class="inp_space30">Type : </div>'
      message += '  <div class="inp_space70">'
      message += '  <select id="popCntrTypCd">'
      message += '  <option value="">' + this.$t('msg.CSBL200.075') + '</option>'
      message += '  <option value="GP">Dry</option>'
      message += '  <option value="RF">Reefer</option>'
      message += '  <option value="FR">Flat Rack</option>'
      message += '  <option value="OT">Open Top</option>'
      message += '  </select>'
      message += '  </div>'
      message += '</div>'

      let rtnVal = ''
      const isOk = await this.$ekmtcCommon.asyncAlertMessage({
        message: message,
        useConfirmBtn: true,
        onConfirmSelf: (resolve, $Simplert) => {
          const popCntrTypCd = document.querySelector('#popCntrTypCd')

          if (this.$ekmtcCommon.isEmpty(popCntrTypCd.value)) {
            this.$ekmtcCommon.showErrorTooltip(popCntrTypCd, this.$t('msg.CSBK100.147'))
          } else {
            rtnVal = popCntrTypCd.value
            resolve(true)
            $Simplert.close()
          }
        }
      })

      return {
        isOk: isOk,
        rtnVal: rtnVal
      }
    },
    async fnFrtConfirm () {
      const parentInfo = this.parentInfo
      const params = {
        porCtrCd: parentInfo.porCtrCd,
        porPlcCd: parentInfo.porPlcCd,
        dlyCtrCd: parentInfo.dlyCtrCd,
        dlyPlcCd: parentInfo.dlyPlcCd,
        etd: parentInfo.etd,
        eiCatCd: parentInfo.eiCatCd
      }

      const cntrMsgInfo = await this.getCntrTypeMessage()

      if (cntrMsgInfo.isOk) {
        params.cntrTypCd = cntrMsgInfo.rtnVal

        await schedules.fnPopFreReq(params).then((response) => {
          const result = response.data.insertRateMngResult
          let msg = ''
          if (result !== undefined && result === 1) {
            //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
            msg = this.$t('msg.FARE010T010.025') // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.
          } else if (result !== undefined && result === 2) { // 등록된 운임이 있음
            msg = this.$t('msg.FARE010T010.024')
          } else if (result !== undefined && result === 3) { // 요청중인 내역이 있는경우 메시지만 동일하게 출력
            //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
            msg = this.$t('msg.FARE010T010.025') // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.
          } else {
            msg = this.$t('msg.FARE010T010.023') // 처리 중 오류가 발생하였습니다. 시스템 관리자에게 문의해 주세요.
          }
          this.$ekmtcCommon.alertDefault(msg)
        })
      }
    },
    getFreSurcharge () {
      this.frtAppNo = this.parentInfo.frtAppNo
      // 출발, 도착지명
      var polnm = this.parentInfo.polNm.split(',')
      var podnm = this.parentInfo.podNm.split(',')
      if (polnm.length > 1) {
        this.polNm = polnm[1] + ' / ' + polnm[0]
      } else {
        this.polNm = this.parentInfo.polNm
      }
      if (podnm.length > 1) {
        this.podNm = podnm[1] + ' / ' + podnm[0]
      } else {
        this.podNm = this.parentInfo.podNm
      }
      // 운임 Data
      let dry = 'N'
      let reefee = 'N'
      this.surChargeGPList = []
      this.surChargeRFList = []
      this.surChargeList2Show = []
      this.surChargeNoteList = []
      this.parentInfo.cntrTypCd = this.cntrTypCd2
      vslScheduleInfo.getPopFreSurcharge(this.parentInfo).then((rtnData) => {
        // console.log('######### [freSurcharge] getPopFreSurcharge #########')
        // console.log(rtnData)
        this.surChargeList = rtnData.data.surChargeList
        this.rateFreeDdsList = rtnData.data.rateFreeDdsList
        this.of20Rate = rtnData.data.of20Rate
        this.of40Rate = rtnData.data.of40Rate
        for (const item of this.surChargeList) {
          if (item.frtCd === 'DCF' || item.frtCd === 'SRD' || item.frtCd === 'AFS') {
            this.surChargeNoteList.push(item)
          }
          if (this.cntrTypCd2 === 'GP') {
            if (item.cntrTypCd === 'GP' || item.cntrTypCd === 'HC') {
              this.surChargeGPList.push(item)
            }
          } else if (this.cntrTypCd2 === 'RF') {
            if (item.cntrTypCd === 'RF' || item.cntrTypCd === 'RH') {
              this.surChargeRFList.push(item)
            }
          }
        }
        if (this.cntrTypCd2 === 'GP') {
          this.surChargeList2Show = this.surChargeGPList
        } else if (this.cntrTypCd2 === 'RF') {
          this.surChargeList2Show = this.surChargeRFList
        } else {
          this.surChargeList2Show = this.surChargeList
        }
        // 운임 유효기간
        this.startDate = rtnData.data.strDt.substring(0, 4) + '-' + rtnData.data.strDt.substring(4, 6) + '-' + rtnData.data.strDt.substring(6, 8)
        this.endDate = rtnData.data.endDt.substring(0, 4) + '-' + rtnData.data.endDt.substring(4, 6) + '-' + rtnData.data.endDt.substring(6, 8)
        this.strDt = rtnData.data.strDt
        this.endDt = rtnData.data.endDt
        this.schgReqRno = rtnData.data.schgReqRno
        if (this.surChargeList === undefined || this.surChargeList.length === 0) {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
        }
        this.vslReqRno = rtnData.data.vslReqRno
        this.vslSeq = rtnData.data.vslSeq
      })
    },
    changeCntnTypeV2 (e) {
      const val = e.target.value
      // 프로모션 유입시 reqRno 값이 바뀌는 경우가 있어 프로모션 유입인 경우 변경 못하도록 처리함
      if (this.parentInfo.promotionChk !== 'Y') {
        this.parentInfo.reqRno = val
      }
      for (const item of this.cntrTypList) {
        if (item.reqRno === val) {
          this.cntrTypCd2 = item.cntrTypCd
        }
      }
      this.getFreSurcharge()
    },
    changeCntnType (e) {
      const val = e.target.value
      this.surChargeList2Show = []
      if (val === 'D') {
        this.surChargeList2Show = this.surChargeListDry
      } else if (val === 'R') {
        this.surChargeList2Show = this.surChargeListRee
      } else {
        this.surChargeList2Show = this.surChargeListEtc
      }
    },
    fnAccept () {
      if (this.surChargeList.length !== 0) {
        let obj = {
          // onClose: this.closeConfirm,
          onConfirm: this.acceptQuote,
          customCloseBtnText: this.$t('msg.VOSD100_M5.004'),
          customCloseBtnClass: 'button blue lg',
          customConfirmBtnText: this.$t('msg.VOSD100_M5.003'),
          customConfirmBtnClass: 'button blue lg',
          useConfirmBtn: true,
          message: this.$t('msg.VOSD100_M5.001'),
          type: 'info'
        }
        this.$ekmtcCommon.alert(obj)
      }
    },
    closeConfirm () {
      // console.log('######### [freSurcharge] closeConfirm #########')
    },
    acceptQuote () {
      window.app.$Simplert.close() //컨텀창 안닫혀서 추가
      /*
            let startDt = ''
            let overDt = ''

            // 프로모션인 경우 운임의 유효일자는 프로모션 기간과 동일하게 세팅함
            if (this.parentInfo.promotionChk === 'Y') {
              startDt = this.parentInfo.applyFr
              overDt = this.parentInfo.applyTo
            } else {
              startDt = this.strDt
              overDt = this.endDt
            }
      */
      const params = {
        porCtrCd: this.parentInfo.porCtrCd,
        porPlcCd: this.parentInfo.porPlcCd,
        dlyCtrCd: this.parentInfo.dlyCtrCd,
        dlyPlcCd: this.parentInfo.dlyPlcCd,
        etd: this.parentInfo.etd,
//        strDt: startDt,
//        endDt: overDt,
        strDt: this.strDt,
        endDt: this.endDt,
        reqRno: this.parentInfo.reqRno,
        rteCd: this.parentInfo.rteCd,
        vslCd: this.parentInfo.vslCd,
        voyNo: this.parentInfo.voyNo,
        schgReqRno: this.schgReqRno,
        of20Rate: this.of20Rate,
        of40Rate: this.of40Rate,
        cntrTypeCd: this.cntrTypCd2,
        eiCatCd: this.parentInfo.eiCatCd,
        promotionChk: this.parentInfo.promotionChk,
        scenarioCd: this.parentInfo.scenarioCd,
        promoNo: this.parentInfo.promoNo,
        vslReqRno: this.vslReqRno,
        vslSeq: this.vslSeq,
        spread20: this.spread20,
        spread40: this.spread40,
        hotDealYn: this.cntrTypCd2 === 'GP' ? 'Y' : 'N'
      }
      vslScheduleInfo.insertAcceptQuote(params).then((rtnData) => {
        // console.log('######### [freSurcharge] insertAcceptQuote #########')
        // console.log(rtnData)
        this.surChargeList = rtnData.data.surChargeList
        this.frtAppNo = rtnData.data.frtAppNo
        this.checkCharge = 'Y'
        this.parentInfo.frtResult = rtnData.data.frtResult
        this.parentInfo.frtAppNo = rtnData.data.frtAppNo
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.SCHD010P071.001') }) // 문의하신 구간의 운임 SC No. 가 생성되었습니다.
        // // 운임 유효기간
        // this.startDate = rtnData.data.strDt.substring(0, 4) + '-' + rtnData.data.strDt.substring(4, 6) + '-' + rtnData.data.strDt.substring(6, 8)
        // this.endDate = rtnData.data.endDt.substring(0, 4) + '-' + rtnData.data.endDt.substring(4, 6) + '-' + rtnData.data.endDt.substring(6, 8)
        this.acceptYn = true
      })
    },
    goNegoPop () {
      this.parentInfo.negoYN = 'Y'
      let param = { ...this.parentInfo }
      param.acceptYn = this.acceptYn
      this.$emit('close', param)
    },
    goBooking () {
      if (!this.$ekmtcCommon.isEmpty(this.parentInfo.etaBookingMsg)) {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.parentInfo.etaBookingMsg }) // 해당 국가 ( 한국 ) 수출화물에 대해서만 부킹이 가능합니다.
        return
      }

      if (this.checkCharge === 'Y') {
        this.parentInfo.detailResp2.frtAppNo = this.frtAppNo || ''
        let param = { ...this.parentInfo }
        param.acceptYn = this.acceptYn
        this.$emit('close', param)
        this.$emit('booking', param.detailResp2)
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('msg.VOSD100_M5.063'))
      }
    },
    booking () {
      console.log('######### [freSurcharge2] booking #########')
    },
    calcPromotionDC (cntrType, originAmt, frtCd, cntrTypCd, cgoTypNm) {
      let calcAmt = 0
      let calcDcRate = 0

      // originAmt 숫자가 아닌 경우
      if (originAmt <= 0 || originAmt === 'NaN') {
        return this.$ekmtcCommon.changeNumberFormat(originAmt, { isComma: true })
      }

      if (frtCd === 'O/F' && cntrTypCd === 'GP') {
        // GP and Cargo HZ 가 아니면서 직접할인 이면, 차액계산 저장하여 Cargo HZ 할인시 사용함
        if (cgoTypNm === undefined && cntrType === '20') {
          this.spread20 = originAmt - this.parentInfo.dtDcRate01
        } else if (cgoTypNm === undefined && cntrType === '40') {
          this.spread40 = originAmt - this.parentInfo.dtDcRate02
        }

        // 프로모션의 offset과 직접운임간 우선순위가 결정되지 않아 우선 offset에 우선순위를 두고 계산함.
        // 금액 계산시 %, 정액 할인은 원단위 올림
        // offset 금액 계산 1
        if (cntrType === this.parentInfo.ofCntrSize01) {
          if (this.parentInfo.ofDcType01 === '$') {
            calcAmt = originAmt - this.parentInfo.ofDcRate01
          } else { // % 계산
            calcDcRate = this.parentInfo.ofDcRate01 / 100
            calcAmt = originAmt - Math.floor(calcDcRate * originAmt)
          }
          if (calcAmt <= 0) {
            calcAmt = 1
          }
          return this.$ekmtcCommon.changeNumberFormat(calcAmt, { isComma: true })
        }

        // offset 금액 계산 2
        if (cntrType === this.parentInfo.ofCntrSize02) {
          if (this.parentInfo.ofDcType02 === '$') {
            calcAmt = originAmt - this.parentInfo.ofDcRate02
          } else { // % 계산
            calcDcRate = this.parentInfo.ofDcRate02 / 100
            calcAmt = originAmt - Math.floor(calcDcRate * originAmt)
          }
          if (calcAmt <= 0) {
            calcAmt = 1
          }
          return this.$ekmtcCommon.changeNumberFormat(calcAmt, { isComma: true })
        }

        // 직접운임 금액 계산 1
        if (cntrType === this.parentInfo.dtCntrSize01) {
          if (this.parentInfo.dtDcType01 === '$') {
            if (cgoTypNm === 'HZ') {
              calcAmt = originAmt - this.spread20
            } else {
              calcAmt = this.parentInfo.dtDcRate01
            }
          } else { // % 계산
            calcDcRate = this.parentInfo.dtDcRate01 / 100
            calcAmt = originAmt - Math.floor(calcDcRate * originAmt)
          }
          if (calcAmt <= 0) {
            calcAmt = 1
          }
          return this.$ekmtcCommon.changeNumberFormat(calcAmt, { isComma: true })
        }

        // 직접운임 금액 계산 2
        if (cntrType === this.parentInfo.dtCntrSize02) {
          if (this.parentInfo.dtDcType02 === '$') {
            if (cgoTypNm === 'HZ') {
              calcAmt = originAmt - this.spread40
            } else {
              calcAmt = this.parentInfo.dtDcRate02
            }
          } else { // % 계산
            calcDcRate = this.parentInfo.dtDcRate02 / 100
            calcAmt = originAmt - Math.floor(calcDcRate * originAmt)
          }
          if (calcAmt <= 0) {
            calcAmt = 1
          }
          return this.$ekmtcCommon.changeNumberFormat(calcAmt, { isComma: true })
        }
      }
      return this.$ekmtcCommon.changeNumberFormat(originAmt, { isComma: true })
    },
    closeNmove () {
      this.$emit('close', { acceptYn: this.acceptYn })
      if (this.parentInfo.promotionChk === 'Y') {
        this.$router.push('/main')
      }
    }
  }
}
</script>
<style scoped>
.content_box {
  border: 0px !important;
}
</style>
