<template>
  <div class="position_relative">
    <textarea
      ref="textareaTransAddr"
      :id="id"
      :name="name"
      v-model="text"
      @blur="handleBlur($event)"
      :cols="columnLength"
      :class="{'upper-textarea': isUpper}"
      :style="{ 'padding-right': `${paddingRight === 0 ? 'initial' : `calc(100% - ${paddingRight}px)`}`,
                'height' : `${height === -1 && maxLine === 0 ? '' : (height !== -1 ? `${height}px` : `calc(15.6 * ${maxLine}px)`)}`
      }"
      @input="onInput($event)"
    ></textarea>
    <div class="div_req_byte" v-if="maxByte > 0">
      <span class="color_black font_medium" :id="'byte_' + id">0</span>
      <span class="color_lightgray">/</span>
      <span class="color_lightgray">2,000</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ETextarea2',
  props: {
    id: { type: String, required: true },
    isRequired: { type: Boolean, default: false, required: false },
    messageRequired: { type: String, default: '', required: false },
    maxLine: { type: Number, default: 0, required: false },
    columnLength: { type: Number, default: 0, required: false },
    name: { type: String, default: '', required: false },
    value: { type: String, default: '', required: false },
    maxByte: { type: Number, default: 0, required: false },
    isUpper: { type: Boolean, default: false, required: false },
    paddingRight: { type: Number, default: 0, required: false },
    height: { type: Number, default: -1, required: false } // height값이 -1인 경우 maxLine으로 계산
  },
  data () {
    return {
      elemId: '',
      text: '',
      rtnText: '',
      rtnNumber: 0,
      interval: undefined,
      prevSelectionPosition: 0
    }
  },
  watch: {
    value () {
      if (this.text !== this.$props.value) {
        this.text = this.$props.value
      }
    }
  },
  created () {
    this.text = this.$props.value
  },
  methods: {
    async handleBlur (e) {
      // 교정처리 이제 여기서
      // 18줄 자르기 한글 자르기
      // 텍스트가 25자가 넘어가면 25자로 자름
      // console.log('blur!')
      // console.log(e.target.value)
      let s = ''
      s = e.target.value

      let arr = s.split('\n')
      let _arr = []
      // console.log('@@@@ arr: ', arr)
      // console.log('@@@@ arr.length: ', arr.length)

      const elem = document.querySelector('#' + this.id)
      arr.forEach((value, index) => {
        // if (index > this.maxLine) {
        //   let msg = ''
        //   msg = this.maxLine + this.$t('js.utillmanager.010')
        //   this.$ekmtcCommon.showErrorTooltip(elem, msg)

        //   isOverRow = true // Row 초과여부

        //   return false
        // }

        const maxLine = Math.ceil(value.length / (this.columnLength))
        // console.log('@@@@ maxLine: ', maxLine)
        if (maxLine < 1) {
          _arr.push(value)
        } else {
            for (let i = 0; i < maxLine; i++) {
            _arr.push(value.substring(i * (this.columnLength), (this.columnLength) * (i + 1)))
          }
        }

        // console.log('@@@@ index: ', index)
        // console.log('@@@@ value: ', value)
        // console.log('@@@@ value.length: ', value.length)
      })

      // console.log('@@@@ _arr: ', _arr)
      // 자동 줄바꿈 후 maxLine 체크
      let isOverRow = false // Row 초과여부
      if (_arr.length > this.maxLine) { // MaxLine 체크
        const options = {
          useConfirmBtn: true,
          message: this.$t('js.utillmanager.021', { maxLine: this.maxLine }),
          onConfirmSelf: (resolve, $Simplert) => {
            isOverRow = true
            _arr.splice(this.maxLine) // 줄삭제처리

            resolve(true)
            $Simplert.close()
          }
        }

        if (!await this.$ekmtcCommon.asyncAlertMessage(options)) {
          return
        }
      }

      s = _arr.join('\n').toUpperCase()

      e.target.value = s
      this.text = s
      this.$emit('input', s)

      if (isOverRow) {
        this.$ekmtcCommon.showErrorTooltip(elem, this.maxLine + this.$t('js.utillmanager.010')) // 줄 까지 입력 가능합니다.

        // 3초뒤 Tooltip Clear
        if (this.interval !== undefined) {
          clearInterval(this.interval)
          this.interval = undefined
        }

        this.interval = setInterval(() => {
          this.$ekmtcCommon.hideErrorTooltip(elem)
          clearInterval(this.interval)
          this.interval = undefined
        }, 3000)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
    },
    onInput (e) {
      let s = e.target.value
      this.prevSelectionPosition = this.$refs.textareaTransAddr.selectionStart
      // console.log(this.prevSelectionPosition)
      // console.log(this.$refs.textareaTransAddr.selectionStart)
      // console.log('@@@@ this.$refs.textareaTransAddr.selectionEnd:', this.$refs.textareaTransAddr.selectionEnd)

      const koreanExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g
      const isKor = koreanExp.test(s)

      // console.log('@@@@ isKor: ', isKor)
      const elem = document.querySelector('#' + this.id)
      if (isKor) { // 한글 체크
        s = s.replace(koreanExp, '')

        e.target.value = s
        this.text = s

        this.$refs.textareaTransAddr.setSelectionRange(this.prevSelectionPosition - 1, this.prevSelectionPosition - 1)

        const THIS = this

        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK215')) // 특수문자 또는 한글을 사용할 수 없습니다.

        if (this.interval !== undefined) {
          clearInterval(this.interval)
          this.interval = undefined
        }

        this.interval = setInterval(() => {
          THIS.$ekmtcCommon.hideErrorTooltip(elem)
          clearInterval(this.interval)
          this.interval = undefined
        }, 3000)
      } else if (s.split('\n').length > this.maxLine) { // MaxLine 체크
        s = s.split('\n').slice(0, this.maxLine).join('\n')
        e.target.value = s
        this.text = s

        this.$refs.textareaTransAddr.setSelectionRange(this.prevSelectionPosition - 1, this.prevSelectionPosition - 1)

        this.$ekmtcCommon.showErrorTooltip(elem, this.maxLine + this.$t('js.utillmanager.010')) // 줄 까지 입력 가능합니다.
      }
    },
    check (val, e) {
      const arr = []
      let cnt = 0
      let lineCnt = 0
      let c

      const elem = document.querySelector('#' + this.id)

      if (val === '') {
        lineCnt = 0
      } else if (val.indexOf('\r\n') > -1) {
        const arr = val.split('\r\n')
        console.log('@@@@ arr: ', arr)

        lineCnt = arr.length

        if (this.columnLength > 0) {
          const tempArr = []

          arr.forEach((str) => {
            while (str.length > this.columnLength) {
              tempArr.push(str.substring(0, this.columnLength))
              str = str.substring(this.columnLength)
            }
            tempArr.push(str)
          })

          val = tempArr.join('\r\n')
          lineCnt = tempArr.length
        }
      } else if (val.indexOf('\n') > -1) {
        const arr = val.split('\n')
        console.log('@@@@ arr: ', arr)
        console.log('@@@@ ', this.$refs.textareaTransAddr)
        console.log(this.$refs.textareaTransAddr.selectionStart)

        console.log(this.$refs.textareaTransAddr.selectionEnd)

        lineCnt = arr.length

        if (this.columnLength > 0) {
          const tempArr = []

          // arr.forEach((str, idx) => {
          //   let _str = str
          //   console.log('@@@@ str.length: ', str.length)
          //   while (str.length > this.columnLength) {
          //     tempArr.push(str.substring(0, this.columnLength))

          //     str = str.substring(this.columnLength)
          //     if (arr.length > idx + 1) {
          //       str += arr[idx + 1]
          //     }
          //   }
          //   console.log('@@@@ str: ', str)
          //   console.log('@@@@ _str: ', _str)
          //   // tempArr.push(_str + arr[idx + 1])
          //   tempArr.push(str)
          //   // tempArr.push(arr[idx + 1] + str)
          // })

          arr.forEach((str) => {
            while (str.length > this.columnLength) {
              tempArr.push(str.substring(0, this.columnLength))
              str = str.substring(this.columnLength)
            }
            tempArr.push(str)
          })
          console.log('@@@@ tempArr: ', tempArr)

          val = tempArr.join('\n')
          lineCnt = tempArr.length
        }
      } else {
        lineCnt = 1
        // 한 Line 에 columnLength 초과 될경우 줄바꿈 처리
        if (this.columnLength > 0 && val.length > this.columnLength) {
          const tempArr = []
          while (val.length > this.columnLength) {
            tempArr.push(val.substring(0, this.columnLength))
            val = val.substring(this.columnLength)
          }
          tempArr.push(val)
          val = tempArr.join('\n')
          lineCnt = tempArr.length
        }
      }

      let isOk = true
      let msg = ''

      if (this.isRequired && val === '') {
        isOk = false
        msg = this.messageRequired
      } else if (this.maxLine > 0 && lineCnt > this.maxLine) {
        isOk = false
        // msg = this.$t('js.utillmanager.010', { maxLine: this.maxLine })
        msg = this.maxLine + this.$t('js.utillmanager.010')
      } else if (
        this.maxByte > 0 &&
        !this.$ekmtcCommon.checkTextByte(val, this.maxByte)
      ) {
        isOk = false
        msg = 'Too large text to create S/R.'
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      this.prevSelectionPosition = this.$refs.textareaTransAddr.selectionStart

      if (e !== undefined) {
        e.target.value = val
      }
      this.text = val

      if (lineCnt !== 1 && this.prevSelectionPosition !== this.$refs.textareaTransAddr.selectionStart) {
        this.$refs.textareaTransAddr.selectionStart = this.prevSelectionPosition + 1
        this.$refs.textareaTransAddr.selectionEnd = this.prevSelectionPosition + 1
      }
    },
    onKeyup (e) {
      let lineCount = (e.target.value.match(/\n/g) || []).length + 1
      let lines = e.target.value.split('\n')
      if (lineCount > this.maxLine) {
        const elem = document.querySelector('#' + this.id)
        let msg = ''
        msg = this.maxLine + this.$t('js.utillmanager.010')
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
        // console.log('@@@@ lineCount: ', lineCount)
        // console.log('@@@@ this.maxLine: ', this.maxLine)
        // this.text = this.$props.value

        if (lineCount > this.maxLine) {
          lines = lines.slice(0, this.maxLine - lines.length)
          if (lines.length > this.maxLine) {
            lines = lines.splice(0, this.maxLine - lines.length)
          }
          if (e !== undefined) {
            e.target.value = lines.join('\n')
          }
          this.text = lines.join('\n')

          if (lineCnt !== 1 && this.prevSelectionPosition !== this.$refs.textareaTransAddr.selectionStart) {
            this.$refs.textareaTransAddr.selectionStart = this.prevSelectionPosition + 1
            this.$refs.textareaTransAddr.selectionEnd = this.prevSelectionPosition + 1
          }
        }
      }

      if (this.maxByte === 0) {
        return
      }
      this.$ekmtcCommon.fnKeyupByteCheck(e, this.maxByte)
    }
  }
}
</script>

<style scoped>
.div_req_byte {
  text-align: right;
  margin-top: 2px;
}

.upper-textarea {
  text-transform: uppercase;
}
textarea {
  font-family: 'Courier New';
  /* overflow: hidden; */
  /* width: auto; */
  /* padding-right: calc(100% - 206px); */
  /* padding-top: 2px; */
  padding-bottom: 2px;
  box-sizing: border-box;
  text-align: start;
  writing-mode: horizontal-tb !important;
  /* resize: none; */
  line-height: normal;
}
</style>
