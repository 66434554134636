<template>
  <div class="div_booking_leg">
    <table class="tbl_col tal">
      <colgroup>
        <col style="width:20%">
        <col style="width:32%">
        <col style="width:auto">
        <col style="width:15%">
      </colgroup>
      <thead>
        <tr>
          <th scope="col">{{ $t('msg.CSBK100.014') }}</th>
          <th scope="col">{{ $t('msg.CSBK100.012') }}</th>
          <th scope="col">{{ $t('msg.CSBK100.013') }}</th>
          <th scope="col">{{ $t('msg.CSBK100.155') }}</th>
        </tr>
      </thead>
      <tbody v-for="(vo, idx) in schdLegList" :key="'schdLeg_' + idx">
        <tr v-if="$ekmtcCommon.isNotEmpty(vo.transitTSTime)">
          <td colspan="3" class="text_center">{{ $t('msg.VOSD100_M5.069') }}</td><!-- T/S 대기 -->
          <td class="text_center">{{ vo.transitTSTime }}</td>
        </tr>
        <tr>
          <td rowspan="2">
            <a v-if="vo.vslNm !== 'Rail' && vo.vslNm !== 'Truck'" @click="vesselPop(vo, idx)" :class="urlOrNot ? 'td_underline dsm_info' : 'td_underline dsm_info'">{{ vo.vslNm }} {{ vo.voyNo }}</a>
            <a v-if="vo.vslNm === 'Rail'|| vo.vslNm === 'Truck'" :class="urlOrNot ? 'dsm_info' : 'dsm_info'">{{ vo.vslNm }} {{ vo.voyNo }}</a><br>
            <a v-if="$ekmtcCommon.isNotEmpty(vo.rteCd)" @click="routePop(vo)" :class="urlOrNot ? 'td_underline dsm_info' : 'td_underline dsm_info'">(Route: {{ vo.rteCd }})</a>
          </td>
          <td><a @click="portTerminalCyPop(vo.pol, vo.polTrmlCd)" class="td_underline">{{ vo.polNm }}<span v-if="idx !== 0">(<span class="color_red">T/S</span>)</span></a></td>
          <td><a @click="portTerminalCyPop(vo.pod, vo.podTrmlCd)" class="td_underline">{{ vo.podNm }}<span v-if="idx !== schdLegList.length - 1">(<span class="color_red">T/S</span>)</span></a></td>
          <td rowspan="2" class="text_center">{{ vo.transitTime }}</td>
        </tr>
        <tr>
          <td class="bor-left">
            <ul>
              <li>{{ idx === 0 ? $t('msg.CSBK100.157') : $t('msg.CSBK100.156') }} : {{ vo.polEtbDT }}</li>
              <li v-if="idx === 0">{{ $t('msg.CSBK100.156') }} : {{ vo.etdDT }}</li>
              <li><a @click="portTerminalCyPop(vo.pol, vo.polTrmlCd)" class="td_underline">{{ vo.polTml }}</a></li>
            </ul>
          </td>
          <td>
            <ul>
              <li>{{ $t('msg.CSBK100.157') }} : {{ vo.etaDT }}</li>
              <li><a @click="portTerminalCyPop(vo.pod, vo.podTrmlCd)" class="td_underline">{{ vo.podTml }}</a></li>
              <li
                v-if="idx === schdLegList.length - 1 && saveFlag !== 'PICKUP' && legFixShow"
                class="mt5"
              >
                <input
                  v-model="legTrmlFx"
                  type="checkbox"
                  id="legTrmlFx"
                  true-value="Y"
                  false-value="N"
                  :disabled="disabledYn === 'Y'"
                >
                <label for="legTrmlFx">Terminal Fix</label>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
      <tbody v-if="schdLegList.length === 0">
        <tr>
          <td colspan="4" class="text_center" style="cursor:default;">
            {{ isPickup ? $t('msg.CSBK100.392') : $t('msg.CSBK100.219') }} <!-- 선택된 스케줄이 없습니다. 우측 '스케줄 선택'을 해주세요. -->
          </td>
        </tr>
      </tbody>
    </table>
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePopup"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import schedule from '@/api/rest/schedule/schedule'

export default {
  name: 'BookingScheduleInfo',
  components: {
    'vessel-schedule-info-pop': () => import('@/pages/schedule/popup/VesselScheduleInfoPop'),
    'route-pop': () => import('@/pages/schedule/popup/RoutePop'),
    'port-terminal-cy-pop': () => import('@/pages/schedule/popup/PortTerminalCYPop'),
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop')
  },
  props: {
    legTrmlFixdYn: {
      type: String,
      default: 'N'
    },
    bkgNo: {
      type: String,
      default: ''
    },
    saveFlag: {
      type: String,
      default: ''
    },
    disabledYn: {
      type: String,
      default: 'N'
    },
    legFixShow: {
      type: Boolean,
      default: false
    },
    exceptWatchBkgNo: {
      type: Boolean,
      default: false
    },
    searchInPickUpInfo: {
      type: Boolean,
      default: false
    },
    isPickup: {
      type: Boolean,
      default: false
    },
    isUrl: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      schdLegList: [], // 스케줄 leg 리스트
      oldBkgNo: '',
      legTrmlFx: 'N',
      oldLegFixShow: false,
      popParams: {},
      customComponent: null,
      lang: 'KOR',
      urlOrNot: false // JamSin DSM
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    searchInPickUpInfo () {
      if (this.$props.searchInPickUpInfo) {
        this.initLegInfo(this.bkgNo)
      }
    },
    bkgNo () {
      if (!this.$props.exceptWatchBkgNo && this.bkgNo !== this.oldBkgNo) {
        this.initLegInfo(this.bkgNo)
      }
    },
    legTrmlFixdYn () {
      if (this.legTrmlFx !== this.legTrmlFixdYn) {
        this.legTrmlFx = this.legTrmlFixdYn
      }
    },
    legFixShow () {}
  },
  created () {
    this.lang = this.$ekmtcCommon.isNotEmpty(this.auth.serviceLang) ? this.auth.serviceLang : 'KOR'
    this.urlOrNot = this.$ekmtcCommon.isNotEmpty(this.$props.isUrl) ? this.$props.isUrl : false // JamSin DSM Booking에서 스케줄 정보 url구분 체크
    if (this.$ekmtcCommon.isNotEmpty(this.bkgNo)) {
      this.initLegInfo(this.bkgNo)
    }
  },
  methods: {
    setScheduleLegInfo (obj) {
      const newLegList = []
      const newObject = {
        vslNm: obj.vslNm1,
        vslCd: obj.vslCd,
        voyNo: obj.voyNo,
        rteCd: obj.rteCd,
        polNm: obj.polNm,
        podNm: obj.pod1Nm,
        transitTime: obj.transitTime1,
        transitTSTime: '',
        polEtbDT: obj.polEtbDT,
        etdDT: obj.etdDT,
        polTml: obj.polTml,
        polTrmlCd: obj.polTmlCd,
        etaDT: obj.etaDT,
        podTml: obj.podTml,
        podTrmlCd: obj.podTmlCd,
        pol: this.$ekmtcCommon.isEmpty(obj.pol1) ? obj.pol : obj.pol1,
        pod: this.$ekmtcCommon.isEmpty(obj.pod1) ? obj.pod : obj.pod1
      }

      newLegList.push(newObject)

      if (this.$ekmtcCommon.isNotEmpty(obj.polEtbDT2)) {
        const vo = {
          vslNm: obj.vslNm2,
          vslCd: obj.vslCd2,
          voyNo: obj.voyNo2,
          rteCd: obj.rteCd2,
          polNm: obj.pod1Nm,
          podNm: obj.pod2Nm,
          polEtbDT: obj.polEtbDT2,
          polTml: obj.polTml2,
          polTrmlCd: obj.polTmlCd2,
          etaDT: obj.etaDT2,
          podTml: obj.podTml2,
          podTrmlCd: obj.podTmlCd2,
          transitTime: obj.transitTime2,
          transitTSTime: obj.transitTSTime2,
          pol: obj.pol2,
          pod: obj.pod2
        }

        newLegList.push(vo)
      }

      if (this.$ekmtcCommon.isNotEmpty(obj.polEtbDT3)) {
        const vo = {
          vslNm: obj.vslNm3,
          vslCd: obj.vslCd3,
          voyNo: obj.voyNo3,
          rteCd: obj.rteCd3,
          polNm: obj.pod2Nm,
          podNm: obj.pod3Nm,
          polEtbDT: obj.polEtbDT3,
          polTml: obj.polTml3,
          polTrmlCd: obj.polTmlCd3,
          etaDT: obj.etaDT3,
          podTml: obj.podTml3,
          podTrmlCd: obj.podTmlCd3,
          transitTime: obj.transitTime3,
          transitTSTime: obj.transitTSTime3,
          pol: obj.pol3,
          pod: obj.pod3
        }

        newLegList.push(vo)
      }

      if (this.$ekmtcCommon.isNotEmpty(obj.polEtbDT4)) {
        const vo = {
          vslNm: obj.vslNm4,
          vslCd: obj.vslCd4,
          voyNo: obj.voyNo4,
          rteCd: obj.rteCd4,
          polNm: obj.pod3Nm,
          podNm: obj.pod4Nm,
          polEtbDT: obj.polEtbDT4,
          polTml: obj.polTml4,
          polTrmlCd: obj.polTmlCd4,
          etaDT: obj.etaDT4,
          podTml: obj.podTml4,
          podTrmlCd: obj.podTmlCd4,
          transitTime: obj.transitTime4,
          transitTSTime: obj.transitTSTime4,
          pol: obj.pol4,
          pod: obj.pod4
        }

        newLegList.push(vo)
      }

      this.$set(this, 'schdLegList', newLegList)
    },
    initLegInfo (bkgNo) {
      schedule.getLegDetail(bkgNo).then(res => {
        const list = res.data
        const len = list !== undefined ? list.length : 0
        if (len > 0) {
          list.forEach((c, idx) => {
            if (this.$ekmtcCommon.isEmpty(c.etd)) {
              c.etd = ''
            }

            if (this.$ekmtcCommon.isEmpty(c.eta)) {
              c.eta = ''
            }

            if (this.$ekmtcCommon.isEmpty(c.polEtbDT)) {
              c.polEtbDT = ''
            }

            const etdDT = this.lang === 'KOR' ? this.$ekmtcCommon.changeDatePattern(c.etd, '.') : this.$ekmtcCommon.changeDatePatternEng(c.etd, '.')
            const etaDT = this.lang === 'KOR' ? this.$ekmtcCommon.changeDatePattern(c.eta, '.') : this.$ekmtcCommon.changeDatePatternEng(c.eta, '.')

            if (idx !== 0) {
              c.polEtbDT = etdDT
            } else {
              c.polEtbDT = this.lang === 'KOR' ? this.$ekmtcCommon.changeDatePattern(c.tmpPolEtb, '.') : this.$ekmtcCommon.changeDatePatternEng(c.tmpPolEtb, '.')
            }

            c.etaDT = etaDT
            c.etdDT = etdDT

            if (this.$ekmtcCommon.isEmpty(c.transitTSTime)) {
              c.transitTSTime = ''
            }
          })

          this.$set(this, 'schdLegList', list)
        } else {
          this.$set(this, 'schdLegList', [])
        }
      })

      this.oldBkgNo = bkgNo
    },
    vesselPop (vo, idx) {
      this.popParams = {
        vslNm: vo.vslNm,
        vslCd: vo.vslCd,
        voyNo: vo.voyNo,
        polPortCd: vo.pol,
        podPortCd: vo.pod,
        polTrmlCd: vo.polTrmlCd,
        menuCd: '01',
        vesselSeq: Number(idx + 1),
        bound: 'O'
      }

      this.openPopup('vessel-schedule-info-pop')
    },
    routePop (vo) {
      this.popParams = {
        rteCd: vo.rteCd,
        vesselNm: vo.vslNm
      }

      this.openPopup('route-pop')
    },
    portTerminalCyPop (portCd, trmlCd) {
      this.popParams = {
        asPortCd: portCd,
        asCyCd: trmlCd,
        asSvcLang: '01'
      }

      this.openPopup('port-terminal-cy-pop')
    },
    openPopup (compNm) {
      this.customComponent = compNm

      this.$ekmtcCommon.layerOpen('.div_booking_leg > .popup_dim')
    },
    closePopup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.div_booking_leg > .popup_dim')
    }
  }
}
</script>

<style scoped>
  .td_underline {text-decoration: underline; color: inherit; cursor: pointer;}
</style>
