var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "working_detail_area" },
    [
      _c("div", { staticClass: "flex_box" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(" " + _vm._s(_vm.$t("menu.MENU03.000")) + " "),
        ]),
        _c("span", { staticClass: "ml_auto mt20" }, [
          _c(
            "a",
            { staticClass: "button md print", on: { click: _vm.screenPrint } },
            [
              _c("span", { staticClass: "btn_icon print" }),
              _vm._v(_vm._s(_vm.$t("msg.CSBK100.243"))),
            ]
          ),
        ]),
      ]),
      _c(
        "e-tab",
        {
          ref: "tab",
          attrs: { "page-type": "DETAIL" },
          on: { tabName: _vm.getTabName },
        },
        [
          _c(
            "e-tab-item",
            {
              attrs: {
                title: "Booking",
                name: "booking-edit",
                path: "/working/detail",
                selected: true,
                disabled: false,
              },
            },
            [
              _vm.tab === "booking-edit" && _vm.bookingType === "S"
                ? _c("booking-new-sr", {
                    ref: "bkg",
                    attrs: {
                      "detail-bkg-no": _vm.detailBkgNo,
                      "detail-sr-rno": _vm.detailSrRno,
                    },
                  })
                : _vm._e(),
              _vm.tab === "booking-edit" && _vm.bookingType === "B"
                ? _c("booking-new", {
                    ref: "bkg",
                    attrs: {
                      "detail-bkg-no": _vm.detailBkgNo,
                      "sr-cncl-yn": _vm.srCnclYn,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "e-tab-item",
            {
              attrs: {
                title: "B/L",
                name: "bl",
                path: "/working/detail",
                disabled: _vm.tabDisabled,
              },
            },
            [
              _vm.tab === "bl"
                ? _c("working-bl-main", {
                    ref: "bl",
                    attrs: {
                      "detail-bkg-no": _vm.detailBkgNo,
                      "detail-sr-rno": _vm.detailSrRno,
                      "detail-new-sr-rno": _vm.detailNewSrRno,
                      "detail-bl-no": _vm.detailBlNo,
                      "detail-arr-bkg-no": _vm.detailArrBkgNo,
                      "sr-cncl-yn": _vm.srCnclYn,
                    },
                    on: { after: _vm.fnAddNewSr },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "e-tab-item",
            {
              attrs: {
                title: "Freight",
                name: "freight",
                path: "/working/detail",
                disabled: _vm.checkFreghit(),
              },
            },
            [
              _vm.tab === "freight"
                ? _c("freight-bl", {
                    ref: "freight",
                    attrs: {
                      "parent-data": _vm.freightReqData,
                      "detail-bkg-no": _vm.detailBkgNo,
                      "detail-sr-rno": _vm.detailSrRno,
                      "detail-bl-no": _vm.detailBlNo,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "e-tab-item",
            {
              attrs: {
                title: "Free Time",
                name: "free-time",
                path: "/working/detail",
                disabled: _vm.tabDisabled,
              },
            },
            [
              _vm.tab === "free-time"
                ? _c("freetime-request", {
                    ref: "workingFreetimeReq",
                    attrs: { "parent-data": _vm.freetimeReqData },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "e-tab-item",
            {
              attrs: {
                title: "Certificate",
                name: "bl-certificate",
                path: "/working/detail",
                disabled: _vm.blDisabled,
              },
            },
            [
              _vm.tab === "bl-certificate"
                ? _c("bl-certificate", {
                    ref: "blCertificate",
                    attrs: {
                      "detail-bkg-no": _vm.detailBkgNo,
                      "bl-no": _vm.detailBlNo || _vm.detailSrRno,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popParams },
                on: { close: _vm.closePopup, callback: _vm.selectFunc },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { attrs: { id: "pdf_area" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }