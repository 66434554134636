import { render, staticRenderFns } from "./BookingNewSr.vue?vue&type=template&id=244e9671&scoped=true"
import script from "./BookingNewSr.vue?vue&type=script&lang=js"
export * from "./BookingNewSr.vue?vue&type=script&lang=js"
import style0 from "./BookingNewSr.vue?vue&type=style&index=0&id=244e9671&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "244e9671",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/kmtcadmin/myagent/_work/15/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('244e9671')) {
      api.createRecord('244e9671', component.options)
    } else {
      api.reload('244e9671', component.options)
    }
    module.hot.accept("./BookingNewSr.vue?vue&type=template&id=244e9671&scoped=true", function () {
      api.rerender('244e9671', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/trans/BookingNewSr.vue"
export default component.exports