var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "900px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" 닫기 ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONIM070P020.001"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col mt10" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.002")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.003")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.004")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.005")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.006")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.007")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.008")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.009")))]),
              ]),
            ]),
            _vm.items.length > 0
              ? _c(
                  "tbody",
                  _vm._l(_vm.items, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.checkedCy,
                              expression: "checkedCy",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "checkedCy",
                            id: "checkedCy_" + index,
                          },
                          domProps: {
                            value: index,
                            checked: _vm._q(_vm.checkedCy, index),
                          },
                          on: {
                            change: function ($event) {
                              _vm.checkedCy = index
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "checkedCy_" + index } }),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.cyCd))]),
                      _c("td", { staticClass: "text_left" }, [
                        _vm._v(_vm._s(item.cyEnm)),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(item.cyCd === "KBGK" ? "장승훈" : item.picEnm)
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            item.cyCd === "KBGK"
                              ? "031-389-6210,\n 031-389-6209"
                              : item.telNo
                          )
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.plcNm))]),
                      _c(
                        "td",
                        { style: { color: item.restYn > 0 ? "red" : "" } },
                        [
                          _vm._v(
                            _vm._s(
                              item.restYn > 0
                                ? _vm.$t("msg.ONIM070P020.010")
                                : _vm.$t("msg.ONIM070P020.011")
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            item.restYn > 0 ? _vm.$t("msg.ONIM070P020.012") : ""
                          )
                        ),
                      ]),
                    ])
                  }),
                  0
                )
              : _c("tbody", [_vm._m(1)]),
          ]),
          _c("div", { staticClass: "mt10 text_center" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg mr3",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.returnChangeRequest.apply(null, arguments)
                  },
                },
              },
              [_vm._v("OK")]
            ),
            _c(
              "a",
              {
                staticClass: "button gray lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("Close")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "5%" } }),
      _c("col", { staticStyle: { width: "11%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "8" } }, [_vm._v("No Data")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }