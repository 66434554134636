var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("menu.MENU02.000"))),
      ]),
      _c(
        "e-tab",
        { ref: "tab" },
        [
          _c(
            "e-tab-item",
            {
              attrs: {
                title: _vm.$t("menu.MENU02.010"),
                name: "leg",
                path: "/schedule",
                selected: true,
              },
            },
            [_c("schedule-leg")],
            1
          ),
          _c(
            "e-tab-item",
            {
              attrs: {
                title: _vm.$t("menu.MENU02.020"),
                name: "vessel",
                path: "/schedule",
              },
            },
            [_c("schedule-vessel")],
            1
          ),
          _c(
            "e-tab-item",
            {
              attrs: {
                title: _vm.$t("menu.MENU02.030"),
                name: "long-term",
                path: "/schedule",
              },
            },
            [_c("schedule-long-term")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }