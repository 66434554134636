var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "800px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.closeNmove()
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont capture_area" }, [
        _c(
          "h1",
          { staticClass: "page_title", staticStyle: { display: "inline" } },
          [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.005")))]
        ),
        _vm._v("  "),
        _c(
          "div",
          {
            staticClass: "button sm green",
            staticStyle: { position: "absolute", top: "2px" },
          },
          [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.038")))]
        ),
        _c("div", { staticClass: "content_box capture_sub_area" }, [
          _c("p", { staticClass: "txt_desc none" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("msg.VOSD100.002")) +
                " : " +
                _vm._s(_vm.polNm) +
                " ~ " +
                _vm._s(_vm.$t("msg.VOSD100.003")) +
                " : " +
                _vm._s(_vm.podNm)
            ),
            _c("br"),
            _vm._v(
              " " +
                _vm._s(_vm.$t("msg.VOSD100_M5.006")) +
                " : " +
                _vm._s(_vm.parentInfo.rteCd) +
                " / " +
                _vm._s(_vm.parentInfo.vslNm) +
                " / " +
                _vm._s(_vm.parentInfo.voyNo)
            ),
            _c("br"),
          ]),
          _c("table", { staticClass: "tbl_search mt10" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", { staticClass: "pl0" }, [
                  _vm._v(" " + _vm._s(_vm.$t("msg.VOSD100_M5.007"))),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      staticClass: "wid150",
                      attrs: { name: "", id: "", disabled: "true" },
                      on: { change: _vm.changeCntnTypeV2 },
                    },
                    _vm._l(_vm.cntrTypList, function (vo) {
                      return _c(
                        "option",
                        {
                          key: "sel_" + vo.reqRno,
                          domProps: { value: vo.reqRno },
                        },
                        [_vm._v(_vm._s(vo.asCntrTypCd))]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "table",
            { staticClass: "tbl_col mt10" },
            [
              _vm._m(1),
              _vm._m(2),
              _vm._l(_vm.surChargeList2Show, function (row, idx) {
                return _c("tr", { key: idx }, [
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd === "O/F" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                      staticClass: "text_left",
                    },
                    [_vm._v(_vm._s(row.frtCdNm))]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd === "O/F" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [_vm._v(_vm._s(row.curCd))]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd === "O/F" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        _vm._s(
                          row.cntrTypCd === "RF" ? "Reefer" : row.cntrTypCd
                        )
                      ),
                    ]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd === "O/F" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [_vm._v(_vm._s(row.cgoTypNm))]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd === "O/F" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [
                      row.rate20 !== "" &&
                      row.rate20 !== "0" &&
                      _vm.frtAppNo === "" &&
                      _vm.of20Rate !== "" &&
                      _vm.of20Rate !== undefined
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$ekmtcCommon.changeNumberFormat(
                                    parseInt(row.rate20) +
                                      parseInt(_vm.of20Rate),
                                    { isComma: true }
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : row.rate20 !== "" &&
                          row.rate20 !== "0" &&
                          _vm.of20Rate !== "" &&
                          _vm.of20Rate !== undefined
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$ekmtcCommon.changeNumberFormat(
                                    parseInt(row.rate20) +
                                      parseInt(_vm.of20Rate),
                                    { isComma: true }
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rate20,
                                  { isComma: true }
                                )
                              )
                            ),
                          ]),
                    ]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd === "O/F" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [
                      row.rate40 !== "" &&
                      row.rate40 !== "0" &&
                      _vm.frtAppNo === "" &&
                      _vm.of40Rate !== "" &&
                      _vm.of40Rate !== undefined
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$ekmtcCommon.changeNumberFormat(
                                    parseInt(row.rate40) +
                                      parseInt(_vm.of40Rate),
                                    { isComma: true }
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : row.rate40 !== "" &&
                          row.rate40 !== "0" &&
                          _vm.of40Rate !== "" &&
                          _vm.of40Rate !== undefined
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$ekmtcCommon.changeNumberFormat(
                                    parseInt(row.rate40) +
                                      parseInt(_vm.of40Rate),
                                    { isComma: true }
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rate40,
                                  { isComma: true }
                                )
                              )
                            ),
                          ]),
                    ]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd === "O/F" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [
                      row.rateHc !== "" &&
                      row.rateHc !== "0" &&
                      _vm.frtAppNo === "" &&
                      _vm.of40Rate !== "" &&
                      _vm.of40Rate !== undefined
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$ekmtcCommon.changeNumberFormat(
                                    parseInt(row.rateHc) +
                                      parseInt(_vm.of40Rate),
                                    { isComma: true }
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : row.rateHc !== "" &&
                          row.rateHc !== "0" &&
                          _vm.of40Rate !== "" &&
                          _vm.of40Rate !== undefined
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$ekmtcCommon.changeNumberFormat(
                                    parseInt(row.rateHc) +
                                      parseInt(_vm.of40Rate),
                                    { isComma: true }
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rateHc,
                                  { isComma: true }
                                )
                              )
                            ),
                          ]),
                    ]
                  ),
                ])
              }),
            ],
            2
          ),
          _c("table", { staticClass: "tbl_col mt10" }, [
            _vm._m(3),
            _vm._m(4),
            _c(
              "tbody",
              _vm._l(_vm.surChargeList2Show, function (row, idx) {
                return _c("tr", { key: idx }, [
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd !== "O/F" &&
                            row.frtCd !== "DCF" &&
                            row.frtCd !== "SRD" &&
                            row.frtCd !== "AFS" &&
                            row.frtPncCd === "P" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                      staticClass: "text_left",
                    },
                    [_vm._v(_vm._s(row.frtCdNm))]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd !== "O/F" &&
                            row.frtCd !== "DCF" &&
                            row.frtCd !== "SRD" &&
                            row.frtCd !== "AFS" &&
                            row.frtPncCd === "P" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [_vm._v(_vm._s(row.curCd))]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd !== "O/F" &&
                            row.frtCd !== "DCF" &&
                            row.frtCd !== "SRD" &&
                            row.frtCd !== "AFS" &&
                            row.frtPncCd === "P" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        _vm._s(
                          row.cntrTypCd === "RF" ? "Reefer" : row.cntrTypCd
                        )
                      ),
                    ]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd !== "O/F" &&
                            row.frtCd !== "DCF" &&
                            row.frtCd !== "SRD" &&
                            row.frtCd !== "AFS" &&
                            row.frtPncCd === "P" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [_vm._v(_vm._s(row.cgoTypNm))]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd !== "O/F" &&
                            row.frtCd !== "DCF" &&
                            row.frtCd !== "SRD" &&
                            row.frtCd !== "AFS" &&
                            row.frtPncCd === "P" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [
                      row.waive20 === "Y"
                        ? _c("div", { staticStyle: { color: "red" } }, [
                            _c("del", [
                              _vm._v(
                                _vm._s(
                                  _vm.$ekmtcCommon.changeNumberFormat(
                                    row.rate20,
                                    { isComma: true }
                                  )
                                )
                              ),
                            ]),
                          ])
                        : row.waive20 === "N"
                        ? _c("div", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rate20,
                                  { isComma: true }
                                )
                              )
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rate20,
                                  { isComma: true }
                                )
                              )
                            ),
                          ]),
                    ]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd !== "O/F" &&
                            row.frtCd !== "DCF" &&
                            row.frtCd !== "SRD" &&
                            row.frtCd !== "AFS" &&
                            row.frtPncCd === "P" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [
                      row.waive40 === "Y"
                        ? _c("div", { staticStyle: { color: "red" } }, [
                            _c("del", [
                              _vm._v(
                                _vm._s(
                                  _vm.$ekmtcCommon.changeNumberFormat(
                                    row.rate40,
                                    { isComma: true }
                                  )
                                )
                              ),
                            ]),
                          ])
                        : row.waive40 === "N"
                        ? _c("div", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rate40,
                                  { isComma: true }
                                )
                              )
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rate40,
                                  { isComma: true }
                                )
                              )
                            ),
                          ]),
                    ]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd !== "O/F" &&
                            row.frtCd !== "DCF" &&
                            row.frtCd !== "SRD" &&
                            row.frtCd !== "AFS" &&
                            row.frtPncCd === "P" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [
                      row.waiveHc === "Y"
                        ? _c("div", { staticStyle: { color: "red" } }, [
                            _c("del", [
                              _vm._v(
                                _vm._s(
                                  _vm.$ekmtcCommon.changeNumberFormat(
                                    row.rateHc,
                                    { isComma: true }
                                  )
                                )
                              ),
                            ]),
                          ])
                        : row.waiveHc === "N"
                        ? _c("div", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rateHc,
                                  { isComma: true }
                                )
                              )
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rateHc,
                                  { isComma: true }
                                )
                              )
                            ),
                          ]),
                    ]
                  ),
                ])
              }),
              0
            ),
          ]),
          _c("table", { staticClass: "tbl_col mt10" }, [
            _vm._m(5),
            _vm._m(6),
            _c(
              "tbody",
              _vm._l(_vm.surChargeList2Show, function (row, idx) {
                return _c("tr", { key: idx }, [
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd !== "O/F" &&
                            row.frtCd !== "DCF" &&
                            row.frtPncCd === "C" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                      staticClass: "text_left",
                    },
                    [_vm._v(_vm._s(row.frtCdNm))]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd !== "O/F" &&
                            row.frtCd !== "DCF" &&
                            row.frtPncCd === "C" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [_vm._v(_vm._s(row.curCd))]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd !== "O/F" &&
                            row.frtCd !== "DCF" &&
                            row.frtPncCd === "C" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        _vm._s(
                          row.cntrTypCd === "RF" ? "Reefer" : row.cntrTypCd
                        )
                      ),
                    ]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd !== "O/F" &&
                            row.frtCd !== "DCF" &&
                            row.frtPncCd === "C" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [_vm._v(_vm._s(row.cgoTypNm))]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd !== "O/F" &&
                            row.frtCd !== "DCF" &&
                            row.frtPncCd === "C" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [
                      row.waive20 === "Y"
                        ? _c("div", { staticStyle: { color: "red" } }, [
                            _c("del", [
                              _vm._v(
                                _vm._s(
                                  _vm.$ekmtcCommon.changeNumberFormat(
                                    row.rate20,
                                    { isComma: true }
                                  )
                                )
                              ),
                            ]),
                          ])
                        : row.waive20 === "N"
                        ? _c("div", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rate20,
                                  { isComma: true }
                                )
                              )
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rate20,
                                  { isComma: true }
                                )
                              )
                            ),
                          ]),
                    ]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd !== "O/F" &&
                            row.frtCd !== "DCF" &&
                            row.frtPncCd === "C" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [
                      row.waive40 === "Y"
                        ? _c("div", { staticStyle: { color: "red" } }, [
                            _c("del", [
                              _vm._v(
                                _vm._s(
                                  _vm.$ekmtcCommon.changeNumberFormat(
                                    row.rate40,
                                    { isComma: true }
                                  )
                                )
                              ),
                            ]),
                          ])
                        : row.waive40 === "N"
                        ? _c("div", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rate40,
                                  { isComma: true }
                                )
                              )
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rate40,
                                  { isComma: true }
                                )
                              )
                            ),
                          ]),
                    ]
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd !== "O/F" &&
                            row.frtCd !== "DCF" &&
                            row.frtPncCd === "C" &&
                            row.cntrTypCd !== "" &&
                            row.cntrTypCd !== "RH" &&
                            row.cntrTypCd !== "HC" &&
                            row.cgoTypCd !== "01",
                          expression:
                            "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC' && row.cgoTypCd !== '01'",
                        },
                      ],
                    },
                    [
                      row.waiveHc === "Y"
                        ? _c("div", { staticStyle: { color: "red" } }, [
                            _c("del", [
                              _vm._v(
                                _vm._s(
                                  _vm.$ekmtcCommon.changeNumberFormat(
                                    row.rateHc,
                                    { isComma: true }
                                  )
                                )
                              ),
                            ]),
                          ])
                        : row.waiveHc === "N"
                        ? _c("div", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rateHc,
                                  { isComma: true }
                                )
                              )
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeNumberFormat(
                                  row.rateHc,
                                  { isComma: true }
                                )
                              )
                            ),
                          ]),
                    ]
                  ),
                ])
              }),
              0
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.surChargeNoteList.length !== 0,
                  expression: "surChargeNoteList.length !== 0",
                },
              ],
            },
            [
              _vm._m(7),
              _vm._l(_vm.surChargeNoteList, function (row, idx) {
                return _c("div", { key: idx }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            row.frtCd === "DCF" ||
                            row.frtCd === "SRD" ||
                            row.frtCd === "AFS",
                          expression:
                            "row.frtCd === 'DCF' || row.frtCd === 'SRD' || row.frtCd === 'AFS'",
                        },
                      ],
                    },
                    [
                      _c("p", { staticClass: "txt_desc2" }, [
                        _vm._v(
                          _vm._s(idx + 1) +
                            ". Subject to " +
                            _vm._s(row.frtCdNm) +
                            " : " +
                            _vm._s(row.curCd) +
                            " " +
                            _vm._s(
                              _vm.$ekmtcCommon.changeNumberFormat(row.blRate, {
                                isComma: true,
                              })
                            ) +
                            " per Bill of Lading."
                        ),
                        _c("br"),
                      ]),
                    ]
                  ),
                  idx === _vm.surChargeNoteList.length - 1
                    ? _c("div", [
                        _c("p", { staticClass: "txt_desc2" }, [
                          _vm._v(
                            _vm._s(idx + 2) +
                              ". Subject to SPECIAL DG HANDLING SURCHARGE : USD 600/20', USD 700/40' per container (※For only DG CLASS 2 by PUSAN T/S)."
                          ),
                          _c("br"),
                        ]),
                      ])
                    : _vm._e(),
                ])
              }),
            ],
            2
          ),
          _c("table", { staticClass: "tbl_col mt10" }, [
            _vm._m(8),
            _vm._m(9),
            _c(
              "tbody",
              _vm._l(_vm.rateFreeDdsList, function (row, idx) {
                return _c("tr", { key: idx }, [
                  _c("td", { staticClass: "text_left" }, [
                    _vm._v(_vm._s(row.demDetCatNm)),
                  ]),
                  _c("td", [_vm._v(_vm._s(row.ldGpFreeDds))]),
                  _c("td", [_vm._v(_vm._s(row.ldHcFreeDds))]),
                  _c("td", [_vm._v(_vm._s(row.disGpFreeDds))]),
                  _c("td", [_vm._v(_vm._s(row.disHcFreeDds))]),
                ])
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c("span", [
            _c(
              "a",
              {
                staticClass: "button gray lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.closeNmove()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.VOSD100.042")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "80px" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "28%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Freight Charges")]),
        _c("th", [_vm._v("Currency")]),
        _c("th", [_vm._v("Type")]),
        _c("th", [_vm._v("Cargo")]),
        _c("th", [_vm._v("20'")]),
        _c("th", [_vm._v("40'")]),
        _c("th", [_vm._v("HC")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "28%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Export Charges")]),
        _c("th", [_vm._v("Currency")]),
        _c("th", [_vm._v("Type")]),
        _c("th", [_vm._v("Cargo")]),
        _c("th", [_vm._v("20'")]),
        _c("th", [_vm._v("40'")]),
        _c("th", [_vm._v("HC")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "28%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Import Charges")]),
        _c("th", [_vm._v("Currency")]),
        _c("th", [_vm._v("Type")]),
        _c("th", [_vm._v("Cargo")]),
        _c("th", [_vm._v("20'")]),
        _c("th", [_vm._v("40'")]),
        _c("th", [_vm._v("HC")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "txt_desc asterisk" }, [
      _vm._v("Notes"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { rowspan: "2" } }, [_vm._v("KIND")]),
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("Loading")]),
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("Discharging")]),
      ]),
      _c("tr", [
        _c("th", { staticStyle: { "border-left": "1px solid #e1e3eb" } }, [
          _vm._v("GP"),
        ]),
        _c("th", [_vm._v("HC")]),
        _c("th", [_vm._v("GP")]),
        _c("th", [_vm._v("HC")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }